import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IxupHttpCustomParams } from '../../http/http-interceptor-params';
import { IAppState } from '../../state/app.interfaces';
import { IxupBaseService } from '../ixup-base.service';

@Injectable()
export class HubConnectionService extends IxupBaseService {
  constructor(private http: HttpClient, private store: Store<IAppState>) {
    super();
  }

  public hubConnect() {
    return this.http.post('/hubadmin/connect', null, {
      params: new IxupHttpCustomParams(true),
    });
  }

  public hubConnectWithId(collaborationId: number) {
    return this.http.post(`/hubadmin/${collaborationId}/connect`, null, {
      params: new IxupHttpCustomParams(true),
    });
  }

  public hubStatus(collaborationId: number) {
    return this.http.post(`/hub/${collaborationId}/status`, null, {
      params: new IxupHttpCustomParams(true),
    });
  }

  // public checkHubStatus(collaborationId: number) {
  //   this.store.dispatch(new CheckHubStatus({ collaborationId }));
  // }
}
