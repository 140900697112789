<form
  [formGroup]="modelForm"
  novalidate
>
  <h1 mat-dialog-title>
    <mat-icon *ngIf="!!options.icon">{{ options.icon }}</mat-icon>
    {{ options.title | translate }}
  </h1>
  <mat-dialog-content>
    <p>{{ options.message | translate }}</p>
    <div class="form-group push-bottom">
      <mat-form-field class="block">
        <input
          matInput
          type="password"
          placeholder="Collaborator passphrase"
          formControlName="password"
          [type]="hide ? 'password' : 'text'"
        />
        <mat-icon
          matSuffix
          (click)="hide = !hide"
        >
          {{ hide ? 'visibility' : 'visibility_off' }}
        </mat-icon>
        <mat-hint align="start">
          {{
          'The passphrase is likely to be different to your user password'
          | translate
          }}
        </mat-hint>
        <mat-error *ngIf="modelForm.get('password').hasError('required')">
          {{ 'The collaborator passphrase is required...' | translate }}
        </mat-error>
        <mat-error *ngIf="modelForm.get('password').hasError('passwordIncorrect')">
          {{ 'The collaborator passphrase is incorrect...' | translate }}
        </mat-error>

        <div
          *ngIf="passwordIncorrect"
          style="color: red"
        >
          {{ 'Incorrect passphrase...' | translate }}
        </div>
      </mat-form-field>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button
      mat-button
      *ngIf="!!options.canceltext"
      (click)="cancel()"
      class="sm-block"
    >
      {{ options.canceltext | translate }}
    </button>
    <button
      mat-raised-button
      cdkFocusInitial
      (click)="confirm()"
      [color]="options.okcolor || 'warn'"
      class="sm-block"
    >
      {{ options.oktext | translate }}
    </button>
  </mat-dialog-actions>
</form>