<mat-toolbar
  color="primary"
  class="mat-elevation-z1"
>
  <div>Smart Contract signing</div>
  <div class="flex flex-1 full-width justify-end place-content-end">
    <button
      mat-button
      mat-dialog-close
      type="button"
      (click)="onClose()"
      class="qa-pending-approval-sign-close text-sm btn-rounded"
    >
      Close
      <mat-icon>cancel</mat-icon>
    </button>
  </div>
</mat-toolbar>
<mat-dialog-content class="ixup-dialog-sign">
  <iframe
    #docusignHtml
    [src]="url"
    style="width: 100%; height: 80vh; vertical-align: middle; border: 0"
  ></iframe>
</mat-dialog-content>
