import { InjectionToken, NgModule } from '@angular/core';

import {
  IPublicClientApplication,
  PublicClientApplication,
} from '@azure/msal-browser';
import { environment } from '../environments/environment';

export const FILE_DROP_MAX_FILE_SIZE_BYTES = new InjectionToken(
  'FILE_DROP_MAX_FILE_SIZE_BYTES',
);
export const FILE_DROP_ALLOWED_EXTENSIONS = new InjectionToken(
  'FILE_DROP_ALLOWED_EXTENSIONS',
);
export const CLIENT_API_ENDPOINT = new InjectionToken('CLIENT_API_ENDPOINT');

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: environment,
  });
}

@NgModule(/* TODO(standalone-migration): clean up removed NgModule class manually.
{
    declarations: [AppComponent],
    imports: [
    JwtModule.forRoot({
        config: {
            tokenGetter: () => '',
        },
    }),
    MsalModule,
    BrowserModule,
    RouterModule.forRoot(APP_ROUTES, {
        preloadingStrategy: CustomPreloadingStrategy,
    }),
    HttpClientModule,
    BrowserAnimationsModule,
    LoginModule,
    MatIconModule,
    MatCardModule,
    MatInputModule,
    MatMenuModule,
    MatButtonModule,
    NotFoundModule,
    MatSidenavModule,
    MatFormFieldModule,
    OverlayModule,
    PipeModule,
    MatDialogModule,
    MatSnackBarModule,
    MatListModule,
    MatTooltipModule,
    MatToolbarModule,
    InactiveAccountModule,
    NoRoleAccountModule,
    FormsModule,
    AppHeaderModule,
    ToastrModule.forRoot(),
    StateModule.forRoot(),
    SharedModule,
    StoreRouterConnectingModule.forRoot({
        serializer: DefaultRouterStateSerializer,
        stateKey: 'router',
    }),
    jsPlumbToolkitModule,
    jsPlumbToolkitDragDropModule,
    SpinnerComponent,
    CertifyComponent,
    EnablerConsoleLeftNavigationMenuComponent,
    AdminConsoleLeftNavigationMenuComponent,
    CustodianConsoleLeftNavigationMenuComponent,
    PlayXSafeDashboardLeftNavigationMenuComponent,
    ConvertrDashboardLeftNavigationMenuComponent,
    VennDashboardLeftNavigationMenuComponent
],
    providers: [
        { provide: USE_SSO, useValue: environment.useSSO },
        { provide: API_BASE_URL, useValue: environment.webApiEndpoint },
        { provide: CLIENT_MANAGER_URL, useValue: environment.clientManagerUrl },
        { provide: REQUIRE_CLIENT_CERT, useValue: environment.requireClientCert },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: IxupHttpInterceptor,
            multi: true,
        },
        // { provide: Http, useClass: ExtendedHttpService },
        {
            provide: FILE_DROP_ALLOWED_EXTENSIONS,
            useValue: environment.fileDrop.allowedExtensions,
        },
        {
            provide: FILE_DROP_MAX_FILE_SIZE_BYTES,
            useValue: environment.fileDrop.maxFileSizeBytes,
        },
        { provide: CLIENT_API_ENDPOINT, useValue: environment.clientApiEndpoint },
        PingService,
        AuthService,
        DatePipe,
        CertifyGuard,
        SSOGuard,
        LoggedInGuard,
        TRANSLATION_PROVIDERS,
        TranslateService,
        LoggingService,
        EmitterService,
        ImagesService,
        ToasterService,
        LoggingService,
        FilesService,
        SidebarService,
        StyleManager,
        AuditLogService,
        CanvasService,
        SignalRService,
        AgentService,
        HubConnectionService,
        PBIService,
        PlayXSafeService,
        CollaborationService,
        ConvertrService,
        VennService,
        CustomPreloadingStrategy,
        SiteStatus,
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory,
        },
        MsalService,
        LandingPageService,
        UserFactoryService,
        PagePermissionService,
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
} */)
export class AppModule {}
