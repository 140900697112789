import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';

import { NgIf } from '@angular/common';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import {
  MatDialogModule,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslatePipe } from '../../../../../services/translate/translate.pipe';
import { fadeIn } from '../../../../../theme/animations/fadein-animation';
import { PassphraseResetTypeEnum } from '../../../../collaborator/profile/model/passphrase-reset-type.model';
import { ValidationHelper } from '../../../validators/validation-helper';
import { ChangeOrResetPassphraseDialog } from '../change-or-reset-passphrase-dialog.component';

@Component({
  selector: 'passphrase-form',
  templateUrl: 'passphrase-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeIn],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgIf,
    MatLegacyFormFieldModule,
    MatLegacyInputModule,
    MatIconModule,
    MatTooltipModule,
    MatDialogModule,
    MatButtonModule,
    TranslatePipe,
  ],
})
export class PassphraseFormComponent implements OnInit {
  @Input() incorrectPassphrase: boolean;
  public passphraseForm: UntypedFormGroup;
  hideOldDCPassphrase = true;
  hideAddDCPassphrase = true;
  hideReenterDCPassphrase = true;
  public readonly PASSPHRASE_TYPE: typeof PassphraseResetTypeEnum =
    PassphraseResetTypeEnum;
  @Output() buttonPassphraseSubmit = new EventEmitter<UntypedFormGroup>();

  constructor(
    public dialogRef: MatDialogRef<ChangeOrResetPassphraseDialog>,
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA)
    public data: { authCode: PassphraseResetTypeEnum },
  ) {}

  ngOnInit(): void {
    this.passphraseForm = this.fb.group(
      {
        oldPassword: ['', [Validators.required]],
        newPassword: ['', [Validators.required]],
        confirmPassKey: ['', [Validators.required]],
      },
      {
        validator: ValidationHelper.checkPassword,
      },
    );

    if (this.data.authCode === PassphraseResetTypeEnum.reset) {
      this.passphraseForm.controls['oldPassword'].clearValidators();
      this.passphraseForm.updateValueAndValidity();
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  submit() {
    if (this.passphraseForm.valid) {
      this.buttonPassphraseSubmit.emit(this.passphraseForm);
    }
  }

  validationErrorMessage(): string {
    if (this.passphraseForm.hasError('invalidPassword'))
      return 'The entered passphrases must match.';
    return null;
  }
}
