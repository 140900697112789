import { Component, OnInit } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import {
  MatLegacyDialogModule,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';

import { NgIf } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { CollaboratorKeyService } from '../../../services/collaborator-key/collaborator-key.service';
import { DataProtectionService } from '../../../services/data-protection/data-protection.service';
import { TranslatePipe } from '../../../services/translate/translate.pipe';

@Component({
  selector: 'ixup-password-dialog',
  templateUrl: './ixup-dialog-password-template.component.html',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatLegacyDialogModule,
    NgIf,
    MatIconModule,
    MatLegacyFormFieldModule,
    MatLegacyInputModule,
    MatButtonModule,
    TranslatePipe,
  ],
})
export class PasswordTemplateDialog implements OnInit {
  options;
  modelForm: UntypedFormGroup;
  gotPassword = false;
  passwordIncorrect = false;
  hide = true;

  constructor(
    public dialogRef: MatDialogRef<PasswordTemplateDialog>,
    private fb: UntypedFormBuilder,
    private dataProtectionService: DataProtectionService,
    private collaboratorKeyService: CollaboratorKeyService,
  ) {}

  ngOnInit(): void {
    const password: string = this.dataProtectionService.getStoredPassphrase();

    this.modelForm = this.fb.group({
      password: [password, [Validators.required]],
    });

    this.gotPassword = password !== null;
  }

  confirm() {
    const password = this.modelForm.controls.password.value;

    this.collaboratorKeyService.checkKey(password).subscribe((valid) => {
      this.passwordIncorrect = !valid;

      if (valid) {
        this.dataProtectionService.setStoredPassphrase(password);
        this.dialogRef.close(true);
      }
    });
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
