<form
  [formGroup]="modelForm"
  (submit)="submit()"
>
  <h1 mat-dialog-title>
    <mat-icon class="text-top"><i class="icon icon-agent" aria-hidden="true"></i></mat-icon>
    Confirm passphrase
  </h1>
  <mat-dialog-content>
    <p
      *ngIf="sampleDataWarning"
      class="push-bottom-md"
    >
      {{ sampleDataWarning }}
    </p>
    <div class="alert alert-primary push-bottom-lg">
      <div class="flex flex-row justify-start align-center">
        <div class="pad-right">
          <mat-icon class="mat-primary">
            <i class="icon icon-agent" aria-hidden="true"></i>
          </mat-icon>
        </div>
        <div class="full-width">
          <strong>
            This function is performed client-side through the IXUP Encryption Gateway.
          </strong>
        </div>
      </div>
    </div>
    <p>
      Data Custodian and collaboration passphrases are required to access and work with
      collaboration data.
    </p>

    <div class="push-top push-bottom-lg">
      <mat-checkbox
        formControlName="checkFilesReady"
        labelPosition="after"
        (change)="check($event.checked)"
      >
        I do not have more data to ingest after this. Begin processing my dashboard once this data
        has been uploaded.
      </mat-checkbox>
    </div>
    <p>You will be notified by email when your dashboard is ready.</p>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button
      mat-button
      (click)="cancel()"
      class="btn-rounded"
      type="button"
    >
      {{ 'Cancel' | translate }}
    </button>
    <button
      mat-stroked-button
      color="primary"
      class="btn-rounded"
      type="submit"
      [disabled]="modelForm.invalid"
    >
      <i class="icon icon-agent" aria-hidden="true"></i>
      {{ 'Confirm' | translate }}
    </button>
  </mat-dialog-actions>
</form>
