<footer class="ixup-bg-venn pad-left-lg pad-right-lg">
  <div class="flex flex-row justify-between align-center">
    <div>
    </div>
    <div>
      <img
        src="assets/images/venn/footer-venn.svg"
        alt="Venn"
        style="height:26px"
      >
    </div>
  </div>
</footer>