import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { from, Observable, Subject, Subscription } from 'rxjs';

import * as signalR from '@microsoft/signalr';
import { filter } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { CancelListen, Listen } from '../../state/signalR/signalR.action';
import { SignalRState } from '../../state/signalR/signalR.reducer';
import { IxupBaseService } from '../ixup-base.service';

export interface ISignalRSubscription {
  groupName: string;
  subscription: Subscription;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  callback: (payload: any) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  unsubscribeCondition: (payload: any) => boolean;
}

export interface ISubscriptionPayload {
  groupName: string;
  data: object;
}

export enum ConnectionStateEnum {
  closed,
  Initialising,
  Initialised,
  InError,
}

const ConnectionInitialisationState = {
  Closed: 0,
  Initializing: 1,
  Initialised: 2,
  InError: 3,
};

@Injectable()
export class SignalRService extends IxupBaseService {
  subscriptions: ISignalRSubscription[] = [];
  private initializeingSignalRConnection: number;
  public hubConnection: signalR.HubConnection;
  response: Subject<ISubscriptionPayload>;

  constructor(private store: Store<SignalRState>) {
    super();
    this.response = new Subject<ISubscriptionPayload>();
    this.initializeingSignalRConnection = ConnectionInitialisationState.Closed;
    this.initializeSignalRConnection().subscribe();
  }

  delay(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  private logToConsole(
    callingMethod: string,
    message: string,
    force = false,
  ) {
    if (force || window.sessionStorage.getItem('signalRLogging') === 'true') {
      const datTimeStamp = new Date().toISOString();
      console.log(
        '[' +
          datTimeStamp +
          '] SignalRService.' +
          callingMethod +
          ': ' +
          message,
      );
    }
  }

  setupListener(
    groupName: string,
    description: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    callback: (payload: any) => any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    unsubscribeCondition: (payload: any) => boolean,
  ) {
    if (
      this.initializeingSignalRConnection ===
        ConnectionInitialisationState.InError ||
      this.initializeingSignalRConnection ===
        ConnectionInitialisationState.Closed
    ) {
      this.logToConsole(
        'setupListener',
        'INITIALISING => initializeingSignalRConnection [' +
          this.initializeingSignalRConnection +
          '].',
      );
      this.initializeSignalRConnection().subscribe();
      this.delay(500).then(() =>
        this.setupListener(
          groupName,
          description,
          callback,
          unsubscribeCondition,
        ),
      );
      return;
    }

    if (
      this.initializeingSignalRConnection ===
      ConnectionInitialisationState.Initializing
    ) {
      this.logToConsole(
        'setupListener',
        'DELAYING => initializeingSignalRConnection [' +
          this.initializeingSignalRConnection +
          '].',
      );
      this.delay(500).then(() =>
        this.setupListener(
          groupName,
          description,
          callback,
          unsubscribeCondition,
        ),
      );
      return;
    }

    this.setupListenerProcess(
      groupName,
      description,
      callback,
      unsubscribeCondition,
    );
  }

  private setupListenerProcess(
    groupName: string,
    description: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    callback: (payload: any) => any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    unsubscribeCondition: (payload: any) => boolean,
  ) {
    const subscription = this.getSubscription(groupName);

    if (subscription !== undefined) {
      this.logToConsole(
        'setupListener',
        'ABORTING => groupName [' + groupName + '] is already registered.',
      );
      return;
    }

    if (
      !this.hubConnection ||
      this.hubConnection.state === signalR.HubConnectionState.Disconnected
    ) {
      this.logToConsole(
        'setupListener',
        'CONNECTING => groupName [' + groupName + '].',
      );

      this.initializeSignalRConnection().subscribe(
        (state: signalR.HubConnectionState) => {
          if (!!state && state === signalR.HubConnectionState.Connected) {
            this.hubConnection.invoke('RegisterGroup', groupName);
          }
        },
      );
    } else {
      this.hubConnection.invoke('RegisterGroup', groupName);
    }

    const newSubscription = this.createSubscription(
      this.hubConnection,
      groupName,
      description,
      callback,
      unsubscribeCondition,
    );
    this.pushSubscription(newSubscription);
  }

  resubscribe(subscription: ISignalRSubscription) {
    this.logToConsole(
      'resubscribe',
      'RESUBSCRIBING => groupName [' + subscription.groupName + '].',
    );
    this.hubConnection.invoke('RegisterGroup', subscription.groupName);
    subscription.subscription.unsubscribe();
    subscription.subscription = this.listenFor(subscription.groupName)
      .pipe(filter((payload) => payload.groupName === subscription.groupName))
      .subscribe((payload: ISubscriptionPayload) => {
        subscription.callback(payload.data);
        // check if we need to unsubscribe
        if (subscription.unsubscribeCondition(payload.data)) {
          this.unsubscribe(subscription.groupName);
        }
      });
  }

  unsubscribe(groupName: string) {
    this.logToConsole(
      'unsubscribe',
      'UNSUBSCRIBING => groupName [' + groupName + '].',
    );
    const subscription = this.getSubscription(groupName);
    if (subscription) {
      subscription.subscription.unsubscribe();
      this.store.dispatch(new CancelListen(groupName));
      this.popSubscription(groupName);
      this.deRegisterGroup(groupName);
      return;
    }
    this.logToConsole(
      'unsubscribe',
      'UNSUBSCRIBING => groupName [' + groupName + '] not found.',
    );
  }

  private reInitializeSignalRConnections() {
    const delayMs = 1000;

    if (
      this.initializeingSignalRConnection ===
        ConnectionInitialisationState.Closed ||
      this.initializeingSignalRConnection ===
        ConnectionInitialisationState.InError
    ) {
      this.logToConsole(
        'reInitializeSignalRConnections',
        'INITIALISING => Connection is Idle or InError attempting reconnection.',
      );
      this.initializeSignalRConnection().subscribe();
      this.delay(delayMs).then(() => {
        this.reInitializeSignalRConnections();
      });
      return;
    }

    if (
      this.initializeingSignalRConnection ===
      ConnectionInitialisationState.Initializing
    ) {
      this.logToConsole(
        'reInitializeSignalRConnections',
        'DELAYING => waiting for initialisation to complete.',
      );
      this.delay(delayMs).then(() => {
        this.reInitializeSignalRConnections();
      });
      return;
    }

    if (
      this.initializeingSignalRConnection ===
      ConnectionInitialisationState.Initialised
    ) {
      this.logToConsole(
        'reInitializeSignalRConnections',
        'INITIALISED => re-subscribing groups.',
      );
      this.subscriptions.forEach((subscription) => {
        this.resubscribe(subscription);
      });
    }
  }
  private initializeSignalRConnection(): Observable<signalR.HubConnectionState> {
    this.initializeingSignalRConnection =
      ConnectionInitialisationState.Initializing;

    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(environment.webApiEndpoint + '/messaginghub')
      .build();
    this.hubConnection.keepAliveIntervalInMilliseconds = 10000;

    this.hubConnection.onclose(() => {
      this.logToConsole(
        'initializeSignalRConnection',
        'CONNECTION-CLOSED => Hub connection closed. Attempting to reconnect...',
        true,
      );
      this.initializeingSignalRConnection =
        ConnectionInitialisationState.Closed;
      this.reInitializeSignalRConnections();
    });

    return from(
      this.hubConnection
        .start()
        .then(() => {
          this.logToConsole(
            'initializeSignalRConnection',
            'CONNECTION-STARTED => Hub connection started.',
            true,
          );
          this.initializeingSignalRConnection =
            ConnectionInitialisationState.Initialised;
          return this.hubConnection.state;
        })
        .catch((err) => {
          this.logToConsole(
            'initializeSignalRConnection',
            'CONNECTION-ERROR => error [' + err + '].',
            true,
          );
          this.initializeingSignalRConnection =
            ConnectionInitialisationState.InError;
          return this.hubConnection.state;
        }),
    );
  }

  private deRegisterGroup(groupName: string) {
    this.logToConsole(
      'deRegisterGroup',
      'DEREGISTERING => groupName [' + groupName + '].',
    );
    if (this.hubConnection.state === signalR.HubConnectionState.Connected) {
      return this.hubConnection.invoke('DeRegisterGroup', groupName);
    } else {
      this.initializeSignalRConnection().subscribe((state) => {
        if (
          !!state &&
          state === ConnectionStateEnum[ConnectionStateEnum.Initialised]
        ) {
          return this.hubConnection.invoke('DeRegisterGroup', groupName);
        }
      });
    }
  }

  private listenFor(groupName: string): Observable<ISubscriptionPayload> {
    this.logToConsole(
      'listenFor',
      'LISTENING-FOR => groupName [' + groupName + '].',
    );
    this.hubConnection.on(groupName, (data) => {
      this.logToConsole(
        'listenFor',
        'HEARD => groupName [' +
          groupName +
          '], data [' +
          JSON.stringify(data) +
          '].',
      );
      if (data) {
        this.response.next({
          groupName,
          data,
        });
      }
    });
    return this.response.asObservable();
  }

  private getSubscription(groupName: string): ISignalRSubscription {
    return this.subscriptions.find((s) => s.groupName === groupName);
  }

  private pushSubscription(subscription: ISignalRSubscription) {
    this.subscriptions.push(subscription);
    return this.getSubscription(subscription.groupName);
  }

  private popSubscription(groupName: string): ISignalRSubscription {
    const subscription = this.getSubscription(groupName);
    if (subscription) {
      this.logToConsole(
        'popSubscription',
        'SUBSCRIPTION-GET => groupName [' + groupName + '].',
      );
      const beforeCount = this.subscriptions.length;
      this.subscriptions = this.subscriptions.filter(
        (s) => s.groupName !== groupName,
      );
      this.logToConsole(
        'popSubscription',
        'SUBSCRIPTION-COUNT=> before [' +
          beforeCount +
          '], after [' +
          this.subscriptions.length +
          '].',
      );
      return subscription;
    }
    this.logToConsole(
      'popSubscription',
      'SUBSCRIPTION-NOTFOUND => groupName [' + groupName + '].',
    );
    return null;
  }

  private createSubscription(
    connection: signalR.HubConnection,
    groupName: string,
    description: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    callback: (payload: any) => any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    unsubscribeCondition: (payload: any) => boolean,
  ) {
    this.logToConsole(
      'createSubscription',
      'SUBSCRIBING => groupName [' +
        groupName +
        '], description [' +
        description +
        '].',
    );
    this.store.dispatch(new Listen({ groupName, description }));
    return {
      groupName,
      callback,
      unsubscribeCondition,
      subscription: this.listenFor(groupName)
        .pipe(
          filter((payload) => {
            return payload.groupName === groupName;
          }),
        )
        .subscribe((payload: ISubscriptionPayload) => {
          try {
            callback(payload.data);

            // check if we need to unsubscribe
            if (unsubscribeCondition(payload.data)) {
              this.unsubscribe(groupName);
            }
          } catch (e) {
            //do nothing
          }
        }),
    };
  }
}
