<mat-progress-spinner
  @animate
  *ngIf="showSpinner"
  class="ixup-saving-spinner"
  mode="indeterminate"
  color="accent"
  [diameter]="32"
></mat-progress-spinner>

<ng-content @animate *ngIf="!showSpinner" style="margin: auto"></ng-content>
