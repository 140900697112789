<h2 mat-dialog-title>
  <mat-icon
    *ngIf="!!data.model.customicon"
    class="push-right-sm ixup-canvas-dialog-icon"
  >
    <i class="icon {{ data.model.customicon }}" aria-hidden="true"></i>
  </mat-icon>
  <mat-icon
    *ngIf="!!data.model.icon"
    class="push-right-sm ixup-canvas-dialog-icon"
  >
    {{ data.model.icon }}
  </mat-icon>
  {{ data.model.title | translate }}
</h2>
<mat-dialog-content>
  <p>{{ data.model.message | translate }}</p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button
    mat-button
    type="button"
    *ngIf="!!data.model.canceltext"
    (click)="cancel()"
    tabindex="-1"
  >
    {{ data.model.canceltext | translate }}
  </button>
  <button
    *ngIf="!!data.model.okother"
    mat-button
    type="button"
    (click)="okother()"
    [color]="data.model.okcolor || 'warn'"
    class="venn-button-flat-primary push-left-sm"
  >
    {{ data.model.okothertext | translate }}
  </button>
  <button
    mat-button
    type="button"
    (click)="confirm()"
    [color]="data.model.okcolor || 'warn'"
    class="venn-button-flat-primary push-left-sm"
  >
    {{ data.model.oktext | translate }}
  </button>

</mat-dialog-actions>
