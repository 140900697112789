import { Action } from '@ngrx/store';
import { ISafeModel } from '../../../../../model/five-safes/safe.model';
import { IFiveSafesDataModel } from '../../../../../model/five-safes/safe-data.model';

export enum SafeActionTypes {
  LoadSafes = '[ControlCentre.Safes] Load Safes',
  LoadSafesSuccess = '[ControlCentre.Safes] Load Safes Success',
  LoadSafesFailed = '[ControlCentre.Safes] Load Safes Failed',
  LoadSafesData = '[ControlCentre.Safes] Load Safes Data',
  LoadSafesDataSuccess = '[ControlCentre.Safes] Load Safes Data Success',
  LoadSafesDataFailed = '[ControlCentre.Safes] Load Safes Data Failed',
}

export class LoadSafes implements Action {
  readonly type = SafeActionTypes.LoadSafes;
}

export class LoadSafesSuccess implements Action {
  readonly type = SafeActionTypes.LoadSafesSuccess;

  constructor(public payload: { safes: ISafeModel[] }) {}
}

export class LoadSafesFailed implements Action {
  readonly type = SafeActionTypes.LoadSafesFailed;
}

export class LoadSafesData implements Action {
  readonly type = SafeActionTypes.LoadSafesData;
  constructor(public payload: { collaborationId: number }) {}
}

export class LoadSafesDataSuccess implements Action {
  readonly type = SafeActionTypes.LoadSafesDataSuccess;

  constructor(public payload: { safesData: IFiveSafesDataModel[] }) {}
}

export class LoadSafesDataFailed implements Action {
  readonly type = SafeActionTypes.LoadSafesDataFailed;
}

export type SafeActions = LoadSafes 
| LoadSafesSuccess 
| LoadSafesFailed 
| LoadSafesData 
| LoadSafesDataSuccess 
| LoadSafesDataFailed;
