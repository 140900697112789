<div
  [@animate]
  class="pad-left pad-right"
  style="background: #28384f"
>
  <nav
    mat-tab-nav-bar
    backgroundColor="primary"
    class="playXsafe-dashboard-nav-bar"
  >
    <a
      *ngIf="playXSafeAdmin"
      mat-tab-link
      [routerLink]="['/playXsafe/dashboard/' + (this.collaborationId$ | async) + '/overview']"
      routerLinkActive
      #rla0="routerLinkActive"
      [active]="rla0.isActive"
    >
      <span mat-line>Overview</span>
    </a>
    <a
      *ngIf="playXSafeAdmin || playXSafeUpload"
      mat-tab-link
      [routerLink]="['/playXsafe/dashboard/' + (this.collaborationId$ | async) + '/tables']"
      routerLinkActive
      #rla1="routerLinkActive"
      [active]="rla1.isActive"
    >
      <span mat-line>Inputs</span>
    </a>
    <a
      *ngIf="playXSafeAdmin"
      mat-tab-link
      [routerLink]="['/playXsafe/dashboard/' + (this.collaborationId$ | async) + '/output']"
      routerLinkActive
      #rla2="routerLinkActive"
      [active]="rla2.isActive"
    >
      <span mat-line>Outputs</span>
    </a>
    <a
      *ngIf="playXSafeAdmin"
      mat-tab-link
      [routerLink]="['/playXsafe/dashboard/' + (this.collaborationId$ | async) + '/audit']"
      routerLinkActive
      #rla3="routerLinkActive"
      [active]="rla3.isActive"
    >
      <span mat-line>Audit</span>
    </a>
  </nav>
  <div class="flex flex-1 full-width">
  </div>
</div>