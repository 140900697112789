import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IxupHttpCustomParams } from 'app/http/http-interceptor-params';
import { CanvasActionChart } from 'app/state/model/canvas-action-chart';
import { map, Observable, of } from 'rxjs';
import { ResultModelGeneric } from '../../../model/result.model';
import { IxupBaseService } from '../../../services/ixup-base.service';
import { ApproveResultRequestModel } from '../models/approve-result-request';
import { CountriesModel } from '../models/countries.model';
import { DataJsonModel } from '../models/data-json/data-json.model';
import { IReportTokenRequestModel } from '../models/report-token-request.model';
import { IReportTokenModel } from '../models/report-token.model';
import { RunProgressModel } from '../models/run-progress.model';
import { SaveStatusModel } from '../models/save-status.model';
import { CanvasValidationModel } from '../models/SourceValidation/CanvasValidation.model';
import { TableModel } from '../models/tables.model';
import { UploadTableModel } from '../models/upload-table.model';
import { SetSaveStatus } from '../state/actions/save-status.action';
@Injectable()
export class CanvasService extends IxupBaseService {
  constructor(
    @Inject(HttpClient) private http: HttpClient,
    private store: Store<CanvasState>,
  ) {
    super();
  }

  public getList(id) {
    return this.resolveErrorGeneric<TableModel[]>(
      this.http.get(`/api/Canvas/${id}/tables`),
    );
  }
  public saveCanvasDraft(
    model: DataJsonModel,
    saveStatus: SaveStatusModel,
  ): Observable<ResultModelGeneric<boolean>> {
    if (saveStatus.isSaving) {
      const item = {
        status: true,
        data: false,
        message: 'Canvas has not saved',
      };
      return of(item);
    }
    this.store.dispatch(
      new SetSaveStatus({
        status: {
          isSaving: true,
        },
      }),
    );
    console.log(`Canvas ${model.canvasId} save initiated...`);
    return this.resolveErrorGeneric(
      this.http.post(`/api/Canvas/${model.canvasId}/save`, model),
    );
  }

  public runCanvas(
    collaborationId: number,
    model: DataJsonModel,
  ): Observable<ResultModelGeneric<string>> {
    return this.resolveErrorGeneric<string>(
      this.http.post(`/api/Canvas/${collaborationId}/run`, model),
    );
  }

  public getCanvas(id) {
    return this.resolveErrorGeneric<DataJsonModel>(
      this.http.get(`/api/Canvas/${id}`),
    );
  }

  public saveSnapshotImage(model) {
    return this.resolveErrorGeneric(this.http.post('/api/Image/snapshot', model));
  }

  public getRunProgress(collaborationId: number) {
    return this.resolveErrorGeneric<RunProgressModel>(
      this.http.get(`/api/canvas/${collaborationId}/progress`),
    );
  }

  public exportRequest(uniqueName) {
    return this.resolveErrorGeneric(
      this.http.post(`/api/Canvas/download/${uniqueName}`, null),
    );
  }
  public acceptExportRequest(approveResultRequestModel: ApproveResultRequestModel) {
    return this.http.post('/api/Canvas/download/accept', approveResultRequestModel, {
      responseType: 'blob',
    });
  }

  public uploadTable(
    collaborationId: number,
    uploadTableModel: UploadTableModel,
  ): Observable<ResultModelGeneric<TableModel>> {
    return this.resolveErrorGeneric<TableModel>(
      this.http.post(`/api/Canvas/${collaborationId}/tables`, uploadTableModel),
    );
  }

  public getSourceValidation(canvasId: number) {
    return this.resolveErrorGeneric<CanvasValidationModel>(
      this.http.get(`/api/Canvas/sourceValidation/${canvasId}`),
    );
  }

  public getCountries(): Observable<ResultModelGeneric<CountriesModel[]>> {
    return this.resolveErrorGeneric<CountriesModel[]>(
      this.http.get('/api/Canvas/countries'),
    );
  }

  public getReportToken(
    collaborationId: number,
    reportTokenRequest: IReportTokenRequestModel,
  ): Observable<ResultModelGeneric<IReportTokenModel>> {
    return this.resolveErrorGeneric<IReportTokenModel>(
      this.http.post(
        `/api/Canvas/reportToken/${collaborationId}`,
        reportTokenRequest,
      ),
    );
  }

  public getApprovalStatus(collaborationId: number) {
    return this.resolveError(
      this.http.get(`/api/canvas/${collaborationId}/approval/status`),
    );
  }

  public stopCanvasRun(collaborationId: number): Observable<boolean> {
    return this.resolveErrorGeneric<boolean>(
      this.http.post(`/api/Canvas/${collaborationId}/stop`, null),
    ).pipe(map((result: ResultModelGeneric<boolean>) => result.data));
  }

  public getCanvasRunLog(canvasId: number) {
    return this.resolveError(this.http.get(`/api/canvas/${canvasId}/run`));
  }

  public getCanvasRunActionsLog(canvasId: number, canvasRunActionId: number) {
    return this.resolveError(
      this.http.get(`/api/canvas/${canvasId}/runActions/${canvasRunActionId}`),
    );
  }

  public getChartDetails(
    collaborationId: number,
  ): Observable<ResultModelGeneric<CanvasActionChart[]>> {
    return this.resolveErrorGeneric<any>(
      this.http.post(`/collaborations/${collaborationId}/charts`, null, {
        params: new IxupHttpCustomParams(true),
      }),
    );
  }

  public getChartByGroupId(
    collaborationId: number,
    groupId: string
  ): Observable<ResultModelGeneric<CanvasActionChart[]>> {
    return this.resolveErrorGeneric<any>(
      this.http.post(`/collaborations/${collaborationId}/charts/${groupId}`, null, {
        params: new IxupHttpCustomParams(true),
      }),
    );
  }
}
