import { Routes } from '@angular/router';

import { CertifyComponent } from './components/certify/certify.component';
import { InactiveAccountComponent } from './components/inactive-account/inactive-account.component';
import { NoRoleAccountComponent } from './components/norole-account/norole-account.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { CertifyGuard } from './guards/certify.guard';
import { LoggedInGuard } from './guards/loggedIn.guard';
import { InsufficientPermissionComponent } from './pages/insufficient-permission/insufficient-permission.component';

type PathMatch = 'full' | 'prefix' | undefined;

export const APP_ROUTES: Routes = [
  {
    path: '',
    pathMatch: 'full' as PathMatch,
    redirectTo: 'login',
  },
  {
    path: 'inactiveaccount',
    component: InactiveAccountComponent,
  },
  {
    path: 'noroleaccount',
    component: NoRoleAccountComponent,
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./pages/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'about',
    loadChildren: () =>
      import('./pages/about/about.module').then((m) => m.AboutModule),
  },
  {
    path: 'certify',
    component: CertifyComponent,
    canActivate: [CertifyGuard],
  },
  {
    path: 'audit-log',
    loadChildren: () =>
      import('./pages/audit-log/audit-log.module').then(
        (m) => m.AuditLogModule,
      ),
    canActivate: [LoggedInGuard],
  },
  {
    path: 'collaborator',
    loadChildren: () =>
      import('./pages/collaborator/collaborator.module').then(
        (m) => m.CollaboratorModule,
      ),
    canActivate: [LoggedInGuard],
  },
  {
    path: ':role/agent',
    loadChildren: () =>
      import('./pages/agent/agent.module').then((m) => m.AgentModule),
    canActivate: [LoggedInGuard],
  },
  {
    path: 'user',
    loadChildren: () =>
      import('./pages/user/user.module').then((m) => m.UserModule),
    canActivate: [LoggedInGuard],
  },
  {
    path: 'users',
    loadChildren: () =>
      import('./pages/shared/users/users.module').then((m) => m.UsersModule),
    canActivate: [LoggedInGuard],
  },
  {
    path: 'about',
    loadChildren: () =>
      import('./pages/about/about.module').then((m) => m.AboutModule),
    canActivate: [LoggedInGuard],
  },
  {
    path: 'custodian-console',
    loadChildren: () =>
      import(
        './pages/consoles/custodian-console/custodian-management.module'
      ).then((m) => m.CustodianManagementModule),
    canActivate: [LoggedInGuard],
    data: { applyPreload: true },
  },
  {
    path: 'control-centre',
    loadChildren: () =>
      import('./pages/control-centre/control-centre.module').then(
        (m) => m.ControlCentreModule,
      ),
    canActivate: [LoggedInGuard],
    data: { applyPreload: true },
  },
  {
    path: 'admin-console',
    loadChildren: () =>
      import('./pages/consoles/admin-console/admin-console.module').then(
        (m) => m.AdminConsoleModule,
      ),
    canActivate: [LoggedInGuard],
  },
  {
    path: 'enabler-console',
    loadChildren: () =>
      import('./pages/consoles/enabler-console/enabler-console.module').then(
        (m) => m.EnablerConsoleModule,
      ),
    canActivate: [LoggedInGuard],
  },
  {
    path: 'infrastructure-console',
    loadChildren: () =>
      import(
        './pages/consoles/infrastructure-console/infrastructure-console.module'
      ).then((m) => m.InfrastructureConsoleModule),
    canActivate: [LoggedInGuard],
  },
  {
    path: 'playXsafe/dashboard',
    loadChildren: () =>
      import('./pages/playxsafe/playXSafe-dashboard.module').then(
        (m) => m.PlayXSafeDashboardModule,
      ),
    canActivate: [LoggedInGuard],
  },
  {
    path: 'playXsafe/noCollaborationId',
    loadChildren: () =>
      import(
        './pages/playXsafe/no-collaborationId/playXSafe-no-collaborationId.module'
      ).then((m) => m.PlayXSafeNoCollaborationIdModule),
    canActivate: [LoggedInGuard],
  },
  {
    path: 'convertr/dashboard',
    loadChildren: () =>
      import('./pages/convertr/convertr-dashboard.module').then(
        (m) => m.ConvertrDashboardModule,
      ),
    canActivate: [LoggedInGuard],
  },
  {
    path: 'venn/dashboard',
    loadChildren: () =>
      import('./pages/venn/venn-dashboard.module').then(
        (m) => m.VennDashboardModule,
      ),
    canActivate: [LoggedInGuard],
  },
  {
    path: 'convertr/collaborations',
    loadChildren: () =>
      import('./pages/convertr/campaigns/campaigns.module').then(
        (m) => m.ConvertrCampaignsModule,
      ),
    canActivate: [LoggedInGuard],
  },
  {
    path: 'venn/collaborations',
    loadChildren: () =>
      import('./pages/venn/campaigns/campaigns.module').then(
        (m) => m.VennCampaignsModule,
      ),
    canActivate: [LoggedInGuard],
  },
  {
    path: 'terms-and-conditions',
    loadChildren: () =>
      import('./pages/terms-and-conditions/terms-and-conditions.module').then(
        (m) => m.TermsAndConditionsModule,
      ),
    canActivate: [LoggedInGuard],
  },
  {
    path: 'insufficient-permission',
    component: InsufficientPermissionComponent,
  },
  {
    path: '404',
    component: NotFoundComponent,
    canActivate: [LoggedInGuard],
  },
  { path: '**', redirectTo: '/404', pathMatch: 'full' as PathMatch },
];
