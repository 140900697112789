import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Observable, of as observableOf } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { DataProtectionPasswordDialog } from '../../components/shared/utilities/data-protection/data-protection-password-dialog.component';
import { ValidatePasswordIsCorrect } from '../../components/shared/validators/async-password.validator';
import { AuthService } from '../auth/AuthService';
import { CollaboratorKeyService } from '../collaborator-key/collaborator-key.service';

@Injectable()
export class DataProtectionService {
  public passphrase$: Observable<string>;
  private sessionStorageKey: string;

  constructor(
    authService: AuthService,
    private collaboratorKeyService: CollaboratorKeyService,
    private dialog: MatDialog
  ) {
    this.passphrase$ = new Observable(observer => {
      this.resolvePassphrase().subscribe(
        (passphrase: string) => {
          if (passphrase) {
            this.setStoredPassphrase(passphrase);
          }
          observer.next(passphrase);
          observer.complete();
        },
        () => {
          console.log('error in DataProtectionService constructor');
        }
      );
    });

    authService.currentUser$.subscribe(user => {
      this.sessionStorageKey = `data-protection.passphrase[${user.CollaboratorId}]`;
    });
  }

  public removeStoredPassphrase() {
    sessionStorage.removeItem(this.sessionStorageKey);
  }

  private resolvePassphrase(): Observable<string> {
    const storedPassphrase = this.getStoredPassphrase() || '-';

    return this.checkPassphraseIsCorrect(storedPassphrase).pipe(
      switchMap(result => {
        if (result) {
          return observableOf(storedPassphrase);
        } else {
          return this.getPassphraseFromUser();
        }
      })
    );
  }

  public getPassphraseFromUser(): Observable<string> {
    const dialogRef = this.dialog.open(DataProtectionPasswordDialog, {
      data: {
        model: '',
        cancel: true
      }
    });
    return dialogRef
      .afterClosed()
      .pipe(map(val => (val ? val.value.passphrase : null)));
  }

  public getStoredPassphrase(): string {
    return sessionStorage.getItem(this.sessionStorageKey);
  }

  public setStoredPassphrase(passphrase: string): void {
    return sessionStorage.setItem(this.sessionStorageKey, passphrase);
  }

  public checkPassphraseIsCorrect(passphrase: string): Observable<boolean> {
    return this.collaboratorKeyService.checkKey(passphrase);
  }

  public getAsyncPassphraseValidator() {
    return ValidatePasswordIsCorrect.createValidator(
      this.collaboratorKeyService
    );
  }
}
