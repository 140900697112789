import cloneDeep from 'lodash-es/cloneDeep';

import {
  CampaignModel,
  StatusModel,
} from '../../pages/convertr/model/campaign.model';
import CollaboratorsOverviewModel from '../../pages/convertr/model/collaborators-overview-model';
import { IOutputTableModel } from '../../pages/output-tables/models/output-table.model';
import { DashboardStatusEnum } from '../../pages/powaindex/model/dashboard-status.enum';
import { ReportDashboardStatusEnum } from '../../pages/powaindex/model/report-dashboard-status.enum';
import { CanvasActionChart } from '../model/canvas-action-chart';
import { TableStateEnum } from '../model/table-state';
import { DashboardActionTypes, DashboardActions } from './actions';

export interface ConvertrDashboardState {
  // additional entities state properties
  collaborations: CampaignModel[];
  currentDashboardCollaborationId: number;
  canvasActionChart: CanvasActionChart[];
  currentStatus: DashboardStatusEnum | ReportDashboardStatusEnum;
  submittedForMatching: boolean;
  loading: boolean;
  dataOwner: boolean;
  overview: CollaboratorsOverviewModel;
  tableState: TableStateEnum;
  segments: IOutputTableModel[];
}

export const initialState: ConvertrDashboardState = {
  // additional entity state properties
  collaborations: null,
  currentDashboardCollaborationId: null,
  canvasActionChart: null,
  currentStatus: null,
  submittedForMatching: false,
  loading: false,
  dataOwner: false,
  overview: {
    collaborationUploads: null,
    custodianDownloads: null,
    custodianName: null,
  },
  tableState: null,
  segments: null,
};

export function ConvertrDashboardReducer(
  state = initialState,
  action: DashboardActions,
): ConvertrDashboardState {
  switch (action.type) {
  case DashboardActionTypes.LoadConvertrDashboard: {
    return { ...state, loading: true };
  }
  case DashboardActionTypes.LoadConvertrDashboardSuccess: {
    return {
      ...state,
      collaborations: action.payload.collaborations,
      loading: false,
    };
  }
  case DashboardActionTypes.GetConvertrDashboard:
  case DashboardActionTypes.GetChartDetails: {
    return { ...state, canvasActionChart: null, loading: true };
  }

  case DashboardActionTypes.GetChartDetailsSuccess: {
    return {
      ...state,
      canvasActionChart: action.payload.canvasActionChart,
      loading: false,
    };
  }
  case DashboardActionTypes.GetConvertrDashboardSuccess: {
    const newState = cloneDeep(state);
    const index: number = state.collaborations.findIndex(
      (a) =>
        a.collaborationId === action.payload.collaboration.collaborationId,
    );
    if (index !== -1) {
      newState.collaborations[index] = action.payload.collaboration;
    }
    return {
      ...newState,
      loading: false,
    };
  }
  case DashboardActionTypes.LoadCollaboratorOverview: {
    return { ...state, loading: true };
  }
  case DashboardActionTypes.LoadCollaboratorOverviewSuccess: {
    return {
      ...state,
      loading: false,
      overview: action.payload.collaboratorsOverview,
    };
  }
  case DashboardActionTypes.SetCurrentConvertrDashboard: {
    return {
      ...state,
      currentDashboardCollaborationId: action.payload.currentCollaborationId,
      loading: true,
    };
  }
  case DashboardActionTypes.SetCurrentConvertrDashboardSuccess:
  case DashboardActionTypes.SetCurrentConvertrDashboardFailed:
  case DashboardActionTypes.UpdateConvertrApprovalStatusFailed:
  case DashboardActionTypes.UpdateConvertrLastExportedFailed:
  case DashboardActionTypes.UpdateConvertrPercentageMatchesFailed:
  case DashboardActionTypes.GetDashboardConvertrStatusFailed:
  case DashboardActionTypes.UpdateDashboardConvertrStatusFailed:
  case DashboardActionTypes.UpdateTableStateFailed:
  case DashboardActionTypes.SubmitForMatchingFailed:
  case DashboardActionTypes.LoadSegmentsFailed: {
    return {
      ...state,
      loading: false,
    };
  }
  case DashboardActionTypes.GetDashboardConvertrStatus:
  case DashboardActionTypes.UpdateDashboardConvertrStatus:
  case DashboardActionTypes.UpdateConvertrApprovalStatus:
  case DashboardActionTypes.UpdateConvertrLastExported:
  case DashboardActionTypes.UpdateConvertrPercentageMatches:
  case DashboardActionTypes.LoadSegments: {
    return {
      ...state,
      loading: true,
    };
  }
  case DashboardActionTypes.UpdateConvertrPercentageMatchesSuccess: {
    const newState = cloneDeep(state);
    const index: number = state.collaborations.findIndex(
      (a) => a.collaborationId === action.payload.collaborationId,
    );
    if (index !== -1) {
      newState.collaborations[index].percentageMatches =
          action.payload.percentageMatches;
    }
    return {
      ...newState,
      loading: false,
    };
  }
  case DashboardActionTypes.UpdateConvertrApprovalStatusSuccess: {
    const newState = cloneDeep(state);
    const index: number = state.collaborations.findIndex(
      (a) => a.collaborationId === action.payload.collaborationId,
    );
    if (index !== -1) {
      newState.collaborations[index].approved = action.payload.approved;
    }
    return {
      ...newState,
      loading: false,
    };
  }
  case DashboardActionTypes.UpdateConvertrLastExportedSuccess: {
    const newState = cloneDeep(state);
    const index: number = state.collaborations.findIndex(
      (a) => a.collaborationId === action.payload.collaborationId,
    );
    if (index !== -1) {
      newState.collaborations[index].lastExportedDate = action.payload.date;
    }
    return {
      ...newState,
      loading: false,
    };
  }
  case DashboardActionTypes.SubmitForMatchingSuccess: {
    return {
      ...state,
      submittedForMatching: true,
      loading: false,
    };
  }
  case DashboardActionTypes.UpdateTableState: {
    return {
      ...state,
      tableState: action.payload.tableState,
      loading: false,
    };
  }

  case DashboardActionTypes.UpdateStatusList: {
    const newState = cloneDeep(state);
    const index = state.collaborations.findIndex(
      (a) => a.collaborationId === action.payload.collaborationId,
    );

    const payloadStatusModel = action.payload.statusList as StatusModel;

    if (index !== -1) {
      const campaignModel = newState.collaborations[index];

      if (action.payload.dataCustodianId) {
        const newStatusList = campaignModel.statusList;
        const statusIndex = newStatusList.findIndex(
          (dc) => dc.dataCustodianId === action.payload.dataCustodianId,
        );
          // console.assert(statusIndex === -1);
        if (statusIndex !== -1) {
          campaignModel.statusList[statusIndex] = {
            ...campaignModel.statusList[statusIndex],
            ...payloadStatusModel,
          };
        } else {
          console.log(
            'couldnt find dc index',
            action.payload.dataCustodianId,
          );
        }
      } else {
        campaignModel.statusList = newState.collaborations[
          index
        ].statusList.map((status) => ({ ...status, ...payloadStatusModel }));
      }
    }
    return {
      ...newState,
      loading: false,
    };
  }

  case DashboardActionTypes.GetDashboardConvertrStatusSuccess: {
    return {
      ...state,
      currentStatus: action.payload.dashboardStatus.status,
      dataOwner: action.payload.dashboardStatus.dataOwner,
      loading: false,
    };
  }
  case DashboardActionTypes.UpdateDashboardConvertrStatusSuccess: {
    return {
      ...state,
      currentStatus: action.payload.currentStatus,
      loading: false,
    };
  }
  case DashboardActionTypes.UpdateTableStateSuccess: {
    return {
      ...state,
      tableState: action.payload.tableState,
      loading: false,
    };
  }
  case DashboardActionTypes.LoadSegmentsSuccess:
    return {
      ...state,
      segments: action.payload.segments,
      loading: false,
    };
  default: {
    return state;
  }
  }
}
