<footer class="ixup-bg-convertr pad-left-lg pad-right-lg flex flex-row">
  <div class="flex flex-row flex-1 justify-between align-center items-center">
    <div>
    </div>
    <div>
      <img
        src="assets/images/convertr/footer-convertr.svg"
        alt="Convertr by Cipher"
        style="height:26px"
      >
    </div>
  </div>
</footer>