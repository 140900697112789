import { Injectable } from '@angular/core';
import { ImagesService } from '../shared/Images.service';
import { AuthUserModel } from '../../model/authuser.model';
import { JwtHelperService } from '@auth0/angular-jwt';
import pick from 'lodash-es/pick';
@Injectable()
export class UserFactoryService {
  constructor(
    private imagesService: ImagesService,
    private jwtHelper: JwtHelperService,
  ) {}

  createUserFromToken(accessToken): AuthUserModel {
    const parsedJson = this.jwtHelper.decodeToken(accessToken);
    const userObj = pick(parsedJson, [
      'UserId',
      'OrganisationId',
      'OrganisationTypeId',
      'CollaboratorName',
      'CollaboratorTypeId',
      'CollaboratorId',
      'Roles',
      'EnablerId',
      'EnablerName',
      'name',
      'AvatarId',
      'Permissions',
      'ClientName',
      'Legacy',
      'OrganisationId',
      'OrganisationName',
      'AdminConsole',
      'DataCustodianConsole',
      'IconId',
      'EnablerIconId',
      'OrganisationIconId',
      'TandC',
      'IsIxup',
      'EGBaseURL',
      'AuthCode',
      'SetPassphrase',
      'sso',
      'Signing',
      'MultipleSigning',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ]) as any;

    // return a properly typed object
    return {
      UserId: Number(userObj.UserId),
      OrganisationId: Number(userObj.OrganisationId),
      OrganisationName: userObj.OrganisationName,
      CollaboratorName: userObj.CollaboratorName,
      CollaboratorTypeId: Number(userObj.CollaboratorTypeId),
      OrganisationTypeId: Number(userObj.OrganisationTypeId),
      CollaboratorId: Number(userObj.CollaboratorId),
      EnablerId: Number(userObj.EnablerId),
      EnablerName: userObj.EnablerName,
      Roles: userObj.Roles,
      TandC: userObj.TandC,
      EGBaseURL: userObj.EGBaseURL,
      name: userObj.name,
      IsIxup: userObj.IsIxup,
      AvatarId: Number(userObj.AvatarId),
      Permissions: userObj.Permissions,
      ClientName: userObj.ClientName,
      Legacy: userObj.Legacy,
      AdminConsole: userObj.AdminConsole,
      DataCustodianConsole: userObj.DataCustodianConsole,
      IconId: Number(userObj.IconId),
      OrganisationIconId: Number(userObj.OrganisationIconId),
      EnablerIconId: Number(userObj.EnablerIconId),
      avatarImage$: this.imagesService.getImage({
        imageId: userObj.AvatarId,
        alternativeName: userObj.name,
        shape: 'round',
      }),
      AuthCode: userObj.AuthCode,
      SetPassphrase: userObj.SetPassphrase,
      SSO: String(userObj.sso) === 'True' ? true : false,
      Signing: Number(userObj.Signing),
      MultipleSigning: String(userObj.MultipleSigning) === 'True' ? true : false,
    };
  }
}
