
import {throwError as observableThrowError, Observable } from 'rxjs';

import {catchError, refCount, publishReplay, map} from 'rxjs/operators';
import 'rxjs';

import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { ActivityModel } from '../../model/activity.model';

export enum ActivityType {
    pageVisit = 'Page visit',
    editView = 'Edit view',
    createView = 'Create view',
    viewed = 'View only',
    reportLoad = 'Report load',
    created = 'Information saved',
    updated = 'Information updated',
    deleted = 'Information deleted',
    release = 'Data Warehouse Release',
    extract = 'Data Warehouse Extract',
    success = 'Action successful',
    fail = 'Action failed',
}


@Injectable()

export class LoggingService {
  constructor(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        @Inject(HttpClient) private http: HttpClient, @Inject(DOCUMENT) private document: any) {
  }

  log(activity: string, action: string, comments: string) {
    const activityViewModel: ActivityModel = new ActivityModel();
    activityViewModel.activity = activity;
    activityViewModel.action = action;
    activityViewModel.comments = comments;
    activityViewModel.url = this.document.location.href;
    return this.resolveError(this.http.post('/api/Activities', activityViewModel));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private resolveError(observable: Observable<any>): Observable<any> {
    return observable.pipe(map(
      (item) => {
        if (item.status === true) {
          return item.data;
        } else {
          throw new Error(item.message);
        }
      }),publishReplay(1),refCount(),catchError(
      (error) => {
        throw observableThrowError(error.message);
      }
    ),);
  }
}
