import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'enumToArray',
  standalone: true,
})
export class EnumToArrayPipe implements PipeTransform {
  transform(data: object) {
    return Object.keys(data).map((key) => ({
      id: key,
      name: data[key],
    }));
  }
}
