export const LANG_PI_NAME = 'pi';

export const LANG_PI_TRANS = {
  'hello world': 'arrr! me hearties',
  'collaboration': 'shiver me timbers!',
  'Dashboard': 'Treasure map',
  'organisation': 'fleet',
  'organisations': 'fleets',
  'Organisation': 'Fleet',
  'Organisations': 'Fleets',        
  'Collaborations': 'Accords',
  'collaborator': 'ship',
  'Collaborator': 'Ship',
  'collaborators': 'ships',
  'Collaborators': 'Ships',
  'Remove': 'Throw overboard',
  'Cancel': 'Bail out!',
  'OK': 'Savvy',
  'Invitations': 'Messages in bottles',
  'Profile': 'Ye olde file of pro',
  'role': 'role xxx',



  'Created': 'Launched',

  'User': 'Shipmate',
  'Login': 'Board the ship',
  'Logout': 'Abandon ship!',

  'assets/images/logo-ixup-x-azure-200px.png': 'https://s-media-cache-ak0.pinimg.com/originals/d8/94/c9/d894c9ea174390b823ded07f8b3cfd10.png',
  './app.scss': './app.scss',
    
};