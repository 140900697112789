import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { NgIf } from '@angular/common';
import { MatLegacyProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { untilDestroyed } from '../../state/shared/utils';
import { fadeIn } from '../../theme/animations/fadein-animation';

@Component({
  selector: 'ixup-progress-spinner',
  templateUrl: './progress-spinner.component.html',
  animations: [fadeIn],
  standalone: true,
  imports: [NgIf, MatLegacyProgressSpinnerModule],
})
export class ProgressSpinnerComponent implements OnInit {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() monitor: Observable<any>;

  showSpinner;
  private untilDestroyed = untilDestroyed();


  ngOnInit(): void {
    this.showSpinner = true;

    if (this.monitor) {
      this.monitor.pipe(this.untilDestroyed()).subscribe(
        (i) => {
          if (i === null) {
            return;
          } else if (i instanceof Array) {
            this.showSpinner = false;
          } else if (typeof i === 'string' || i instanceof String) {
            this.showSpinner = i === 'ShowSpinner';
          } else if (typeof i === 'boolean' || i instanceof Boolean) {
            this.showSpinner = i;
          } else {
            this.showSpinner = false;
          }
        },
        () => (this.showSpinner = false),
      );
    }
  }
}
