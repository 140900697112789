import { Component } from '@angular/core';

@Component({
  selector: 'insufficient-permission',
  templateUrl: './insufficient-permission.component.html',
  standalone: true,
})
export class InsufficientPermissionComponent{

}
