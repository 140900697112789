import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IFiveSafesDataModel } from 'app/model/five-safes/safe-data.model';
import { IVersionSigningModel } from 'app/model/version-signing.model';
import { Observable } from 'rxjs';

import { ISafeModel } from '../../../../model/five-safes/safe.model';
import { ResultModelGeneric } from '../../../../model/result.model';
import { IxupBaseService } from '../../../../services/ixup-base.service';

@Injectable()
export class SafesService extends IxupBaseService {
  constructor(private http: HttpClient) {
    super();
  }

  public getList(): Observable<ResultModelGeneric<ISafeModel[]>> {
    return this.resolveErrorGeneric<ISafeModel[]>(
      this.http.get('/api/safes/list')
    );
  }

  public getSafesDataList(collaborationId: number): Observable<ResultModelGeneric<IFiveSafesDataModel[]>> {
    return this.resolveErrorGeneric<IFiveSafesDataModel[]>(
      this.http.get(`/api/safes/list/${collaborationId}`)
    );
  }

  public isTwoPersonIntegrity(
    canvasId: number
  ): Observable<ResultModelGeneric<boolean>> {
    return this.resolveErrorGeneric<boolean>(
      this.http.get(`/api/safes/tpi/${canvasId}`)
    );
  }

  public getMultiSigningIds(): Observable<ResultModelGeneric<IVersionSigningModel[]>> {
    return this.resolveErrorGeneric<IVersionSigningModel[]>(
      this.http.get('/api/safes/signing')
    );
  }
  
}
