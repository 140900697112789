import { Action } from '@ngrx/store';

import { IConsoleOrganisationModel } from '../../pages/consoles/model/console-organisation.model';

export enum OrganisationActionTypes {
  LoadOrganisation = '[Organisation] Load Organisation',
  LoadOrganisationSuccess = '[Organisation] Load Organisation success',
  UpdateOrganisation = '[Organisation] Update Organisation',
  UpdateOrganisationSuccess = '[Organisation] Update Organisation success',
  UpdateOrganisationProfile = '[Organisation] Update Organisation Profile',
}

export class LoadOrganisation implements Action {
  readonly type = OrganisationActionTypes.LoadOrganisation;

  constructor(public payload: { organisationId: number }) {}
}

export class LoadOrganisationSuccess implements Action {
  readonly type = OrganisationActionTypes.LoadOrganisationSuccess;

  constructor(public payload: { Organisation: IConsoleOrganisationModel }) {}
}

export class UpdateOrganisation implements Action {
  readonly type = OrganisationActionTypes.UpdateOrganisation;

  constructor(public payload: { Organisation: IConsoleOrganisationModel }) {}
}

export class UpdateOrganisationSuccess implements Action {
  readonly type = OrganisationActionTypes.UpdateOrganisationSuccess;

  constructor(public payload: { Organisation: IConsoleOrganisationModel }) {}
}

export class UpdateOrganisationProfile implements Action {
  readonly type = OrganisationActionTypes.UpdateOrganisationProfile;

  constructor(
    public payload: {
      organisationId: number;
      organisationName: string;
      image: string;
      iconImageId: number;
    },
  ) {}
}

export type OrganisationActions =
  | LoadOrganisation
  | LoadOrganisationSuccess
  | UpdateOrganisation
  | UpdateOrganisationSuccess
  | UpdateOrganisationProfile;
