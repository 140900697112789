import * as fromActions from './agent-action';
import { IDefaultLocationsModel } from '../model/default-location.model';
import { IAgentConfigurationModel } from '../model/agent-configuration';

export interface IAgentState {
  status: boolean;
  lastCheckedOn: Date;
  agentConfiguration: IAgentConfigurationModel;
  defaultLocations: IDefaultLocationsModel;
  loading: boolean;
}

export const initialState: IAgentState = {
  lastCheckedOn: new Date(),
  agentConfiguration: null,
  defaultLocations: {
    uploadLocation: null,
    uploadLocationIsValid: null,
    downloadLocation: null,
    downloadLocationIsValid: null,
  },
  status: false,
  loading: false,
};

export function AgentReducer(
  state = initialState,
  action: fromActions.AgentActions,
): IAgentState {
  switch (action.type) {
  case fromActions.AgentActionTypes.LoadAgentConfiguration: {
    return { ...state, loading: true };
  }
  case fromActions.AgentActionTypes.LoadAgentConfigurationSuccess: {
    return {
      ...state,
      agentConfiguration: action.payload.AgentConfiguration,
      loading: false,
    };
  }
  case fromActions.AgentActionTypes.LoadAgentConfigurationFailed: {
    return {
      ...state,
      loading: false,
    };
  }
  case fromActions.AgentActionTypes.UpdateAgentConfiguration: {
    return {
      ...state,
      loading: true,
    };
  }
  case fromActions.AgentActionTypes.UpdateAgentConfigurationSuccess: {
    return {
      ...state,
      agentConfiguration: action.payload.agentConfiguration,
      loading: false,
    };
  }
  case fromActions.AgentActionTypes.UpdateAgentConfigurationFailed: {
    return {
      ...state,
      loading: false,
    };
  }
  case fromActions.AgentActionTypes.CheckAgentStatusSuccess:
  case fromActions.AgentActionTypes.CheckAgentStatusFailed: {
    return {
      ...state,
      lastCheckedOn: action.payload.lastCheckedOn,
      status: action.payload.status,
      loading: false
    };
  }
  case fromActions.AgentActionTypes.CheckLocationsIsValid:
    return {
      ...state,
      loading: true,
    };
  case fromActions.AgentActionTypes.CheckLocationsIsValidSuccess:
    return {
      ...state,
      defaultLocations: action.payload.defaultLocations,
      loading: false,
    };
  case fromActions.AgentActionTypes.CheckLocationsIsValidFailed:
    return {
      ...state,
      loading: false,
    };
  default:
    return state;
  }
}
