import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input } from '@angular/core';
import { TranslatePipe } from '../../services/translate/translate.pipe';

@Component({
  selector: 'ixup-page-header',
  templateUrl: './page-header.component.html',
  animations: [
    trigger('loading', [
      transition('void => *', [style({ opacity: 0 }), animate(300)]),
    ]),
  ],
  standalone: true,
  imports: [TranslatePipe],
})
export class PageHeaderComponent {
  @Input() header;
}
