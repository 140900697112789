import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'venn-footer',
  templateUrl: './footer-venn.component.html',
  standalone: true,
})
export class VennFooterComponent {
  constructor(public router: Router) {}

}
