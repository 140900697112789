<p class="push-bottom text-center">
  The Data Custodian passphrase has been successfully {{ authCode === PASSPHRASE_TYPE.change ? 'changed' : 'reset' }}.
</p>
<p
  *ngIf='authCode === PASSPHRASE_TYPE.reset'
  class="push-bottom text-center"
>
  This has inactivated all of your Data Custodian's unprocessed data, so data encrypted with the previous
  passphrase may need to be reingested.</p>
<button
  mat-stroked-button
  color="primary"
  class="block full-width btn-rounded text-upper push-top-lg"
  type="submit"
  (click)="close()"
> close
</button>
