import { createFeatureSelector, createSelector } from '@ngrx/store';

import cloneDeep from 'lodash-es/cloneDeep';
import { MatchingStatusEnum } from '../../pages/venn/model/campaign.model';
import { ChartType, MapObject } from '../model/canvas-action-chart';
import { TableStateEnum } from '../model/table-state';
import { VennDashboardState } from './reducer';

export const vennDashboardState =
  createFeatureSelector<VennDashboardState>('vennDashboard');

export const getVennDashboardState = createSelector(
  vennDashboardState,
  (state) => {
    return state;
  },
);

export const getVennCollaboratorOverviewState = createSelector(
  vennDashboardState,
  (state) => state.overview,
);

export const getVennUserCollaborationState = createSelector(
  getVennDashboardState,
  (state) => state.collaborations,
);

export const getCanvasActionChartState = createSelector(
  getVennDashboardState,
  (state) => {
    return state.canvasActionChart;
  },
);

export const getDefaultVennUserCollaborationId = createSelector(
  getVennUserCollaborationState,
  (state) => {
    return state ? state[0]?.collaborationId : null;
  },
);

export const getCurrentVennCollaborationId = createSelector(
  getVennDashboardState,
  (state) => {
    return state ? state.currentDashboardCollaborationId : null;
  },
);

export const getStartDate = createSelector(getVennDashboardState, (state) => {
  return state.startDate;
});

export const getEndDate = createSelector(getVennDashboardState, (state) => {
  return state.endDate;
});

export const getTimeseriesData = createSelector(
  getCanvasActionChartState,
  (state) => {
    if (state) {
      return state.find((c) => c.chartType === ChartType[ChartType.TimeSeries]);
    }
  },
);
export const getFilteredVennChartData = createSelector(
  getCanvasActionChartState,
  getStartDate,
  getEndDate,
  (state, startDate, endDate) => {
    if (state) {
      const vennChart = state.find(
        (c) => c.chartType === ChartType[ChartType.Venn],
      );
      if (!startDate || !endDate) {
        return vennChart;
      }

      const vennState = cloneDeep(vennChart);

      const sets: string[] = Object.keys(vennState.timeSeriesGraphData).filter(
        (n) => n != 'match',
      );

      vennState.vennGraphData = Object.entries(
        vennState.timeSeriesGraphData,
      ).map(([key, value]) => ({
        sets: key == 'match' ? sets : null,
        name: key,
        value: Object.values(
          value.filter((g) => {
            return (
              new Date(g.date) >= new Date(startDate) &&
              new Date(g.date) <= new Date(endDate)
            );
          }),
        ).reduce((a, b) => a + b.value, 0),
      }));
      vennState.vennGraphData.sort((a, b) => b.value - a.value);
      return vennState;
    }
  },
);

export const getFilteredMapBubblesChartData = createSelector(
  getCanvasActionChartState,
  getStartDate,
  getEndDate,
  (state, startDate, endDate) => {
    if (state) {
      const mapBubblesChart = state.find(
        (c) => c.chartType === ChartType[ChartType.MapBubbles],
      );
      if (!startDate && !endDate) {
        return mapBubblesChart;
      }
      const mapBubblesState = cloneDeep(mapBubblesChart);

      const timedata = mapBubblesState.timeSeriesGraphData;
      const geoPointData = mapBubblesState.geoMapData;
      const filteredData = timedata['Geo'].filter(
        (g) => new Date(g.date) >= startDate && new Date(g.date) <= endDate,
      );

      const map = new Map<string, MapObject>();
      filteredData.forEach((group) => {
        const mapObject = map.get(group.name);
        if (mapObject) {
          mapObject.properties.value++;
        } else {
          const other: MapObject = geoPointData.find(
            (m) => m.properties.code.toLocaleLowerCase() == group.name.toLocaleLowerCase(),
          );
          const point: MapObject = {
            type: 'Feature',
            properties: {
              code: group.name,
              name: other.properties.name,
              value: Number(group.value),
            },
            geometry: {
              type: 'Point',
              coordinates: [
                other.geometry.coordinates[0],
                other.geometry.coordinates[1],
              ],
            },
          };
          map.set(group.name, point);
        }
        mapBubblesState.geoMapData = Array.from(map.values());
      });

      return mapBubblesState;
    }
  },
);

export const getCanvasActionChartById = (collaborationId: number) =>
  createSelector(getCanvasActionChartState, (state) => {
    return state
      ? state.find((chart) => chart.collaborationId === collaborationId)
      : null;
  });

export const isMatchingDone = (collaborationId: number) =>
  createSelector(getCampaginById(collaborationId), (state) => {
    return state
      ? state.statusList.some(
        (status) => status.matching === MatchingStatusEnum.Done,
      )
      : null;
  });

export const getStatusListByCollaborator = (
  collaborationId: number,
  collaboratorId: number,
) =>
  createSelector(getCampaginById(collaborationId), (state) => {
    return state
      ? state.statusList.find(
        (status) => status.dataCustodianId === collaboratorId,
      )
      : null;
  });

export const submitForMatchingState = (
  collaborationId: number,
  collaboratorId: number,
) =>
  createSelector(
    getStatusListByCollaborator(collaborationId, collaboratorId),
    (statusList) => {
      if (statusList) {
        console.log(statusList);

        if (!statusList.uploadDatabase === true) return true;
        else if (statusList.matching === MatchingStatusEnum.InProgress)
          return true;
        else if (statusList.submittedMatch === true) return true;
        else if (
          statusList.matching === MatchingStatusEnum.Done &&
          statusList.submittedMatch === false
        )
          return true;
        else return false;
      }
    },
  );

export const setTableEncrytionState = (
  collaborationId: number,
  collaboratorId: number,
) =>
  createSelector(
    getStatusListByCollaborator(collaborationId, collaboratorId),
    (statusList) => {
      if (statusList) {
        if (!statusList.uploadDatabase === true) return null;
        if (
          statusList.uploadDatabase === true &&
          !statusList.submittedMatch === true
        )
          return TableStateEnum.encrypted;
        if (
          statusList.submittedMatch === true &&
          statusList.matching === MatchingStatusEnum.Pending
        )
          return TableStateEnum.encryptedsubmit;
        if (String(statusList.matching) === 'InProgress')
          return TableStateEnum.matching;
        if (statusList.matching === MatchingStatusEnum.Done)
          return TableStateEnum.matched;
      }
    },
  );

export const getCampaginById = (collaborationId: number) =>
  createSelector(getVennUserCollaborationState, (state) => {
    return state
      ? state.find(
        (collaboration) => collaboration.collaborationId === collaborationId,
      )
      : null;
  });

export const getCampaignArrayFilteredById = (collaborationId: number) =>
  createSelector(getVennUserCollaborationState, (state) => {
    return state
      ? state.filter(
        (collaboration) => collaboration.collaborationId === collaborationId,
      )
      : null;
  });

export const getCurrentCanvasId = (collaborationId: number) =>
  createSelector(getVennUserCollaborationState, (state) => {
    return state
      ? state.find(
        (collaboration) => collaboration.collaborationId === collaborationId,
      )?.canvasId
      : null;
  });

export const getListOfCampaigns = createSelector(
  getVennUserCollaborationState,
  (state) => {
    return state ? state : null;
  },
);

export const getDashboardStatus = createSelector(
  getVennDashboardState,
  (state) => {
    return state ? state.currentStatus : null;
  },
);

export const getTableState = createSelector(getVennDashboardState, (state) => {
  return state ? state.tableState : null;
});

export const getDataOwner = createSelector(getVennDashboardState, (state) => {
  return state ? state.dataOwner : false;
});

export const getSegements = createSelector(getVennDashboardState, (state) => {
  return state ? state.segments : null;
});
