import { Inject, Injectable } from '@angular/core';

import { TRANSLATIONS } from './translations';

@Injectable()
export class TranslateService {
  private _defaultLang: string;
  private _currentLang: string;
  private _fallback: boolean;

  public get currentLang() {
    return (this._currentLang = localStorage.getItem('language'));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(@Inject(TRANSLATIONS) private _translations: any) {

  }

  public use(lang: string): void {
    localStorage.setItem('language', lang);
  }

  private translate(key: string): string {
    const translation = key;

    if (
      this._translations[this.currentLang] &&
      this._translations[this.currentLang][key]
    ) {
      return this._translations[this.currentLang][key];
    }

    return translation;
  }

  public instant(key: string): string {
    return this.translate(key);
  }
}
