import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { IxupBaseService } from './ixup-base.service';

export const SiteMode = {
  Standard: 0,
  PowaIndex: 1
};

@Injectable()
export class SiteStatus extends IxupBaseService {

  constructor(private location: Location) {
    super();
  }

  currentMode() {
    if (this.location.path().toLowerCase().includes('powaindex/dashboard')) {
      return SiteMode.PowaIndex;
    }
    else {
      return SiteMode.Standard;
    }
  }
}