import { UserCollaborationsModel } from '../../pages/powaindex/model/user-collaborations.model';
import { ReportDashboardStatusEnum } from '../../pages/powaindex/model/report-dashboard-status.enum';
import { DashboardActions, DashboardActionTypes } from './actions';
import { DashboardStatusEnum } from '../../pages/powaindex/model/dashboard-status.enum';

export interface DashboardState {
  // additional entities state properties
  collaborations: UserCollaborationsModel[];
  currentDashboardCollaborationId: number;
  currentStatus: DashboardStatusEnum | ReportDashboardStatusEnum;
  loading: boolean;
  dataOwner: boolean;
}

export const initialState: DashboardState = {
  // additional entity state properties
  collaborations: null,
  currentDashboardCollaborationId: null,
  currentStatus: null,
  loading: false,
  dataOwner: false,
};

export function DashboardReducer(
  state = initialState,
  action: DashboardActions,
): DashboardState {
  switch (action.type) {
  case DashboardActionTypes.LoadDashboard: {
    return { ...state, loading: true };
  }
  case DashboardActionTypes.LoadDashboardSuccess: {
    return {
      ...state,
      collaborations: action.payload.collaborations,
      loading: false,
    };
  }
  case DashboardActionTypes.SetCurrentDashboard: {
    return {
      ...state,
      currentDashboardCollaborationId: action.payload.currentCollaborationId,
      loading: true,
    };
  }
  case DashboardActionTypes.SetCurrentDashboardSuccess: {
    return {
      ...state,
      loading: false,
    };
  }
  case DashboardActionTypes.SetCurrentDashboardFailed: {
    return {
      ...state,
      loading: false,
    };
  }
  case DashboardActionTypes.GetDashboardStatus: {
    return {
      ...state,
      loading: true,
    };
  }
  case DashboardActionTypes.GetDashboardStatusSuccess: {
    return {
      ...state,
      currentStatus: action.payload.dashboardStatus.status,
      dataOwner: action.payload.dashboardStatus.dataOwner,
      loading: false,
    };
  }
  case DashboardActionTypes.GetDashboardStatusFailed: {
    return {
      ...state,
      loading: false,
    };
  }
  case DashboardActionTypes.UpdateDashboardStatus: {
    return {
      ...state,
      loading: true,
    };
  }
  case DashboardActionTypes.UpdateDashboardStatusSuccess: {
    return {
      ...state,
      currentStatus: action.payload.currentStatus,
      loading: false,
    };
  }
  case DashboardActionTypes.UpdateDashboardStatusFailed: {
    return {
      ...state,
      loading: false,
    };
  }
  default: {
    return state;
  }
  }
}
