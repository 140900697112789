import { CommonModule, DecimalPipe } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { CreditsPipe } from './pipes/credits.pipe';
import { DateTimePipe } from './pipes/dateTime.pipe';
import { DateTimeWithSecondsPipe } from './pipes/dateTimeWithSeconds.pipe';
import { EnumToArrayPipe } from './pipes/enum-to-array.pipe';
import { makeGroupPipe } from './pipes/groupby.pipe';
import { statusPipe } from './pipes/status.pipe';
import { TranslatePipe } from './services/translate/translate.pipe';

@NgModule({
  imports: [
    CommonModule,
    TranslatePipe,
    makeGroupPipe,
    statusPipe,
    EnumToArrayPipe,
    DateTimePipe,
    DateTimeWithSecondsPipe,
    CreditsPipe,
  ],
  providers: [DecimalPipe],
  exports: [
    TranslatePipe,
    makeGroupPipe,
    statusPipe,
    EnumToArrayPipe,
    DateTimePipe,
    DateTimeWithSecondsPipe,
    CreditsPipe,
  ],
})
export class PipeModule {
  static forRoot(): ModuleWithProviders<PipeModule> {
    return {
      ngModule: PipeModule,
      providers: [],
    };
  }
}
