import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { IxupBaseService } from '../ixup-base.service';

Injectable();

@Injectable()
export class CollaboratorKeyService extends IxupBaseService {
  constructor(@Inject(HttpClient) private http: HttpClient) {
    super();
  }

  collaboratorPassphrase(passphrase) {
    const model = { NewPassword: passphrase };
    return this.resolveErrorGeneric<boolean>(
      this.http.post('/api/Collaborator/Key/protect', model),
    );
  }

  collaboratorResetPassphrase() {
    return this.resolveErrorGeneric<boolean>(
      this.http.post('/api/Collaborator/resetrequest', null),
    );
  }

  checkKey(passphrase: string): Observable<boolean> {
    const model = { Password: passphrase };
    return this.resolveError(
      this.http.post('/api/Collaborator/Key/check', model),
    );
  }
}
