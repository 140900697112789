import { Action } from '@ngrx/store';
import { IHubConnectionStatusModel } from '../model/hub-connection-stauts.model';
export enum HubActionTypes {
  ConnectToHub = '[Hub] Load Hub Configuration',
  ConnectToHubSuccess = '[Hub] Load Hub Configuration Success',
  ConnectToHubFailed = '[Hub] Load Hub Configuration Failed',
  CheckHubStatus = '[Hub] Check Hub Status',
  CheckHubStatusSuccess = '[Hub] Check Hub Status Success',
  CheckHubStatusFailed = '[Hub] Check Hub Status Failed',
}

export class ConnectToHub implements Action {
  readonly type = HubActionTypes.ConnectToHub;

}

export class ConnectToHubSuccess implements Action {
  readonly type = HubActionTypes.ConnectToHubSuccess;

  constructor(
    public payload: { hubConnectionStatus: IHubConnectionStatusModel },
  ) {}
}

export class ConnectToHubFailed implements Action {
  readonly type = HubActionTypes.ConnectToHubFailed;

}

export class CheckHubStatus implements Action {
  readonly type = HubActionTypes.CheckHubStatus;
  constructor(public payload: { collaborationId: number }) {}
}

export class CheckHubStatusSuccess implements Action {
  readonly type = HubActionTypes.CheckHubStatusSuccess;
  constructor(
    public payload: { hubConnectionStatus: IHubConnectionStatusModel },
  ) {}
}

export class CheckHubStatusFailed implements Action {
  readonly type = HubActionTypes.CheckHubStatusFailed;
}

export type HubActions =
  | ConnectToHub
  | ConnectToHubSuccess
  | ConnectToHubFailed
  | CheckHubStatus
  | CheckHubStatusSuccess
  | CheckHubStatusFailed;
