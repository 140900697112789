<form [formGroup]="modelForm" novalidate>
  <h2 mat-dialog-title class="text-center">
    <mat-icon class="opacity-50" style="font-size:48px">vpn_key</mat-icon>
    <br />
    Set Data Custodian passphrase
  </h2>
  <mat-dialog-content>
    <p class="text-center push-bottom-lg">
      A passphrase is required to secure your Data Custodian data.
    </p>
    <div class="form-group push-bottom">
      <mat-form-field appearance="outline" class="block">
        <mat-label>
          {{ 'Enter new Data Custodian passphrase' | translate }}
        </mat-label>
        <input matInput formControlName="newPassword" [type]="hideAddCPassphrase ? 'password' : 'text'" />
        <mat-icon matSuffix (click)="hideAddCPassphrase = !hideAddCPassphrase" matTooltip="{{
            hideAddCPassphrase ? 'Show passphrase' : 'Hide passphrase'
          }}" matTooltipClass="ixup-canvas-tooltip">
          {{ hideAddCPassphrase ? 'visibility' : 'visibility_off' }}
        </mat-icon>
        <mat-hint align="start">
          Passphrases can contain spaces, so they can be complex yet memorable.
        </mat-hint>
        <mat-error *ngIf="modelForm.get('newPassword').hasError('required')">
          {{ 'A passphrase is required...' | translate }}
        </mat-error>
      </mat-form-field>
    </div>
    <div class="form-group">
      <mat-form-field appearance="outline" class="block">
        <mat-label>{{ 'Reenter Data Custodian passphrase' | translate }}</mat-label>
        <input matInput formControlName="confirmPassKey" [type]="hideReenterCPassphrase ? 'password' : 'text'" />
        <mat-icon matSuffix (click)="hideReenterCPassphrase = !hideReenterCPassphrase" matTooltip="{{
            hideReenterCPassphrase ? 'Show passphrase' : 'Hide passphrase'
          }}" matTooltipClass="ixup-canvas-tooltip">
          {{ hideReenterCPassphrase ? 'visibility' : 'visibility_off' }}
        </mat-icon>
        <mat-hint align="start"></mat-hint>
        <mat-error *ngIf="modelForm.get('confirmPassKey').hasError('required')">
          {{ 'A passphrase is required...' | translate }}
        </mat-error>
      </mat-form-field>
      <div *ngIf="validationErrorMessage()" class="mat-error" style="font-size:75%; padding-left:12px;">
        {{ validationErrorMessage() }}&nbsp;
      </div>
    </div>
    <div>
      <p class="text-left push-top push-bottom">
        To ensure the security of your data, your passphrase is never stored
        within the IXUP platform.
      </p>
      <p class="text-left push-bottom-md">
        You can change or reset the Data Custodian passphrase later in your Data Custodian profile.
      </p>

      <!-- <br> -->

      <!-- Unlike a password, a passphrase can contain spaces making it easier for you to create a complex yet memorable means to ensure the security of your data. -->
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="text-center full-width block">
      <button mat-stroked-button color="primary" class="block full-width btn-rounded text-upper" type="submit"
        (click)="submit()" [disabled]="!modelForm.valid">
        {{ 'Set Data Custodian passphrase' | translate }}
      </button>
    </div>
  </mat-dialog-actions>
</form>
