import { Action } from '@ngrx/store';
import { IKeyModel } from '../../pages/consoles/admin-console/model/key-request.model';
import { IAgentConfigurationModel } from '../model/agent-configuration';
import { IAgentStatusModel } from '../model/agent-status-model';
import { IDefaultLocationsModel } from '../model/default-location.model';

export enum AgentActionTypes {
  LoadAgentConfiguration = '[Agent] Load Agent Configuration',
  LoadAgentConfigurationSuccess = '[Agent] Load Agent Configuration Success',
  LoadAgentConfigurationFailed = '[Agent] Load Agent Configuration Failed',
  UpdateAgentConfiguration = '[Agent] Update Agent Configuration',
  UpdateAgentConfigurationSuccess = '[Agent] Update Agent Configration Success',
  UpdateAgentConfigurationFailed = '[Agent] Respond Failed',
  UpdateAgentConfigurationWithChecks = '[Agent] Update Agent Configuration With Checks',
  CheckAgentStatus = '[Agent] Check Agent Status',
  CheckAgentStatusSuccess = '[Agent] Check Agent Status Success',
  CheckAgentStatusFailed = '[Agent] Check Agent Status Failed',
  CheckLocationsIsValid = '[Agent] Check Locations Is Valid Status',
  CheckLocationsIsValidSuccess = '[Agent] Check Locations Is Valid Success',
  CheckLocationsIsValidFailed = '[Agent] Check Locations Is Valid Failed',
  SetCustodianPassphrase = '[Agent] Set Custodian Passphrase',
  SetCustodianPassphraseSuccess = '[Agent] Set Custodian Passphrase Success',
  SetCustodianPassphraseFailed = '[Agent] Set Custodian Passphrase Failed',
}

export class LoadAgentConfiguration implements Action {
  readonly type = AgentActionTypes.LoadAgentConfiguration;

}

export class LoadAgentConfigurationSuccess implements Action {
  readonly type = AgentActionTypes.LoadAgentConfigurationSuccess;

  constructor(
    public payload: { AgentConfiguration: IAgentConfigurationModel },
  ) {}
}

export class LoadAgentConfigurationFailed implements Action {
  readonly type = AgentActionTypes.LoadAgentConfigurationFailed;

}

export class UpdateAgentConfiguration implements Action {
  readonly type = AgentActionTypes.UpdateAgentConfiguration;

  constructor(
    public payload: {
      agentConfiguration: IAgentConfigurationModel;
      onSave?: boolean;
    },
  ) {}
}

export class UpdateAgentConfigurationWithChecks implements Action {
  readonly type = AgentActionTypes.UpdateAgentConfigurationWithChecks;

  constructor(
    public payload: {
      agentConfiguration: IAgentConfigurationModel;
      onSave?: boolean;
    },
  ) {}
}

export class UpdateAgentConfigurationSuccess implements Action {
  readonly type = AgentActionTypes.UpdateAgentConfigurationSuccess;

  constructor(
    public payload: {
      agentConfiguration: IAgentConfigurationModel;
    },
  ) {}
}

export class UpdateAgentConfigurationFailed implements Action {
  readonly type = AgentActionTypes.UpdateAgentConfigurationFailed;

  constructor(
    public payload: { AgentConfiguration: IAgentConfigurationModel },
  ) {}
}
export class CheckAgentStatus implements Action {
  readonly type = AgentActionTypes.CheckAgentStatus;
}

export class CheckAgentStatusSuccess implements Action {
  readonly type = AgentActionTypes.CheckAgentStatusSuccess;
  constructor(public payload: IAgentStatusModel) {}
}

export class CheckAgentStatusFailed implements Action {
  readonly type = AgentActionTypes.CheckAgentStatusFailed;
  constructor(public payload: IAgentStatusModel) {}
}

export class CheckLocationsIsValid implements Action {
  readonly type = AgentActionTypes.CheckLocationsIsValid;
  constructor(
    public payload: {
      agentConfiguration: IAgentConfigurationModel;
      onSave?: boolean;
    },
  ) {}
}

export class CheckLocationsIsValidSuccess implements Action {
  readonly type = AgentActionTypes.CheckLocationsIsValidSuccess;
  constructor(public payload: { defaultLocations: IDefaultLocationsModel }) {}
}

export class CheckLocationsIsValidFailed implements Action {
  readonly type = AgentActionTypes.CheckLocationsIsValidFailed;
  constructor(public payload: { defaultLocations: IDefaultLocationsModel }) {}
}

export class SetCustodianPassphrase implements Action {
  readonly type = AgentActionTypes.SetCustodianPassphrase;
  constructor(public payload: { keyModel: IKeyModel; agentBaseUrl: string }) {}
}

export class SetCustodianPassphraseSuccess implements Action {
  readonly type = AgentActionTypes.SetCustodianPassphraseSuccess;
}

export class SetCustodianPassphraseFailed implements Action {
  readonly type = AgentActionTypes.SetCustodianPassphraseFailed;
}

export type AgentActions =
  | LoadAgentConfiguration
  | LoadAgentConfigurationSuccess
  | LoadAgentConfigurationFailed
  | UpdateAgentConfiguration
  | UpdateAgentConfigurationSuccess
  | UpdateAgentConfigurationFailed
  | UpdateAgentConfigurationWithChecks
  | CheckAgentStatus
  | CheckAgentStatusSuccess
  | CheckAgentStatusFailed
  | CheckLocationsIsValid
  | CheckLocationsIsValidSuccess
  | CheckLocationsIsValidFailed
  | SetCustodianPassphrase
  | SetCustodianPassphraseSuccess
  | SetCustodianPassphraseFailed;
