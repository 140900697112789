import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { OrganisationService } from '../../services/organisation/organisation.service';
import { CustodianPassphraseDialogSharedModule } from '../consoles/custodian-console/components/passphrase-dialog/custodian-passphrase-dialog.shared.module';
import { VersionService } from '../control-centre/approvals/services/version.service';
import { SafesService } from '../control-centre/shared/services/safes.service';
import { LoginComponent } from './login.component';
import { LOGIN_ROUTES } from './login.routing';

@NgModule({
  imports: [
    RouterModule.forChild(LOGIN_ROUTES),
    CustodianPassphraseDialogSharedModule,
    MatDialogModule,
    LoginComponent,
  ],
  exports: [LoginComponent],
  providers: [OrganisationService, SafesService , VersionService],
})
export class LoginModule {}
