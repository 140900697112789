<div [@animate] class="pad-left-lg pad-right-lg push-bottom ixup-venn-lettering ixup-tab-links-venn">
  <mat-tab-nav-panel #tabPanel>
    <nav mat-tab-nav-bar mat-stretch-tabs="false" mat-align-tabs="start" [tabPanel]="tabPanel">
      <a *ngIf="venn" mat-tab-link [routerLink]="['/venn/dashboard/' + collaborationId + '/database']" routerLinkActive
        #rla1="routerLinkActive" [active]="rla1.isActive" [class.mat-tab-label-active]="rla1.isActive">
        <span mat-line>
          Database
        </span>
      </a>
      <a *ngIf="venn" [disabled]="!states.insights" mat-tab-link
        [routerLink]="['/venn/dashboard/' + collaborationId + '/insights']" routerLinkActive #rla0="routerLinkActive"
        [active]="rla0.isActive" [class.mat-tab-label-active]="!states.insights">
        <span mat-line>
          Insights
        </span>
      </a>
      <a *ngIf="venn" [disabled]="!states.segments" mat-tab-link
        [routerLink]="['/venn/dashboard/' + collaborationId + '/segments']" routerLinkActive #rla2="routerLinkActive"
        [active]="rla2.isActive" [class.mat-tab-label-active]="!states.segments">
        <span mat-line>
          Segments
        </span>
      </a>
    </nav>
  </mat-tab-nav-panel>
  <div class="flex flex-fill">
  </div>
</div>
