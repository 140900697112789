<h2 mat-dialog-title class="text-center" *ngIf="!passphraseState.newPassphraseSavedSuccessfully">
  <mat-icon class="icon48 opacity-50">vpn_key</mat-icon>
  <br />

  {{ data.authCode === PASSPHRASE_TYPE.change ? 'Change' : 'Reset' }} your Data
  Custodian passphrase
</h2>
<h2 mat-dialog-title class="text-center" *ngIf="
    !passphraseState.showPassphraseForm &&
    passphraseState.newPassphraseSavedSuccessfully
  ">
  <mat-icon class="icon48 opacity-50">verified_user</mat-icon>
  <br />

  Update passphrase confirmation
</h2>

<form [formGroup]="modelForm" novalidate *ngIf="
    !passphraseState.showPassphraseForm &&
    !passphraseState.newPassphraseSavedSuccessfully
  ">
  <mat-dialog-content>
    <!-- confirmation screen -->
    <div class="form-group push-bottom-md">
      <p class="text-center push-bottom-lg">
        Enter your confirmation code and continue.
      </p>
      <mat-form-field appearance="outline" class="block">
        <mat-label>Confirmation code</mat-label>
        <input matInput formControlName="authCode" />
        <mat-hint>
          {{
          'This is the confirmation code that was sent to your email'
          | translate
          }}
        </mat-hint>
      </mat-form-field>
      <div style="font-size: 75%; padding-left: 12px; height: 16px">
        <span *ngIf="passphraseState.incorrectAuthCode" class="mat-error" [@animate]>
          You have provided an incorrect code. Please try again.
        </span>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <!-- Confirmation screen buttons -->
    <div class="flex flex-row-reverse">
      <button mat-stroked-button color="primary" class="btn-rounded text-upper" type="submit" (click)="submit()">
        {{ 'Continue' | translate }}
      </button>
      <button mat-button class="btn-rounded text-upper push-right" type="button" (click)="onCloseAndDeactivateCode()">
        {{ 'Cancel' | translate }}
      </button>
    </div>
  </mat-dialog-actions>
</form>
<passphrase-form *ngIf="
    passphraseState.showPassphraseForm &&
    !passphraseState.newPassphraseSavedSuccessfully
  " [incorrectPassphrase]="passphraseState.incorrectPassphrase"
  (buttonPassphraseSubmit)="onPassphraseSubmit($event)"></passphrase-form>
<confirmation *ngIf="
    !passphraseState.showPassphraseForm &&
    passphraseState.newPassphraseSavedSuccessfully
  " [authCode]="data.authCode" (buttonClose)="onClose()"></confirmation>
