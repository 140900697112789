<a
  mat-list-item
  [routerLink]="['convertr/collaborations/']"
  style="height:64px"
>
  <ixup-logo
    class="ixup-avatar-convertr"
    [imageContainer]="collaboratorIconContainer"
    matTooltip="{{ collaboratorName }}"
    [matTooltipDisabled]="!miniNav"
    matTooltipPosition="after"
    matTooltipClass="ixup-convertr-tooltip"
  ></ixup-logo>
  <span
    [@animate]
    *ngIf="!miniNav"
    class="ixup-title-convertr-16 pad-left-sm"
    style="line-height:14px; color: #fff"
  > {{ collaboratorName }}
  </span>
</a>

<span
  mat-list-item
  class="ixup-left-nav-item"
>
  <a
    mat-button
    [routerLink]="['convertr/collaborations/']"
    [matTooltip]="!miniNav ? '' : 'Collaborations'"
    matTooltipPosition="after"
    matTooltipClass="ixup-convertr-tooltip"
    [ngClass]="{
      'ixup-left-nav-link-active':
        router.url.includes('collaborations') ||
        router.url.includes('database') ||
        router.url.includes('insights') ||
        router.url.includes('segments')
    }"
    class="ixup-left-nav-link"
  >

    <!-- <img
      src="../../../assets/images/convertr/icon-gpsfixed.svg"
      alt=""
      class="ixup-left-nav-svg"
    > -->
    <span
      class="ixup-left-nav-icon-sub"
      [ngClass]="{
      'ixup-left-nav-open':
        !miniNav}"
    >
      <mat-icon svgIcon="convertr-collaborations">
      </mat-icon>
    </span>
    <span
      [@animate]
      class="ixup-left-nav-label tw-500 pad-left-sm"
      *ngIf="!miniNav"
    >Collaborations</span>
  </a>
</span>
<span
  mat-list-item
  class="ixup-left-nav-item"
  *ngIf="manageUsers"
>
  <a
    mat-button
    class="ixup-left-nav-link"
    [ngClass]="{
      'ixup-left-nav-link-active':
        router.url.includes('users')

    }"
    [routerLink]="['/convertr/dashboard/users']"
    [matTooltip]="!miniNav ? '' : 'Users'"
    matTooltipPosition="after"
    matTooltipClass="ixup-convertr-tooltip"
  >
    <span
      class="ixup-left-nav-icon-baseline"
      [ngClass]="{
      'ixup-left-nav-open':
        !miniNav}"
    >
      <mat-icon matListIcon>
        person
      </mat-icon>
    </span>
    <span
      [@animate]
      class="ixup-left-nav-label tw-500 pad-left-sm"
      *ngIf="!miniNav"
    >Users</span>
  </a>
</span>

<!-- <a
  *ngIf="manageUsers"
  mat-list-item
  [routerLink]="['/convertr/dashboard/users']"
  [routerLinkActive]="['mat-active']"
  style="height:64px"
>
  <mat-icon
    matListIcon
    [matTooltip]="!miniNav ? '' : 'Users'"
    matTooltipPosition="after"
    matTooltipClass="ixup-convertr-tooltip"
    style="color:#fff"
  >
    person
  </mat-icon>
  <span
    [@animate]
    mat-line
    *ngIf="!miniNav"
    style="font-family: Cabin; font-size:14px; line-height:14px; color: #fff"
  >Users</span>
</a> -->