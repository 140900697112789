import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ResultModelGeneric } from '../../../../model/result.model';
import { IxupBaseService } from '../../../../services/ixup-base.service';
import { IApprovalRequestModel } from '../models/approval-request.model';
import { IApprovalResponseModel } from '../models/approval-response.model';
import { IApprovalModel } from '../models/approval.model';
import { IResendApprovalRequestModel } from '../models/resend-approval-request';
import { ISigningUserModel } from '../models/signing-user.model';
import { IUpdateSpecificationRecipient } from '../models/update-specification-recipient.model';
import { IVersionModel } from '../models/version.model';

@Injectable()
export class VersionService extends IxupBaseService {
  constructor(private http: HttpClient) {
    super();
  }

  public getAll(
    collaborationId: number
  ): Observable<ResultModelGeneric<IVersionModel[]>> {
    return this.resolveErrorGeneric<IVersionModel[]>(
      this.http.get(`/api/versions/list/${collaborationId}`)
    );
  }

  public get(versionId: number): Observable<ResultModelGeneric<IVersionModel>> {
    return this.resolveErrorGeneric<IVersionModel>(
      this.http.get(`/api/versions/${versionId}`)
    );
  }

  public approvalRequest(
    approval: IApprovalRequestModel
  ): Observable<ResultModelGeneric<string>> {
    return this.resolveErrorGeneric<string>(
      this.http.post('/api/versions/approval/request', approval)
    );
  }

  public GetApprovalsForVersion(
    versionId: number
  ): Observable<ResultModelGeneric<IApprovalModel[]>> {
    return this.resolveErrorGeneric<IApprovalModel[]>(
      this.http.get(`/api/versions/${versionId}/approvals`)
    );
  }

  public resendApprovalRequest(
    resendRequest: IResendApprovalRequestModel
  ): Observable<ResultModelGeneric<boolean>> {
    return this.resolveErrorGeneric(
      this.http.post('/api/versions/approval/resend', resendRequest)
    );
  }

  public respondToApprovalRequest(
    response: IApprovalResponseModel
  ): Observable<ResultModelGeneric<IApprovalResponseModel>> {
    return this.resolveErrorGeneric(
      this.http.post('/api/versions/approval/response', {
        approvalId: response.approvalId,
        collaborationId: response.collaborationId,
        versionId: response.versionId,
        response: response.response,
        message: response.message,
        signingUserId:
          response.signingUserId > 0 ? response.signingUserId : null,
        // safeCategoryLevelIds: response.safeCategoryLevelIds
      })
    );
  }

  public getContractualSpecification(
    versionId: number
  ): Observable<ResultModelGeneric<string>> {
    return this.resolveErrorGeneric<string>(
      this.http.get(`/api/versions/specification/${versionId}`)
    );
  }

  public getContractualSpecificationUrl(
    versionId: number,
    collaboratorId: number
  ): Observable<ResultModelGeneric<string>> {
    return this.resolveErrorGeneric<string>(
      this.http.get(`/api/versions/specification/${versionId}/${collaboratorId}/url`)
    );
  }

  public GetSigningUsers(): Observable<
    ResultModelGeneric<ISigningUserModel[]>
    > {
    return this.resolveErrorGeneric<ISigningUserModel[]>(
      this.http.get('/api/versions/approval/collaboration/signers')
    );
  }

  public updateSpecificationRecipient(
    model: IUpdateSpecificationRecipient
  ): Observable<ResultModelGeneric<boolean>> {
    return this.resolveErrorGeneric<boolean>(
      this.http.post('/api/versions/specification/update', {
        versionId: model.versionId,
        approvalId: model.approvalId,
        userId: model.userId > 0 ? model.userId : null
      })
    );
  }
}
