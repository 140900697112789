import { ReportDashboardStatusEnum } from '../../pages/powaindex/model/report-dashboard-status.enum';
import { UserCollaborationsModel } from '../../pages/powaindex/model/user-collaborations.model';
import { DashboardActions, DashboardActionTypes } from './actions';
import CollaboratorsOverviewModel from '../../pages/playxsafe/model/collaborators-overview-model';
import { DashboardStatusEnum } from '../../pages/powaindex/model/dashboard-status.enum';

export interface PlayXSafeDashboardState {
  // additional entities state properties
  collaborations: UserCollaborationsModel[];
  currentDashboardCollaborationId: number;
  currentStatus: DashboardStatusEnum | ReportDashboardStatusEnum;
  loading: boolean;
  dataOwner: boolean;
  overview: CollaboratorsOverviewModel;
}

export const initialState: PlayXSafeDashboardState = {
  // additional entity state properties
  collaborations: null,
  currentDashboardCollaborationId: null,
  currentStatus: null,
  loading: false,
  dataOwner: false,
  overview: {
    collaborationUploads: null,
    custodianDownloads: null,
    custodianName: null,
  },
};

export function PlayXSafeDashboardReducer(
  state = initialState,
  action: DashboardActions,
): PlayXSafeDashboardState {
  switch (action.type) {
  case DashboardActionTypes.LoadPlayXSafeDashboard: {
    return { ...state, loading: true };
  }
  case DashboardActionTypes.LoadPlayXSafeDashboardSuccess: {
    return {
      ...state,
      collaborations: action.payload.collaborations,
      loading: false,
    };
  }
  case DashboardActionTypes.LoadCollaboratorOverview: {
    return { ...state, loading: true };
  }
  case DashboardActionTypes.LoadCollaboratorOverviewSuccess: {
    return {
      ...state,
      loading: false,
      overview: action.payload.collaboratorsOverview,
    };
  }
  case DashboardActionTypes.SetCurrentPlayXSafeDashboard: {
    return {
      ...state,
      currentDashboardCollaborationId: action.payload.currentCollaborationId,
      loading: true,
    };
  }
  case DashboardActionTypes.SetCurrentPlayXSafeDashboardSuccess: {
    return {
      ...state,
      loading: false,
    };
  }
  case DashboardActionTypes.SetCurrentPlayXSafeDashboardFailed: {
    return {
      ...state,
      loading: false,
    };
  }
  case DashboardActionTypes.GetDashboardStatus: {
    return {
      ...state,
      loading: true,
    };
  }
  case DashboardActionTypes.GetDashboardStatusSuccess: {
    return {
      ...state,
      currentStatus: action.payload.dashboardStatus.status,
      dataOwner: action.payload.dashboardStatus.dataOwner,
      loading: false,
    };
  }
  case DashboardActionTypes.GetDashboardStatusFailed: {
    return {
      ...state,
      loading: false,
    };
  }
  case DashboardActionTypes.UpdateDashboardStatus: {
    return {
      ...state,
      loading: true,
    };
  }
  case DashboardActionTypes.UpdateDashboardStatusSuccess: {
    return {
      ...state,
      currentStatus: action.payload.currentStatus,
      loading: false,
    };
  }
  case DashboardActionTypes.UpdateDashboardStatusFailed: {
    return {
      ...state,
      loading: false,
    };
  }
  default: {
    return state;
  }
  }
}
