import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'convertr-footer',
  templateUrl: './footer-convertr.component.html',
  standalone: true,
})
export class ConvertrFooterComponent {
  constructor(public router: Router) {}
}
