import { HttpParams } from '@angular/common/http';

export class IxupHttpCustomParams extends HttpParams {
  constructor(
    public invokeWebCM: boolean = false,
    public baseUrl = '',

    // Only set useDefaultHostUrl = false, when testing user entered Encryption Gateway path/url (Test Connection)
    public useDefaultHostUrl = true
  ) {
    super();
  }
}
