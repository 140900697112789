import { UserModel } from 'app/model/user.model';
import { AuthUserModel } from '../../model/authuser.model';
import { IOrganisationTypeModel } from '../../pages/consoles/model/organisationTypes.model';

import { UserActionTypes, UserActions } from './user-actions';

export interface IUserState {
  user: AuthUserModel;
  organisationUsers: { orgId: number, users: UserModel[]},
  organisationTypes: IOrganisationTypeModel[];
  loading: boolean;
}

export const initialState: IUserState = {
  user: null,
  organisationUsers: null,
  organisationTypes: [
    { id: 1, organisationType: 'convertr', description: 'test' },
  ],
  loading: false,
};

export function UserReducer(
  state = initialState,
  action: UserActions,
): IUserState {
  switch (action.type) {
  case UserActionTypes.SetUser: {
    return { ...state, loading: true };
  }
  case UserActionTypes.SetUserSuccess: {
    return { ...state, user: action.payload.user, loading: false };
  }
  case UserActionTypes.GetUsersByOrganisationId: {
    return { ...state, loading: true };
  }
  case UserActionTypes.GetUsersByOrganisationIdSuccess: {
    return { ...state, organisationUsers: { orgId: action.payload.orgId, users: action.payload.users }, loading: false };
  }
  case UserActionTypes.LoadOrganisationTypes: {
    return { ...state, loading: true };
  }
  case UserActionTypes.GetUsersByOrganisationIdFailed:
  case UserActionTypes.LoadOrganisationTypesFailed: {
    return { ...state, loading: false };
  }
  case UserActionTypes.LoadOrganisationTypesSuccess: {
    return {
      ...state,
      organisationTypes: action.payload.organisationTypes,
      loading: false,
    };
  }
  default: {
    return state;
  }
  }
}
