import cloneDeep from 'lodash-es/cloneDeep';
import { IOutputTableModel } from '../../pages/output-tables/models/output-table.model';
import { DashboardStatusEnum } from '../../pages/powaindex/model/dashboard-status.enum';
import { ReportDashboardStatusEnum } from '../../pages/powaindex/model/report-dashboard-status.enum';
import {
  CampaignModel,
  StatusModel,
} from '../../pages/venn/model/campaign.model';
import CollaboratorsOverviewModel from '../../pages/venn/model/collaborators-overview-model';
import { CanvasActionChart, ChartType } from '../model/canvas-action-chart';
import { TableStateEnum } from '../model/table-state';
import { DashboardActions, DashboardActionTypes } from './actions';

export interface VennDashboardState {
  // additional entities state properties
  collaborations: CampaignModel[];
  currentDashboardCollaborationId: number;
  canvasActionChart: CanvasActionChart[];
  startDate: Date;
  endDate: Date;
  currentStatus: DashboardStatusEnum | ReportDashboardStatusEnum;
  submittedForMatching: boolean;
  loading: boolean;
  dataOwner: boolean;
  overview: CollaboratorsOverviewModel;
  tableState: TableStateEnum;
  segments: IOutputTableModel[];
}

export const initialState: VennDashboardState = {
  // additional entity state properties
  collaborations: null,
  currentDashboardCollaborationId: null,
  canvasActionChart: null,
  startDate: null,
  endDate: null,
  currentStatus: null,
  submittedForMatching: false,
  loading: false,
  dataOwner: false,
  overview: {
    collaborationUploads: null,
    custodianDownloads: null,
    custodianName: null,
  },
  tableState: null,
  segments: null,
};

export function VennDashboardReducer(
  state = initialState,
  action: DashboardActions,
): VennDashboardState {
  switch (action.type) {
  case DashboardActionTypes.LoadVennDashboard: {
    return { ...state, loading: true };
  }
  case DashboardActionTypes.LoadVennDashboardSuccess: {
    return {
      ...state,
      collaborations: action.payload.collaborations,
      loading: false,
    };
  }
  case DashboardActionTypes.GetVennDashboard:
  case DashboardActionTypes.GetChartDetails: {
    return { ...state, canvasActionChart: null, loading: true };
  }

  case DashboardActionTypes.GetChartDetailsSuccess: {
    return {
      ...state,
      canvasActionChart: action.payload.canvasActionChart,
      loading: false,
    };
  }
  case DashboardActionTypes.GetFilteredChartDetailsSuccess: {
    const timeData = state.canvasActionChart.filter(
      (c) => c.chartType === ChartType[ChartType.TimeSeries],
    );
    const mergedGroup = [...timeData, ...action.payload.canvasActionChart];
    return {
      ...state,
      canvasActionChart: mergedGroup,
      loading: false,
    };
  }
  case DashboardActionTypes.GetVennDashboardSuccess: {
    const newState = cloneDeep(state);
    const index: number = state.collaborations.findIndex(
      (a) =>
        a.collaborationId === action.payload.collaboration.collaborationId,
    );
    if (index !== -1) {
      newState.collaborations[index] = action.payload.collaboration;
    }
    return {
      ...newState,
      loading: false,
    };
  }
  case DashboardActionTypes.LoadCollaboratorOverview: {
    return { ...state, loading: true };
  }
  case DashboardActionTypes.LoadCollaboratorOverviewSuccess: {
    return {
      ...state,
      loading: false,
      overview: action.payload.collaboratorsOverview,
    };
  }
  case DashboardActionTypes.SetCurrentVennDashboard: {
    return {
      ...state,
      currentDashboardCollaborationId: action.payload.currentCollaborationId,
      loading: true,
    };
  }
  case DashboardActionTypes.SetStartDate: {
    return {
      ...state,
      currentDashboardCollaborationId: action.payload.currentCollaborationId,
      startDate: action.payload.startDate,
      loading: true,
    };
  }
  case DashboardActionTypes.SetEndDate: {
    return {
      ...state,
      currentDashboardCollaborationId: action.payload.currentCollaborationId,
      endDate: action.payload.endDate,
      loading: true,
    };
  }
  case DashboardActionTypes.SetCurrentVennDashboardSuccess:
  case DashboardActionTypes.SetCurrentVennDashboardFailed:
  case DashboardActionTypes.UpdateVennApprovalStatusFailed:
  case DashboardActionTypes.UpdateVennLastExportedFailed:
  case DashboardActionTypes.UpdateVennPercentageMatchesFailed:
  case DashboardActionTypes.GetDashboardVennStatusFailed:
  case DashboardActionTypes.UpdateDashboardVennStatusFailed:
  case DashboardActionTypes.UpdateTableStateFailed:
  case DashboardActionTypes.SubmitForMatchingFailed:
  case DashboardActionTypes.LoadSegmentsFailed: {
    return {
      ...state,
      loading: false,
    };
  }
  case DashboardActionTypes.GetDashboardVennStatus:
  case DashboardActionTypes.UpdateDashboardVennStatus:
  case DashboardActionTypes.UpdateVennApprovalStatus:
  case DashboardActionTypes.UpdateVennLastExported:
  case DashboardActionTypes.UpdateVennPercentageMatches:
  case DashboardActionTypes.LoadSegments: {
    return {
      ...state,
      loading: true,
    };
  }
  case DashboardActionTypes.UpdateVennPercentageMatchesSuccess: {
    const newState = cloneDeep(state);
    const index: number = state.collaborations.findIndex(
      (a) => a.collaborationId === action.payload.collaborationId,
    );
    if (index !== -1) {
      newState.collaborations[index].percentageMatches =
          action.payload.percentageMatches;
    }
    return {
      ...newState,
      loading: false,
    };
  }
  case DashboardActionTypes.UpdateVennApprovalStatusSuccess: {
    const newState = cloneDeep(state);
    const index: number = state.collaborations.findIndex(
      (a) => a.collaborationId === action.payload.collaborationId,
    );
    if (index !== -1) {
      newState.collaborations[index].approved = action.payload.approved;
    }
    return {
      ...newState,
      loading: false,
    };
  }
  case DashboardActionTypes.UpdateVennLastExportedSuccess: {
    const newState = cloneDeep(state);
    const index: number = state.collaborations.findIndex(
      (a) => a.collaborationId === action.payload.collaborationId,
    );
    if (index !== -1) {
      newState.collaborations[index].lastExportedDate = action.payload.date;
    }
    return {
      ...newState,
      loading: false,
    };
  }
  case DashboardActionTypes.SubmitForMatchingSuccess: {
    return {
      ...state,
      submittedForMatching: true,
      loading: false,
    };
  }
  case DashboardActionTypes.UpdateTableState: {
    return {
      ...state,
      tableState: action.payload.tableState,
      loading: false,
    };
  }

  case DashboardActionTypes.UpdateStatusList: {
    const newState = cloneDeep(state);
    const index = state.collaborations.findIndex(
      (a) => a.collaborationId === action.payload.collaborationId,
    );

    const payloadStatusModel = action.payload.statusList as StatusModel;

    if (index !== -1) {
      const campaignModel = newState.collaborations[index];

      if (action.payload.dataCustodianId) {
        const newStatusList = campaignModel.statusList;
        const statusIndex = newStatusList.findIndex(
          (dc) => dc.dataCustodianId === action.payload.dataCustodianId,
        );
          // console.assert(statusIndex === -1);
        if (statusIndex !== -1) {
          campaignModel.statusList[statusIndex] = {
            ...campaignModel.statusList[statusIndex],
            ...payloadStatusModel,
          };
        } else {
          console.log(
            'couldnt find dc index',
            action.payload.dataCustodianId,
          );
        }
      } else {
        campaignModel.statusList = newState.collaborations[
          index
        ].statusList.map((status) => ({ ...status, ...payloadStatusModel }));
      }
    }
    return {
      ...newState,
      loading: false,
    };
  }

  case DashboardActionTypes.GetDashboardVennStatusSuccess: {
    return {
      ...state,
      currentStatus: action.payload.dashboardStatus.status,
      dataOwner: action.payload.dashboardStatus.dataOwner,
      loading: false,
    };
  }
  case DashboardActionTypes.UpdateDashboardVennStatusSuccess: {
    return {
      ...state,
      currentStatus: action.payload.currentStatus,
      loading: false,
    };
  }
  case DashboardActionTypes.UpdateTableStateSuccess: {
    return {
      ...state,
      tableState: action.payload.tableState,
      loading: false,
    };
  }
  case DashboardActionTypes.LoadSegmentsSuccess:
    return {
      ...state,
      segments: action.payload.segments,
      loading: false,
    };
  default: {
    return state;
  }
  }
}
