import { IEnvironment } from './environment-model';

export const environment: IEnvironment = {
  authority: 'https://login.microsoftonline.com/95099e1c-6947-4561-830f-cef64868fd2e',
  tenant: 'devadd.ixup.com',
  redirectUri: window.location.origin + '/',
  postLogoutRedirectUri: window.location.origin + '/',
  clientId: '74023344-a4c3-4e7b-bf70-935bf0e55794',
  webApiEndpoint: 'https://ixupdevwebapi.azurewebsites.net',
  clientApiEndpoint: 'http://localhost:5400',
  clientManagerUrl: 'https://ixupdevstorage.blob.core.windows.net/installer/IXUPsetup.exe',
  environmentName: 'dev',
  secEnvironmentName: 'dev',
  requireClientCert: false,
  production: false,
  paas: false,
  fileDrop: {
    allowedExtensions: ['.csv'],
    maxFileSizeBytes: 536870912,
  }
};
