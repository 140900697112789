import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateTimeWithSeconds',
  standalone: true,
})
export class DateTimeWithSecondsPipe extends DatePipe implements PipeTransform {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transform(date: Date): any {
    return super.transform(date, 'd MMM, y, h:mm:ss a');
  }
}
