<mat-toolbar
  *ngIf="tandc"
  class="mat-elevation-z2"
>
  <div
    class="switch-collaborator flex-row flex-[220px]"
    *ngIf="(urlRootSection$ | async) === 'enabler-console'"
  >
    <button
      mat-button
      [routerLink]="urlRootSection$ | async"
      class="flex-row pad-left-none"
    >
      <div class="mat-image-container">
        <ixup-logo [imageContainer]="enablerIconContainer"></ixup-logo>
      </div>
      <div class="flex items-center text-truncate fs-14">
        {{ (currentUser$ | async)?.EnablerName }}
      </div>
    </button>
  </div>
  <div
    class="switch-collaborator flex-row flex-[220px]"
    *ngIf="(urlRootSection$ | async) === 'admin-console'"
  >
    <button
      mat-button
      [routerLink]="urlRootSection$ | async"
      class="flex-row pad-left-none"
    >
      <div class="mat-image-container">
        <ixup-logo [imageContainer]="organisationIconContainer"></ixup-logo>
      </div>
      <div class="flex items-center text-truncate fs-14">
        {{ organisationName }}
      </div>
    </button>
  </div>
  <div
    class='switch-collaborator flex-row flex-[220px]'
    *ngIf="
      (urlRootSection$ | async) === 'custodian-console' ||
      (urlRootSection$ | async) === 'control-centre'
    "
  >
    <button
      mat-button
      [routerLink]="
        (urlRootSection$ | async) === 'custodian-console'
          ? (urlRootSection$ | async)
          : '/custodian-console/collaborations'
      "
      class="flex-row pad-left-none"
    >
      <div class="mat-image-container">
        <ixup-logo [imageContainer]="collaboratorIconContainer"></ixup-logo>
      </div>
      <div class="flex items-center text-truncate fs-14">
        {{ collaboratorName }}
      </div>
    </button>
  </div>

  <div
    class="flex-row flex-[220px]"
    *ngIf="(urlRootSection$ | async) === 'convertr'"
  >
    <img
      src="assets/images/convertr/convertr-full-rev-hor.svg"
      class="ixup-header-convertr"
      alt="Convertr by Cipher"
    />
  </div>
  <div
    class="flex-row flex-[220px]"
    *ngIf="(urlRootSection$ | async) === 'venn'"
  >
    <img
      src="assets/images/venn/venn-full-rev-hor.svg"
      class="ixup-header-venn"
      alt="Venn"
      style="image-rendering: optimizeQuality;"
    />
  </div>


  <div class="flex flex-1 justify-end items-center">
    <div
      class="ixup-select-dashboard push-right"
      *ngIf="(urlRootSection$ | async) === 'playXsafe' && this.playXSafeDashboards"
    >
      <mat-form-field *ngIf="playXSafeDashboards.length > 0">
        <mat-select
          [(value)]="selectedPlayXSafeDashboard"
          panelClass="ixup-console-select"
        >
          <mat-select-trigger>
            <mat-icon class="ixup-button-icon-sm push-right-sm">dashboard </mat-icon>
            <span class="text-truncate"> {{ selectedPlayXSafeDashboard }}</span>
          </mat-select-trigger>
          <mat-option
            [value]="option.viewDashboard"
            *ngFor="let option of playXSafeDashboards"
            (click)="goToPlayXSafeDashboard(option)"
          >
            <mat-icon class="ixup-button-icon-sm push-right-sm">dashboard </mat-icon>
            {{ option.viewDashboard }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <img
      src="assets/images/logos/logo-ixup-protected.svg"
      class="push-right-sm push-left-sm"
      matTooltip="The IXUP Secure Data Engine keeps your data safe. It ensures your data is encrypted at source, remains encrypted in transit, and all matching, processing and computation is performed on encrypted data."
      matTooltipClass="ixup-canvas-tooltip"
      matTooltipShowDelay="200"
      matTooltipPosition="before"
      *ngIf="
        ((urlRootSection$ | async) === 'playXsafe' && this.playXSafeDashboards)
      "
      alt="IXUP Protected"
    />
    <img
      src="assets/images/logos/logo-ixup-protected-w.svg"
      class="push-right push-left-sm"
      matTooltip="The IXUP Secure Data Engine keeps your data safe. It ensures your data is encrypted at source, remains encrypted in transit, and all matching, processing and computation is performed on encrypted data."
      matTooltipClass="ixup-canvas-tooltip"
      matTooltipShowDelay="200"
      matTooltipPosition="before"
      *ngIf="
     ((urlRootSection$ | async) === 'venn' && this.vennDashboards)
    "
      alt="IXUP Protected"
    />
    <button
      *ngIf="!enablerAccessOnly && (!convertrUser && !vennUser) &&  (urlRootSection$ | async) !== 'enabler-console'"
      @animate
      mat-icon-button
      [routerLink]="(urlRootSection$ | async) + '/agent'"
      [routerLinkActive]="['mat-active']"
    >
      <mat-icon
        @animate
        matTooltip="Encryption Gateway connected & active"
        matTooltipClass="ixup-canvas-tooltip"
        matTooltipShowDelay="800"
        matTooltipPosition="before"
        *ngIf="agentRunning"
        class="!flex"
        [ngClass]="{'icon-eg-connected':(urlRootSection$ | async) !== 'convertr' && (urlRootSection$ | async) !== 'venn', 'icon-eg-connected-convertr':(urlRootSection$ | async) === 'convertr' && (urlRootSection$ | async) !== 'venn'}"
      >
        <i class="icon icon-agent text-top" title="Encryption Gateway connected & active" aria-hidden="true"></i>
      </mat-icon>
      <mat-icon
        @animate
        matTooltip="Encryption Gateway not connected"
        matTooltipClass="ixup-canvas-tooltip"
        matTooltipShowDelay="800"
        matTooltipPosition="left"
        *ngIf="!agentRunning"
        class="!flex icon-eg-disconnected"
      >
        <i class="icon icon-agent text-top" title="Encryption Gateway not connected" aria-hidden="true"></i>
      </mat-icon>
    </button>

    <button
      *ngIf="convertrUser && accessAdminConsole"
      @animate
      mat-icon-button
      [routerLink]="(urlRootSection$ | async) + '/agent'"
      [routerLinkActive]="['mat-active']"
    >
      <mat-icon
        @animate
        matTooltip="Encryption Gateway connected & active"
        matTooltipClass="ixup-convertr-tooltip"
        matTooltipShowDelay="800"
        matTooltipPosition="before"
        *ngIf="agentRunning"
        [ngClass]="{'icon-eg-connected':(urlRootSection$ | async) !== 'convertr', 'icon-eg-connected-convertr':(urlRootSection$ | async) === 'convertr'}"
      >
        <i class="icon icon-agent text-top" title="Encryption Gateway connected & active" aria-hidden="true"></i>
      </mat-icon>
      <mat-icon
        @animate
        matTooltip="Encryption Gateway not connected"
        matTooltipClass="ixup-canvas-tooltip"
        matTooltipShowDelay="800"
        matTooltipPosition="before"
        *ngIf="!agentRunning"
        class="icon-eg-disconnected"
      >
        <i class="icon icon-agent text-top" title="Encryption Gateway not connected" aria-hidden="true"></i>
      </mat-icon>
    </button>
    <button
      *ngIf="vennUser && accessAdminConsole"
      @animate
      mat-icon-button
      [routerLink]="(urlRootSection$ | async) + '/agent'"
      [routerLinkActive]="['mat-active']"
    >
      <mat-icon
        @animate
        matTooltip="Encryption Gateway connected & active"
        matTooltipClass="ixup-venn-tooltip"
        matTooltipShowDelay="800"
        matTooltipPosition="before"
        *ngIf="agentRunning"
        [ngClass]="{'icon-eg-connected':(urlRootSection$ | async) !== 'venn', 'icon-eg-connected-venn':(urlRootSection$ | async) === 'venn'}"
      >
        <i class="icon icon-agent text-top" title="Encryption Gateway connected & active" aria-hidden="true"></i>
      </mat-icon>
      <mat-icon
        @animate
        matTooltip="Encryption Gateway not connected"
        matTooltipClass="ixup-canvas-tooltip"
        matTooltipShowDelay="800"
        matTooltipPosition="before"
        *ngIf="!agentRunning"
        class="icon-eg-disconnected"
      >
        <i class="icon icon-agent text-top" title="Encryption Gateway not connected" aria-hidden="true"></i>
      </mat-icon>
    </button>
    <div
      class="flex push-right"
      *ngIf="!accessAdminConsole && convertrUser"
    >
      <mat-icon
        @animate
        matTooltip="Encryption Gateway connected & active"
        matTooltipClass="ixup-canvas-tooltip"
        matTooltipShowDelay="800"
        matTooltipPosition="before"
        *ngIf="agentRunning"
        class="v-align-mid"
        [ngClass]="{'icon-eg-connected':(urlRootSection$ | async) !== 'convertr', 'icon-eg-connected-convertr':(urlRootSection$ | async) === 'convertr'}"
      >
        <i class="icon icon-agent text-top" title="Encryption Gateway connected & active" aria-hidden="true"></i>
      </mat-icon>
      <mat-icon
        @animate
        matTooltip="Encryption Gateway not connected"
        matTooltipClass="ixup-canvas-tooltip"
        matTooltipShowDelay="800"
        matTooltipPosition="before"
        *ngIf="!agentRunning"
        class="icon-eg-disconnected v-align-mid"
      >
        <i class="icon icon-agent text-top" title="Encryption Gateway not connected" aria-hidden="true"></i>
      </mat-icon>
    </div>

    <div
      class="flex push-sm"
      *ngIf="(!accessAdminConsole && vennUser) ||  (urlRootSection$ | async) === 'enabler-console'"
    >
      <mat-icon
        matTooltip="Encryption Gateway connected & active"
        matTooltipClass="ixup-canvas-tooltip"
        matTooltipShowDelay="800"
        matTooltipPosition="before"
        *ngIf="agentRunning"
        class="push-right-xs"
        [ngClass]="{'icon-eg-connected':(urlRootSection$ | async) !== 'venn', 'icon-eg-connected-venn':(urlRootSection$ | async) === 'venn'}"
      >
        <i class="icon icon-agent text-top" title="Encryption Gateway connected & active" aria-hidden="true"></i>
      </mat-icon>
      <mat-icon
        matTooltip="Encryption Gateway not connected"
        matTooltipClass="ixup-canvas-tooltip"
        matTooltipShowDelay="800"
        matTooltipPosition="before"
        *ngIf="!agentRunning"
        class="icon-eg-disconnected push-right-xs"
      >
        <i class="icon icon-agent text-top" title="Encryption Gateway not connected" aria-hidden="true"></i>
      </mat-icon>
    </div>


    <button
      mat-icon-button
      [matMenuTriggerFor]="menuapps"
      *ngIf="
        accessInfraConsole ||
        accessEnablerConsole ||
        accessAdminConsole ||
        accessCustodianConsole ||
        playXSafeUser
      "
    >
      <mat-icon>apps</mat-icon>
    </button>
    <mat-menu
      #menuapps="matMenu"
      [overlapTrigger]="false"
    >
      <button
        mat-menu-item
        *ngIf="accessInfraConsole"
        routerLink="infrastructure-console"
      >
        <span>{{ 'Infrastructure console' | translate }}</span>
      </button>
      <button
        mat-menu-item
        *ngIf="accessEnablerConsole"
        routerLink="enabler-console"
        [class.ixup-convertr-menu-button]="(urlRootSection$ | async) === 'convertr'"
        [class.ixup-venn-menu-button]="(urlRootSection$ | async) === 'venn'"
      >
        <span>{{ 'Enabler console' | translate }}</span>
      </button>
      <button
        mat-menu-item
        *ngIf="accessAdminConsole"
        (click)="adminConsole()"
        [class.ixup-convertr-menu-button]="(urlRootSection$ | async) === 'convertr'"
        [class.ixup-venn-menu-button]="(urlRootSection$ | async) === 'venn'"
      >
        <span>{{ 'Admin console' | translate }}</span>
      </button>
      <button
        mat-menu-item
        *ngIf="accessCustodianConsole"
        (click)="custodianConsole()"
        [class.ixup-convertr-menu-button]="(urlRootSection$ | async) === 'convertr'"
        [class.ixup-venn-menu-button]="(urlRootSection$ | async) === 'venn'"
      >
        <span>{{ 'Data Custodian console' | translate }}</span>
      </button>

      <button
        mat-menu-item
        *ngIf="playXSafeUser"
        (click)="playXSafeDashboard()"
        [class.ixup-convertr-menu-button]="(urlRootSection$ | async) === 'convertr'"
        [class.ixup-venn-menu-button]="(urlRootSection$ | async) === 'venn'"
      >
        <span>{{ 'playXsafe dashboard' | translate }}</span>
      </button>
      <button
        mat-menu-item
        *ngIf="convertrUser"
        (click)="convertrDashboard()"
        [class.ixup-convertr-menu-button]="(urlRootSection$ | async) === 'convertr'"
        [class.ixup-venn-menu-button]="(urlRootSection$ | async) === 'venn'"
      >
        <span>{{ 'Convertr dashboard' | translate }}</span>
      </button>
      <button
        mat-menu-item
        *ngIf="vennUser"
        (click)="vennDashboard()"
        [class.ixup-convertr-menu-button]="(urlRootSection$ | async) === 'convertr'"
        [class.ixup-venn-menu-button]="(urlRootSection$ | async) === 'venn'"
      >
        <span>{{ 'Venn dashboard' | translate }}</span>
      </button>
    </mat-menu>


    <button
      mat-icon-button
      id="menuapps"
      [matMenuTriggerFor]="menuuser"
      class="push-left-xs pad-none"
      *ngIf="(urlRootSection$ | async) !== 'convertr' && (urlRootSection$ | async) !== 'venn'"
    >
      <ixup-logo
        class="flex justify-center"
        [imageContainer]="userIconContainer"
      ></ixup-logo>
    </button>
    <mat-menu
      #menuuser="matMenu"
      [overlapTrigger]="false"
      class="mat-menu-user-settings"
    >
      <button
        mat-menu-item
        [routerLink]="'/' + (urlRootSection$ | async) + '/user-profile'"
        routerLinkActive="mat-accent"
        *ngIf="
          (urlRootSection$ | async) !== 'playXsafe' && (urlRootSection$ | async) !== 'convertr' && (urlRootSection$ | async) !== 'venn'
        "
      >
        <mat-icon
          mat-list-avatar
          class="img-circle"
        >
          <ixup-logo [imageContainer]="userIconContainer"></ixup-logo>
        </mat-icon>
        {{ 'User profile' | translate }}
      </button>
      <button
        mat-menu-item
        [routerLink]="'/' + (urlRootSection$ | async) + '/dashboard/user-profile'"
        routerLinkActive="mat-accent"
        *ngIf="(urlRootSection$ | async) === 'playXsafe' || (urlRootSection$ | async) === 'convertr'  || (urlRootSection$ | async) === 'venn'"
      >
        <mat-icon
          mat-list-avatar
          class="img-circle"
        >
          <ixup-logo [imageContainer]="userIconContainer"></ixup-logo>
        </mat-icon>
        {{ 'User profile' | translate }}
      </button>
      <button
        mat-menu-item
        [routerLink]="'/' + (urlRootSection$ | async) + '/enabler-profile'"
        *ngIf="(urlRootSection$ | async) === 'enabler-console'"
      >
        <mat-icon mat-list-avatar>
          <ixup-logo
            *ngIf="currentUser$ | async as user"
            [imageContainer]="enablerIconContainer"
          ></ixup-logo>
        </mat-icon>
        {{ 'Enabler profile' | translate }}
      </button>
      <button
        mat-menu-item
        [routerLink]="'/' + (urlRootSection$ | async) + '/organisation-profile'"
        *ngIf="(urlRootSection$ | async) === 'admin-console'"
      >
        <mat-icon mat-list-avatar>
          <ixup-logo
            *ngIf="currentUser$ | async as user"
            [imageContainer]="organisationIconContainer"
          ></ixup-logo>
        </mat-icon>
        {{ 'Organisation profile' | translate }}
      </button>
      <button
        mat-menu-item
        [routerLink]="'/' + (urlRootSection$ | async) + '/collaborator-profile'"
        *ngIf="(urlRootSection$ | async) === 'custodian-console'"
      >
        <mat-icon mat-list-avatar>
          <ixup-logo [imageContainer]="collaboratorIconContainer"></ixup-logo>
        </mat-icon>
        {{ 'Data Custodian profile' | translate }}
      </button>
      <button
        mat-menu-item
        [routerLink]="'/custodian-console/collaborator-profile'"
        *ngIf="(urlRootSection$ | async) === 'control-centre'"
      >
        <mat-icon mat-list-avatar>
          <ixup-logo [imageContainer]="collaboratorIconContainer"></ixup-logo>
        </mat-icon>
        {{ 'Data Custodian profile' | translate }}
      </button>
      <button
        mat-menu-item
        [routerLink]="'/' + (urlRootSection$ | async) + '/signing-documents'"
        *ngIf="(urlRootSection$ | async) === 'custodian-console'"
      >
      <mat-icon mat-list-icon>edit_document</mat-icon>
        {{ 'Smart Contracts' | translate }}
      </button>
      <button
        mat-menu-item
        [routerLink]="'/' + (urlRootSection$ | async) + '/dashboard/user-profile'"
        routerLinkActive="mat-accent"
        *ngIf="(urlRootSection$ | async) === 'playXsafe' || (urlRootSection$ | async) === 'convertr'  || (urlRootSection$ | async) === 'venn'"
      >
        <mat-icon mat-list-avatar>
          <ixup-logo [imageContainer]="userIconContainer"></ixup-logo>
        </mat-icon>
        {{ 'User profile' | translate }}
      </button>
      <button
        *ngIf=" (urlRootSection$ | async)==='custodian-console' || ((urlRootSection$ | async)==='control-centre'
        && ownerCollaboratorList?.length> 1)
        "
        mat-menu-item
        [routerLink]="'/custodian-console/switch-collaborator'"
      >
        <mat-icon mat-list-icon>swap_horiz</mat-icon>
        {{ 'Switch Data Custodian' | translate }}
      </button>
      <button
        *ngIf="(urlRootSection$ | async) === 'admin-console'"
        mat-menu-item
        [routerLink]="'/' + (urlRootSection$ | async) + '/switch-organisation'"
      >
        <mat-icon mat-list-icon>swap_horiz</mat-icon>
        {{ 'Switch organisation' | translate }}
      </button>
      <button
        mat-menu-item
        onClick="window.open('https://support.ixup.com')"
        *ngIf="(urlRootSection$ | async) !== 'playXsafe'"
      >
        <mat-icon mat-list-avatar>contact_support</mat-icon>
        {{ 'Help' | translate }}
      </button>
      <button
        mat-menu-item
        onClick="window.open('https://playXsafesupport.ixup.com')"
        *ngIf="(urlRootSection$ | async) === 'playXsafe'"
      >
        <mat-icon mat-list-avatar>contact_support</mat-icon>
        {{ 'Help' | translate }}
      </button>
      <!-- <button
        mat-menu-item
        [routerLink]="'/' + (urlRootSection$ | async) + '/about'"
        *ngIf="
          (urlRootSection$ | async) !== 'playXsafe'
        "
      >
        <mat-icon mat-list-avatar>info</mat-icon>
        {{ 'About' | translate }}
      </button>
      <button
        mat-menu-item
        [routerLink]="'/' + (urlRootSection$ | async) + '/dashboard/about'"
        *ngIf="(urlRootSection$ | async) === 'playXsafe'"
      >
        <mat-icon mat-list-avatar>info</mat-icon>
        {{ 'About' | translate }}
      </button> -->
      <button
        *ngIf="accessAdminConsole || accessCustodianConsole || playXSafeUser"
        mat-menu-item
        targe="_blank"
        (click)="downloadFileManagementApp()"
      >
        <mat-icon mat-list-avatar>file_download</mat-icon>
        {{ 'Encryption Gateway' | translate }}
      </button>
      <mat-divider></mat-divider>
      <span *ngIf="authService.isLoggedIn">
        <button
          mat-menu-item
          (click)="logout()"
        >
          <mat-icon>exit_to_app</mat-icon>
          {{ 'Sign out' | translate }}
        </button>
      </span>
    </mat-menu>



    <button
      mat-icon-button
      id="menuapps"
      [matMenuTriggerFor]="menuuser2"
      class="push-left-sm pad-none"
      *ngIf="(urlRootSection$ | async) === 'convertr' || (urlRootSection$ | async) === 'venn'"
    >
      <ixup-logo [imageContainer]="userIconContainer"></ixup-logo>
    </button>
    <mat-menu
      #menuuser2="matMenu"
      [overlapTrigger]="false"
      class="mat-menu-user-settings"
    >
      <button
        mat-menu-item
        [routerLink]="'/' + (urlRootSection$ | async) + '/user-profile'"
        *ngIf="
        (urlRootSection$ | async) !== 'playXsafe' && (urlRootSection$ | async) !== 'convertr' && (urlRootSection$ | async) !== 'venn'
      "
      >
        <mat-icon
          mat-list-avatar
          class="img-circle"
        >
          <ixup-logo [imageContainer]="userIconContainer"></ixup-logo>
        </mat-icon>
        {{ 'User profile' | translate }}
      </button>
      <button
        mat-menu-item
        [routerLink]="'/' + (urlRootSection$ | async) + '/dashboard/user-profile'"
        *ngIf="(urlRootSection$ | async) === 'playXsafe' || (urlRootSection$ | async) === 'convertr' || (urlRootSection$ | async) === 'venn'"
        [class.ixup-convertr-menu-button]="(urlRootSection$ | async) === 'convertr'"
        [class.ixup-venn-menu-button]="(urlRootSection$ | async) === 'venn'"
      >
        <mat-icon
          mat-list-avatar
          class="img-circle"
        >
          <ixup-logo [imageContainer]="userIconContainer"></ixup-logo>
        </mat-icon>
        {{ 'User profile' | translate }}
      </button>
      <button
        mat-menu-item
        [routerLink]="'/' + (urlRootSection$ | async) + '/enabler-profile'"
        *ngIf="(urlRootSection$ | async) === 'enabler-console'"
      >
        <mat-icon mat-list-avatar>
          <ixup-logo
            *ngIf="currentUser$ | async as user"
            [imageContainer]="enablerIconContainer"
          ></ixup-logo>
        </mat-icon>
        {{ 'Enabler profile' | translate }}
      </button>
      <button
        mat-menu-item
        [routerLink]="'/' + (urlRootSection$ | async) + '/organisation-profile'"
        *ngIf="(urlRootSection$ | async) === 'admin-console'"
      >
        <mat-icon mat-list-avatar>
          <ixup-logo
            *ngIf="currentUser$ | async as user"
            [imageContainer]="organisationIconContainer"
          ></ixup-logo>
        </mat-icon>
        {{ 'Organisation profile' | translate }}
      </button>
      <button
        mat-menu-item
        [routerLink]="'/' + (urlRootSection$ | async) + '/collaborator-profile'"
        *ngIf="(urlRootSection$ | async) === 'custodian-console'"
      >
        <mat-icon mat-list-avatar>
          <ixup-logo [imageContainer]="collaboratorIconContainer"></ixup-logo>
        </mat-icon>
        {{ 'Data Custodian profile' | translate }}
      </button>
      <button
        mat-menu-item
        [routerLink]="'/custodian-console/collaborator-profile'"
        *ngIf="(urlRootSection$ | async) === 'control-centre'"
      >
        <mat-icon mat-list-avatar>
          <ixup-logo [imageContainer]="collaboratorIconContainer"></ixup-logo>
        </mat-icon>
        {{ 'Data Custodian profile' | translate }}
      </button>
      <button
        *ngIf="(urlRootSection$ | async) === 'convertr'  || (urlRootSection$ | async) === 'venn'"
        mat-menu-item
        [routerLink]="'/' + (urlRootSection$ | async) + '/dashboard/switch-collaborator'"
        [class.ixup-convertr-menu-button]="(urlRootSection$ | async) === 'convertr'"
        [class.ixup-venn-menu-button]="(urlRootSection$ | async) === 'venn'"
      >
        <mat-icon mat-list-icon>swap_horiz</mat-icon>
        {{ 'Switch Data Custodian' | translate }}
      </button>

      <button
        *ngIf="(urlRootSection$ | async) === 'admin-console'"
        mat-menu-item
        [routerLink]="'/' + (urlRootSection$ | async) + '/switch-organisation'"
      >
        <mat-icon mat-list-icon>swap_horiz</mat-icon>
        {{ 'Switch organisation' | translate }}
      </button>
      <button
        mat-menu-item
        onClick="window.open('https://support.ixup.com')"
        *ngIf="(urlRootSection$ | async) !== 'playXsafe'"
        [class.ixup-convertr-menu-button]="(urlRootSection$ | async) === 'convertr'"
        [class.ixup-venn-menu-button]="(urlRootSection$ | async) === 'venn'"
      >
        <mat-icon mat-list-avatar>contact_support</mat-icon>
        {{ 'Help' | translate }}
      </button>
      <button
        mat-menu-item
        onClick="window.open('https://playXsafesupport.ixup.com')"
        *ngIf="(urlRootSection$ | async) === 'playXsafe'"
      >
        <mat-icon mat-list-avatar>contact_support</mat-icon>
        {{ 'Help' | translate }}
      </button>
      <!-- <button
      mat-menu-item
      [routerLink]="'/' + (urlRootSection$ | async) + '/about'"
      *ngIf="
        (urlRootSection$ | async) !== 'playXsafe'
      "
    >
      <mat-icon mat-list-avatar>info</mat-icon>
      {{ 'About' | translate }}
    </button>
    <button
      mat-menu-item
      [routerLink]="'/' + (urlRootSection$ | async) + '/dashboard/about'"
      *ngIf="(urlRootSection$ | async) === 'playXsafe'"
    >
      <mat-icon mat-list-avatar>info</mat-icon>
      {{ 'About' | translate }}
    </button> -->
      <button
        *ngIf="accessAdminConsole || accessCustodianConsole || playXSafeUser"
        mat-menu-item
        targe="_blank"
        (click)="downloadFileManagementApp()"
        [class.ixup-convertr-menu-button]="(urlRootSection$ | async) === 'convertr'"
        [class.ixup-venn-menu-button]="(urlRootSection$ | async) === 'venn'"
      >
        <mat-icon mat-list-avatar>file_download</mat-icon>
        {{ 'Encryption Gateway' | translate }}
      </button>
      <mat-divider></mat-divider>
      <span *ngIf="authService.isLoggedIn">
        <button
          mat-menu-item
          (click)="logout()"
          [class.ixup-convertr-menu-button]="(urlRootSection$ | async) === 'convertr'"
          [class.ixup-venn-menu-button]="(urlRootSection$ | async) === 'venn'"
        >
          <mat-icon>exit_to_app</mat-icon>
          {{ 'Sign out' | translate }}
        </button>
      </span>
    </mat-menu>



  </div>
</mat-toolbar>
