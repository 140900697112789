import { Action } from '@ngrx/store';

import CollaboratorsOverviewModel from '../../pages/playxsafe/model/collaborators-overview-model';
import { DashboardStatusEnum } from '../../pages/powaindex/model/dashboard-status.enum';
import { DashboardStatusModel } from '../../pages/powaindex/model/dashboard-status.model';
import { ReportDashboardStatusEnum } from '../../pages/powaindex/model/report-dashboard-status.enum';
import { UserCollaborationsModel } from '../../pages/powaindex/model/user-collaborations.model';

export enum DashboardActionTypes {
  LoadPlayXSafeDashboard = '[Dashboard] Load PlayXSafe Dashboard',
  LoadPlayXSafeDashboardSuccess = '[Dashboard] Load PlayXSafe Dashboard Success',
  LoadCollaboratorOverview = '[Dashboard] Load Overview',
  LoadCollaboratorOverviewSuccess = '[Dashboard] Load Overview Success',
  LoadCollaboratorOverviewFailed = '[Dashboard] Load Overview Failed',
  LoadPlayXSafeDashboardFailed = '[Dashboard] Load PlayXSafe Dashboard Failed',
  SetCurrentPlayXSafeDashboard = '[Dashboard] SetCurrent Dashboard',
  SetCurrentPlayXSafeDashboardSuccess = '[Dashboard] SetCurrent PlayXSafe Dashboard Success',
  SetCurrentPlayXSafeDashboardFailed = '[Dashboard] SetCurrent PlayXSafe Dashboard Failed',
  GetDashboardStatus = '[Dashboard] Get Dashboard Status',
  getDataOwner = '[Dashboard] Get Data Owner',
  GetDashboardStatusSuccess = '[Dashboard] Get Dashboard Status Success',
  GetDashboardStatusFailed = '[Dashboard] Get Dashboard Status Failed',
  UpdateDashboardStatus = '[Dashboard] Update Dashboard Status',
  UpdateDashboardStatusSuccess = '[Dashboard] Update Dashboard Status Success',
  UpdateDashboardStatusFailed = '[Dashboard] Update Dashboard Status Failed',
}

export class LoadPlayXSafeDashboard implements Action {
  readonly type = DashboardActionTypes.LoadPlayXSafeDashboard;

}

export class LoadPlayXSafeDashboardSuccess implements Action {
  readonly type = DashboardActionTypes.LoadPlayXSafeDashboardSuccess;

  constructor(public payload: { collaborations: UserCollaborationsModel[] }) {}
}

export class LoadPlayXSafeDashboardFailed implements Action {
  readonly type = DashboardActionTypes.LoadPlayXSafeDashboardFailed;
}

export class LoadCollaboratorOverview implements Action {
  readonly type = DashboardActionTypes.LoadCollaboratorOverview;

  constructor(public payload: { collaborationId: number }) {}
}

export class LoadCollaboratorOverviewSuccess implements Action {
  readonly type = DashboardActionTypes.LoadCollaboratorOverviewSuccess;

  constructor(
    public payload: { collaboratorsOverview: CollaboratorsOverviewModel },
  ) {}
}

export class LoadCollaboratorOverviewFailed implements Action {
  readonly type = DashboardActionTypes.LoadCollaboratorOverviewFailed;
}

export class SetCurrentPlayXSafeDashboard implements Action {
  readonly type = DashboardActionTypes.SetCurrentPlayXSafeDashboard;

  constructor(public payload: { currentCollaborationId: number }) {}
}

export class SetCurrentPlayXSafeDashboardSuccess implements Action {
  readonly type = DashboardActionTypes.SetCurrentPlayXSafeDashboardSuccess;

}

export class SetCurrentDashboardFailed implements Action {
  readonly type = DashboardActionTypes.SetCurrentPlayXSafeDashboardFailed;

}

export class GetDashboardStatus implements Action {
  readonly type = DashboardActionTypes.GetDashboardStatus;

  constructor(public payload: { collaborationId: number }) {}
}

export class GetDashboardStatusSuccess implements Action {
  readonly type = DashboardActionTypes.GetDashboardStatusSuccess;

  constructor(public payload: { dashboardStatus: DashboardStatusModel }) {}
}

export class GetDashboardStatusFailed implements Action {
  readonly type = DashboardActionTypes.GetDashboardStatusFailed;
}

export class UpdateDashboardStatus implements Action {
  readonly type = DashboardActionTypes.UpdateDashboardStatus;

  constructor(
    public payload: {
      currentStatus: DashboardStatusEnum | ReportDashboardStatusEnum;
    },
  ) {}
}

export class UpdateDashboardStatusSuccess implements Action {
  readonly type = DashboardActionTypes.UpdateDashboardStatusSuccess;

  constructor(
    public payload: {
      currentStatus: DashboardStatusEnum | ReportDashboardStatusEnum;
    },
  ) {}
}

export class UpdateDashboardStatusFailed implements Action {
  readonly type = DashboardActionTypes.UpdateDashboardStatusFailed;
}

export type DashboardActions =
  | LoadPlayXSafeDashboard
  | LoadPlayXSafeDashboardSuccess
  | LoadPlayXSafeDashboardFailed
  | LoadCollaboratorOverview
  | LoadCollaboratorOverviewSuccess
  | LoadCollaboratorOverviewFailed
  | SetCurrentPlayXSafeDashboard
  | SetCurrentPlayXSafeDashboardSuccess
  | SetCurrentDashboardFailed
  | GetDashboardStatus
  | GetDashboardStatusSuccess
  | GetDashboardStatusFailed
  | UpdateDashboardStatus
  | UpdateDashboardStatusSuccess
  | UpdateDashboardStatusFailed;
