import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';

import { NgIf } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { AuthService } from '../../../../../services/auth/AuthService';
import { fadeIn } from '../../../../../theme/animations/fadein-animation';
import { PassphraseResetTypeEnum } from '../../../../collaborator/profile/model/passphrase-reset-type.model';

@Component({
  selector: 'confirmation',
  templateUrl: 'confirmation.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeIn],
  standalone: true,
  imports: [NgIf, MatButtonModule],
})
export class ConfirmationComponent implements OnInit {
  @Input() authCode: PassphraseResetTypeEnum;
  @Output() buttonClose: EventEmitter<boolean> = new EventEmitter<boolean>();
  public readonly PASSPHRASE_TYPE: typeof PassphraseResetTypeEnum =
    PassphraseResetTypeEnum;
  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    this.authService.refreshToken();
  }

  public close() {
    this.buttonClose.emit(true);
  }
}
