import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { AuditLogModel } from '../../../model/audit-log.model';
import { AuditLogService } from '../services/audit-log.service';
import * as AuditLogActions from './audit-log.action';
import { IAuditLogState } from './audit-log.state';

@Injectable()
export class AuditLogEffects {
  
  GetAuditLogs$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<AuditLogActions.GetAuditLog>(AuditLogActions.GET_AUDITLOG),
    mergeMap((action) =>
      this.auditLogService.getAuditLogs(action.payload).pipe(
        map((data: AuditLogModel[]) => {
          return new AuditLogActions.GetAuditLogSuccess({
            searchPage: {
              auditlogs: data,
              downloading: false,
              pagination: {
                offset: action.payload.pagination.offset,
                pageSize: action.payload.pagination.pageSize,
              },
            },
          } as IAuditLogState);
        }),
        catchError(() => of(new AuditLogActions.GetAuditLogError())),
      ),
    ),
  ));

  
  Download$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<AuditLogActions.Download>(AuditLogActions.DOWNLOAD_AUDITLOG),
    mergeMap((action) =>
      this.auditLogService.downloadAuditLogs(action.payload).pipe(
        map((blob) => {
          const objectUrl = window.URL.createObjectURL(blob);
          return new AuditLogActions.DownloadSuccess(objectUrl);
        }),
        catchError(() => {
          return of(new AuditLogActions.DownloadSuccess(''));
        }),
      ),
    ),
  ));

  constructor(private actions$: Actions, private auditLogService: AuditLogService) {}
}
