
<div [@animate]="animateit">
<mat-card >
  <mat-card-content>
    <mat-list>
      <mat-list-item *ngFor="let item of items">
        <mat-icon mat-list-icon *ngIf="item.Final === true">done</mat-icon>
        <mat-icon mat-list-icon *ngIf="item.Final === false">highlight_off</mat-icon>
        <mat-spinner mat-list-avatar class="signalr-spinner" [diameter]="24" color="accent" *ngIf="item.Final === null"></mat-spinner>
        <h4 *ngIf="item.Final === false" mat-line style="white-space:initial; overflow:visible;text-overflow:unset;">{{item.Message}} </h4>
       <p *ngIf="item.Final !== false" mat-line>{{item.Message}}</p>
      </mat-list-item>
    </mat-list>
  </mat-card-content>
</mat-card>
</div>
