import { Observable } from 'rxjs';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { IxupHttpCustomParams } from '../../../http/http-interceptor-params';
import { ResultModelGeneric } from '../../../model/result.model';
import { IxupBaseService } from '../../../services/ixup-base.service';
import { CanvasActionChart } from '../../../state/model/canvas-action-chart';
import { CollaborationTypeEnum } from '../../control-centre/shared/models/collaboration-type-enum';
import { IOutputTableModel } from '../../output-tables/models/output-table.model';
import { IDashboardDataModel } from 'app/pages/venn/model/dashboard-data.model';
import { CampaignResults } from '../model/campaign-results-model';
import { CampaignModel } from '../model/campaign.model';

@Injectable()
export class ConvertrService extends IxupBaseService {
  constructor(@Inject(HttpClient) private http: HttpClient) {
    super();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getUserCollaborations(): Observable<ResultModelGeneric<any>> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.resolveErrorGeneric<any>(
      this.http.get(
        `/api/dashboard/campaigns/${CollaborationTypeEnum.convertr}`,
      ),
    );
  }

  public releaseTableKeys(collaborationId: number, dataCustodianId: number) {
    return this.http.post(
      `/collaborations/${collaborationId}/tables/release/${dataCustodianId}`,
      null,
      { params: new IxupHttpCustomParams(true) },
    );
  }

  ingest(
    file: File,
    collaborationId: number,
    tableId: number,
    userId: number,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Observable<ResultModelGeneric<any>> {
    const uploadFile = new FormData();
    uploadFile.append(
      'uploadfile',
      new Blob([file], { type: 'text/csv' }),
      file.name,
    );
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.resolveErrorGeneric<any>(
      this.http.post(
        `/collaborations/${collaborationId}/${tableId}/ingest/${userId}`,
        uploadFile,
        {
          params: new IxupHttpCustomParams(true),
          headers: new HttpHeaders({
            'Ixup-custodianPassphrase': '',
            'Ixup-collaborationPassphrase': '',
            'Ixup-signalrGroupName': collaborationId + '-IngestTable',
          }),
        },
      ),
    );
  }

  getCampaignById(
    collaborationId: number,
  ): Observable<ResultModelGeneric<CampaignModel>> {
    return this.resolveErrorGeneric<CampaignModel>(
      this.http.get(`/api/dashboard/campaign/${collaborationId}`),
    );
  }

  public getChartDetails(
    collaborationId: number,
  ): Observable<ResultModelGeneric<CanvasActionChart[]>> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.resolveErrorGeneric<any>(
      this.http.post(`/collaborations/${collaborationId}/charts`, null, {
        params: new IxupHttpCustomParams(true),
      }),
    );
  }

  getCampaignResults(
    collaborationId: number,
  ): Observable<ResultModelGeneric<CampaignResults>> {
    return this.resolveErrorGeneric<CampaignResults>(
      this.http.get(`/api/dashboard/campaigns/${collaborationId}/results`),
    );
  }

  getGraph(
    collaborationId: number,
    type: string,
    graph: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Observable<ResultModelGeneric<any>> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.resolveErrorGeneric<any>(
      this.http.get(
        `/collaborations/${collaborationId}/graph/${type}/${graph}`,
        {
          params: new IxupHttpCustomParams(true),
          headers: new HttpHeaders({
            'Ixup-custodianPassphrase': '',
            'Ixup-collaborationPassphrase': '',
            'Ixup-signalrGroupName': collaborationId + '-OutputTable',
          }),
        },
      ),
    );
  }

  public getSegments(
    collaborationId: number,
  ): Observable<ResultModelGeneric<IOutputTableModel[]>> {
    return this.resolveErrorGeneric<IOutputTableModel[]>(
      this.http.get(`/api/dashboard/${collaborationId}/segments`),
    );
  }

  public matchingSubmit(
    collaborationId: number,
    dashboardDataModel: IDashboardDataModel,
  ): Observable<ResultModelGeneric<boolean>> {
    return this.resolveErrorGeneric<boolean>(
      this.http.post(
        `/api/dashboard/${collaborationId}/matching/change`,
        dashboardDataModel,
      ),
    );
  }
}
