<div class="mat-card-image-container">
  <mat-spinner
    [diameter]="24"
    style="margin:8px;"
    *ngIf="showspinner"
    color="accent"
  ></mat-spinner>
  <div>
    <ngx-avatars
      cornerRadius="3"
      *ngIf="!!(image$ | async)?.image"
      [src]="IMAGE_FULLTAG + (image$ | async)?.image"
      [name]="(image$ | async)?.alternativeName || ''"
      [round]="false"
      initialsSize="2"
      size="40"
      textSizeRatio="3"
      class="ixup-avatar"
    ></ngx-avatars>
    <ngx-avatars
      cornerRadius="3"
      *ngIf="!(image$ | async)?.image"
      [name]="(image$ | async)?.alternativeName || ''"
      [round]="false"
      initialsSize="2"
      size="40"
      textSizeRatio="3"
      class="ixup-avatar"
    ></ngx-avatars>
  </div>
</div>