<a mat-list-item [routerLink]="['/admin-console/custodians']" [routerLinkActive]="['mat-active']">
  <mat-icon matListIcon [matTooltip]="!miniNav ? '' : 'Data Custodians'" matTooltipPosition="after"
    matTooltipClass="ixup-canvas-tooltip">
    <span class="icon-data-custodian"></span>
  </mat-icon>
  <span mat-line *ngIf="!miniNav">Data Custodians</span>
</a>
<a mat-list-item [routerLink]="['/admin-console/users']" [routerLinkActive]="['mat-active']">
  <mat-icon matListIcon [matTooltip]="!miniNav ? '' : 'Users'" matTooltipPosition="after"
    matTooltipClass="ixup-canvas-tooltip">
    person
  </mat-icon>
  <span mat-line *ngIf="!miniNav">Users</span>
</a>
<a mat-list-item [routerLink]="['/admin-console/audit']" [routerLinkActive]="['mat-active']">
  <mat-icon matListIcon [matTooltip]="!miniNav ? '' : 'Audit Log'" matTooltipPosition="after"
    matTooltipClass="ixup-canvas-tooltip">
    <span class="icon-audits"></span>
  </mat-icon>
  <span mat-line *ngIf="!miniNav">Audit Log</span>
</a>
<a mat-list-item [routerLink]="['/admin-console/credits']" [routerLinkActive]="['mat-active']">
  <mat-icon matListIcon [matTooltip]="!miniNav ? '' : 'Cost Management'" matTooltipPosition="after"
    matTooltipClass="ixup-canvas-tooltip">
    <span class="icon-costmgmt-billing"><span class="path1"></span><span class="path2"></span><span
        class="path3"></span><span class="path4"></span></span>
  </mat-icon>
  <span mat-line *ngIf="!miniNav">Costs</span>
</a>
<a mat-list-item [routerLink]="['/admin-console/agent']" [routerLinkActive]="['mat-active']">
  <mat-icon matListIcon [matTooltip]="!miniNav ? '' : 'Encryption Gateway configuration'" matTooltipPosition="after"
    matTooltipClass="ixup-canvas-tooltip">
    settings
  </mat-icon>
  <span mat-line *ngIf='!miniNav'>Configuration</span>
</a>
