import { NgIf } from '@angular/common';
import { Component, Input, OnChanges } from '@angular/core';
import { AvatarModule } from 'ngx-avatars';
import { ImageContainer } from '../../../model/imagecontainer.interface';

const IMAGE_TAG = 'data:image';
const IMAGE_FULLTAG = 'data:image/jpeg;base64,';

@Component({
  selector: 'ixup-logo',
  templateUrl: './logo.component.html',
  standalone: true,
  imports: [NgIf, AvatarModule],
})
export class LogoComponent implements OnChanges {
  imageSrc: string;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('imageContainer') imageHolder: ImageContainer;
  @Input() shape: string;

  ngOnChanges(): void {
    if (!!this.imageHolder && !!this.imageHolder.image) {
      this.imageSrc = this.imageHolder.image;
      if (this.imageSrc.indexOf(IMAGE_TAG) < 0) {
        this.imageSrc = IMAGE_FULLTAG + this.imageSrc;
      }
    }
  }
}
