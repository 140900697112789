import { CommonModule } from '@angular/common';
import {
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf
} from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import {
  FullRouterStateSerializer,
  RouterState,
  RouterStateSerializer,
  StoreRouterConnectingModule
} from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../../environments/environment';
import { AuditLogEffects } from '../pages/audit-log/state';
import { AgentEffects } from './agent/agent-effects';
import { appMetaReducers, appReducer } from './app.reducer';
import { ConvertrDashboardEffects } from './dashboard-convertr/effects';
import { VennDashboardEffects } from './dashboard-venn/effects';
import { DashboardEffects } from './dashboard/effects';
import { HubEffects } from './hub/hub-effects';
import { OrganisationEffects } from './organisation/effects';
import { PlayXSafeDashboardEffects } from './playXsafe-dashboard/effects';
import { CustomSerializer } from './shared/utils';
import { SigningDocumentsEffects } from './signing-documents/signing-documents-effects';
import { UserEffects } from './user/user-effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forRoot(appReducer, {
      metaReducers: appMetaReducers,
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      },
    }),
    StoreRouterConnectingModule.forRoot({
      routerState: RouterState.Minimal,
      serializer: FullRouterStateSerializer,
    }),
    EffectsModule.forRoot([
      AuditLogEffects,
      AgentEffects,
      HubEffects,
      UserEffects,
      DashboardEffects,
      PlayXSafeDashboardEffects,
      ConvertrDashboardEffects,
      VennDashboardEffects,
      OrganisationEffects,
      SigningDocumentsEffects
    ]),
    !environment.production
      ? StoreDevtoolsModule.instrument({
        name: `Ixup : ${environment.redirectUri}`,
      })
      : [],
  ],
  declarations: [],
})
export class StateModule {
  static forRoot(): ModuleWithProviders<StateModule> {
    return {
      ngModule: StateModule,
      providers: [
        {
          provide: RouterStateSerializer,
          useClass: CustomSerializer,
        },
      ],
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: StateModule) {
    if (parentModule) {
      throw new Error(
        'StateModule is already loaded. Import it in the AppModule only',
      );
    }
  }
}
