import { NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatLegacyProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { Observable } from 'rxjs';
import { ImageContainer } from '../../../model/imagecontainer.interface';
import { LogoComponent } from './logo.component';

@Component({
  selector: 'ixup-canvas-icon',
  templateUrl: './canvas-icon.component.html',
  standalone: true,
  imports: [NgIf, MatLegacyProgressSpinnerModule, LogoComponent],
})
export class CanvasIconComponent implements OnInit {
  showspinner: boolean;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('imageContainer') imageHolder$: Observable<ImageContainer>;
  imageHolder: ImageContainer;


  ngOnInit(): void {
    this.showspinner = true;
    this.imageHolder$.subscribe((result) => {
      this.showspinner = false;
      this.imageHolder = result;
    });
  }
}
