import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatLineModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyListModule } from '@angular/material/legacy-list';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterLink, RouterLinkActive } from '@angular/router';

@Component({
  selector: 'ixup-enabler-console-left-navigation-menu',
  templateUrl: './enabler-console-left-navigation-menu.component.html',
  standalone: true,
  imports: [
    MatLegacyListModule,
    RouterLink,
    RouterLinkActive,
    MatIconModule,
    MatTooltipModule,
    NgIf,
    MatLineModule,
  ],
})
export class EnablerConsoleLeftNavigationMenuComponent {
  @Input() miniNav: boolean;
  @Input() manageUsers: boolean;

}
