import { Action } from '@ngrx/store';

import { CampaignModel } from '../../pages/convertr/model/campaign.model';
import CollaboratorsOverviewModel from '../../pages/convertr/model/collaborators-overview-model';
import { IOutputTableModel } from '../../pages/output-tables/models/output-table.model';
import { DashboardStatusEnum } from '../../pages/powaindex/model/dashboard-status.enum';
import { DashboardStatusModel } from '../../pages/powaindex/model/dashboard-status.model';
import { ReportDashboardStatusEnum } from '../../pages/powaindex/model/report-dashboard-status.enum';
import { CanvasActionChart } from '../model/canvas-action-chart';
import { TableStateEnum } from '../model/table-state';

export enum DashboardActionTypes {
  LoadConvertrDashboard = '[Dashboard Convertr] Load Convertr Dashboard',
  LoadConvertrDashboardSuccess = '[Dashboard Convertr] Load Convertr Dashboard Success',
  LoadConvertrDashboardFailed = '[Dashboard Convertr] Load Convertr Dashboard Failed',
  GetConvertrDashboard = '[Dashboard Convertr] Get Convertr Dashboard',
  GetConvertrDashboardSuccess = '[Dashboard Convertr] Get Convertr Dashboard Success',
  GetConvertrDashboardFailed = '[Dashboard Convertr] Get Convertr Dashboard Failed',
  LoadCollaboratorOverview = '[Dashboard Convertr] Load Overview',
  LoadCollaboratorOverviewSuccess = '[Dashboard Convertr] Load Overview Success',
  LoadCollaboratorOverviewFailed = '[Dashboard Convertr] Load Overview Failed',
  SetCurrentConvertrDashboard = '[Dashboard Convertr] SetCurrent Dashboard',
  SetCurrentConvertrDashboardSuccess = '[Dashboard Convertr] SetCurrent Convertr Dashboard Success',
  SetCurrentConvertrDashboardFailed = '[Dashboard Convertr] SetCurrent Convertr Dashboard Failed',
  UpdateConvertrLastExported = '[Dashboard Convertr] Update ConvertrLast Exported Date',
  UpdateConvertrLastExportedSuccess = '[Dashboard Convertr] Update ConvertrLast Exported Date Success',
  UpdateConvertrLastExportedFailed = '[Dashboard Convertr] Update ConvertrLast Exported Date Failed',
  UpdateConvertrApprovalStatus = '[Dashboard Convertr] Update Convertr Approval Status',
  UpdateConvertrApprovalStatusSuccess = '[Dashboard Convertr] Update Convertr  Approval Status Success',
  UpdateConvertrApprovalStatusFailed = '[Dashboard Convertr] Update Convertr  Approval Status Failed',
  UpdateConvertrPercentageMatches = '[Dashboard Convertr] Update Convertr Percentage Matches',
  UpdateConvertrPercentageMatchesSuccess = '[Dashboard Convertr] Update Convertr Percentage Matches Success',
  UpdateConvertrPercentageMatchesFailed = '[Dashboard Convertr] Update Convertr Percentage Matches Failed',
  getDataOwner = '[Dashboard Convertr] Get Data Owner',
  GetDashboardConvertrStatus = '[Dashboard Convertr] Get Dashboard Convertr Status',
  GetDashboardConvertrStatusSuccess = '[Dashboard Convertr] Get Dashboard Convertr Status Success',
  GetDashboardConvertrStatusFailed = '[Dashboard Convertr] Get Dashboard Convertr Status Failed',
  GetChartDetails = '[Dashboard Convertr] Get Chart Details',
  GetChartDetailsSuccess = '[Dashboard Convertr] Get Chart Details Success',
  GetChartDetailsFailed = '[Dashboard Convertr] Get Chart Details Failed',
  UpdateDashboardConvertrStatus = '[Dashboard Convertr] Update Dashboard Convertr Status',
  UpdateDashboardConvertrStatusSuccess = '[Dashboard Convertr] Update Dashboard Convertr Status Success',
  UpdateDashboardConvertrStatusFailed = '[Dashboard Convertr] Update Dashboard Convertr Status Failed',
  LoadSegments = '[Dashboard Convertr] Load Segments Dashboard',
  LoadSegmentsSuccess = '[Dashboard Convertr] Load Segments Dashboard Success',
  LoadSegmentsFailed = '[Dashboard Convertr] Load Segments Dashboard Failed',
  SubmitForMatching = '[Dashboard Convertr] Submit for Matching',
  SubmitForMatchingSuccess = '[Dashboard Convertr] Submit for Matching Success',
  SubmitForMatchingFailed = '[Dashboard Convertr] Submit for Matching Failed',
  UpdateStatusList = '[Dashboard Convertr] Update Status List',
  UpdateStatusListSuccess = '[Dashboard Convertr] Update Status List Success',
  UpdateStatusListFailed = '[Dashboard Convertr] Update Status List Failed',
  RunCanvasRequest = '[Dashboard Convertr] Run Canvas Requested',
  RunCanvasRequestSuccess = '[Dashboard Convertr] Run Canvas Requested Success',
  RunCanvasRequestFailed = '[Dashboard Convertr] Run Canvas Requested Failed',
  UpdateTableState = '[Dashboard Convertr] Update Table State',
  UpdateTableStateSuccess = '[Dashboard Convertr] Update Table State Success',
  UpdateTableStateFailed = '[Dashboard Convertr]  Update Table State Failed',
}

export class LoadConvertrDashboard implements Action {
  readonly type = DashboardActionTypes.LoadConvertrDashboard;

}

export class LoadConvertrDashboardSuccess implements Action {
  readonly type = DashboardActionTypes.LoadConvertrDashboardSuccess;

  constructor(public payload: { collaborations: CampaignModel[] }) {}
}

export class LoadConvertrDashboardFailed implements Action {
  readonly type = DashboardActionTypes.LoadConvertrDashboardFailed;
}

export class GetConvertrDashboard implements Action {
  readonly type = DashboardActionTypes.GetConvertrDashboard;

  constructor(public payload: { collaborationId: number }) {}
}

export class GetConvertrDashboardSuccess implements Action {
  readonly type = DashboardActionTypes.GetConvertrDashboardSuccess;

  constructor(public payload: { collaboration: CampaignModel }) {}
}

export class GetConvertrDashboardFailed implements Action {
  readonly type = DashboardActionTypes.GetConvertrDashboardFailed;
}

export class GetChartDetails implements Action {
  readonly type = DashboardActionTypes.GetChartDetails;

  constructor(public payload: { collaborationId: number }) {}
}

export class GetChartDetailsSuccess implements Action {
  readonly type = DashboardActionTypes.GetChartDetailsSuccess;

  constructor(
    public payload: {
      collaborationId: number;
      canvasActionChart: CanvasActionChart[];
    },
  ) {}
}

export class GetChartDetailsFailed implements Action {
  readonly type = DashboardActionTypes.GetChartDetailsFailed;
}

export class LoadCollaboratorOverview implements Action {
  readonly type = DashboardActionTypes.LoadCollaboratorOverview;

  constructor(public payload: { collaborationId: number }) {}
}

export class LoadCollaboratorOverviewSuccess implements Action {
  readonly type = DashboardActionTypes.LoadCollaboratorOverviewSuccess;

  constructor(
    public payload: { collaboratorsOverview: CollaboratorsOverviewModel },
  ) {}
}

export class LoadCollaboratorOverviewFailed implements Action {
  readonly type = DashboardActionTypes.LoadCollaboratorOverviewFailed;
}

export class SetCurrentConvertrDashboard implements Action {
  readonly type = DashboardActionTypes.SetCurrentConvertrDashboard;

  constructor(public payload: { currentCollaborationId: number }) {}
}

export class SetCurrentConvertrDashboardSuccess implements Action {
  readonly type = DashboardActionTypes.SetCurrentConvertrDashboardSuccess;
}

export class SetCurrentDashboardFailed implements Action {
  readonly type = DashboardActionTypes.SetCurrentConvertrDashboardFailed;
}

export class GetDashboardConvertrStatus implements Action {
  readonly type = DashboardActionTypes.GetDashboardConvertrStatus;

  constructor(public payload: { collaborationId: number }) {}
}

export class GetDashboardConvertrStatusSuccess implements Action {
  readonly type = DashboardActionTypes.GetDashboardConvertrStatusSuccess;

  constructor(public payload: { dashboardStatus: DashboardStatusModel }) {}
}

export class UpdateConvertrApprovalStatus implements Action {
  readonly type = DashboardActionTypes.UpdateConvertrApprovalStatus;

  constructor(public payload: { collaborationId: number; approved: boolean }) {}
}

export class UpdateConvertrApprovalStatusSuccess implements Action {
  readonly type = DashboardActionTypes.UpdateConvertrApprovalStatusSuccess;

  constructor(public payload: { collaborationId: number; approved: boolean }) {}
}

export class UpdateConvertrApprovalStatusFailed implements Action {
  readonly type = DashboardActionTypes.UpdateConvertrApprovalStatusFailed;
}

export class UpdateConvertrLastExported implements Action {
  readonly type = DashboardActionTypes.UpdateConvertrLastExported;

  constructor(public payload: { collaborationId: number; date: Date }) {}
}

export class UpdateConvertrLastExportedSuccess implements Action {
  readonly type = DashboardActionTypes.UpdateConvertrLastExportedSuccess;

  constructor(public payload: { collaborationId: number; date: Date }) {}
}

export class UpdateConvertrLastExportedFailed implements Action {
  readonly type = DashboardActionTypes.UpdateConvertrLastExportedFailed;
}

export class UpdateConvertrPercentageMatches implements Action {
  readonly type = DashboardActionTypes.UpdateConvertrPercentageMatches;

  constructor(
    public payload: { collaborationId: number; percentageMatches: number },
  ) {}
}

export class UpdateConvertrPercentageMatchesSuccess implements Action {
  readonly type = DashboardActionTypes.UpdateConvertrPercentageMatchesSuccess;

  constructor(
    public payload: { collaborationId: number; percentageMatches: number },
  ) {}
}

export class UpdateConvertrPercentageMatchesFailed implements Action {
  readonly type = DashboardActionTypes.UpdateConvertrPercentageMatchesFailed;
}

export class GetDashboardConvertrStatusFailed implements Action {
  readonly type = DashboardActionTypes.GetDashboardConvertrStatusFailed;
}

export class UpdateDashboardConvertrStatus implements Action {
  readonly type = DashboardActionTypes.UpdateDashboardConvertrStatus;

  constructor(
    public payload: {
      currentStatus: DashboardStatusEnum | ReportDashboardStatusEnum;
    },
  ) {}
}

export class UpdateDashboardConvertrStatusSuccess implements Action {
  readonly type = DashboardActionTypes.UpdateDashboardConvertrStatusSuccess;

  constructor(
    public payload: {
      currentStatus: DashboardStatusEnum | ReportDashboardStatusEnum;
    },
  ) {}
}

export class UpdateDashboardConvertrStatusFailed implements Action {
  readonly type = DashboardActionTypes.UpdateDashboardConvertrStatusFailed;
}

export class LoadSegments implements Action {
  readonly type = DashboardActionTypes.LoadSegments;

  constructor(
    public payload: {
      collaborationId: number;
      tableType: string[];
      includeDeactivated: boolean;
    },
  ) {}
}

export class LoadSegmentsSuccess implements Action {
  readonly type = DashboardActionTypes.LoadSegmentsSuccess;

  constructor(public payload: { segments: IOutputTableModel[] }) {}
}

export class LoadSegmentsFailed implements Action {
  readonly type = DashboardActionTypes.LoadSegmentsFailed;
}

export class SubmitForMatching implements Action {
  readonly type = DashboardActionTypes.SubmitForMatching;
  constructor(
    public payload: {
      collaborationId: number;
      model: {
        approved: boolean;
        wait: boolean;
      };
      submitForMatchingButtonPressed: boolean;
      dataCustodianId: number;
      reportSignalRGroupName: string;
    },
  ) {}
}

export class SubmitForMatchingSuccess implements Action {
  readonly type = DashboardActionTypes.SubmitForMatchingSuccess;
  constructor(
    public payload: {
      collaborationId: number;
      model: {
        approved: boolean;
        wait: boolean;
      };
      dataCustodianId: number;
      reportSignalRGroupName: string;
    },
  ) {}
}

export class SubmitForMatchingFailed implements Action {
  readonly type = DashboardActionTypes.SubmitForMatchingFailed;
}

export class UpdateStatusList implements Action {
  readonly type = DashboardActionTypes.UpdateStatusList;
  constructor(
    public payload: {
      collaborationId: number;
      dataCustodianId?: number;
      statusList: object;
    },
  ) {}
}

export class UpdateStatusListSuccess implements Action {
  readonly type = DashboardActionTypes.UpdateStatusListSuccess;
  constructor(
    public payload: {
      collaborationId: number;
      dataCustodianId?: number;
      statusList: object;
    },
  ) {}
}

export class UpdateStatusListFailed implements Action {
  readonly type = DashboardActionTypes.UpdateStatusListFailed;
}

export class RunCanvasRequest implements Action {
  readonly type = DashboardActionTypes.RunCanvasRequest;
  constructor(
    public payload: {
      collaborationId: number;
      reportDashboardSignalRGroupName: string;
    },
  ) {}
}

export class RunCanvasRequestSuccess implements Action {
  readonly type = DashboardActionTypes.RunCanvasRequestSuccess;
}

export class RunCanvasRequestFailed implements Action {
  readonly type = DashboardActionTypes.RunCanvasRequestFailed;
}

export class UpdateTableState implements Action {
  readonly type = DashboardActionTypes.UpdateTableState;

  constructor(
    public payload: {
      tableState: TableStateEnum;
    },
  ) {}
}

export class UpdateTableStateSuccess implements Action {
  readonly type = DashboardActionTypes.UpdateTableStateSuccess;

  public payload: {
    tableState: TableStateEnum;
  };
}

export class UpdateTableStateFailed implements Action {
  readonly type = DashboardActionTypes.UpdateTableStateFailed;
}

export type DashboardActions =
  | LoadConvertrDashboard
  | LoadConvertrDashboardSuccess
  | LoadConvertrDashboardFailed
  | GetConvertrDashboard
  | GetConvertrDashboardSuccess
  | GetConvertrDashboardFailed
  | GetChartDetails
  | GetChartDetailsSuccess
  | GetChartDetailsFailed
  | LoadCollaboratorOverview
  | LoadCollaboratorOverviewSuccess
  | LoadCollaboratorOverviewFailed
  | SetCurrentConvertrDashboard
  | SetCurrentConvertrDashboardSuccess
  | SetCurrentDashboardFailed
  | GetDashboardConvertrStatus
  | GetDashboardConvertrStatusSuccess
  | GetDashboardConvertrStatusFailed
  | UpdateConvertrLastExported
  | UpdateConvertrLastExportedSuccess
  | UpdateConvertrLastExportedFailed
  | UpdateConvertrApprovalStatus
  | UpdateConvertrApprovalStatusSuccess
  | UpdateConvertrApprovalStatusFailed
  | UpdateConvertrPercentageMatches
  | UpdateConvertrPercentageMatchesSuccess
  | UpdateConvertrPercentageMatchesFailed
  | UpdateDashboardConvertrStatus
  | UpdateDashboardConvertrStatusSuccess
  | UpdateDashboardConvertrStatusFailed
  | LoadSegments
  | LoadSegmentsSuccess
  | LoadSegmentsFailed
  | SubmitForMatching
  | SubmitForMatchingSuccess
  | SubmitForMatchingFailed
  | UpdateStatusList
  | UpdateStatusListSuccess
  | UpdateStatusListFailed
  | RunCanvasRequest
  | RunCanvasRequestSuccess
  | RunCanvasRequestFailed
  | UpdateTableState
  | UpdateTableStateSuccess
  | UpdateTableStateFailed;
