import { NgIf } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import {
  MatDialogModule,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { take } from 'rxjs/operators';
import { IKeyRequestModel } from '../../../../pages/consoles/admin-console/model/key-request.model';
import { IValidateAuthCodeModel } from '../../../../pages/consoles/admin-console/model/validate-auth-code.model';
import { CollaboratorService } from '../../../../services/collaborator/collaborator.service';
import { TranslatePipe } from '../../../../services/translate/translate.pipe';
import { PassphraseResetTypeEnum } from '../../../collaborator/profile/model/passphrase-reset-type.model';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { PassphraseFormComponent } from './passphrase-form/passphrase-form.component';
import { ResultModelGeneric } from '../../../../model/result.model';

@Component({
  selector: 'ixup-change-or-reset-passphrase-dialog',
  templateUrl: 'change-or-reset-passphrase-dialog.component.html',
  standalone: true,
  imports: [
    NgIf,
    MatDialogModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    MatLegacyFormFieldModule,
    MatLegacyInputModule,
    MatButtonModule,
    PassphraseFormComponent,
    ConfirmationComponent,
    TranslatePipe,
  ],
})
export class ChangeOrResetPassphraseDialog implements OnInit {
  modelForm: UntypedFormGroup;
  public readonly PASSPHRASE_TYPE: typeof PassphraseResetTypeEnum =
    PassphraseResetTypeEnum;
  public passphraseState = {
    showPassphraseForm: false,
    incorrectAuthCode: false,
    incorrectPassphrase: false,
    newPassphraseSavedSuccessfully: false,
  };

  constructor(
    private collaboratorService: CollaboratorService,
    public dialogRef: MatDialogRef<ChangeOrResetPassphraseDialog>,
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      authCode: PassphraseResetTypeEnum;
      userId: number;
      collaboratorId: number;
    },
    private matDialogRef: MatDialogRef<ChangeOrResetPassphraseDialog>,
  ) {}

  ngOnInit(): void {
    this.modelForm = this.fb.group({
      authCode: ['', Validators.required],
    });
  }

  onCloseAndDeactivateCode(): void {
    this.dialogRef.close();
    this.collaboratorService.cancelAuthCode().pipe(take(1)).subscribe();
  }

  submit() {
    if (this.modelForm.valid) {
      const validateModel: IValidateAuthCodeModel = {
        authCode: this.modelForm.get('authCode').value,
        collaboratorId: this.data.collaboratorId,
      };
      this.collaboratorService
        .checkAuthCode(validateModel)
        .pipe(take(1))
        .subscribe((result: ResultModelGeneric<boolean>) => {
          if (result) {
            this.passphraseState = {
              ...this.passphraseState,
              showPassphraseForm: true,
              incorrectAuthCode: false,
            };
          } else {
            this.passphraseState = {
              ...this.passphraseState,
              incorrectAuthCode: true,
            };
          }
        });
    }
  }

  public onPassphraseSubmit(event: UntypedFormGroup) {
    const passphraseModel = event.value;
    const keyRequestModel: IKeyRequestModel = {
      newPassphrase: passphraseModel.newPassword,
      oldPassphrase: passphraseModel.oldPassword
        ? passphraseModel.oldPassword
        : '',
      authCode: this.modelForm.get('authCode').value,
      resetTypeId: this.data.authCode,
      userId: this.data.userId,
    };
    this.collaboratorService
      .resetDataCustodianPassphrase(keyRequestModel)
      .pipe(take(1))
      .subscribe((result: boolean) => {
        if (result) {
          this.passphraseState = {
            ...this.passphraseState,
            showPassphraseForm: false,
            newPassphraseSavedSuccessfully: true,
          };
        } else {
          this.passphraseState = {
            ...this.passphraseState,
            incorrectPassphrase: true,
          };
        }
      });
  }

  onClose() {
    this.matDialogRef.close();
  }
}
