import { MembersModel } from './members.model';

export class CampaignModel {
  canvasId: number;
  canvasImageId: number;
  canvasStatus: string;
  collaborationId: number;
  collaboration: string;
  description: string;
  collaborationType: string;
  lastRunDate: Date;
  lastExportedDate: Date;
  members: MembersModel[];
  statusList: StatusModel[];
  approved: boolean;
  timesDownloaded: number;
  timesIngested: number;
  lastIngestedDate: Date;
  percentageMatches: number;
}

export interface StatusModel {
  percentageMatches: number;
  dataCustodianId: number;
  join: boolean;
  uploadDatabase: boolean;
  matching: MatchingStatusEnum;
  insights: boolean;
  segments: boolean;
  totalCompleted: string;
  submittedMatch: boolean;
  firstRun: boolean;
}

export type matchingStatus = keyof typeof MatchingStatusEnum;

export enum MatchingStatusEnum {
  Pending = 'Pending',
  InProgress = 'In progress',
  Done = 'Done',
  Failed = 'Failed',
}

export function inProgressEnumConverter() {
  return Object.keys(MatchingStatusEnum)[
    Object.values(MatchingStatusEnum).indexOf(MatchingStatusEnum.InProgress)
  ];
}
