import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { ResultModelGeneric } from 'app/model/result.model';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { SafesService } from '../../pages/control-centre/shared/services/safes.service';
import { ToasterService } from '../../services/toaster.service';
import { BaseEffects } from '../baseEffects';
import { GetSigningDocumentsFailed, GetSigningDocumentsSuccess, SigningDocumentsActionTypes, UpdateSigningDocumentStatusFailed, UpdateSigningDocumentStatusSuccess } from './signing-documents-actions';

import { IVersionSigningModel } from 'app/model/version-signing.model';

@Injectable()
export class SigningDocumentsEffects extends BaseEffects {
  
  UpdateSigningDocumentStatus$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(SigningDocumentsActionTypes.UpdateSigningDocumentStatus),
    switchMap((action: UpdateSigningDocumentStatusSuccess) => {
      return [
        new UpdateSigningDocumentStatusSuccess({versionId: action.payload.versionId, status: action.payload.status})
      ];
    }),
    catchError(() => {
      this.showError('Error updating status');
      return of(new UpdateSigningDocumentStatusFailed());
    }),
  )
  );
  

  GeSigningDocuments$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(SigningDocumentsActionTypes.GetSigningDocuments),
    mergeMap(() =>
      this.safesService.getMultiSigningIds().pipe(
        map((signingDocs: ResultModelGeneric<IVersionSigningModel[]>) => {
          return new GetSigningDocumentsSuccess({
            signingDocuments: signingDocs.data,
          });
        }),
        catchError(() => {
          this.showError('Error loading signing documents');
          return of(new GetSigningDocumentsFailed());
        }),
      ),
    ),
  ));


  constructor(
    private actions$: Actions,
    private safesService: SafesService,
    toasterService: ToasterService,
  ) {
    super(toasterService);
  }
}
