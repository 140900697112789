import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { ToasterService } from '../../services/toaster.service';
import { BaseEffects } from '../baseEffects';
import {
  DashboardActionTypes, SetCurrentDashboardFailed,
  SetCurrentDashboardSuccess,
  UpdateDashboardStatus,
  UpdateDashboardStatusFailed,
  UpdateDashboardStatusSuccess
} from './actions';

@Injectable()
export class DashboardEffects extends BaseEffects {
  UpdateDashboardStatus$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(DashboardActionTypes.UpdateDashboardStatus),
    switchMap((action: UpdateDashboardStatus) => [
      new UpdateDashboardStatusSuccess({
        currentStatus: action.payload.currentStatus,
      }),
    ]),
    catchError(() => {
      this.showError('Error getting dashboard status');
      return of(new UpdateDashboardStatusFailed());
    }),
  ));

  
  SetCurrentCollaborationId$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(DashboardActionTypes.SetCurrentDashboard),
    switchMap(() => [new SetCurrentDashboardSuccess()]),
    catchError(() => {
      this.showError('Error setting collaboration.');
      return of(new SetCurrentDashboardFailed());
    }),
  ));

  constructor(
    private actions$: Actions,
    toasterService: ToasterService,
  ) {
    super(toasterService);
  }
}
