import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { AuditLogModel } from '../../../model/audit-log.model';
import { IPagination } from '../../../model/pagination.model';
import { IxupBaseService } from '../../../services/ixup-base.service';

export interface IApiParameters {
  pagination: IPagination;
  title: string;
  username: string;
  consoleType: string;
  organisationId: number;
  collaboratorId: number;
  organisationName: string;
  collaboratorName: string;
  collaborationName: string;
  collaborationId: number;
  eventLogTypeDescription: string;
  alertsOnly: boolean;
}

@Injectable()
export class AuditLogService extends IxupBaseService {
  newAuditLogs$: Observable<AuditLogModel[]>;
  panelCache: AuditLogModel[] = [];
  panelCache$: Subject<AuditLogModel[]> = new Subject<AuditLogModel[]>();
  firstAPICallDone = false;

  constructor(@Inject(HttpClient) private http: HttpClient) {
    super();
  }

  public getAuditLogs(parameters: IApiParameters): Observable<AuditLogModel[]> {
    const params =
      `?collaboratorId=${parameters.collaboratorId}` +
      `&organisationId=${parameters.organisationId}` +
      `&consoleType=${parameters.consoleType}` +
      `&PageOffset=${parameters.pagination.offset}` +
      `&PageSize=${parameters.pagination.pageSize}` +
      `&title=${parameters.title}` +
      `&userName=${parameters.username}` +
      `&organisationName=${parameters.organisationName}` +
      `&collaboratorName=${parameters.collaboratorName}` +
      `&collaborationName=${parameters.collaborationName}` +
      `&collaborationId=${parameters.collaborationId}` +
      `&eventLogTypeDescription=${parameters.eventLogTypeDescription}` +
      `&alertsOnly=${parameters.alertsOnly}`;
    return this.resolveError(
      this.http.get(`/api/notification/audit-log${params}`)
    );
  }

  public downloadAuditLogs(parameters: IApiParameters) {
    return this.http.post('/api/notification/audit-log/download', parameters, {
      responseType: 'blob'
    });
  }
}
