<div
  [@animate]
  class="flex flex-row justify-between pad-left pad-right"
  style="background: #28384f"
>
  <div
    class="flex flex-column place-content-start place-content-center items-start self-center "
    style="color:#ffffff"
  >
    <div *ngIf="collaboration$ | async as collaboration">
      <div class="mat-body push-right ixup-cc-collaboration-name"><span class="text-bold">
          {{ collaboration?.collaboration }}
        </span>
      </div>

      <div class="ixup-cc-collaboration-type mat-caption">{{
        collaborationTypeEnumToString(collaboration?.collaborationType) }} collaboration</div>
    </div>
  </div>
  <div class="flex self-center">
    <mat-tab-nav-panel #tabPanel>
      <nav
        mat-tab-nav-bar
        backgroundColor="primary"
        class="control-centre-nav-bar"
        [tabPanel]="tabPanel"
        fitInkBarToContent
      >
        <a
          mat-tab-link
          *ngFor="let link of getLinksList(isSecureCloudTransfer$ | async)"
          [routerLink]="routeToLink(link)"
          [active]="(activeLink$ | async) === link"
          class="mat-tab-link text-upper"
        >
          {{ label }}
        </a>
        <a
          mat-tab-link
          color="primary"
          [routerLink]="['audit']"
          [active]="(activeLink$ | async) === 'audit'"
          class="mat-tab-link text-upper"
        >
          Audit
        </a>
        <a
          mat-tab-link
          color="primary"
          [routerLink]="['credits']"
          [active]="(activeLink$ | async) === 'credits'"
          class="mat-tab-link text-upper"
        >
          Credits
        </a>
      </nav>
    </mat-tab-nav-panel>
  </div>
  <div
    class="flex flex-column place-content-end place-content-center items-end self-center mat-caption push-left"
    style="color: #ffffff; line-height: 140%"
  >
    <div>
      <span
        class="text-bold"
        [@animate]
      >{{ !!(collaboration$ | async) ? 'v' : '' }}{{ (collaboration$ | async)?.version }}
        {{ ApprovalStatus[(collaboration$ | async)?.canvasStatus] | titlecase }}
      </span>
    </div>
    <div>
      <span> Last run </span>
      <span
        *ngIf="collaboration$ | async as collaboration"
        [@animate]
      >
        {{ (collaboration?.lastRunDate | date:'d MMM, y, h:mm a') || ' - N/A ' }}</span>
    </div>
  </div>
</div>
