import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import { billingReducer, ICollaborationBillingState } from './billing/reducers';
import {
  collaborationReducer,
  ICollaborationState
} from './collaboration/reducers';
import { IFiveSafeState, safesReducer } from './safes/reducers';

export interface IControlCentreState {
  collaboration: ICollaborationState;
  safes: IFiveSafeState;
  collaborationBilling: ICollaborationBillingState;
}

export const reducers: ActionReducerMap<IControlCentreState> = {
  collaboration: collaborationReducer,
  safes: safesReducer,
  collaborationBilling: billingReducer
};

export const getControlCentreState = createFeatureSelector<IControlCentreState>(
  'collaboration'
);
