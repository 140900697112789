<div>
  <ngx-avatars
    cornerRadius="3"
    *ngIf="!!imageHolder?.image"
    [src]="imageSrc"
    [name]="imageHolder?.alternativeName || ''"
    [round]="false"
    initialsSize="2"
    size="40"
    textSizeRatio="3"
    class="ixup-avatar"
  ></ngx-avatars>

  <ngx-avatars
    cornerRadius="3"
    *ngIf="!!imageHolder && !imageHolder?.image"
    [name]="imageHolder?.alternativeName || ''"
    [round]="false"
    initialsSize="2"
    size="40"
    textSizeRatio="3"
    class="ixup-avatar"
  ></ngx-avatars>
</div>