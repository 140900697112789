
import { NgIf } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import {
  MatLegacyDialogModule,
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA
} from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { CollaboratorKeyService } from '../../../../services/collaborator-key/collaborator-key.service';
import { TranslatePipe } from '../../../../services/translate/translate.pipe';

@Component({
  selector: 'ixup-data-protection-password-dialog',
  templateUrl: 'data-protection-password-dialog.component.html',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatLegacyDialogModule,
    MatLegacyFormFieldModule,
    MatLegacyInputModule,
    MatIconModule,
    NgIf,
    MatButtonModule,
    TranslatePipe,
  ],
})
export class DataProtectionPasswordDialog implements OnInit {
  modelForm: UntypedFormGroup;
  passwordIncorrect = false;
  hide = true;

  constructor(
    public dialogRef: MatDialogRef<DataProtectionPasswordDialog>,
    private collaboratorKeyService: CollaboratorKeyService,
    private fb: UntypedFormBuilder,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Inject(MAT_DIALOG_DATA) public data: any,
    private matDialogRef: MatDialogRef<DataProtectionPasswordDialog>,
  ) {}

  ngOnInit(): void {
    this.modelForm = this.fb.group({
      password: ['', Validators.required],
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  submit() {
    const password = this.modelForm.controls.password.value;

    this.collaboratorKeyService.checkKey(password).subscribe((valid) => {
      this.passwordIncorrect = !valid;

      if (valid) {
        this.matDialogRef.close(this.modelForm);
      }
    });
  }
}
