import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { SignDocumentDialog } from 'app/pages/control-centre/approvals/components/dialogs/sign-document-dialog/sign-document-dialog.component';
import { VersionService } from 'app/pages/control-centre/approvals/services/version.service';
import { UpdateSigningDocumentStatus } from 'app/state/signing-documents/signing-documents-actions';
import { map, take } from 'rxjs';

import { StatusEnum } from '../../model/version-signing.model';
import { IAppState } from '../../state/app.interfaces';
import { untilDestroyed } from '../../state/shared/utils';
import { ToasterService } from '../toaster.service';

@Injectable()
export class LandingPageService {
  private untilDestroyed = untilDestroyed();
  constructor(private router: Router,
    private dialog: MatDialog,
    private store: Store<IAppState>,
    private versionService: VersionService,
    private toasterService: ToasterService,) {}

  redirectToCorrectLandingPage(
    userConsole: string,
    hasInfrastructureConsolePermission: boolean,
    hasEnablerConsolePermission: boolean,
    hasAdminConsolePermission: boolean,
    hasCustodianConsolePermission: boolean,
    hasPowaIndexConsolePermission: boolean,
    // hasPlayXSafeAdminPermission: boolean,
    // hasPlayXSafeUploadPermission: boolean,
    hasConvertrPermission: boolean,
    hasVennPermission: boolean,
    hasMultiSigning: boolean
  ): void {
    if (hasPowaIndexConsolePermission) {
      this.router.navigate(['/powaindex/dashboard']);
    // } else if (hasPlayXSafeAdminPermission) {
    //   this.store
    //     .select(getCurrentPlayXSafeCollaborationId)
    //     .pipe(this.untilDestroyed())
    //     .subscribe((collaborationId: number) => {
    //       if (collaborationId) {
    //       //   if (hasPlayXSafeUploadPermission) {
    //       //     this.router.navigate([
    //       //       `/playXsafe/dashboard/${collaborationId}/tables`,
    //       //     ]);
    //       //   }
    //       //   this.router.navigate([
    //       //     `/playXsafe/dashboard/${collaborationId}/overview`,
    //       //   ]);
    //       // } else {
    //         this.router.navigate(['/playXsafe/noCollaborationId']);
    //       }
    //     });
    // } else if (hasPlayXSafeUploadPermission) {
    //   this.store
    //     .select(getCurrentPlayXSafeCollaborationId)
    //     .pipe(this.untilDestroyed())
    //     .subscribe((collaborationId: number) => {
    //       if (collaborationId) {
    //         this.router.navigate([
    //           `/playXsafe/dashboard/${collaborationId}/tables`,
    //         ]);
    //       } else {
    //         this.router.navigate(['/playXsafe/noCollaborationId']);
    //       }
    //     });
    } else if (hasConvertrPermission) {
      this.router.navigate(['/convertr/collaborations']);
    } else if (hasVennPermission) {
      this.router.navigate(['/venn/collaborations']);
    } else if (
      hasInfrastructureConsolePermission &&
      (userConsole === '' || userConsole === 'infrastructure-console')
    ) {
      this.router.navigate(['/infrastructure-console']);
    } else if (
      hasEnablerConsolePermission &&
      (userConsole === '' || userConsole === 'enabler-console')
    ) {
      this.router.navigate(['/enabler-console']);
    } else if (
      hasAdminConsolePermission &&
      (userConsole === '' || userConsole === 'admin-console')
    ) {
      this.router.navigate(['/admin-console']);
    } else if (
      hasCustodianConsolePermission &&
      (userConsole === '' || userConsole === 'custodian-console')
    ) {
      this.hasMultiSigning(hasMultiSigning);
    } else if (hasInfrastructureConsolePermission) {
      this.router.navigate(['/infrastructure-console']);
    } else if (hasEnablerConsolePermission) {
      this.router.navigate(['/enabler-console']);
    } else if (hasAdminConsolePermission) {
      this.router.navigate(['/admin-console']);
    } else if (hasCustodianConsolePermission) {
      this.hasMultiSigning(hasMultiSigning);
    } else {
      this.router.navigate(['/noroleaccount']);
    }
  }

  private hasMultiSigning(multiSigning: boolean) {
    if(multiSigning) {
      this.router.navigate(['/custodian-console/signing-documents']);
    } else {
      this.router.navigate(['/custodian-console']);
    }
  }

  openDocument(versionId: number, collaboratorId: number, single = false) {
    this.versionService
      .getContractualSpecificationUrl(versionId, collaboratorId)
      .pipe(
        take(1),
        map((result) => result.data),
      )
      .subscribe({
        next:  (url) => {
          const dialogRef = this.dialog.open(SignDocumentDialog, {
            panelClass: 'toolbar-dialog',
            minHeight: '90vh',
            minWidth: '90vw',
            disableClose: true,
            data: {
              model: { collaboratorId, url }
            },
          });
          dialogRef.afterClosed().subscribe((result: {action: boolean, actionType: string}) => {
            if (!single)
            { 
              this.store.dispatch(new UpdateSigningDocumentStatus({
                versionId,
                status: StatusEnum[result.actionType],
              }));
            }
            this.toasterService.showToaster(
              `Smart Contract ${result.actionType}.`,
              'Ok',
              true,
              true,
              7000,
            );
          }
          );
        },
        error:  () => {
          this.toasterService.showToaster(
            'An error occurred retrieving specification url',
            'Ok',
            true,
            true,
            7000,
          );
        },
      });
  }
}
