import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'makeGroup',
  standalone: true,
})
export class makeGroupPipe implements PipeTransform {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transform(value: any): any {
    const groups = {};
    value.forEach(function (o) {
      const group = o.permissionGroup;
      groups[group] = groups[group]
        ? groups[group]
        : { name: group, child: [] };
      groups[group].child.push(o);
    });
    return Object.keys(groups).map((key) => {
      return groups[key];
    });
  }
}
