import { Action } from '@ngrx/store';
import {
  SignalRListenModel
} from './signalR-listen.model';

export enum SignalRActionTypes {
  Listen = '[SignalR] LISTEN',
  CancelListen = '[SignalR] CANCEL_LISTEN'
}

export class Listen implements Action {
  readonly type = SignalRActionTypes.Listen;

  constructor(public payload: SignalRListenModel) {}
}

export class CancelListen implements Action {
  readonly type = SignalRActionTypes.CancelListen;

  constructor(public groupName: string) {}
}

export type All = Listen | CancelListen;
