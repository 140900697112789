import { Action } from '@ngrx/store';
import { UserModel } from 'app/model/user.model';
import { AuthUserModel } from '../../model/authuser.model';
import { IOrganisationTypeModel } from '../../pages/consoles/model/organisationTypes.model';

export enum UserActionTypes {
  SetUser = '[User] Set user',
  SetUserSuccess = '[User] Set user success',
  GetUsersByOrganisationId = '[User] Get Users By OrganisationId',
  GetUsersByOrganisationIdSuccess = '[User] Get Users By OrganisationId Success',
  GetUsersByOrganisationIdFailed = '[User] Get Users By OrganisationId Failed',
  UpdateUser = '[User] Update user',
  LoadOrganisationTypes = '[User] Load Orgainsation Types',
  LoadOrganisationTypesSuccess = '[User] Load Orgainsation Types Success',
  LoadOrganisationTypesFailed = '[User] Load Orgainsation Types Failed',
}

export class SetUser implements Action {
  readonly type = UserActionTypes.SetUser;
}

export class SetUserSuccess implements Action {
  readonly type = UserActionTypes.SetUserSuccess;

  constructor(public payload: { user: AuthUserModel }) {}
}

export class UpdateUser implements Action {
  readonly type = UserActionTypes.UpdateUser;

  constructor(public payload: { user: AuthUserModel }) {}
}

export class GetUsersByOrganisationId implements Action {
  readonly type = UserActionTypes.GetUsersByOrganisationId;

  constructor(public payload: { organisationId: number }) {}
}

export class GetUsersByOrganisationIdSuccess implements Action {
  readonly type = UserActionTypes.GetUsersByOrganisationIdSuccess;

  constructor(public payload: { orgId: number, users: UserModel[] }) {}
}

export class GetUsersByOrganisationIdFailed implements Action {
  readonly type = UserActionTypes.GetUsersByOrganisationIdFailed;
}

export class LoadOrganisationTypes implements Action {
  readonly type = UserActionTypes.LoadOrganisationTypes;
}

export class LoadOrganisationTypesSuccess implements Action {
  readonly type = UserActionTypes.LoadOrganisationTypesSuccess;

  constructor(
    public payload: { organisationTypes: IOrganisationTypeModel[] },
  ) {}
}

export class LoadOrganisationTypesFailed implements Action {
  readonly type = UserActionTypes.LoadOrganisationTypesFailed;
}
export type UserActions =
  | SetUser
  | SetUserSuccess
  | UpdateUser
  | GetUsersByOrganisationId
  | GetUsersByOrganisationIdSuccess
  | GetUsersByOrganisationIdFailed
  | LoadOrganisationTypes
  | LoadOrganisationTypesSuccess
  | LoadOrganisationTypesFailed;
