import toPairs from 'lodash-es/toPairs';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';

import { ResultModel, ResultModelGeneric } from '../model/result.model';

export interface MessageResponse { 
  status: boolean,
  data: object,
  message: string
}

export class IxupBaseService {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected resolveError(observable: Observable<any>): Observable<any> {
    return observable.pipe(
      map((item) => {
        if (item.status === true) {
          return item.data;
        } else {
          throw new Error(item.message);
        }
      }),
      shareReplay(),
      catchError((error) => {
        throw observableThrowError(error.message);
      }),
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected resolveErrorMessage(observable: Observable<any>): Observable<any> {
    return observable.pipe(
      map((item) => {
        if (item.status === true) {
          return item;
        } else {
          throw new Error(item.message);
        }
      }),
      shareReplay(),
      catchError((error) => {
        throw observableThrowError(error.message);
      }),
    );
  }

  protected resolveErrorFlat(
    observable: Observable<ResultModel>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Observable<any> {
    return observable.pipe(
      map((item) => {
        if (item.status === true) {
          return item.data;
        } else {
          throw new Error(item.message);
        }
      }),
      shareReplay(),
      catchError((error) => {
        throw error.message;
      }),
    );
  }

  protected resolveErrorGeneric<T>(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    observable: Observable<any>,
  ): Observable<ResultModelGeneric<T>> {
    return observable.pipe(
      map((item) => {
        if (item.status === true) {
          return {
            status: item.status,
            message: item.message,
            data: item.data,
          } as ResultModelGeneric<T>;
        } else {
          throw new Error(item.message);
        }
      }),
    );
  }

  protected convertToDictionary(values): object[] {
    const pairs = toPairs(values);
    const nameValuepairs = pairs.map((item) => {
      const val: object = {
        name: item[0].toLowerCase(),
        value: item[1],
      };
      return val;
    });
    return nameValuepairs;
  }
}
