<div class="container">
  <div class="empty-state push-top-lg">
    <mat-icon>error</mat-icon>
    <h1 class="mat-display-3 push-bottom-lg">{{ 'Oops!' | translate }}</h1>
    <h4>{{ "It seems this isn't the page you were looking for..." | translate }}</h4>
    <button mat-stroked-button color="primary" (click)="cancel()" class="btn-rounded">{{ 'Back to previous page' |
      translate }}</button>
    <p>{{ 'Error code 404' | translate }}</p>
  </div>
</div>
