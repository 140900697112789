import { ToasterService } from '../services/toaster.service';

export class BaseEffects {
  constructor(private toasterService: ToasterService) {}

  showError(error: string) {
    this.toasterService.showToaster(error, 'Ok', true, true, 7000);
  }

  showMessage(message: string) {
    this.toasterService.showToaster(message, 'Ok', true, true, 7000);
  }
}
