import { NgIf } from '@angular/common';
import {
  Component,
  Inject,
  InjectionToken, ViewChild
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { PingService } from '../../services/ping/ping.service';
import { TranslatePipe } from '../../services/translate/translate.pipe';

export const CLIENT_MANAGER_URL = new InjectionToken('CLIENT_MANAGER_URL');

@Component({
  selector: 'app-certify',
  templateUrl: './certify.component.html',
  providers: [PingService],
  standalone: true,
  imports: [NgIf, MatButtonModule, MatIconModule, TranslatePipe],
})

// This component/page exists to provide context & instruction to the user
// when the first call to backend API results in a client certificate challenge.
export class CertifyComponent {
  // this component is protected by CertifyGuard

  constructor(
    private router: Router,
    private pingService: PingService,
    @Inject(CLIENT_MANAGER_URL) private clientManagerUrl: string,
  ) {}

  @ViewChild('fileDownloadAnchor') fileDownloadAnchor;

  showAppDownloadButton: boolean;
  showPingFailed: boolean;

  onUseExistingCert(): void {
    this.showPingFailed = false;

    this.pingService.ping().subscribe({
      next: () => {
        this.router.navigate(['']);
      },
      error: () => {
        this.showAppDownloadButton = true;
        this.showPingFailed = true;
      },
    });
  }

  onGetNewCert(): void {
    this.showAppDownloadButton = true;
  }

  downloadClientManager() {
    this.fileDownloadAnchor.nativeElement.href = this.clientManagerUrl;
    this.fileDownloadAnchor.nativeElement.click();
  }
}
