import { Observable } from 'rxjs';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';

import { NgIf } from '@angular/common';
import { MatLineModule } from '@angular/material/core';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { AuthUserModel } from '../../../model/authuser.model';
import { Permission } from '../../../model/Permission';
import { DateComparisonService } from '../../../pages/venn/components/shared/date-comparison.utils';
import {
  CampaignModel,
  StatusModel,
} from '../../../pages/venn/model/campaign.model';
import { AuthService } from '../../../services/auth/AuthService';
import { fadeIn } from '../../../theme/animations/fadein-animation';

@Component({
  selector: 'venn-topmenu',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './venn-topmenu.component.html',
  animations: [fadeIn],
  standalone: true,
  imports: [MatTabsModule, NgIf, RouterLinkActive, RouterLink, MatLineModule],
})
export class VennTopmenuComponent implements OnInit {
  @Input() campaign$: Observable<CampaignModel>;
  @Input() collaborationId: number;
  activeLinkIndex = -1;
  collaboratorId: number;
  background = '';
  label: string;
  url: string[];
  currentUser: AuthUserModel;
  public collaborationId$: Observable<number>;
  public venn = false;
  public states: StatusModel;
  constructor(
    private authService: AuthService,
    public dateComparison: DateComparisonService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.authService.hasPermission([Permission.Venn]).subscribe((result) => {
      this.venn = result[0];
    });
    this.authService.currentUser$.subscribe((user) => {
      this.currentUser = user;
    });
    if (this.campaign$) {
      this.campaign$.subscribe((a) => {
        this.states = a.statusList.filter(
          (s) => s.dataCustodianId == this.currentUser.CollaboratorId,
        )[0];
        this.cdr.detectChanges();
      });
    }
  }

  toggleBackground() {
    this.background = this.background ? '' : 'primary';
  }
}
