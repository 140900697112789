import { Observable } from 'rxjs';

import { IRoleModel } from './role.model';

export class UserModel {
  public firstName: string;
  public lastName: string;
  public title: string;
  public preferredLanguage: string;
  public message: string;
  public salutation: string;
  public phone: string;
  public languages: string;
  public avatar: Observable<string>;
  public avatarImageId: number;
  public username: string;
  public reportPassword: string;
  public email: string;
  public name: string;
  public userid: number;
  public id: number;
  public display: boolean;
  public roles: IRoleModel[];
  public isAvatarUpdated: boolean;
}
