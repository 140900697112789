import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { ResultModelGeneric } from '../../../model/result.model';
import { IxupBaseService } from '../../../services/ixup-base.service';
import { CollaborationTypeEnum } from '../../control-centre/shared/models/collaboration-type-enum';
import CollaboratorsOverviewModel from '../model/collaborators-overview-model';

@Injectable()
export class PlayXSafeService extends IxupBaseService {
  constructor(@Inject(HttpClient) private http: HttpClient) {
    super();
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getUserCollaborations(): Observable<ResultModelGeneric<any>> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.resolveErrorGeneric<any>(
      this.http.get(
        `/api/collaboration/collaborations/${CollaborationTypeEnum.playXSafe}`,
      ),
    );
  }

  getCollaboratorOverview(
    collaborationId: number,
  ): Observable<ResultModelGeneric<CollaboratorsOverviewModel>> {
    return this.resolveErrorGeneric<CollaboratorsOverviewModel>(
      this.http.get(`/api/playpause/overview/${collaborationId}`),
    );
  }
}
