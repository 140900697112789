import { IFiveSafesDataModel } from 'app/model/five-safes/safe-data.model';
import { ISafeModel } from '../../../../../model/five-safes/safe.model';
import { SafeActions, SafeActionTypes } from './actions';

export interface IFiveSafeState {
  safesData: IFiveSafesDataModel[];
  safesSlider: ISafeModel[];
  loadingSafesSlider: boolean;
  loadingSafesData: boolean
}

export const initialState: IFiveSafeState = {
  safesData: null,
  safesSlider: null,
  loadingSafesSlider: false,
  loadingSafesData: false,
};

export function safesReducer(
  state = initialState,
  action: SafeActions,
): IFiveSafeState {
  switch (action.type) {
  case SafeActionTypes.LoadSafes: {
    return { ...state, loadingSafesSlider: true };
  }
  
  case SafeActionTypes.LoadSafesSuccess: {
    return {
      ...state,
      safesSlider: action.payload.safes,
      loadingSafesSlider: false,
    };
  }
  
  case SafeActionTypes.LoadSafesFailed: {
    return { ...state, loadingSafesSlider: false };
  }
  case SafeActionTypes.LoadSafesData: {
    return { ...state, loadingSafesData: true };
  }

  case SafeActionTypes.LoadSafesDataSuccess: {
    return {
      ...state,
      safesData: action.payload.safesData,
      loadingSafesData: false,
    };
  }

  case SafeActionTypes.LoadSafesDataFailed: {
    return { ...state, loadingSafesData: false };
  }


  default: {
    return state;
  }
  }
}
