import { NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { SpinnerService } from '../../../services/spinnerService';
@Component({
  selector: 'spinner-component',
  template: '<div *ngIf="active" class="spinner loading"></div>',
  standalone: true,
  imports: [NgIf],
})
export class SpinnerComponent {
  active: boolean;

  constructor(spinner: SpinnerService) {
    spinner.status.subscribe((status: boolean) => {
      this.active = status;
    });
  }
}
