import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatLineModule } from '@angular/material/core';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Permission } from '../../../model/Permission';
import { AuthService } from '../../../services/auth/AuthService';
import { IAppState } from '../../../state/app.interfaces';
import { getCurrentPlayXSafeCollaborationId } from '../../../state/playXsafe-dashboard/selectors';
import { fadeIn } from '../../../theme/animations/fadein-animation';

@Component({
  selector: 'playXsafe-topmenu',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './playXsafe-topmenu.component.html',
  animations: [fadeIn],
  standalone: true,
  imports: [
    MatTabsModule,
    NgIf,
    RouterLinkActive,
    RouterLink,
    MatLineModule,
    AsyncPipe,
  ],
})
export class PlayXSafeTopmenuComponent implements OnInit {
  activeLinkIndex = -1;

  background = '';
  label: string;
  url: string[];
  public collaborationId$: Observable<number>;
  collaborationId: number;
  public playXSafeAdmin = false;
  public playXSafeUpload = false;

  constructor(
    private authService: AuthService,
    private store: Store<IAppState>,
  ) {}

  ngOnInit() {
    this.collaborationId$ = this.store.select(
      getCurrentPlayXSafeCollaborationId,
    );

    this.authService
      .hasPermission([Permission.PlayXSafeAdmin])
      .subscribe((result) => {
        this.playXSafeAdmin = result[0];
      });
    this.authService
      .hasPermission([Permission.PlayXSafeUpload])
      .subscribe((result) => {
        this.playXSafeUpload = result[0];
      });
  }

  toggleBackground() {
    this.background = this.background ? '' : 'primary';
  }
}
