import { AuditLogModel } from '../../../model/audit-log.model';
import { IPagination } from '../../../model/pagination.model';

export interface IAuditLogState {
  searchPage: {
    auditlogs: AuditLogModel[];
    loading: boolean;
    downloading: boolean;
    pagination: IPagination;
    blobUrl: string;
  };
}

export const initializeAuditLogState = () => {
  return {
    searchPage: {
      auditlogs: [] as AuditLogModel[],
      loading: false,
      downloading: false,
      blobUrl: null,
      pagination: {
        offset: 0,
        pageSize: 50
      } as IPagination
    }
  } as IAuditLogState;
};
