export enum TableType {
  Source,
  Product,
  Derived,
  Aggregate,
  Report,
  Export,
  Connector,
  Analysis
}
