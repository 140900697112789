export enum CollaborationTypeEnum {
  workbench = 1,
  canvas = 2,
  powaIndex = 3,
  playXSafe = 4,
  convertr = 5,
  SecureCloud = 6,
  SecureCloudTransfer = 7,
  venn = 8,
}

export function collaborationTypeEnumToString(
  collaborationType: string,
): string {
  switch (collaborationType) {
  case CollaborationTypeEnum[CollaborationTypeEnum.workbench]:
    return 'Workbench';
  case CollaborationTypeEnum[CollaborationTypeEnum.canvas]:
    return 'Canvas';
  case CollaborationTypeEnum[CollaborationTypeEnum.powaIndex]:
    return 'PowaIndex';
  case CollaborationTypeEnum[CollaborationTypeEnum.playXSafe]:
    return 'PlayXSafe';
  case CollaborationTypeEnum[CollaborationTypeEnum.convertr]:
    return 'Convertr';
  case CollaborationTypeEnum[CollaborationTypeEnum.SecureCloud]:
    return 'Secure Cloud';
  case CollaborationTypeEnum[CollaborationTypeEnum.SecureCloudTransfer]:
    return 'Secure Cloud Transfer';
  case CollaborationTypeEnum[CollaborationTypeEnum.venn]:
    return 'Venn';
  default:
    return 'Unknown';
  }
}
