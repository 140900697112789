import { CollaborationActionTypes, CollaborationActions } from './actions';

import { ICollaborationModel } from '../../models/collaboration.model';

export interface ICollaborationState {
  collaboration: ICollaborationModel;
  loadingCollaboration: boolean;
  savingCollaboration: boolean;
  updatingMembers: boolean;
  collaborationCanvas: {
    newCollaborationCanvasId: number;
    isCreatingCollaborationCanvas: boolean;
  };
}

export const initialState: ICollaborationState = {
  collaboration: null,
  loadingCollaboration: false,
  savingCollaboration: false,
  updatingMembers: false,
  collaborationCanvas: {
    newCollaborationCanvasId: null,
    isCreatingCollaborationCanvas: false,
  },
};

export function collaborationReducer(
  state = initialState,
  action: CollaborationActions,
): ICollaborationState {
  switch (action.type) {
  case CollaborationActionTypes.LoadCollaboration: {
    return { ...state, loadingCollaboration: true };
  }

  case CollaborationActionTypes.LoadCollaborationSuccess: {
    return {
      ...state,
      collaboration: action.payload.collaboration,
      loadingCollaboration: false,
    };
  }

  case CollaborationActionTypes.LoadCollaborationFailed: {
    return { ...state, loadingCollaboration: false };
  }

  case CollaborationActionTypes.CreateCollaborationCanvas: {
    return {
      ...state,
      collaborationCanvas: {
        newCollaborationCanvasId: null,
        isCreatingCollaborationCanvas: true,
      },
    };
  }

  case CollaborationActionTypes.CreateCollaborationCanvasSuccess: {
    console.log({
      ...state,
      collaborationCanvas: {
        newCollaborationCanvasId: action.payload.newCollaborationId,
        isCreatingCollaborationCanvas: true,
      },
    });

    return {
      ...state,
      collaborationCanvas: {
        newCollaborationCanvasId: action.payload.newCollaborationId,
        isCreatingCollaborationCanvas: true,
      },
    };
  }

  case CollaborationActionTypes.CreateCollaborationCanvasFailed: {
    return { ...state, loadingCollaboration: false };
  }

  case CollaborationActionTypes.SaveCollaboration: {
    return { ...state, savingCollaboration: true };
  }

  case CollaborationActionTypes.SaveCollaborationSuccess: {
    return { ...state, savingCollaboration: false };
  }

  case CollaborationActionTypes.SaveCollaborationFailed: {
    return { ...state, savingCollaboration: false };
  }

  case CollaborationActionTypes.UnlockCanvas: {
    return {
      ...state,
      savingCollaboration: true,
    };
  }

  case CollaborationActionTypes.UnlockCanvasSuccess: {
    return {
      ...state,
      savingCollaboration: false,
    };
  }

  case CollaborationActionTypes.ClearCollaboration: {
    return {
      ...state,
      collaboration: null,
      loadingCollaboration: false,
    };
  }

  case CollaborationActionTypes.UpdateCollaborationMembers: {
    return {
      ...state,
      updatingMembers: true,
    };
  }

  case CollaborationActionTypes.UpdateCollaborationMembersSuccess: {
    return {
      ...state,
      updatingMembers: false,
    };
  }

  case CollaborationActionTypes.UpdateCollaborationMembersFailed: {
    return {
      ...state,
      updatingMembers: false,
    };
  }

  default: {
    return state;
  }
  }
}
