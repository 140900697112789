import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { debounceTime, Subject } from 'rxjs';
import { fadeIn } from '../../../theme/animations/fadein-animation';

@Component({
  selector: 'ixup-search-bar',
  templateUrl: 'search-bar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeIn],
  standalone: true,
  imports: [MatLegacyFormFieldModule, MatIconModule, MatLegacyInputModule],
})
export class SearchBarComponent implements OnInit {
  @Input() placeholder: string;
  @Input() debounce = 400;
  @Output() buttonSearch = new EventEmitter<string>();

  private subject: Subject<string> = new Subject();


  ngOnInit() {
    this.subject.pipe(debounceTime(this.debounce)).subscribe((searchText) => {
      this.buttonSearch.emit(searchText);
    });
  }

  search(searchText: string) {
    this.subject.next(searchText);
  }
}
