import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { StatusEnum } from 'app/model/version-signing.model';
import { AuthService } from 'app/services/auth/AuthService';
import { DialogDataModel } from '../../../../../../model/dialog-data.model';

@Component({
  selector: 'ixup-sign-document-dialog',
  templateUrl: 'sign-document-dialog.component.html',
  standalone: true,
  imports: [
    MatToolbarModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
  ],
})
export class SignDocumentDialog {
  @ViewChild('docusignHtml', { static: false }) docusignHtml: ElementRef;

  url: SafeResourceUrl;
  
  constructor(
    public dialogRef: MatDialogRef<SignDocumentDialog>,
    private authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataModel<{collaboratorId: number, url: string}>,
    private sanitizer: DomSanitizer,
  ) {
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(data.model.url);
  }

  public onClose() {
    const hostElement = this.docusignHtml.nativeElement;
    if(hostElement.contentDocument) {
      this.authService.authenticateWithCollaboratorId(this.data.model.collaboratorId, false);
      if (hostElement.contentWindow.event === 'decline') {
        this.dialogRef.close({action: false, actionType: StatusEnum.declined});
      } 
      if (hostElement.contentWindow.event === 'signing_complete') {
        this.dialogRef.close({action: true, actionType: StatusEnum.signed});
      }
    } else {
      this.dialogRef.close({action: false, actionType: StatusEnum.closed});
    } 
  }
}
