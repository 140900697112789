import { Injectable } from '@angular/core';

import { DateTimePipe } from '../../../../pipes/dateTime.pipe';

@Injectable()
export class DateComparisonService {
  constructor(private formatDate: DateTimePipe) {}

  public dateGreaterThanEqualTo(
    lastExportedDate: Date,
    lastRunDate: Date,
  ): boolean {
    const lastExported = this.formatDate.transform(lastExportedDate);
    const lastRun = this.formatDate.transform(lastRunDate);
    return lastExported >= lastRun;
  }
}
