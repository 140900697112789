import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResultModelGeneric } from '../../../../model/result.model';
import { IxupBaseService } from '../../../../services/ixup-base.service';
import { IUpdateCollaborationModel } from '../../approvals/models/update-collaboration.model';
import { ICollaborationBillingModel } from '../models/collaboration-billing.model';
import { ICollaborationModel } from '../models/collaboration.model';
import { IUnlockCanvasResponseModel } from '../models/unlock-canvas-response.model';
import { IUpdateCollaborationMembersModel } from '../models/update-collaboration-members.model';

@Injectable()
export class CanvasCollaborationService extends IxupBaseService {
  constructor(private http: HttpClient) {
    super();
  }

  public getOverview(collaborationId: number): Observable<ResultModelGeneric<ICollaborationModel>> {
    return this.resolveErrorGeneric<ICollaborationModel>(
      this.http.get(`/api/collaboration/overview/${collaborationId}`),
    );
  }

  public updateOverview(
    update: IUpdateCollaborationModel,
  ): Observable<ResultModelGeneric<boolean>> {
    return this.resolveErrorGeneric<boolean>(
      this.http.post('/api/collaboration/overview/update', update),
    );
  }

  public checkName(
    name: string,
  ): Observable<ResultModelGeneric<boolean>> {
    return this.resolveErrorGeneric<boolean>(
      this.http.post('/api/collaboration/check/name',{name}),
    );
  }

  public unlockCanvas(
    canvasId: number,
    unlockReason: string,
  ): Observable<ResultModelGeneric<IUnlockCanvasResponseModel>> {
    return this.resolveErrorGeneric<IUnlockCanvasResponseModel>(
      this.http.post('/api/Canvas/unlock', { canvasId, unlockReason }),
    );
  }

  public getCollaborationBilling(
    collaborationId: number,
  ): Observable<ResultModelGeneric<ICollaborationBillingModel>> {
    return this.resolveErrorGeneric<ICollaborationBillingModel>(
      this.http.get(`/api/billing/overview/billing/${collaborationId}`),
    );
  }

  public updateCollaborationMembers(collaborationMembers: IUpdateCollaborationMembersModel) {
    return this.resolveErrorGeneric<IUpdateCollaborationMembersModel>(
      this.http.post('/api/collaboration/members/update', collaborationMembers),
    );
  }
}
