import * as fromActions from './hub-action';

export interface IHubState {
  connected: boolean;
  connectionErrorMessage: string;
  loading: boolean;
}

export const initialState: IHubState = {
  connected: false,
  connectionErrorMessage: '',
  loading: false,
};

export function HubReducer(
  state = initialState,
  action: fromActions.HubActions,
): IHubState {
  switch (action.type) {
  case fromActions.HubActionTypes.ConnectToHub: {
    return { ...state, loading: true };
  }
  case fromActions.HubActionTypes.ConnectToHubSuccess: {
    return {
      ...state,
      connected: action.payload.hubConnectionStatus.connected,
      connectionErrorMessage:
          action.payload.hubConnectionStatus.connectionErrorMessage,
      loading: false,
    };
  }
  case fromActions.HubActionTypes.ConnectToHubFailed: {
    return {
      ...state,
      loading: false,
    };
  }
  case fromActions.HubActionTypes.CheckHubStatus: {
    return {
      ...state,
      loading: true,
    };
  }
  case fromActions.HubActionTypes.CheckHubStatusSuccess:
    return {
      ...state,
      connected: action.payload.hubConnectionStatus.connected,
      connectionErrorMessage:
          action.payload.hubConnectionStatus.connectionErrorMessage,
      loading: false,
    };
  case fromActions.HubActionTypes.CheckHubStatusFailed:
    return {
      ...state,
      loading: false,
    };
  default:
    return state;
  }
}
