import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { AvatarModule } from 'ngx-avatars';
import { NgxSliderModule } from 'ngx-slider-v2';
import { ConvertrFooterComponent } from '../../pages/convertr/components/shared/footer-convertr/footer-convertr.component';
import { ConfirmDataToIngestDialog } from '../../pages/shared/dialogs/confirm-data-to-ingest/confirm-data-to-ingest-dialog.component';
import { ConfirmPassphraseDialog } from '../../pages/shared/dialogs/confirm-passphrase/confirm-passphrase-dialog.component';
import { PipeModule } from '../../pipe.module';
import { CollaboratorService } from '../../services/collaborator/collaborator.service';
import { UserService } from '../../services/user/user.service';
import { AddExistingUserComponent } from '../users/add-existing-user/add-existing-user.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';

import { VennFooterComponent } from '../../pages/venn/components/shared/footer-venn/footer-venn.component';
import { ControlCentreTopmenuComponent } from './control-centre-topmenu/control-centre-topmenu.component';
import { ConvertrTopmenuComponent } from './convertr-topmenu/convertr-topmenu.component';
import { FooterComponent } from './footer/footer.component';
import { IxupDialog } from './ixup-dialog/ixup-dialog';
import { ConvertrDialog } from './ixup-dialog/ixup-dialog-convertr.component';
import { PasswordTemplateDialog } from './ixup-dialog/ixup-dialog-password-template.component';
import { PreviewDialog } from './ixup-dialog/ixup-dialog-preview.component';
import { StandardDialog } from './ixup-dialog/ixup-dialog-standard.component';
import { TemplateDialog } from './ixup-dialog/ixup-dialog-template.component';
import { IxupVennDialog } from './ixup-dialog/ixup-dialog-venn.component';
import { AsyncLogoComponent } from './logo/asynclogo.component';
import { CanvasIconComponent } from './logo/canvas-icon.component';
import { LogoComponent } from './logo/logo.component';
import { PageHeaderComponent } from './page-header.component';
import { PlayXSafeTopmenuComponent } from './playxsafe-topmenu/playXSafe-topmenu.component';
import { ProgressSpinnerComponent } from './progress-spinner.component';
import { SavingindicatorComponent } from './saving-indicator/saving-indicator.component';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { NewSpinnerComponent } from './spinner.component';
import { SummaryComponent } from './summary/summary.component';
import { UploaderComponent } from './uploader/uploader.component';
import { DataProtectionModule } from './utilities/data-protection/data-protection.module';
import { VennTopmenuComponent } from './venn-topmenu/venn-topmenu.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatSelectModule,
    MatTabsModule,
    MatTooltipModule,
    MatToolbarModule,
    MatCardModule,
    AvatarModule,
    PipeModule.forRoot(),
    DataProtectionModule,
    NgxSliderModule,
    MatDividerModule,
    FooterComponent,
    ConvertrFooterComponent,
    VennFooterComponent,
    PlayXSafeTopmenuComponent,
    ConvertrTopmenuComponent,
    VennTopmenuComponent,
    ControlCentreTopmenuComponent,
    IxupDialog,
    TemplateDialog,
    StandardDialog,
    ConvertrDialog,
    IxupVennDialog,
    PasswordTemplateDialog,
    PreviewDialog,
    ConfirmPassphraseDialog,
    ConfirmDataToIngestDialog,
    PageHeaderComponent,
    AsyncLogoComponent,
    CanvasIconComponent,
    LogoComponent,
    NewSpinnerComponent,
    ProgressSpinnerComponent,
    SavingindicatorComponent,
    SummaryComponent,
    BreadcrumbComponent,
    UploaderComponent,
    AddExistingUserComponent,
    SearchBarComponent,
    
  ],
  exports: [
    FooterComponent,
    ConvertrFooterComponent,
    VennFooterComponent,
    PageHeaderComponent,
    ControlCentreTopmenuComponent,
    PlayXSafeTopmenuComponent,
    ConvertrTopmenuComponent,
    VennTopmenuComponent,
    AsyncLogoComponent,
    CanvasIconComponent,
    LogoComponent,
    NewSpinnerComponent,
    ProgressSpinnerComponent,
    SavingindicatorComponent,
    SummaryComponent,
    BreadcrumbComponent,
    UploaderComponent,
    AddExistingUserComponent,
    SearchBarComponent,
  ],
  providers: [CollaboratorService, UserService],
})
export class SharedModule {}
