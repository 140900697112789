import { createFeatureSelector, createSelector } from '@ngrx/store';

import { IAppState } from '../app.interfaces';
import { IAgentState } from './agent-reducer';

export const agentState = createFeatureSelector<IAgentState>('agent');

export const getAgentState = createSelector(agentState, (state) => state);

export const getAgentConfigurationState = createSelector(
  getAgentState,
  (state) => state.agentConfiguration,
);

export const getDefaultLocationsState = createSelector(
  getAgentState,
  (state) => state.defaultLocations,
);

export const getAgentStatusState = (state: IAppState) => state.agent;

export const getAgentStatus = (state: IAppState) => state.agent.status;

export const locationsIsValid = createSelector(
  getDefaultLocationsState,
  (locations) => {
    return locations.downloadLocationIsValid && locations.uploadLocationIsValid;
  },
);
