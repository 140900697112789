import * as SignalRActions from './signalR.action';
import { EntityAdapter, createEntityAdapter, EntityState } from '@ngrx/entity';
import { SignalRListenModel } from './signalR-listen.model';
import { SignalRActionTypes } from './signalR.action';
import { createSelector, createFeatureSelector } from '@ngrx/store';

export type Action = SignalRActions.All;

export const adapter: EntityAdapter<SignalRListenModel> = createEntityAdapter<
  SignalRListenModel
>({ selectId: m => m.groupName });

export type SignalRState = EntityState<SignalRListenModel>

export const initialState: SignalRState = adapter.getInitialState({});

export const { selectIds, selectEntities, selectAll } = adapter.getSelectors();

export function SignalRReducer(state = initialState, action: Action) {
  switch (action.type) {
  case SignalRActionTypes.Listen: {
    return adapter.addOne(action.payload, state);
  }
  case SignalRActionTypes.CancelListen: {
    return adapter.removeOne(action.groupName, state);
  }
  }

  return state;
}

export const getSignalRState = createFeatureSelector<SignalRState>('signalR');

export const getSignalREntities = createSelector(
  getSignalRState,
  selectAll
);
