<div [class.ixup-toolbar-convertr]="router.url.includes('convertr')"
  [class.ixup-toolbar-venn]="router.url.includes('venn')" [class.z-2]="!router.url.includes('convertr')">
  <app-header [model]="user$ | async" (orgId)="(orgId)"></app-header>
</div>
<mat-drawer-container autosize style="height: 100%" [class.ixup-bg-convertr]="router.url.includes('convertr')">
  <mat-drawer #drawerSideMenu mode="side" class="mat-elevation-z1" opened="true" disableClose="false"
    style="overflow-x: hidden" [class.ixup-drawer-convertr]="router.url.includes('convertr')"
    [class.ixup-drawer-venn]="router.url.includes('venn')" [ngClass]="{
      'ixup-mininav-open': !miniNav,
      'ixup-mininav-closed': miniNav
    }"
    *ngIf="(router.url.includes('console') || router.url.includes('control') || router.url.includes('convertr') || router.url.includes('venn') || router.url.includes('playxsafe') )"
    >
    <div class="flex flex-column flex-fill full-height">
      <mat-nav-list class="flex flex-1 ixup-mini-nav pad-top-none push-top-none">
        <ixup-enabler-console-left-navigation-menu
          *ngIf="(urlRootSection$ | async) === 'enabler-console' && accessEnablerConsole" [miniNav]="miniNav"
          [manageUsers]="manageUsers"></ixup-enabler-console-left-navigation-menu>
        <ixup-admin-console-left-navigation-menu
          *ngIf="(urlRootSection$ | async) === 'admin-console' && accessAdminConsole" [miniNav]="miniNav"
          [manageUsers]="manageUsers"></ixup-admin-console-left-navigation-menu>
        <ixup-custodian-console-left-navigation-menu *ngIf="
            (urlRootSection$ | async) === 'custodian-console' ||
            (urlRootSection$ | async) === 'control-centre'
          " [miniNav]="miniNav" [manageUsers]="manageUsers" [dataCustodianId]="dataCustodianId"
          [billingAdmin]="billingAdmin"></ixup-custodian-console-left-navigation-menu>


        <ixup-convertr-dashboard-left-navigation-menu
          *ngIf="(urlRootSection$ | async) === 'convertr' && accessConvertrDashboard" [manageUsers]="manageUsers"
          [miniNav]="miniNav" class="ixup-left-nav-convertr">
        </ixup-convertr-dashboard-left-navigation-menu>
        <ixup-venn-dashboard-left-navigation-menu *ngIf="(urlRootSection$ | async) === 'venn' && accessVennDashboard"
          [manageUsers]="manageUsers" [miniNav]="miniNav" class="ixup-left-nav-venn">
        </ixup-venn-dashboard-left-navigation-menu>
        <mat-divider *ngIf="
        ((urlRootSection$ | async) === 'admin-console' && accessAdminConsole) ||
        (urlRootSection$ | async) === 'custodian-console' ||
        ((urlRootSection$ | async) === 'control-centre' && accessCustodianConsole)"></mat-divider>
        <a aria-hidden='true' mat-list-item *ngIf="
          ((urlRootSection$ | async) === 'admin-console' && accessAdminConsole) ||
            (urlRootSection$ | async) === 'custodian-console' ||
            ((urlRootSection$ | async) === 'control-centre' && accessCustodianConsole)"
          (click)="downloadFileManagementApp()">
          <mat-icon mat-list-icon [matTooltip]="!miniNav ? '' : 'Download Encryption Gateway'"
            matTooltipPosition="after" matTooltipClass="ixup-canvas-tooltip">
            file_download
          </mat-icon>
          <span mat-line *ngIf="!miniNav" class="text-no-truncate">Encryption Gateway</span>
        </a>
        <mat-divider></mat-divider>
      </mat-nav-list>
      <a aria-hidden='true' #fileDownloadAnchor download="IxupClientManagerSetup.msi"></a>
      <div class="td-layout-footer flex pad-top-sm pad-bottom-sm pad-left-none items-center">
        <div class="ixup-mininav-closed flex justify-around">
          <button type="button" mat-icon-button (click)="miniNav = !miniNav" class="push-left-none">
            <mat-icon class="caret" [ngClass]="{
            'collapsed':
            !miniNav
          }">keyboard_arrow_left</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </mat-drawer>
  <div style="height: 100%">
    <spinner-component></spinner-component>
    <router-outlet></router-outlet>
  </div>
</mat-drawer-container>
