import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IxupHttpCustomParams } from '../../http/http-interceptor-params';
import { CollaborationModel } from '../../model/collaboration.model';
import { ICreateCollaborationModel } from '../../model/collaborationcreate.model';
import { CollaborationListModel } from '../../model/collaborationlist.model';
import { CollaborationNameModel } from '../../model/collaborationname.model';
import { UpdateCollaborationModel } from '../../model/collaborationupdate.model';
import { ResultModelGeneric } from '../../model/result.model';
import { CollaborationTypeEnum } from '../../pages/control-centre/shared/models/collaboration-type-enum';
import { ICollaborationModel } from '../../pages/control-centre/shared/models/collaboration.model';
import { IDataSourceDefinition } from '../../pages/input-tables/models/data-source-definition.model';
import { IDataSource } from '../../pages/input-tables/models/data-source.model';
import { IngestTablesModel } from '../../pages/input-tables/models/ingest-tables.model';
import { ITableFieldModel } from '../../pages/input-tables/models/table-field.model';
import { ITableModel } from '../../pages/input-tables/models/table.model';
import { IReleaseSettings } from '../../pages/output-tables/models/auto-release.model';
import { IExportParamsModel } from '../../pages/output-tables/models/export-params.model';
import { IOutputTableModel } from '../../pages/output-tables/models/output-table.model';
import { IReleaseTableRequest } from '../../pages/output-tables/models/release-table-request.model';
import { ICredentialsRequestModel } from '../../pages/output-tables/state/output-tables.actions';
import { TableType } from '../../pages/shared/table-types-enum';
import { IxupBaseService } from '../ixup-base.service';

export interface IReportTokenRequest {
  tableUniqueName: string;
  dataCustodianPassphrase: string;
  collaborationPassphrase: string;
  signalRGroupName: string;
  deactivateExistingTokens: boolean;
}

@Injectable()
export class CollaborationService extends IxupBaseService {
  constructor(@Inject(HttpClient) private http: HttpClient) {
    super();
  }

  public getList(status: string): Observable<CollaborationListModel[]> {
    // resolveError
    return this.resolveErrorGeneric<CollaborationListModel[]>(
      this.http.get(`/api/collaboration/status/${status}`),
    ).pipe(map((response) => response.data as CollaborationListModel[]));
  }

  public getCollaborators() {
    return this.resolveError(this.http.get('/api/collaboration/collaborators'));
  }

  public getAllByUser(): Observable<
    ResultModelGeneric<CollaborationListModel[]>
    > {
    // resolveError
    return this.resolveErrorGeneric<CollaborationListModel[]>(
      this.http.get('/api/collaboration/user'),
    );
  }

  public getAll(): Observable<ResultModelGeneric<CollaborationNameModel[]>> {
    // resolveError
    return this.resolveErrorGeneric<CollaborationNameModel[]>(
      this.http.get('/api/collaboration/all/names'),
    );
  }

  public getCollaboration(
    id: number,
  ): Observable<ResultModelGeneric<CollaborationModel>> {
    // resolveError
    return this.resolveErrorGeneric<CollaborationModel>(
      this.http.get(`/api/collaboration/details/${id}`),
    );
  }

  public createCollaboration(
    model: ICollaborationModel,
    signalRGroupName: string,
  ) {
    const createCollaborationViewModel: ICreateCollaborationModel = {
      name: model.collaboration,
      description: model.description,
      invitationMessage: model.invitationMessage,
      members: model.selectMultiple || [],
      coordinatorId: model.coordinatorId,
      coordinatorFromAnotherOrganisation:
        model.coordinatorFromAnotherOrganisation,
      custodiansFromAnotherOrganisation:
        model.custodiansFromAnotherOrganisation || [],
      collaborationType: String(CollaborationTypeEnum[model.collaborationType]),
      passphrase: model.passphrase,
      safeSettings: model.safeSettings,
      signalRGroupName,
    };
    return this.resolveErrorGeneric(
      this.http.post('/api/collaboration/create', createCollaborationViewModel),
    );
  }

  public getCollaborationTypes() {
    return this.resolveError(
      this.http.get('/api/collaboration/collaboration/types'),
    );
  }

  public updateCollaboration(model: ICollaborationModel) {
    const updateCollaborationViewModel: UpdateCollaborationModel = {
      id: model.id,
      description: model.description,
      invitationMessage: model.invitationMessage,
      approval: model.status,
      members: model.selectMultiple,
      coordinatorId: model.coordinatorId,
      custodiansFromAnotherOrganisation:
        model.custodiansFromAnotherOrganisation,
      coordinatorFromAnotherOrganisation:
        model.coordinatorFromAnotherOrganisation,
    };
    return this.resolveErrorGeneric(
      this.http.post('/api/collaboration/update', updateCollaborationViewModel),
    );
  }

  public deleteCollaboration(
    id: number,
  ): Observable<ResultModelGeneric<number>> {
    return this.resolveErrorGeneric<number>(
      this.http.delete(`/api/collaboration/${id}`),
    );
  }

  public setNewOwner(tableId: number, custodianId: number) {
    return this.resolveErrorGeneric(
      this.http.post(
        `/api/tablemanagement/${tableId}/update/owner/${custodianId}`,
        null,
      ),
    );
  }

  public getCollaborationTables(collaborationId: number) {
    return this.resolveError(
      this.http.get(`/api/tablemanagement/${collaborationId}/tables`),
    );
  }

  public getDataResources(
    collaborationId: number,
    includeDeactivated: boolean,
  ): Observable<ResultModelGeneric<ITableModel[]>> {
    const tableTypes: string[] = [
      TableType[TableType.Source],
      TableType[TableType.Product],
      TableType[TableType.Analysis],
    ];
    return this.resolveErrorGeneric<ITableModel[]>(
      this.http.post(
        `/api/tablemanagement/${collaborationId}/${includeDeactivated}/tables`,
        tableTypes,
      ),
    );
  }

  public getTableFields(tableId: number): Observable<ITableFieldModel[]> {
    return this.resolveErrorGeneric<ITableFieldModel[]>(
      this.http.get(`/api/tablemanagement/table/${tableId}/fields`),
    ).pipe(
      map((result: ResultModelGeneric<ITableFieldModel[]>) => result.data),
    );
  }

  public updateTableFields(
    tableId: number,
    fields: ITableFieldModel[],
  ): Observable<boolean> {
    return this.resolveErrorGeneric<boolean>(
      this.http.post(`/api/tablemanagement/table/${tableId}/fields`, fields),
    ).pipe(map((result: ResultModelGeneric<boolean>) => result.data));
  }

  public getCollaboratorTables() {
    return this.resolveError(this.http.get('/api/tablemanagement/tables'));
  }

  public getTableBatches(tableId: number) {
    return this.resolveError(
      this.http.get(`/api/tablemanagement/${tableId}/batches`),
    );
  }
  public getCollaboratorCanvasTable(tableId: number) {
    return this.resolveError(
      this.http.get(`/api/tablemanagement/table/${tableId}`),
    );
  }

  public deleteCanvasTables(item) {
    const table = { tableId: item.id, Status: true };
    return this.resolveError(
      this.http.post('/api/tablemanagement/deleteTable', table),
    );
  }

  public deleteDataResources(model: { tableId: number; status: boolean }[]) {
    return this.resolveErrorGeneric(
      this.http.post('/api/tablemanagement/deleteTable', model),
    );
  }

  public deleteCanvasTableBatches(item) {
    const table = {
      tableId: item.sourceTableId,
      Active: true,
      BatchIds: [item.id],
    };
    return this.resolveError(
      this.http.post('/api/tablemanagement/deleteBatch', table),
    );
  }

  public deactivateCanvasTables(item, status: boolean) {
    const table = { tableId: item.id, Status: status };
    return this.resolveError(
      this.http.post('/api/tablemanagement/modifyTable', table),
    );
  }

  public updateDataResourceState(
    model: { tableId: number; status: boolean }[],
  ) {
    return this.resolveErrorGeneric(
      this.http.post('/api/tablemanagement/modifyTable', model),
    );
  }

  public deactivateCanvasTableBatches(item, status: boolean) {
    const table = {
      TableId: item.sourceTableId,
      Active: status,
      BatchIds: [item.id],
    };
    return this.resolveError(
      this.http.post('/api/tablemanagement/modifyBatch', table),
    );
  }

  public getCollaboratorData(tableId: number) {
    return this.resolveError(
      this.http.get(`/api/tablemanagement/table/${tableId}`),
    );
  }

  public deleteData(item) {
    const table = { tableId: item.id, Status: true };
    return this.resolveError(
      this.http.post('/api/tablemanagement/deleteTable', table),
    );
  }

  public deleteDataBatches(item) {
    const table = {
      tableId: item.sourceTableId,
      Active: true,
      BatchIds: [item.id],
    };
    return this.resolveError(
      this.http.post('/api/tablemanagement/deleteBatch', table),
    );
  }

  public deleteTableBatches(tableId: number, batchIds: number[]) {
    const table = {
      TableId: tableId,
      Active: false,
      BatchIds: batchIds,
    };
    return this.resolveErrorGeneric(
      this.http.post('/api/tablemanagement/deleteBatch', table),
    );
  }

  public deactivateData(item, status: boolean) {
    const table = { tableId: item.id, Status: status };
    return this.resolveError(
      this.http.post('/api/tablemanagement/modifyTable', table),
    );
  }

  public deactivateDataBatches(item, status: boolean) {
    const table = {
      TableId: item.sourceTableId,
      Active: status,
      BatchIds: [item.id],
    };
    return this.resolveError(
      this.http.post('/api/tablemanagement/modifyBatch', table),
    );
  }

  public toggleTableBatchStatus(
    batchIds: number[],
    sourceTableId: number,
    status: boolean,
  ) {
    const table = {
      TableId: sourceTableId,
      Active: status,
      BatchIds: batchIds,
    };
    return this.resolveError(
      this.http.post('/api/tablemanagement/modifyBatch', table),
    );
  }

  public getOutputTables(
    collaborationId: number,
  ): Observable<ResultModelGeneric<IOutputTableModel[]>> {
    return this.resolveErrorGeneric<IOutputTableModel[]>(
      this.http.get(`/api/tablemanagement/${collaborationId}/output`),
    );
  }

  public setAutoRelease(
    model: IReleaseSettings,
  ): Observable<ResultModelGeneric<null>> {
    return this.resolveError(
      this.http.post('/api/tablemanagement/release/settings', model),
    );
  }

  public getCanvasRunDate(collaborationId: number, isLastRunCheck: boolean) {
    return this.resolveErrorGeneric(
      this.http.get(`/api/canvas/rundate/${collaborationId}/${isLastRunCheck}`),
    );
  }

  ////////// new-client-manager ///////////
  public updateDataSource(dataSource: IDataSource, collaborationId: number) {
    return this.http.post(
      `/collaborations/${collaborationId}/tables/${dataSource.tableId}/source`,
      dataSource,
      { params: new IxupHttpCustomParams(true) },
    );
  }

  public createTableSource(
    tableDefinition: IDataSourceDefinition,
    collaborationId: number,
  ) {
    return this.http.post(
      `/collaborations/${collaborationId}/tables`,
      tableDefinition,
      {
        params: new IxupHttpCustomParams(true),
      },
    );
  }

  public verifyDataSource(dataSource: IDataSource) {
    return this.http.post('/datasources/verify', dataSource, {
      params: new IxupHttpCustomParams(true),
    });
  }

  public verifyExistingDataSource(
    collaborationId: number,
    tableId: number,
    dataSource: IDataSource,
  ) {
    return this.http.post(
      `/collaborations/${collaborationId}/tables/${tableId}/source/verify`,
      dataSource,
      {
        params: new IxupHttpCustomParams(true),
      },
    );
  }

  public getReportToken(
    collaborationId: number,
    reportTokenRequest: ICredentialsRequestModel,
  ) {
    return this.http.get(
      `/collaborations/${collaborationId}/tables/${reportTokenRequest.tableUniqueName}/report?deactivateExistingTokens=${reportTokenRequest.deactivateExistingTokens}`,
      {
        params: new IxupHttpCustomParams(true),
        headers: new HttpHeaders({
          'Ixup-custodianPassphrase':
            reportTokenRequest.dataCustodianPassphrase,
          'Ixup-collaborationPassphrase':
            reportTokenRequest.collaborationPassphrase,
          'Ixup-signalrGroupName': reportTokenRequest.signalRGroupName,
        }),
      },
    );
  }

  public encryptIngest(collaborationId: number, data: IngestTablesModel) {
    return this.http.post(`/collaborations/${collaborationId}/import`, data, {
      params: new IxupHttpCustomParams(true),
      headers: new HttpHeaders({
        'Ixup-custodianPassphrase': data.dataCustodianPassphrase,
        'Ixup-collaborationPassphrase': data.collaborationSharedPassphrase,
        'Ixup-signalrGroupName': data.SignalRGroupName,
      }),
    });
  }

  public releaseTableRequest(data: IReleaseTableRequest) {
    return this.http.post('/collaborations/table/release', data, {
      params: new IxupHttpCustomParams(true),
    });
  }

  public getExportParams(tableId: number, collaborationId: number) {
    return this.http.get(
      `/collaborations/${collaborationId}/tables/${tableId}/export?replace=true`,
      { params: new IxupHttpCustomParams(true) },
    );
  }

  public postKeysFileContent(collaborationId: number, fileContents: string) {
    return this.http.post(
      `/collaborations/${collaborationId}/keys`,
      fileContents,
      {
        params: new IxupHttpCustomParams(true),
        headers: new HttpHeaders().set('content-type', 'text/plain'),
      },
    );
  }

  public runCanvasFromAgent(
    collaborationId: number,
    signalRProgressGroupName: string,
  ) {
    return this.http.post(`/collaborations/${collaborationId}/run`, null, {
      params: new IxupHttpCustomParams(true),
      headers: new HttpHeaders({
        'Ixup-signalrGroupName': signalRProgressGroupName,
      }),
    });
  }

  public postExportParams(
    collaborationId: number,
    tableId: number,
    exportParams: IExportParamsModel,
  ) {
    console.log(exportParams);

    return this.http.post(
      `/collaborations/${collaborationId}/tables/${tableId}/export`,
      exportParams,
      {
        params: new IxupHttpCustomParams(true),
        headers: new HttpHeaders({
          'Ixup-custodianPassphrase': exportParams.dataCustodianPassphrase,
          'Ixup-collaborationPassphrase':
            exportParams.collaborationSharedPassphrase,
          'Ixup-signalrGroupName': exportParams.signalRGroupName,
        }),
      },
    );
  }

  public streamFile(
    collaborationId: number,
    tableId: number,
    name: string,
    batchId: number,
  ) {
    this.http
      .get(
        `/collaborations/${collaborationId}/tables/${tableId}/stream/${batchId}`,
        {
          responseType: 'blob',
          params: new IxupHttpCustomParams(true),
          headers: new HttpHeaders({
            'Ixup-custodianPassphrase': '',
            'Ixup-collaborationPassphrase': '',
            'Ixup-signalrGroupName': collaborationId + '-OutputTable',
          }),
        },
      )
      .subscribe(
        (result) => {
          const url = window.URL.createObjectURL(result);
          const downloadAnchor = document.createElement('a');
          document.body.appendChild(downloadAnchor);
          downloadAnchor.setAttribute('style', 'display: none');
          const blob = new Blob([result], { type: 'txt/csv' });
          downloadAnchor.href = URL.createObjectURL(blob);
          downloadAnchor.download = `${name}.csv`;
          downloadAnchor.click();
          window.URL.revokeObjectURL(url);
          downloadAnchor.remove();
        },
        () => {
          //do nothing
        },
      );
  }
}
