import { InjectionToken } from '@angular/core';

import { LANG_EN_TRANS } from './lang-en';
import { LANG_PI_TRANS } from './lang-pi';
import { LANG_XX_TRANS } from './lang-xx';
import { LANG_ZH_TRANS } from './lang-zh';

export const TRANSLATIONS = new InjectionToken('translations');

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const dictionary: { [key: string]: any } = {
  'xx': LANG_XX_TRANS,
  'en': LANG_EN_TRANS,
  'zh': LANG_ZH_TRANS,
  'PI': LANG_PI_TRANS,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const TRANSLATION_PROVIDERS: any = [
  { provide: TRANSLATIONS, useValue: dictionary },
];
