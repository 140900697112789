import { Observable, of as observableOf } from 'rxjs';

import 'rxjs';
import { map, publishReplay, refCount, switchMap } from 'rxjs/operators';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { PassphraseResetTypeEnum } from '../../components/collaborator/profile/model/passphrase-reset-type.model';
import { IxupHttpCustomParams } from '../../http/http-interceptor-params';
import { CollaboratorModel } from '../../model/collaborator.model';
import { CollaboratorTypeModel } from '../../model/collaborators/collaboratortype.model';
import { CustomCollaboratorTypeModel } from '../../model/collaborators/customcollaboratortype.model';
import { CreateCustodianModel } from '../../model/create-custodian.model';
import { CustodianModel } from '../../model/custodian.model';
import { ResultModelGeneric } from '../../model/result.model';
import { SignalRWrapperModel } from '../../model/signalR-wrapper.model';
import {
  ICheckKeyModel,
  IKeyModel,
  IKeyRequestModel
} from '../../pages/consoles/admin-console/model/key-request.model';
import { IValidateAuthCodeModel } from '../../pages/consoles/admin-console/model/validate-auth-code.model';
import { IxupBaseService } from '../ixup-base.service';
import { ImagesService } from '../shared/Images.service';

@Injectable()
export class CollaboratorService extends IxupBaseService {
  filteredCollaboratorTypeList: CustomCollaboratorTypeModel[];
  constructor(
    @Inject(HttpClient)
    private http: HttpClient,
    private imagesService: ImagesService,
  ) {
    super();
  }

  public get(id: number): Observable<CollaboratorModel> {
    return this.resolveErrorMessage(
      this.http.get(`/api/Collaborator/${id}`),
    ).pipe(
      switchMap((item) => {
        let newView = new CollaboratorModel();
        newView = Object.assign(newView, item.data);
        newView.logo = this.asynImage(item.data.logoImageId);
        newView.icon = this.asynImage(item.data.iconImageId);
        return observableOf(newView);
      }),
      publishReplay(1),
      refCount(),
    );
  }

  public getCollaboratorTypesFilteredList(isHost) {
    return this.getCollaboratorTypes().pipe(
      map((types: CollaboratorTypeModel[]) => {
        const collaboratorTypesList: CustomCollaboratorTypeModel[] = [];
        types.forEach((element) => {
          const descriptionArray = element.description.split(';');
          collaboratorTypesList.push({
            id: element.id,
            collaboratorType: element.collaboratorType,
            description: descriptionArray,
          });
        });
        return isHost
          ? collaboratorTypesList.filter((x) => x.collaboratorType !== 'Tenant')
          : collaboratorTypesList.filter(
            (x) => x.collaboratorType !== 'Anchor',
          );
      }),
    );
  }


  public collaboratorList(id) {
    return this.resolveErrorMessage(
      this.http.get(`/api/Collaborator/${id}/list`),
    );
  }

  public resetDataCustodianPassphrase(keyModel: IKeyRequestModel) {
    return this.http.post('/masterkeys/reset', keyModel, {
      params: new IxupHttpCustomParams(true),
      headers: new HttpHeaders({
        'Ixup-custodianPassphrase': '',
        'Ixup-collaborationPassphrase': '',
        'Ixup-signalrGroupName': '',
      }),
    });
  }

  public requestToResetDataCustodianPassphrase(
    resetType: PassphraseResetTypeEnum,
  ) {
    return this.resolveErrorMessage(
      this.http.post(`/api/Collaborator/resetrequest/${resetType}`, null),
    );
  }

  public getCollaboratorById(id) {
    return this.resolveError(
      this.http.put('/api/collaborator/getCollaborator', id),
    );
  }

  public getCollaboratorUsers() {
    return this.resolveError(this.http.get('/api/Collaborator/users'));
  }

  public getAllCollaborators() {
    return this.resolveErrorGeneric(this.http.get('/api/Collaborator/all'));
  }

  public getCollaboratorTypes() {
    return this.resolveError(this.http.get('/api/Collaborator/types'));
  }

  public getCollaboratorProfile() {
    return this.resolveError(this.http.get('/api/Collaborator/profile'));
  }

  public create(collaborator: CollaboratorModel) {
    return this.resolveErrorMessage(
      this.http.post('/api/collaborator/create', collaborator),
    );
  }

  public update(collaborator: CollaboratorModel) {
    return this.resolveErrorMessage(
      this.http.post('/api/Collaborator/update', collaborator),
    );
  }

  public delete(collaboratorId: number) {
    return this.resolveErrorMessage(
      this.http.delete(`/api/collaborator/${collaboratorId}/remove`),
    );
  }

  public getCollaboratorsForCurrentUser() {
    return this.resolveError(this.http.get('/api/Collaborator/my')).pipe(
      map((items) => {
        items.forEach(
          (item) =>
            (item.image$ = this.imagesService.getImage(item.iconImageId)),
        );
        return items;
      }),
    );
  }

  public getProviderTypesForCurrentUserCollaborator() {
    return this.resolveError(
      this.http.get('/api/Collaborator/my/providertypes'),
    );
  }

  public getProviderTypesDetailsForCurrentUserCollaborator(
    collaboratorId: number,
  ) {
    return this.resolveError(
      this.http.get(`/api/Collaborator/${collaboratorId}/providertypesdetails`),
    );
  }

  public getDefaultProviderType() {
    return this.resolveErrorMessage(
      this.http.get('/api/Collaborator/defaultprovidertype'),
    );
  }

  public getTheme() {
    return this.resolveError(this.http.get('/api/Collaborator/theme'));
  }

  public saveTheme(theme: string) {
    return this.resolveErrorMessage(
      this.http.post(`/api/Collaborator/${theme}`, null),
    );
  }

  public checkAuthCode(
    model: IValidateAuthCodeModel,
  ): Observable<ResultModelGeneric<boolean>> {
    return this.resolveError(
      this.http.post('/api/Collaborator/checkauthcode', model),
    );
  }

  public cancelAuthCode(): Observable<ResultModelGeneric<string>> {
    return this.resolveError(
      this.http.post('/api/Collaborator/cancelauthcode', null),
    );
  }

  public setCustodianPassphrase(model: IKeyModel, agentBaseUrl?: string) {
    const params = agentBaseUrl
      ? new IxupHttpCustomParams(true, agentBaseUrl.replace(/\/$/, ''), false)
      : new IxupHttpCustomParams(true);
    return this.http.post('/masterkeys/setPassphrase', model, {
      params,
      headers: new HttpHeaders({
        'Ixup-custodianPassphrase': '',
        'Ixup-collaborationPassphrase': '',
        'Ixup-signalrGroupName': '',
      }),
    });
  }

  public checkCustodianPassphrase(model: ICheckKeyModel) {
    return this.http.post('/masterkeys/checkPassphrase', model, {
      params: new IxupHttpCustomParams(true),
      headers: new HttpHeaders({
        'Ixup-custodianPassphrase': '',
        'Ixup-collaborationPassphrase': '',
        'Ixup-signalrGroupName': '',
      }),
    });
  }

  public createCustodian(custodian: SignalRWrapperModel<CreateCustodianModel>) {
    // the UI had to give it an id to put it in the store.  Remove it here.
    return this.resolveErrorMessage(
      this.http.post('/api/Collaborator/createcustodian', {
        ...custodian,
        payload: { ...custodian.payload, id: null },
      }),
    );
  }

  private asynImage(id: number): Observable<string> {
    return this.imagesService.getCustomerImage(id).pipe(
      switchMap((im) => observableOf(im.image)),
      publishReplay(1),
      refCount(),
    );
  }

  public getCollaboratorIdByName(name: string) {
    return this.resolveErrorGeneric<number>(
      this.http.get(`/api/collaborator/getid/${encodeURIComponent(name)}`),
    );
  }

  getDataCustodianById(id: number): Observable<CustodianModel> {
    return this.resolveErrorGeneric(
      this.http.get(`/api/Collaborator/${id}`),
    ).pipe(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      map((result: ResultModelGeneric<any>) => {
        return {
          name: result.data.collaboratorName,
          id: result.data.id,
          collaboratorType: result.data.collaboratorType,
          organisationId: result.data.organisationId,
          organisationName: result.data.organisationName,
          description: result.data.description,
          iconImageId: result.data.iconImageId,
          numberOfUsers: result.data.numberOfUsers,
          numberOfCollaborations: result.data.numberOfCollaborations,
          creditCap: result.data.creditCap,
          creditsSpent: result.data.creditsSpent,
          signalRMessage: result.data.signalRMessage,
          defaultDataSource: result.data.defaultDataSource,
        };
      }),
    );
  }
}
