import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'status',
  standalone: true,
})
export class statusPipe implements PipeTransform {
  status: string;
  transform(value: boolean): string {
    if (value) {
      this.status = 'Active';
    } else if (!value) {
      this.status = 'Inactive';
    }
    return this.status;
  }
}
