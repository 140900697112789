export class IVersionSigningModel {
  versionId: number;
  custodianId: number;
  canvasId: number;
  custodianName: string;
  collaborationName: string;
  status?: StatusEnum;
}

export enum StatusEnum { 
  declined = 'declined',
  signed = 'signed',
  closed = 'closed',
}
