import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatLineModule } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyListModule } from '@angular/material/legacy-list';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSidenavModule } from '@angular/material/sidenav';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, RouterOutlet } from '@angular/router';
import { Store } from '@ngrx/store';
import { map, Observable } from 'rxjs';
import { AppHeaderComponent } from './components/app-header/app-header.component';
import { AdminConsoleLeftNavigationMenuComponent } from './components/left-navigation-menus/admin-console/admin-console-left-navigation-menu.component';
import { ConvertrDashboardLeftNavigationMenuComponent } from './components/left-navigation-menus/convertr-dashboard/convertr-dashboard-left-navigation-menu.component';
import { CustodianConsoleLeftNavigationMenuComponent } from './components/left-navigation-menus/custodian-console/custodian-console-left-navigation-menu.component';
import { EnablerConsoleLeftNavigationMenuComponent } from './components/left-navigation-menus/enabler-console/enabler-console-left-navigation-menu.component';
import { VennDashboardLeftNavigationMenuComponent } from './components/left-navigation-menus/venn-dashboard/venn-dashboard-left-navigation-menu.component';
import { SpinnerComponent } from './components/shared/spinner/spinner.component';
import { StyleManager } from './components/shared/style-manager/style-manager';
import { AuthUserModel } from './model/authuser.model';
import { Permission } from './model/Permission';
import { AuthService } from './services/auth/AuthService';
import { CollaboratorService } from './services/collaborator/collaborator.service';
import { FilesService } from './services/files/files.service';
import { SpinnerService } from './services/spinnerService';
import { IAppState } from './state/app.interfaces';
import { getRouterUrlRootSection } from './state/app.reducer';

@Component({
  selector: 'app-root',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './app.component.html',
  styleUrls: ['./app.scss'],
  providers: [SpinnerService],
  standalone: true,
  imports: [
    AppHeaderComponent,
    MatSidenavModule,
    NgIf,
    NgClass,
    MatLegacyListModule,
    EnablerConsoleLeftNavigationMenuComponent,
    AdminConsoleLeftNavigationMenuComponent,
    CustodianConsoleLeftNavigationMenuComponent,
    ConvertrDashboardLeftNavigationMenuComponent,
    VennDashboardLeftNavigationMenuComponent,
    MatDividerModule,
    MatIconModule,
    MatTooltipModule,
    MatLineModule,
    MatButtonModule,
    SpinnerComponent,
    RouterOutlet,
    AsyncPipe,
  ],
})
export class AppComponent implements OnInit, AfterViewInit {
  collaboratorIsHost: boolean;
  title = 'IXUP';

  public user$: Observable<AuthUserModel>;
  public orgId: number;
  emitMessage: string;
  viewWorkbench = false;
  CreateCollaborator = false;
  CreateCollaboration = false;
  viewCollaborator = false;
  viewCollaboration = false;

  miniNav = true;
  filemanager = false;
  @ViewChild('fileDownloadAnchor', { static: true }) fileDownloadAnchor;
  @ViewChild('drawerSideMenu', { static: true }) public drawerSideMenu;

  accessAdminConsole = false;
  accessCustodianConsole = false;
  accessEnablerConsole = false;
  accessDataUploader = false;
  manageUsers = false;
  billingAdmin = false;

  urlRootSection$: Observable<string>;
  dataCustodianId: number;
  public accessPowaIndexDashboard = false;
  public accessPlayXSafeDashboard = false;
  public playXSafeAdmin = false;
  public playXSafeUpload = false;
  public accessConvertrDashboard = false;
  public accessVennDashboard = false;

  constructor(
    private authService: AuthService,
    public styleManager: StyleManager,
    private filesService: FilesService,
    public dialog: MatDialog,
    public collaboratorService: CollaboratorService,
    private _changeDetectorRef: ChangeDetectorRef,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    public router: Router,
    store: Store<IAppState>,
  ) {
    this.urlRootSection$ = store.select(getRouterUrlRootSection).pipe(
      map((rootRoute: string) => {
        if (!rootRoute) {
          return null;
        }

        switch (rootRoute) {
        case 'enabler-console':
        case 'admin-console':
        case 'custodian-console':
        case 'control-centre':
        case 'powaindex':
        case 'playXsafe':
        case 'convertr':
        case 'venn':
        case 'terms-and-conditions':
          return rootRoute;
        default:
          return 'legacy';
        }
      }),
    );

    this.matIconRegistry.addSvgIcon(
      'icon-save-canvas',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/images/canvas/icon/canvas-dirty.svg',
      ),
    );

    this.authService.currentUser$.subscribe((user) => {
      this.dataCustodianId = user.CollaboratorId;
      this.collaboratorIsHost = user.CollaboratorTypeId === 1 ? true : false;
    });
  }

  ngOnInit(): void {
    this.user$ = this.authService.currentUser$;

    // this.sidebarService.sidebar = this.drawerSideMenu;
    this.authService
      .hasPermission([Permission.CreateCollaborator])
      .subscribe((result) => {
        this.CreateCollaborator = result[0];
      });
    this.authService
      .hasPermission([Permission.CreateCollaboration])
      .subscribe((result) => {
        this.CreateCollaboration = result[0];
      });
    this.authService
      .hasPermission([Permission.ViewCollaboration])
      .subscribe((result) => {
        this.viewCollaboration = result[0];
      });
    this.authService
      .hasPermission([Permission.PowaIndexConsole])
      .subscribe((result) => {
        this.accessPowaIndexDashboard = result[0];
      });
    this.authService
      .hasPermission([Permission.PlayXSafeAdmin])
      .subscribe((result) => {
        this.playXSafeAdmin = result[0];
      });
    this.authService
      .hasPermission([Permission.PlayXSafeUpload])
      .subscribe((result) => {
        this.playXSafeUpload = result[0];
      });
    this.authService
      .hasPermission([Permission.Convertr])
      .subscribe((result) => {
        this.accessConvertrDashboard = result[0];
      });
    this.authService.hasPermission([Permission.Venn]).subscribe((result) => {
      this.accessVennDashboard = result[0];
    });

    this.authService
      .hasPermission([
        Permission.ViewCollaborator,
        Permission.AdminConsole,
        Permission.CustodianConsole,
        Permission.EnablerConsole,
      ])
      .subscribe((result) => {
        this.viewCollaborator = result[0];
        this.accessAdminConsole = result[1];
        this.accessCustodianConsole = result[2];
        this.accessEnablerConsole = result[3];
      });

    this.authService
      .hasPermission([Permission.ManageUsersAndPermissions])
      .subscribe((result: boolean[]) => {
        this.manageUsers = result[0];
      });

    this.authService
      .hasPermission([Permission.BillingAdmin])
      .subscribe((result: boolean[]) => {
        this.billingAdmin = result[0];
      });
  }

  ngAfterViewInit(): void {
    // broadcast to all listener observables when loading the page
    // this.media.broadcast();
    this._changeDetectorRef.detectChanges();
  }

  toggleMiniNav(): void {
    this.miniNav = !this.miniNav;
    setTimeout(() => {
      // do nothing.
    });
  }

  downloadFileManagementApp() {
    this.filesService.downloadFileManagementApp();
  }
}
