/* eslint-disable no-useless-escape */
import * as _moment from 'moment';

import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';

const moment = _moment;

export class ValidationHelper {
  static isWindowsFileName(control: UntypedFormControl) {
    const fileNameRegEx = /^[-\w^&'@{}[\],$=!#().%+~ ]+$/;
    return fileNameRegEx.test(control.value) ? null : { isWindowsFileName: true };
  }

  static isValidEmail(control: UntypedFormControl) {
    const EMAIL_REGEXP =
      /^[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/;
    return EMAIL_REGEXP.test(control.value) ? null : { isValidEmail: true };
  }

  static cannotContainSpace(control: UntypedFormControl) {
    return control.value.indexOf(' ') >= 0 ? { cannotContainSpace: true } : null;
  }

  static minAlphabetsNumeric(control: UntypedFormControl) {
    const ALPHABETS_REGEXP = /^([a-zA-Z0-9]{3,})[a-zA-Z0-9\s]*$/;
    return ALPHABETS_REGEXP.test(control.value) ? null : { minAlphabetsNumeric: true };
  }

  static alphabetsWithSpaceOnly(control: UntypedFormControl) {
    const ALPHABETS_REGEXP = /^[a-zA-Z\s]*$/;
    return ALPHABETS_REGEXP.test(control.value) ? null : { alphabetsWithSpaceOnly: true };
  }

  static alphabetsWithoutSpace(control: UntypedFormControl) {
    const ALPHABETS_REGEXP = /^[a-zA-Z]*$/;
    return ALPHABETS_REGEXP.test(control.value) ? null : { alphabetsWithoutSpace: true };
  }

  static alphabetsWithNumeric(control: UntypedFormControl) {
    const ALPHABETS_REGEXP = /^[a-zA-Z0-9]*$/;
    return ALPHABETS_REGEXP.test(control.value) ? null : { alphabetsWithNumeric: true };
  }

  static alphabetsWithNumericWithSpaces(control: UntypedFormControl) {
    const ALPHABETS_REGEXP = /^[a-zA-Z0-9\s]*$/;
    return ALPHABETS_REGEXP.test(control.value) ? null : { alphabetsWithNumericWithSpaces: true };
  }

  // static cannotHaveOnlyDigits(control: FormControl) {
  //   let ALPHABETS_REGEXP = /(?!^\d+$)^.+(?=.*[a-z])/;
  //   return (ALPHABETS_REGEXP.test(control.value)) ? null : {cannotHaveOnlyDigits: true};
  // }

  static alphabetsWithNumericWithoutSpace(control: UntypedFormControl) {
    const ALPHABETS_REGEXP = /^([a-zA-z]{1})[a-zA-Z\d]*$/;
    return ALPHABETS_REGEXP.test(control.value) ? null : { alphabetsWithoutSpace: true };
  }

  static greaterThan100(control: UntypedFormControl) {
    const ALPHABETS_REGEXP = /^[1-9]?[0-9]{1}$|^100$/;
    return ALPHABETS_REGEXP.test(control.value) ? null : { greaterThan100: true };
  }

  static validURL(control: UntypedFormControl) {
    const URL_REGEXP =
      /^((https?):\/\/)?([w|W]{3}\.)+[a-zA-Z0-9\-.]{3,}\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/;
    return URL_REGEXP.test(control.value);
  }

  static notWhiteSpaceOnly(control: UntypedFormControl) {
    const WHITESPACEONLY_REGEXP = /^\s+$/;
    return WHITESPACEONLY_REGEXP.test(control.value) ? { notWhiteSpaceOnly: true } : null;
  }

  static noWhitespaceValidator(control: UntypedFormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }

  static alphaNumericWhiteSpaceDash(control: UntypedFormControl) {
    const ALPHANUMERICDASH_REGEXP = /^([a-zA-Z0-9-]{3})+[a-zA-Z0-9\s-]*$/;
    return ALPHANUMERICDASH_REGEXP.test(control.value)
      ? null
      : { alphaNumericWhiteSpaceDash: true };
  }

  static alphaNumericWhiteSpaceDash2Characters(control: UntypedFormControl) {
    const ALPHANUMERICDASH_REGEXP = /^([a-zA-Z0-9-]{2})+[a-zA-Z0-9\s-]*$/;
    return ALPHANUMERICDASH_REGEXP.test(control.value)
      ? null
      : { alphaNumericWhiteSpaceDash2Characters: true };
  }

  static alphaNumericWhiteSpaceApostrophes2Characters(control: UntypedFormControl) {
    const ALPHANUMERICDASH_REGEXP = /^([a-zA-Z0-9-']{2})+[a-zA-Z0-9\s-']*$/;
    return ALPHANUMERICDASH_REGEXP.test(control.value)
      ? null
      : { alphaNumericWhiteSpaceApostrophes2Characters: true };
  }

  static alphaNumericUserName(control: UntypedFormControl) {
    // eslint-disable-next-line no-useless-escape
    const ALPHANUMERICDASH_REGEXP = /([a-zA-z]{2})([\\s\\\'-][a-zA-z]*)*/;
    return ALPHANUMERICDASH_REGEXP.test(control.value) ? null : { alphaNumericUserName: true };
  }

  static hubConnection(control: UntypedFormControl) {
    // eslint-disable-next-line no-useless-escape
    const HUBCONNECTION_REGEXP = /^[A-Za-z0-9]$|^[A-Za-z0-9][\s\w-\.\~]*[A-Za-z0-9]$/;
    return HUBCONNECTION_REGEXP.test(control.value) ? null : { hubconnection: true };
  }



  static validUserName(control: UntypedFormControl) {
    const USERNAME_REGEXP = /^[a-zA-Z0-9.]*$/;
    return USERNAME_REGEXP.test(control.value) ? null : { validUserName: true };
  }

  static integer(control: UntypedFormControl) {
    const DECIMALORINTEGER_REGEXP = /^\d+$/;
    return DECIMALORINTEGER_REGEXP.test(control.value) ? null : { integer: true };
  }

  static decimalOrInteger(control: UntypedFormControl) {
    const DECIMALORINTEGER_REGEXP = /^[1-9]\d*(\.\d+)?$/;
    return DECIMALORINTEGER_REGEXP.test(control.value) ? null : { decimalOrInteger: true };
  }

  static limitTo2DecimalPlaces(control: UntypedFormControl) {
    const DECIMALORINTEGER_REGEXP = /^(?=.*[1-9])\d*(?:\.\d{1,2})$/;
    return DECIMALORINTEGER_REGEXP.test(control.value) ? null : { limitTo2DecimalPlaces: true };
  }

  static leadingAndTrailingWhiteSpace(control: UntypedFormControl) {
    const LEADINGORTRAILINGWHITESPACE_REGEXP = /^[\s]+|[\s]+$/;
    return LEADINGORTRAILINGWHITESPACE_REGEXP.test(control.value) ? { leadingAndTrailingWhiteSpace: true } : null;
  }

  static limitTo5DecimalPlaces(control: UntypedFormControl) {
    const DECIMALORINTEGER_REGEXP = /^(?=.*[1-9])\d*(?:\.\d{1,5})$/;
    return DECIMALORINTEGER_REGEXP.test(control.value) ? null : { limitTo5DecimalPlaces: true };
  }


  static greaterThanZero(control: UntypedFormControl) {
    return Number(control.value < 0) ? { greaterThanZero: true } : null;
  }

  static notGreaterThanOne(control: UntypedFormControl) {
    return Number(control.value > 1) ? { greaterThanOne: true } : null;
  }

  static notGreaterThanOrEqualToOne(control: UntypedFormControl) {
    return Number(control.value >= 1) ? { greaterThanOrEqualToOne: true } : null;
  }

  static lessThanNegative120(control: UntypedFormControl) {
    return Number(control.value < -120) ? { lessThanNegative120: true } : null;
  }

  static greaterThan120(control: UntypedFormControl) {
    return Number(control.value > 120) ? { greaterThan120: true } : null;
  }

  static greaterThanAndNotEqualToZero(control: UntypedFormControl) {
    return Number(control.value <= 0) ? { greaterThanAndNotEqualToZero: true } : null;
  }

  static lessThanZero(control: UntypedFormControl) {
    return Number(control.value > 0) ? { lessThanZero: true } : null;
  }

  static hasValue(inputControl: AbstractControl): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (
        inputControl == null ||
        isEmptyInputValue(inputControl.value) ||
        isEmptyInputValue(control.value)
      ) {
        return null;
      }
      const expr = new RegExp(inputControl.value, 'i');
      if (expr.test(control.value)) {
        return { hasValue: inputControl.value };
      } else {
        return null;
      }
    };
  }

  static nValidValidator(min: number, validators: ValidatorFn[]) {
    return (control: AbstractControl): ValidationErrors | null => {
      const validated = validators.map((v) => v(control));
      const validCount = validated.reduce((res, v) => {
        return v === null ? ++res : res;
      }, 0);
      if (validCount >= min) {
        return null;
      } else {
        return { nValidValidator: { min, actual: validCount } };
      }
    };
  }

  static checkDates(group: UntypedFormGroup) {
    const start = moment(group.controls.startDate.value);
    const end = moment(group.controls.endDate.value);

    const invalid = start > end;
    return invalid ? { notValid: true } : null;
  }

  static minSmallerThanMax(group: UntypedFormGroup) {
    if (
      group.value.minValue != null &&
      group.value.maxValue != null &&
      group.value.minValue !== '' &&
      group.value.maxValue !== '' &&
      parseFloat(group.value.minValue) > parseFloat(group.value.maxValue)
    ) {
      return {
        // failed to validate
        minSmallerThanMax: true,
      };
    } else {
      return null;
    }
  }

  static valueLessThanPrevious: ValidatorFn = (group: UntypedFormGroup) => {
    const firstValue = Number(group.get('value1').value);
    const secondValue = Number(group.get('value2').value);
    const thirdValue = Number(group.get('value3').value);
    return firstValue !== null &&
      secondValue !== null &&
      thirdValue !== null &&
      firstValue < secondValue &&
      secondValue < thirdValue
      ? null
      : { valueLessThanPrevious: true };
  };

  static checkPassword(group: UntypedFormGroup) {
    const password = group.value.newPassword;

    if (password.length === 0) {
      return;
    }

    if (
      group.value.complexityPolicy &&
      !new RegExp(group.value.complexityPolicy.regexPattern).test(password)
    ) {
      return { invalidPasswordComplexity: true };
    }

    const confirmPassword = group.value.confirmPassKey;

    if (confirmPassword.length === 0) {
      return;
    }

    const invalid = password !== confirmPassword;
    return invalid ? { invalidPassword: true } : null;
  }

  static isValidNumber(control: AbstractControl) {
    if (!!control.value && isNaN(control.value)) {
      return { invalidNumber: true };
    }
    return null;
  }

  static shouldNotBeZero(control: AbstractControl) {
    if (!!control.value && Number(control.value) === 0) {
      return { zero: true };
    }
    return null;
  }

  static shouldNotBeDecimal(control: AbstractControl) {
    if (!!control.value && (control.value as number).toString().indexOf('.') >= 0) {
      return { isDecimal: true };
    }
    return null;
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isEmptyInputValue(value: any): boolean {
  // we don't check for string here so it also works with arrays

  return value == null || value.length === 0;
}
