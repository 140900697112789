import { Observable } from 'rxjs';
export class CollaboratorModel {
  id: 0;
  collaboratorName = '';
  contactEmail = '';
  description = '';
  organisationId: 0;
  url = '';
  host: boolean;
  collaboratorType: '';
  address = '';
  suburb = '';
  postcode = '';
  state = '';
  country = '';
  countryCode = '';
  logo: Observable<string>;
  icon: Observable<string>;
  iconImageId = 0;
  logoImageId = 0;
  theme = '';
  contactNumber = '';
}
