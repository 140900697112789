import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import find from 'lodash-es/find';
import groupBy from 'lodash-es/groupBy';
import isNumber from 'lodash-es/isNumber';
import transform from 'lodash-es/transform';
import {
  Observable,
  of as observableOf,
  throwError as observableThrowError
} from 'rxjs';
import { map, publishReplay, refCount, switchMap } from 'rxjs/operators';
import { IUserAccessModel } from '../../components/users/model/user-access.model';
import { DomainNameModel } from '../../model/domainname.model';
import { PermissionsListModel } from '../../model/permissionslist.model';
import { ReportPasswordModel } from '../../model/reportPassword.model';
import { ResultModelGeneric } from '../../model/result.model';
import { RoleListModel } from '../../model/rolelist.model';
import { UserModel } from '../../model/user.model';
import { UserCreateViewModel } from '../../model/usercreate.model';
import { UserViewModel } from '../../model/userview.model';
import { IConsoleUserModel } from '../../pages/consoles/model/console-user.model';
import { IxupBaseService } from '../ixup-base.service';
import { ImagesService } from '../shared/Images.service';

@Injectable()
export class UserService extends IxupBaseService {
  constructor(
    @Inject(HttpClient) private http: HttpClient,
    private imagesService: ImagesService,
  ) {
    super();
  }

  createUser(
    user: UserCreateViewModel,
  ): Observable<ResultModelGeneric<UserCreateViewModel>> {
    return this.resolveErrorGeneric<UserCreateViewModel>(
      this.http.post('/api/user/createuser', user),
    );
  }

  createConsoleUser(
    user: IConsoleUserModel,
  ): Observable<ResultModelGeneric<IConsoleUserModel>> {
    const mappedUser = {
      ...user,
      id: user.id ? [user.id] : [],
      roles: user.roles.map((r) => r.id),
    };

    return this.resolveErrorGeneric<IConsoleUserModel>(
      this.http.post('/api/user/createuser', mappedUser),
    );
  }

  isUserUnique(username: string): Observable<ResultModelGeneric<boolean>> {
    return this.resolveErrorGeneric<boolean>(
      this.http.get(`/api/User/domains/${username}/isunique`),
    );
  }

  checkAccess(url: string) {
    return this.resolveErrorGeneric(
      this.http.get(`/api/User/CheckAccess/${url}`),
    );
  }

  listRoles(): Observable<ResultModelGeneric<RoleListModel[]>> {
    return this.resolveErrorGeneric<RoleListModel[]>(
      this.http.get('/api/User/listRoles'),
    );
  }

  listUniversalRoles(): Observable<ResultModelGeneric<RoleListModel[]>> {
    return this.resolveErrorGeneric<RoleListModel[]>(
      this.http.get('/api/User/universalRoles'),
    );
  }

  getAllUsersInOrganisationCollaborators(): Observable<
    ResultModelGeneric<UserViewModel[]>
    > {
    return this.resolveErrorGeneric(
      this.http.get('/api/User/collaborator/organisation'),
    );
  }

  addUserToCollaborator(
    userAccess: IUserAccessModel,
  ): Observable<ResultModelGeneric<IUserAccessModel>> {
    return this.resolveErrorGeneric<IUserAccessModel>(
      this.http.put('/api/User/collaborator/access', userAccess),
    );
  }

  getAllUsersByDataCustodian() {
    return this.resolveErrorGeneric(
      this.http.get('/api/User/dataCustodian/user/all'),
    );
  }

  getUserDetails(id) {
    return this.resolveError(this.http.get(`/api/User/${id}/details`));
  }

  checkDeleteRole(id) {
    return this.resolveError(this.http.get(`/api/User/check/${id}/delete`));
  }

  deleteRole(id: number): Observable<ResultModelGeneric<number>> {
    return this.resolveErrorGeneric<number>(
      this.http.delete(`/api/User/${id}/delete`),
    );
  }

  getLanguages() {
    return this.resolveError(this.http.get('/api/User/languages'));
  }

  listRolesPermissions() {
    return this.resolveError(this.http.get('/api/User/roles/all'));
  }

  getPermissions() {
    return this.resolveError(this.http.get('/api/User/permissions')).pipe(
      switchMap((permissions) => {
        const groupByPermissionGroup = permissions
          .groupBy((x) => x.permissionGroup)
          .map((value, key) => {
            const firstInGroup = find(permissions, ['permissionGroup', key]);
            return {
              permissionGroup: key,
              permissionSeqNo: firstInGroup.permissionSeqNo,
              category: firstInGroup.category,
              categoryDesc: firstInGroup.categoryDesc,
              categorySeqNo: firstInGroup.categorySeqNo,
              items: value,
            };
          })
          .sortBy(['permissionSeqNo'])
          .value();

        const groupByPermissionCategory = groupByPermissionGroup
          .groupBy((x) => x.category)
          .map((value, key) => {
            const firstInGroup = find(groupByPermissionGroup, [
              'category',
              key,
            ]);
            return {
              category: key,
              categoryDesc: firstInGroup.categoryDesc,
              categorySeqNo: firstInGroup.categorySeqNo,
              permissionGroups: value,
            };
          })
          .filter((category) => category.category !== '')
          .sortBy(['categorySeqNo'])
          .value();

        return observableOf(groupByPermissionCategory);
      }),
    );
  }

  getRole(id: number): Observable<ResultModelGeneric<RoleListModel>> {
    return this.resolveErrorGeneric<RoleListModel>(
      this.http.get(`/api/User/roles/${id}`),
    );
  }

  updateRole(model: any): Observable<ResultModelGeneric<number>> {
    return this.resolveError(this.http.put('/api/User/roles', model));
  }

  updateUser(
    model: UserViewModel,
  ): Observable<ResultModelGeneric<UserViewModel>> {
    return this.resolveErrorGeneric<UserViewModel>(
      this.http.post('/api/User/user', model),
    );
  }

  updateConsoleUser(
    model: IConsoleUserModel,
  ): Observable<ResultModelGeneric<IConsoleUserModel>> {
    return this.resolveErrorGeneric<IConsoleUserModel>(
      this.http.post('/api/User/custodianUser', model),
    );
  }

  removeUserFromCustodian(id: number) {
    return this.resolveError(this.http.put('/api/User/deactivate', id));
  }

  getRolePermissions(id: number) {
    return this.resolveErrorGeneric<PermissionsListModel>(
      this.http.get(`/api/User/roles/${id}/permissions`),
    ).pipe(
      switchMap((permissions) => {
        return observableOf(
          transform(
            groupBy(permissions.data, 'permissionGroup'),
            (permissionCategory, itemsInCategory, categoryKey) => {
              permissionCategory[categoryKey] = itemsInCategory.map(
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (i: any) => i.id,
              );
            },
            {},
          ),
        );
      }),
    );
  }

  getRolePermissionsByUser(): Observable<
    ResultModelGeneric<PermissionsListModel[]>
    > {
    return this.resolveErrorGeneric<PermissionsListModel[]>(
      this.http.get('/api/User/collaborator/role'),
    );
  }

  getDomains() {
    return this.resolveError(this.http.get('/api/User/domain'));
  }

  getDomainName(): Observable<DomainNameModel> {
    return this.resolveError(this.http.get('/api/user/domain'));
  }

  getUserProfile(): Observable<UserModel> {
    return this.resolveError(this.http.get('/api/User/profile')).pipe(
      switchMap((item) => {
        if (item) {
          let newView = new UserModel();
          newView = { ...newView, ...item };
          newView.roles = item.roles.map(
            (role: { roleName: string; defaultCollaboratorId: string }) => {
              return {
                roleName: role.roleName,
                description: role.defaultCollaboratorId,
              };
            },
          );
          if (isNumber(item.avatarImageId)) {
            newView.avatar = this.imagesService
              .getImage(item.avatarImageId)
              .pipe(
                switchMap((im) => {
                  return observableOf(im.image);
                }),
              );
          } else {
            newView.avatar = observableOf(null);
          }
          return observableOf(newView);
        } else {
          observableThrowError(new Error(item.message));
        }
      }),
      publishReplay(1),
      refCount(),
    );
  }

  getReportPassword(): Observable<ReportPasswordModel> {
    return this.resolveErrorGeneric<ReportPasswordModel>(
      this.http.get('/api/user/reportpassword'),
    ).pipe(
      map((result) => {
        return result.data;
      }),
    );
  }

  resendReportPassword() {
    return this.http.post('/api/User/report/password/resend', null);
  }

  resetReportPassword() {
    return this.http.post('/api/user/report/password/reset', null);
  }

  public update(model) {
    return this.resolveError(this.http.post('/api/User/user', model));
  }

  public activateExistingUserInCollaborator(id: number): Observable<number> {
    return this.resolveError(
      this.http.post(`/api/User/collaborator/user/${id}/active`, null),
    );
  }

  setActiveUser(action: { id: number; active: boolean }) {
    if (!action.active) {
      return this.resolveError(
        this.http.put('/api/User/deactivate', action.id),
      );
    } else {
      return this.resolveError(
        this.http.post(`/api/User/collaborator/user/${action.id}/active`, null),
      );
    }
  }

  setAdminActiveUser(action: { id: number; active: boolean }) {
    return this.resolveError(this.http.put('/api/User/active', action));
  }

  public setUserCurrentConsole(selectedConsole: string): Observable<boolean> {
    return this.resolveError(
      this.http.post(`/api/user/set/console/${selectedConsole}`, null),
    );
  }

  public getUserCurrentConsole() {
    return this.resolveError(this.http.get('/api/user/get/console'));
  }

  public getCurrentUser() {
    return this.resolveError(this.http.get('/api/user/get/user'));
  }

  public saveTerms() {
    return this.resolveError(this.http.post('/api/user/terms', null));
  }
}
