import { NgIf } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import {
  MatLegacyDialogModule,
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Permission } from '../../../../model/Permission';
import { AuthService } from '../../../../services/auth/AuthService';
import { CollaborationPassphraseService } from '../../../../services/data-protection/collaboration-passphrase.service';
import { DataProtectionService } from '../../../../services/data-protection/data-protection.service';
import { TranslatePipe } from '../../../../services/translate/translate.pipe';
import { CanvasService } from '../../../canvas/services/canvas.service';

@Component({
  selector: 'confirm-passphrase-dialog',
  templateUrl: './confirm-passphrase-dialog.component.html',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatLegacyDialogModule,
    MatIconModule,
    NgIf,
    MatLegacyFormFieldModule,
    MatLegacyInputModule,
    MatButtonModule,
    MatTooltipModule,
    MatDividerModule,
    TranslatePipe,
  ],
})
export class ConfirmPassphraseDialog implements OnInit {
  modelForm: UntypedFormGroup;
  custodianPassphraseIncorrect = false;
  collabPassphraseIncorrect = false;
  collaboratorId: number;
  collaborationId: number;
  hideDCPassphrase = true;
  hideCPassphrase = true;
  sampleDataWarning: string;
  ingestFiles = false;
  allSelected = false;
  powaIndexUser = false;
  dashboard = true;

  constructor(
    private dialogRef: MatDialogRef<ConfirmPassphraseDialog>,
    private dataProtectionService: DataProtectionService,
    private collaborationPassphraseService: CollaborationPassphraseService,
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) data,
    private authService: AuthService,
    canvasSerice: CanvasService,
  ) {
    this.collaborationId = data.collaborationId;
    this.allSelected = data.allSelected;
    this.dashboard = data.dashboard;

    authService.currentUser$.subscribe((user) => {
      this.collaboratorId = user.CollaboratorId;
    });

    canvasSerice
      .getApprovalStatus(data.collaborationId)
      .subscribe((response: string) => {
        this.sampleDataWarning =
          response !== 'approved'
            ? (this.sampleDataWarning = 'This collaboration has not been approved. Only sample data from the first 50 rows will be ingested and available to use at this time.')
            : null;
      });
  }

  ngOnInit() {
    const custodianPassphrase: string =
      this.dataProtectionService.getStoredPassphrase();

    const collaborationPassphrase =
      this.collaborationPassphraseService.getStoredPassphrase(
        this.collaborationId,
      );

    this.modelForm = this.fb.group({
      custodianPassphrase: [custodianPassphrase, [Validators.required]],
      collaborationPassphrase: [collaborationPassphrase, [Validators.required]],
      checkFilesReady: [''],
    });

    this.authService
      .hasPermission([Permission.PowaIndexConsole])
      .subscribe((result) => {
        this.powaIndexUser = result[0];
        this.ingestFiles = this.allSelected && this.powaIndexUser;
      });
  }

  check(check: boolean) {
    if (check) {
      this.ingestFiles = true;
    } else {
      this.ingestFiles = false;
    }
  }

  submit() {
    this.dataProtectionService.setStoredPassphrase(
      this.modelForm.controls.custodianPassphrase.value,
    );

    this.collaborationPassphraseService.setStoredPassphrase(
      this.collaborationId,
      this.modelForm.controls.collaborationPassphrase.value,
    );

    this.dialogRef.close({
      dataCustodianPassphrase:
        this.modelForm.controls.custodianPassphrase.value,
      collaborationSharedPassphrase:
        this.modelForm.controls.collaborationPassphrase.value,
      ingestFiles: this.ingestFiles,
    });
  }

  cancel() {
    this.dialogRef.close(null);
  }
}
