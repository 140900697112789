import { Action } from '@ngrx/store';

import { CampaignModel } from '../../pages/venn/model/campaign.model';
import CollaboratorsOverviewModel from '../../pages/venn/model/collaborators-overview-model';
import { IOutputTableModel } from '../../pages/output-tables/models/output-table.model';
import { DashboardStatusEnum } from '../../pages/powaindex/model/dashboard-status.enum';
import { DashboardStatusModel } from '../../pages/powaindex/model/dashboard-status.model';
import { ReportDashboardStatusEnum } from '../../pages/powaindex/model/report-dashboard-status.enum';
import { CanvasActionChart } from '../model/canvas-action-chart';
import { TableStateEnum } from '../model/table-state';
import { IFilterChart } from '../../pages/venn/model/filter-chart.model';

export enum DashboardActionTypes {
  LoadVennDashboard = '[Dashboard Venn] Load Venn Dashboard',
  LoadVennDashboardSuccess = '[Dashboard Venn] Load Venn Dashboard Success',
  LoadVennDashboardFailed = '[Dashboard Venn] Load Venn Dashboard Failed',
  GetVennDashboard = '[Dashboard Venn] Get Venn Dashboard',
  GetVennDashboardSuccess = '[Dashboard Venn] Get Venn Dashboard Success',
  GetVennDashboardFailed = '[Dashboard Venn] Get Venn Dashboard Failed',
  LoadCollaboratorOverview = '[Dashboard Venn] Load Overview',
  LoadCollaboratorOverviewSuccess = '[Dashboard Venn] Load Overview Success',
  LoadCollaboratorOverviewFailed = '[Dashboard Venn] Load Overview Failed',
  SetCurrentVennDashboard = '[Dashboard Venn] SetCurrent Dashboard',
  SetStartDate = '[Dashboard Venn] Set Start Date',
  SetEndDate = '[Dashboard Venn] Set End Date',
  SetCurrentVennDashboardSuccess = '[Dashboard Venn] SetCurrent Venn Dashboard Success',
  SetCurrentVennDashboardFailed = '[Dashboard Venn] SetCurrent Venn Dashboard Failed',
  UpdateVennLastExported = '[Dashboard Venn] Update VennLast Exported Date',
  UpdateVennLastExportedSuccess = '[Dashboard Venn] Update VennLast Exported Date Success',
  UpdateVennLastExportedFailed = '[Dashboard Venn] Update VennLast Exported Date Failed',
  UpdateVennApprovalStatus = '[Dashboard Venn] Update Venn Approval Status',
  UpdateVennApprovalStatusSuccess = '[Dashboard Venn] Update Venn  Approval Status Success',
  UpdateVennApprovalStatusFailed = '[Dashboard Venn] Update Venn  Approval Status Failed',
  UpdateVennPercentageMatches = '[Dashboard Venn] Update Venn Percentage Matches',
  UpdateVennPercentageMatchesSuccess = '[Dashboard Venn] Update Venn Percentage Matches Success',
  UpdateVennPercentageMatchesFailed = '[Dashboard Venn] Update Venn Percentage Matches Failed',
  getDataOwner = '[Dashboard Venn] Get Data Owner',
  GetDashboardVennStatus = '[Dashboard Venn] Get Dashboard Venn Status',
  GetDashboardVennStatusSuccess = '[Dashboard Venn] Get Dashboard Venn Status Success',
  GetDashboardVennStatusFailed = '[Dashboard Venn] Get Dashboard Venn Status Failed',
  GetChartDetails = '[Dashboard Venn] Get Chart Details',
  GetChartDetailsSuccess = '[Dashboard Venn] Get Chart Details Success',
  GetChartDetailsFailed = '[Dashboard Venn] Get Chart Details Failed',
  GetFilteredChartDetails = '[Dashboard Convertr] Get Filtered Chart Details',
  GetFilteredChartDetailsSuccess = '[Dashboard Convertr] Get Filtered Chart Details Success',
  GetFilteredChartDetailsFailed = '[Dashboard Convertr] Get Filtered Chart Details Failed',
  UpdateDashboardVennStatus = '[Dashboard Venn] Update Dashboard Venn Status',
  UpdateDashboardVennStatusSuccess = '[Dashboard Venn] Update Dashboard Venn Status Success',
  UpdateDashboardVennStatusFailed = '[Dashboard Venn] Update Dashboard Venn Status Failed',
  LoadSegments = '[Dashboard Venn] Load Segments Dashboard',
  LoadSegmentsSuccess = '[Dashboard Venn] Load Segments Dashboard Success',
  LoadSegmentsFailed = '[Dashboard Venn] Load Segments Dashboard Failed',
  SubmitForMatching = '[Dashboard Venn] Submit for Matching',
  SubmitForMatchingSuccess = '[Dashboard Venn] Submit for Matching Success',
  SubmitForMatchingFailed = '[Dashboard Venn] Submit for Matching Failed',
  UpdateStatusList = '[Dashboard Venn] Update Status List',
  UpdateStatusListSuccess = '[Dashboard Venn] Update Status List Success',
  UpdateStatusListFailed = '[Dashboard Venn] Update Status List Failed',
  RunCanvasRequest = '[Dashboard Venn] Run Canvas Requested',
  RunCanvasRequestSuccess = '[Dashboard Venn] Run Canvas Requested Success',
  RunCanvasRequestFailed = '[Dashboard Venn] Run Canvas Requested Failed',
  UpdateTableState = '[Dashboard Venn] Update Table State',
  UpdateTableStateSuccess = '[Dashboard Venn] Update Table State Success',
  UpdateTableStateFailed = '[Dashboard Venn]  Update Table State Failed',
}

export class LoadVennDashboard implements Action {
  readonly type = DashboardActionTypes.LoadVennDashboard;

}

export class LoadVennDashboardSuccess implements Action {
  readonly type = DashboardActionTypes.LoadVennDashboardSuccess;

  constructor(public payload: { collaborations: CampaignModel[] }) {}
}

export class LoadVennDashboardFailed implements Action {
  readonly type = DashboardActionTypes.LoadVennDashboardFailed;
}

export class GetVennDashboard implements Action {
  readonly type = DashboardActionTypes.GetVennDashboard;

  constructor(public payload: { collaborationId: number }) {}
}

export class GetVennDashboardSuccess implements Action {
  readonly type = DashboardActionTypes.GetVennDashboardSuccess;

  constructor(public payload: { collaboration: CampaignModel }) {}
}

export class GetVennDashboardFailed implements Action {
  readonly type = DashboardActionTypes.GetVennDashboardFailed;
}

export class GetChartDetails implements Action {
  readonly type = DashboardActionTypes.GetChartDetails;

  constructor(public payload: { collaborationId: number }) {}
}

export class GetChartDetailsSuccess implements Action {
  readonly type = DashboardActionTypes.GetChartDetailsSuccess;

  constructor(
    public payload: {
      collaborationId: number;
      canvasActionChart: CanvasActionChart[];
    },
  ) {}
}

export class GetChartDetailsFailed implements Action {
  readonly type = DashboardActionTypes.GetChartDetailsFailed;
}

export class GetFilteredChartDetails implements Action {
  readonly type = DashboardActionTypes.GetFilteredChartDetails;

  constructor(
    public payload: {
      filteredModel: IFilterChart;
      collaborationId: number;
    },
  ) {}
}

export class GetFilteredChartDetailsSuccess implements Action {
  readonly type = DashboardActionTypes.GetFilteredChartDetailsSuccess;

  constructor(
    public payload: {
      collaborationId?: number;
      canvasActionChart: CanvasActionChart[];
    },
  ) {}
}

export class GetFilteredChartDetailsFailed implements Action {
  readonly type = DashboardActionTypes.GetFilteredChartDetailsFailed;
}

export class LoadCollaboratorOverview implements Action {
  readonly type = DashboardActionTypes.LoadCollaboratorOverview;

  constructor(public payload: { collaborationId: number }) {}
}

export class LoadCollaboratorOverviewSuccess implements Action {
  readonly type = DashboardActionTypes.LoadCollaboratorOverviewSuccess;

  constructor(
    public payload: { collaboratorsOverview: CollaboratorsOverviewModel },
  ) {}
}

export class LoadCollaboratorOverviewFailed implements Action {
  readonly type = DashboardActionTypes.LoadCollaboratorOverviewFailed;
}

export class SetCurrentVennDashboard implements Action {
  readonly type = DashboardActionTypes.SetCurrentVennDashboard;

  constructor(public payload: { currentCollaborationId: number }) {}
}

export class SetStartDate implements Action {
  readonly type = DashboardActionTypes.SetStartDate;

  constructor(
    public payload: {
      currentCollaborationId: number;
      startDate: Date;
    },
  ) {}
}

export class SetEndDate implements Action {
  readonly type = DashboardActionTypes.SetEndDate;

  constructor(
    public payload: {
      currentCollaborationId: number;
      endDate: Date;
    },
  ) {}
}
export class SetCurrentVennDashboardSuccess implements Action {
  readonly type = DashboardActionTypes.SetCurrentVennDashboardSuccess;

}

export class SetCurrentDashboardFailed implements Action {
  readonly type = DashboardActionTypes.SetCurrentVennDashboardFailed;

}

export class GetDashboardVennStatus implements Action {
  readonly type = DashboardActionTypes.GetDashboardVennStatus;

  constructor(public payload: { collaborationId: number }) {}
}

export class GetDashboardVennStatusSuccess implements Action {
  readonly type = DashboardActionTypes.GetDashboardVennStatusSuccess;

  constructor(public payload: { dashboardStatus: DashboardStatusModel }) {}
}

export class UpdateVennApprovalStatus implements Action {
  readonly type = DashboardActionTypes.UpdateVennApprovalStatus;

  constructor(public payload: { collaborationId: number; approved: boolean }) {}
}

export class UpdateVennApprovalStatusSuccess implements Action {
  readonly type = DashboardActionTypes.UpdateVennApprovalStatusSuccess;

  constructor(public payload: { collaborationId: number; approved: boolean }) {}
}

export class UpdateVennApprovalStatusFailed implements Action {
  readonly type = DashboardActionTypes.UpdateVennApprovalStatusFailed;
}

export class UpdateVennLastExported implements Action {
  readonly type = DashboardActionTypes.UpdateVennLastExported;

  constructor(public payload: { collaborationId: number; date: Date }) {}
}

export class UpdateVennLastExportedSuccess implements Action {
  readonly type = DashboardActionTypes.UpdateVennLastExportedSuccess;

  constructor(public payload: { collaborationId: number; date: Date }) {}
}

export class UpdateVennLastExportedFailed implements Action {
  readonly type = DashboardActionTypes.UpdateVennLastExportedFailed;
}

export class UpdateVennPercentageMatches implements Action {
  readonly type = DashboardActionTypes.UpdateVennPercentageMatches;

  constructor(
    public payload: { collaborationId: number; percentageMatches: number },
  ) {}
}

export class UpdateVennPercentageMatchesSuccess implements Action {
  readonly type = DashboardActionTypes.UpdateVennPercentageMatchesSuccess;

  constructor(
    public payload: { collaborationId: number; percentageMatches: number },
  ) {}
}

export class UpdateVennPercentageMatchesFailed implements Action {
  readonly type = DashboardActionTypes.UpdateVennPercentageMatchesFailed;
}

export class GetDashboardVennStatusFailed implements Action {
  readonly type = DashboardActionTypes.GetDashboardVennStatusFailed;
}

export class UpdateDashboardVennStatus implements Action {
  readonly type = DashboardActionTypes.UpdateDashboardVennStatus;

  constructor(
    public payload: {
      currentStatus: DashboardStatusEnum | ReportDashboardStatusEnum;
    },
  ) {}
}

export class UpdateDashboardVennStatusSuccess implements Action {
  readonly type = DashboardActionTypes.UpdateDashboardVennStatusSuccess;

  constructor(
    public payload: {
      currentStatus: DashboardStatusEnum | ReportDashboardStatusEnum;
    },
  ) {}
}

export class UpdateDashboardVennStatusFailed implements Action {
  readonly type = DashboardActionTypes.UpdateDashboardVennStatusFailed;
}

export class LoadSegments implements Action {
  readonly type = DashboardActionTypes.LoadSegments;

  constructor(
    public payload: {
      collaborationId: number;
      tableType: string[];
      includeDeactivated: boolean;
    },
  ) {}
}

export class LoadSegmentsSuccess implements Action {
  readonly type = DashboardActionTypes.LoadSegmentsSuccess;

  constructor(public payload: { segments: IOutputTableModel[] }) {}
}

export class LoadSegmentsFailed implements Action {
  readonly type = DashboardActionTypes.LoadSegmentsFailed;
}

export class SubmitForMatching implements Action {
  readonly type = DashboardActionTypes.SubmitForMatching;
  constructor(
    public payload: {
      collaborationId: number;
      model: {
        approved: boolean;
        wait: boolean;
      };
      submitForMatchingButtonPressed: boolean;
      dataCustodianId: number;
      reportSignalRGroupName: string;
    },
  ) {}
}

export class SubmitForMatchingSuccess implements Action {
  readonly type = DashboardActionTypes.SubmitForMatchingSuccess;
  constructor(
    public payload: {
      collaborationId: number;
      model: {
        approved: boolean;
        wait: boolean;
      };
      dataCustodianId: number;
      reportSignalRGroupName: string;
    },
  ) {}
}

export class SubmitForMatchingFailed implements Action {
  readonly type = DashboardActionTypes.SubmitForMatchingFailed;
}

export class UpdateStatusList implements Action {
  readonly type = DashboardActionTypes.UpdateStatusList;
  constructor(
    public payload: {
      collaborationId: number;
      dataCustodianId?: number;
      statusList: object;
    },
  ) {}
}

export class UpdateStatusListSuccess implements Action {
  readonly type = DashboardActionTypes.UpdateStatusListSuccess;
  constructor(
    public payload: {
      collaborationId: number;
      dataCustodianId?: number;
      statusList: object;
    },
  ) {}
}

export class UpdateStatusListFailed implements Action {
  readonly type = DashboardActionTypes.UpdateStatusListFailed;
}

export class RunCanvasRequest implements Action {
  readonly type = DashboardActionTypes.RunCanvasRequest;
  constructor(
    public payload: {
      collaborationId: number;
      reportDashboardSignalRGroupName: string;
    },
  ) {}
}

export class RunCanvasRequestSuccess implements Action {
  readonly type = DashboardActionTypes.RunCanvasRequestSuccess;
}

export class RunCanvasRequestFailed implements Action {
  readonly type = DashboardActionTypes.RunCanvasRequestFailed;
}

export class UpdateTableState implements Action {
  readonly type = DashboardActionTypes.UpdateTableState;

  constructor(
    public payload: {
      tableState: TableStateEnum;
    },
  ) {}
}

export class UpdateTableStateSuccess implements Action {
  readonly type = DashboardActionTypes.UpdateTableStateSuccess;

  public payload: {
    tableState: TableStateEnum;
  };
}

export class UpdateTableStateFailed implements Action {
  readonly type = DashboardActionTypes.UpdateTableStateFailed;
}

export type DashboardActions =
  | LoadVennDashboard
  | LoadVennDashboardSuccess
  | LoadVennDashboardFailed
  | GetVennDashboard
  | GetVennDashboardSuccess
  | GetVennDashboardFailed
  | GetChartDetails
  | GetChartDetailsSuccess
  | GetChartDetailsFailed
  | GetFilteredChartDetails
  | GetFilteredChartDetailsSuccess
  | GetFilteredChartDetailsFailed
  | LoadCollaboratorOverview
  | LoadCollaboratorOverviewSuccess
  | LoadCollaboratorOverviewFailed
  | SetStartDate
  | SetEndDate
  | SetCurrentVennDashboard
  | SetCurrentVennDashboardSuccess
  | SetCurrentDashboardFailed
  | GetDashboardVennStatus
  | GetDashboardVennStatusSuccess
  | GetDashboardVennStatusFailed
  | UpdateVennLastExported
  | UpdateVennLastExportedSuccess
  | UpdateVennLastExportedFailed
  | UpdateVennApprovalStatus
  | UpdateVennApprovalStatusSuccess
  | UpdateVennApprovalStatusFailed
  | UpdateVennPercentageMatches
  | UpdateVennPercentageMatchesSuccess
  | UpdateVennPercentageMatchesFailed
  | UpdateDashboardVennStatus
  | UpdateDashboardVennStatusSuccess
  | UpdateDashboardVennStatusFailed
  | LoadSegments
  | LoadSegmentsSuccess
  | LoadSegmentsFailed
  | SubmitForMatching
  | SubmitForMatchingSuccess
  | SubmitForMatchingFailed
  | UpdateStatusList
  | UpdateStatusListSuccess
  | UpdateStatusListFailed
  | RunCanvasRequest
  | RunCanvasRequestSuccess
  | RunCanvasRequestFailed
  | UpdateTableState
  | UpdateTableStateSuccess
  | UpdateTableStateFailed;
