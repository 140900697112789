import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  empty as observableEmpty,
  Observable,
  throwError as observableThrowError,
} from 'rxjs';
import { catchError, take } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { AuthUserModel } from '../model/authuser.model';
import { AgentService } from '../services/agent/agent.service';
import { PingService } from '../services/ping/ping.service';
import { IAgentConfigurationModel } from '../state/model/agent-configuration';
import { IxupHttpCustomParams } from './http-interceptor-params';
import { AuthService } from '../services/auth/AuthService';
import { API_BASE_URL } from '../../environments/environment-model';

@Injectable()
export class IxupHttpInterceptor implements HttpInterceptor {
  currentUser: AuthUserModel;
  host: string;
  constructor(
    @Inject(API_BASE_URL) private baseUrl: string,
    private pingService: PingService,
    private authService: AuthService,
    private router: Router,
    private agentService: AgentService,
  ) {
    this.host = this.baseUrl;
    this.authService.currentUser$.subscribe((user) => {
      this.currentUser = user;
    });
  }

  intercept(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    req: HttpRequest<any>,
    next: HttpHandler,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Observable<HttpEvent<any>> {
    if (!this.pingService.canMakeApiConnection()) {
      // we don't yet have confirmation that backend connection can be made,
      // so don't attempt any backend call until we've been through /certify page.
      this.router.navigate(['certify']);
      return observableEmpty();
    }

    const headers = {};
    headers['Accept'] = 'application/json';
    headers['Access-Control-Allow-Origin'] = '*';

    const token = localStorage.getItem('token');

    if (token != null && token !== 'null') {
      headers['Authorization'] = 'Bearer ' + token;
    }
    // when initailly setting passphrase for EG
    if (
      req.params instanceof IxupHttpCustomParams &&
      req.params.invokeWebCM &&
      !req.params.useDefaultHostUrl
    ) {
      this.host = req.params.baseUrl;

      headers['ixup-EnvironmentName'] = environment.environmentName;
      headers['ixup-SecEnvironmentName'] = environment.secEnvironmentName;
      headers['ixup-WebApiEndpoint'] = environment.webApiEndpoint;
    }
    // when "Test Connection" for EG
    else if (
      req.params instanceof IxupHttpCustomParams &&
      !req.params.invokeWebCM &&
      !req.params.useDefaultHostUrl
    ) {
      this.host = req.params.baseUrl;
      this.authService.refreshTokenWhenNearExpiry();
    } else if (
      req.params instanceof IxupHttpCustomParams &&
      req.params.invokeWebCM
    ) {
      this.host = this.currentUser.EGBaseURL;

      headers['ixup-EnvironmentName'] = environment.environmentName;
      headers['ixup-SecEnvironmentName'] = environment.secEnvironmentName;
      headers['ixup-WebApiEndpoint'] = environment.webApiEndpoint;
      this.agentService
        .getAgentConfigurationState()
        .pipe(take(1))
        .subscribe((state: IAgentConfigurationModel) => {
          if (state) {
            this.host = state.baseUrl.endsWith('/')
              ? state.baseUrl.substring(0, state.baseUrl.length - 1)
              : state.baseUrl;
          }
        });
    } else {
      this.host = this.baseUrl;
      this.authService.refreshTokenWhenNearExpiry();
    }

    const newReq = req.clone({
      url:
        req.url.startsWith('http://') || req.url.startsWith('https://')
          ? req.url
          : this.host + req.url,
      setHeaders: headers,
    });

    return next.handle(newReq).pipe(catchError(this.catchErrors()));
  }

  private catchErrors() {
    return (response: Response) => {
      if (
        (response.status === 401 || response.status === 403) &&
        !response.url.startsWith(this.currentUser.EGBaseURL)
      ) {
        console.log('IxupHttpInterceptor - inactiveaccount');
        this.authService.logout('/inactiveaccount');
      }
      return observableThrowError(response);
    };
  }
}
