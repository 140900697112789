import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { AuthService } from '../../services/auth/AuthService';
import { TranslatePipe } from '../../services/translate/translate.pipe';
@Component({
  selector: 'inactive-account',
  templateUrl: './inactive-account.html',
  standalone: true,
  imports: [MatIconModule, TranslatePipe],
})
export class InactiveAccountComponent {
  constructor(private authService: AuthService) {}

  cancel() {
    this.authService.logout('/');
  }
}
