import { Action } from '@ngrx/store';
import { SaveStatusModel } from '../../models/save-status.model';

export enum SaveStatusActionTypes {
  GetSaveStatus = '[Canvas.SaveStatus.GetSaveStatus]',
  GetSaveStatusSuccess = '[Canvas.SaveStatus.GetSaveStatusSuccess]',
  SetSaveStatus = '[Canvas.SaveStatus.SetSaveStatus]',
  SetSaveStatusSuccess = '[Canvas.SaveStatus.SetSaveStatusSuccess]',
}

export class GetSaveStatus implements Action {
  readonly type = SaveStatusActionTypes.GetSaveStatus;

  constructor(public payload: { canvasId: number }) {}
}

export class GetSaveStatusSuccess implements Action {
  readonly type = SaveStatusActionTypes.GetSaveStatusSuccess;

  constructor(public payload: { status: SaveStatusModel }) {}
}

export class SetSaveStatus implements Action {
  readonly type = SaveStatusActionTypes.SetSaveStatus;

  constructor(public payload: { status: SaveStatusModel }) {}
}

export class SetSaveStatusSuccess implements Action {
  readonly type = SaveStatusActionTypes.SetSaveStatusSuccess;

  constructor(public payload: { status: SaveStatusModel }) {}
}

export type SaveStatusActions =
  | GetSaveStatus
  | GetSaveStatusSuccess
  | SetSaveStatus
  | SetSaveStatusSuccess;
