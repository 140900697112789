
import {map} from 'rxjs/operators';
import { AbstractControl } from '@angular/forms';
import { CollaboratorKeyService } from '../../../services/collaborator-key/collaborator-key.service';
import { Injectable } from '@angular/core';

@Injectable()
export class ValidatePasswordIsCorrect {
  static createValidator(collaboratorKeyService: CollaboratorKeyService) {
    return (control: AbstractControl) => {
      return collaboratorKeyService.checkKey(control.value).pipe(map(res => {

        return res ? null : { passwordIncorrect: true };
      }));
    };
  }
}
