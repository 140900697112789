import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { CanvasCollaborationService } from '../../pages/control-centre/shared/services/collaboration.service';
import { PipeModule } from '../../pipe.module';
import { EnablerService } from '../../services/enabler/enabler.service';
import { LoggingService } from '../../services/logging/logging.service';
import { SharedModule } from '../shared/shared.module';
import { AppHeaderComponent } from './app-header.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    RouterModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatMenuModule,
    MatListModule,
    MatIconModule,
    MatSelectModule,
    MatToolbarModule,
    MatTooltipModule,
    OverlayModule,
    BrowserAnimationsModule,
    PipeModule.forRoot(),
    AppHeaderComponent,
  ],
  exports: [AppHeaderComponent],
  providers: [LoggingService, EnablerService, CanvasCollaborationService],
})
export class AppHeaderModule {}
