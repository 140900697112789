import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { UserCollaborationsModel } from '../../pages/powaindex/model/user-collaborations.model';
import { BaseEffects } from '../baseEffects';
import CollaboratorsOverviewModel from '../../pages/playxsafe/model/collaborators-overview-model';
import { PlayXSafeService } from '../../pages/playxsafe/service/playxsafe.service';
import { ResultModelGeneric } from '../../model/result.model';
import { ToasterService } from '../../services/toaster.service';
import {
  DashboardActionTypes,
  LoadCollaboratorOverview,
  LoadCollaboratorOverviewFailed,
  LoadCollaboratorOverviewSuccess,
  LoadPlayXSafeDashboardFailed,
  LoadPlayXSafeDashboardSuccess,
  SetCurrentPlayXSafeDashboardSuccess,
} from './actions';

@Injectable()
export class PlayXSafeDashboardEffects extends BaseEffects {
  GetUserCollaborations$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActionTypes.LoadPlayXSafeDashboard),
      mergeMap(() =>
        this.playXSafeService.getUserCollaborations().pipe(
          map(
            (collaborations: ResultModelGeneric<UserCollaborationsModel[]>) => {
              return new LoadPlayXSafeDashboardSuccess({
                collaborations: collaborations.data,
              });
            },
          ),
          catchError(() => {
            this.showError('Error loading collaborations');
            return of(new LoadPlayXSafeDashboardFailed());
          }),
        ),
      ),
    ),
  );

  GetUploadedOverview$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActionTypes.LoadCollaboratorOverview),
      mergeMap((action: LoadCollaboratorOverview) =>
        this.playXSafeService
          .getCollaboratorOverview(action.payload.collaborationId)
          .pipe(
            map(
              (
                collaborators: ResultModelGeneric<CollaboratorsOverviewModel>,
              ) => {
                return new LoadCollaboratorOverviewSuccess({
                  collaboratorsOverview: collaborators.data,
                });
              },
            ),
            catchError(() => {
              this.showError('Error loading overview');
              return of(new LoadCollaboratorOverviewFailed());
            }),
          ),
      ),
    ),
  );

  SetCurrentPlayXSafeCollaborationId$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActionTypes.SetCurrentPlayXSafeDashboard),
      switchMap(() => [new SetCurrentPlayXSafeDashboardSuccess()]),
      catchError(() => {
        this.showError('Error setting collaboration.');
        return of(new SetCurrentPlayXSafeDashboardSuccess());
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private playXSafeService: PlayXSafeService,
    toasterService: ToasterService,
  ) {
    super(toasterService);
  }
}
