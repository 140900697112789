import { Action } from '@ngrx/store';

import { ICollaborationBillingModel } from '../../models/collaboration-billing.model';

export enum CollaborationActionTypes {
  LoadCollaborationBilling = '[ControlCentre.Collaborations] Load Collaboration Billing',
  LoadCollaborationBillingSuccess = '[ControlCentre.Collaborations] Load Collaboration Billing Success',
  LoadCollaborationBillingFailed = '[ControlCentre.Collaborations] Load Collaboration Billing Failed'
}

export class LoadCollaborationBilling implements Action {
  readonly type = CollaborationActionTypes.LoadCollaborationBilling;

  constructor(public payload: { collaborationId: number }) {}
}

export class LoadCollaborationBillingSuccess implements Action {
  readonly type = CollaborationActionTypes.LoadCollaborationBillingSuccess;

  constructor(public payload: { billing: ICollaborationBillingModel }) {}
}

export class LoadCollaborationBillingFailed implements Action {
  readonly type = CollaborationActionTypes.LoadCollaborationBillingFailed;
}

export type CollaborationActions =
  | LoadCollaborationBilling
  | LoadCollaborationBillingSuccess
  | LoadCollaborationBillingFailed;
