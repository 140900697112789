import { createFeatureSelector, createSelector } from '@ngrx/store';

import { MatchingStatusEnum } from '../../pages/convertr/model/campaign.model';
import { ConvertrDashboardState } from './reducer';
import { TableStateEnum } from '../model/table-state';

export const convertrDashboardState =
  createFeatureSelector<ConvertrDashboardState>('convertrDashboard');

export const getConvertrDashboardState = createSelector(
  convertrDashboardState,
  (state) => state,
);

export const getConvertrCollaboratorOverviewState = createSelector(
  convertrDashboardState,
  (state) => state.overview,
);

export const getConvertrUserCollaborationState = createSelector(
  getConvertrDashboardState,
  (state) => state.collaborations,
);

export const getCanvasActionChartState = createSelector(
  getConvertrDashboardState,
  (state) => state.canvasActionChart,
);

export const getDefaultConvertrUserCollaborationId = createSelector(
  getConvertrUserCollaborationState,
  (state) => {
    return state ? state[0]?.collaborationId : null;
  },
);

export const getCurrentConvertrCollaborationId = createSelector(
  getConvertrDashboardState,
  (state) => {
    return state ? state.currentDashboardCollaborationId : null;
  },
);

export const getCanvasActionChartById = (collaborationId: number) =>
  createSelector(getCanvasActionChartState, (state) => {
    return state
      ? state.find((chart) => chart.collaborationId === collaborationId)
      : null;
  });

export const isMatchingDone = (collaborationId: number) =>
  createSelector(getCampaginById(collaborationId), (state) => {
    return state
      ? state.statusList.some(
        (status) => status.matching === MatchingStatusEnum.Done,
      )
      : null;
  });

export const getStatusListByCollaborator = (
  collaborationId: number,
  collaboratorId: number,
) =>
  createSelector(getCampaginById(collaborationId), (state) => {
    return state
      ? state.statusList.find(
        (status) => status.dataCustodianId === collaboratorId,
      )
      : null;
  });

export const submitForMatchingState = (
  collaborationId: number,
  collaboratorId: number,
) =>
  createSelector(
    getStatusListByCollaborator(collaborationId, collaboratorId),
    (statusList) => {
      if (statusList) {
        console.log(statusList);

        if (!statusList.uploadDatabase === true) return true;
        else if (statusList.matching === MatchingStatusEnum.InProgress)
          return true;
        else if (statusList.submittedMatch === true) return true;
        else if (
          statusList.matching === MatchingStatusEnum.Done &&
          statusList.submittedMatch === false
        )
          return true;
        else return false;
      }
    },
  );

export const setTableEncrytionState = (
  collaborationId: number,
  collaboratorId: number,
) =>
  createSelector(
    getStatusListByCollaborator(collaborationId, collaboratorId),
    (statusList) => {
      if (statusList) {
        if (!statusList.uploadDatabase === true) return null;
        if (
          statusList.uploadDatabase === true &&
          !statusList.submittedMatch === true
        )
          return TableStateEnum.encrypted;
        if (
          statusList.submittedMatch === true &&
          statusList.matching === MatchingStatusEnum.Pending
        )
          return TableStateEnum.encryptedsubmit;
        if (String(statusList.matching) === 'InProgress')
          return TableStateEnum.matching;
        if (statusList.matching === MatchingStatusEnum.Done)
          return TableStateEnum.matched;
      }
    },
  );

export const getCampaginById = (collaborationId: number) =>
  createSelector(getConvertrUserCollaborationState, (state) => {
    return state
      ? state.find(
        (collaboration) => collaboration.collaborationId === collaborationId,
      )
      : null;
  });

export const getCampaignArrayFilteredById = (collaborationId: number) =>
  createSelector(getConvertrUserCollaborationState, (state) => {
    return state
      ? state.filter(
        (collaboration) => collaboration.collaborationId === collaborationId,
      )
      : null;
  });

export const getCurrentCanvasId = (collaborationId: number) =>
  createSelector(getConvertrUserCollaborationState, (state) => {
    return state
      ? state.find(
        (collaboration) => collaboration.collaborationId === collaborationId,
      )?.canvasId
      : null;
  });

export const getListOfCampaigns = createSelector(
  getConvertrUserCollaborationState,
  (state) => {
    return state ? state : null;
  },
);

export const getDashboardStatus = createSelector(
  getConvertrDashboardState,
  (state) => {
    return state ? state.currentStatus : null;
  },
);

export const getTableState = createSelector(
  getConvertrDashboardState,
  (state) => {
    return state ? state.tableState : null;
  },
);

export const getDataOwner = createSelector(
  getConvertrDashboardState,
  (state) => {
    return state ? state.dataOwner : false;
  },
);

export const getSegements = createSelector(
  getConvertrDashboardState,
  (state) => {
    return state ? state.segments : null;
  },
);
