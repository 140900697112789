<footer>
  <mat-toolbar>
    <mat-toolbar-row>
      <span>&copy; IXUP</span>
      <span class="full-width"></span>
      <span class="flex justify-end">
        <i class="icon icon-logo-ixup" aria-hidden="true"></i>
      </span>
    </mat-toolbar-row>
  </mat-toolbar>
</footer>
