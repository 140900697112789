
import { InjectionToken } from '@angular/core';

export interface IEnvironment {
    authority: string;
    tenant: string;
    redirectUri: string;
    postLogoutRedirectUri: string;
    clientId: string;
    webApiEndpoint: string;
    clientApiEndpoint: string;
    clientManagerUrl: string;
    environmentName: string;
    secEnvironmentName: string;
    requireClientCert: boolean;
    production: boolean;
    paas: boolean;
    fileDrop: {
        allowedExtensions: string[];
        maxFileSizeBytes: number;
    }
}

export const API_BASE_URL = new InjectionToken('API_BASE_URL');
