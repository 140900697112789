import { PreloadingStrategy, Route } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class CustomPreloadingStrategy implements PreloadingStrategy {
  // loadModule could be any function name here
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  preload(route: Route, loadModule: () => Observable<any>): Observable<any> {
    return route.data && route.data.applyPreload ? loadModule() : of(null);
  }
}
