<form
  [formGroup]="passphraseForm"
  novalidate
>
  <p class="text-center push-bottom">
    Note: This update will apply to all users within this Data Custodian.
  </p>
  <p
    class="text-center push-bottom-lg"
    *ngIf='data.authCode === PASSPHRASE_TYPE.reset'
  >
    A reset will inactivate all of your Data Custodian's unprocessed data. Data encrypted with the previous passphrase
    may need to be reingested.
  </p>

  <div
    class="form-group push-top-lg pad-bottom"
    *ngIf='data.authCode === PASSPHRASE_TYPE.change'
  >

    <p class="ixup-section-title">
      Current passphrase
    </p>

    <mat-form-field
      appearance="outline"
      class="block"
    >
      <mat-label>
        {{ 'Enter passphrase' | translate }}
      </mat-label>
      <input
        matInput
        formControlName="oldPassword"
        [type]="hideOldDCPassphrase ? 'password' : 'text'"
      />
      <mat-icon
        matSuffix
        (click)="hideOldDCPassphrase = !hideOldDCPassphrase"
        matTooltip="{{
            hideOldDCPassphrase ? 'Show passphrase' : 'Hide passphrase'
          }}"
        matTooltipClass="ixup-canvas-tooltip"
      >
        {{ hideOldDCPassphrase ? 'visibility' : 'visibility_off' }}
      </mat-icon>
      <mat-hint align="start">
        This is your current Data Custodian passphrase
      </mat-hint>
      <mat-error *ngIf="passphraseForm.get('oldPassword').hasError('required')">
        {{ 'Your current Data Custodian passphrase is required...' | translate }}
      </mat-error>
    </mat-form-field>
    <div style="font-size:75%; padding-left:12px; height:16px;">
      <span
        [@animate]
        *ngIf="incorrectPassphrase"
        class="mat-error"
      >The current Data Custodian passphrase you entered is incorrect...</span>
    </div>
  </div>
  <div class="form-group pad-bottom">
    <!-- <p class="text-center push-bottom-lg">
      Note: This update will apply to all users within this Data Custodian.
    </p> -->
    <p class="ixup-section-title">
      {{ data.authCode === PASSPHRASE_TYPE.change ? 'Change' : 'Reset' }} passphrase
    </p>

    <mat-form-field
      appearance="outline"
      class="block"
    >
      <mat-label>
        {{ 'Enter new passphrase' | translate }}
      </mat-label>
      <input
        matInput
        formControlName="newPassword"
        [type]="hideAddDCPassphrase ? 'password' : 'text'"
      />
      <mat-icon
        matSuffix
        (click)="hideAddDCPassphrase = !hideAddDCPassphrase"
        matTooltip="{{
            hideAddDCPassphrase ? 'Show passphrase' : 'Hide passphrase'
          }}"
        matTooltipClass="ixup-canvas-tooltip"
      >
        {{ hideAddDCPassphrase ? 'visibility' : 'visibility_off' }}
      </mat-icon>
      <mat-hint align="start">
        Passphrases can contain spaces, so they can be complex yet memorable.
      </mat-hint>
      <mat-error *ngIf="passphraseForm.get('newPassword').hasError('required')">
        {{ 'Your new passphrase is required...' | translate }}
      </mat-error>
    </mat-form-field>
  </div>
  <div class="form-group">
    <mat-form-field
      appearance="outline"
      class="block"
    >
      <mat-label>{{ 'Reenter new passphrase' | translate }}</mat-label>
      <input
        matInput
        formControlName="confirmPassKey"
        [type]="hideReenterDCPassphrase ? 'password' : 'text'"
      />
      <mat-icon
        matSuffix
        (click)="hideReenterDCPassphrase = !hideReenterDCPassphrase"
        matTooltip="{{
              hideReenterDCPassphrase ? 'Show passphrase' : 'Hide passphrase'
            }}"
        matTooltipClass="ixup-canvas-tooltip"
      >
        {{ hideReenterDCPassphrase ? 'visibility' : 'visibility_off' }}
      </mat-icon>
      <mat-hint align="start"></mat-hint>
      <mat-error *ngIf="passphraseForm.get('confirmPassKey').hasError('required')">
        {{ 'Your new passphrase is required to be reentered...' | translate }}
      </mat-error>
    </mat-form-field>
    <div style="font-size:75%; padding-left:12px; height:16px;">
      <span
        [@animate]
        *ngIf="validationErrorMessage()"
        class="mat-error"
      >{{ validationErrorMessage() }}&nbsp;</span>
    </div>

  </div>
  <div>
    <p class="text-left push-top push-bottom-md">
      To ensure the security of your data, your passphrase is never stored
      within the IXUP platform.
    </p>

  </div>


  <mat-dialog-actions align="end">


    <!-- Update / Reset screen button -->
    <div class="text-center full-width block">
      <button
        mat-stroked-button
        color="primary"
        class="block full-width btn-rounded text-upper"
        type="submit"
        (click)="submit()"
      >
        {{ data.authCode === PASSPHRASE_TYPE.change ? 'Change' : 'Reset' }} passphrase
      </button>
    </div>
  </mat-dialog-actions>
</form>
