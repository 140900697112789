
import { Observable, of as observableOf } from 'rxjs';

import { Injectable } from '@angular/core';
import { catchError, switchMap } from 'rxjs/operators';

import { Router } from '@angular/router';
import { PingService } from '../services/ping/ping.service';

@Injectable()

export class CertifyGuard {

  constructor(
    private pingService: PingService,
    private router: Router) {

  }

  canActivate(): Observable<boolean> {

    if (this.pingService.clientCertAccepted()) {

      // It looks from localStorage as we've had a certificate accepted in the past
      // Attempt a backend ping, if it succeeds then cancel navigation to /certify
      return this.pingService.ping().pipe(switchMap(
        () => {

          // ping succeeds, no need to visit /certify page
          this.router.navigate(['']);
          return observableOf(false);
        }

      ),catchError(() => {

        // ping failed, continue to /certify page
        return observableOf(true);
      }),);
    }
    else {

      // no record of prior successful backend ping - proceed to /certify page
      return observableOf(true);
    }
  }
}
