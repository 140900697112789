import { NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import {
  MatLegacyDialogModule,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { TranslatePipe } from '../../../services/translate/translate.pipe';

@Component({
  selector: 'ixup-dialog',
  templateUrl: './ixup-dialog-template.component.html',
  standalone: true,
  imports: [
    MatLegacyDialogModule,
    NgIf,
    MatIconModule,
    MatButtonModule,
    TranslatePipe,
  ],
})
export class TemplateDialog {
  options;
  constructor(public dialogRef: MatDialogRef<TemplateDialog>) {}

  confirm() {
    this.dialogRef.close(true);
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
