import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { PipeModule } from '../../pipe.module';
import { NotFoundComponent } from './not-found.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    PipeModule.forRoot(),
    MatButtonModule,
    MatIconModule,
    NotFoundComponent,
  ],
  exports: [NotFoundComponent],
  providers: [],
})
export class NotFoundModule {}
