import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PipeModule } from '../../../../pipe.module';
import { CollaboratorKeyService } from '../../../../services/collaborator-key/collaborator-key.service';
import { DataProtectionService } from '../../../../services/data-protection/data-protection.service';
import { ValidatePasswordIsCorrect } from '../../validators/async-password.validator';
import { DataProtectionPassphrasePromptComponent } from './data-protection-passphrase-prompt/data-protection-passphrase-prompt.component';
import { DataProtectionPasswordDialog } from './data-protection-password-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PipeModule,
    MatButtonModule,
    MatInputModule,
    MatAutocompleteModule,
    MatCardModule,
    MatTooltipModule,
    MatSelectModule,
    MatIconModule,
    MatExpansionModule,
    MatDialogModule,
    DataProtectionPasswordDialog,
    DataProtectionPassphrasePromptComponent,
  ],
  exports: [DataProtectionPasswordDialog, DataProtectionPassphrasePromptComponent],
  providers: [
    DataProtectionService,
    CollaboratorKeyService,
    ValidatePasswordIsCorrect,
  ],
})
export class DataProtectionModule {}
