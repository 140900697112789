import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatLegacyProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { fadeIn } from '../../../theme/animations/fadein-animation';

@Component({
  selector: 'ixup-saving',
  templateUrl: './saving-indicator.component.html',
  animations: [fadeIn],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatLegacyProgressSpinnerModule],
})
export class SavingindicatorComponent {
}
