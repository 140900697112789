import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';

import { HubConnectionService } from '../../services/hub/hub.services';
import { ToasterService } from '../../services/toaster.service';
import { BaseEffects } from '../baseEffects';
import { IHubConnectionStatusModel } from '../model/hub-connection-stauts.model';
import {
  CheckHubStatus,
  CheckHubStatusFailed,
  CheckHubStatusSuccess,
  ConnectToHubFailed,
  ConnectToHubSuccess,
  HubActions,
  HubActionTypes,
} from './hub-action';

@Injectable()
export class HubEffects extends BaseEffects {
  
  connectToHubEffects$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(HubActionTypes.ConnectToHub),
    mergeMap(() =>
      this.hubConnectionService.hubConnect().pipe(
        map((result: IHubConnectionStatusModel) => {
          return new ConnectToHubSuccess({ hubConnectionStatus: result });
        }),

        catchError(() => {
          // this.showError('Error connecting to hub');
          return of(new ConnectToHubFailed());
        }),
      ),
    ),
  ));

  
  checkHubStatusEffects$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(HubActionTypes.CheckHubStatus),
    mergeMap((action: CheckHubStatus) =>
      this.hubConnectionService.hubStatus(action.payload.collaborationId).pipe(
        map((result: IHubConnectionStatusModel) => {
          return new CheckHubStatusSuccess({ hubConnectionStatus: result });
        }),

        catchError(() => {
          this.showError('Error checking hub status');
          return of(new CheckHubStatusFailed());
        }),
      ),
    ),
  ));

  constructor(
    private actions$: Actions<HubActions>,
    private hubConnectionService: HubConnectionService,
    toasterService: ToasterService,
  ) {
    super(toasterService);
  }
}
