import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { empty as observableEmpty, Observable } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';

import { ResultModelGeneric } from '../../model/result.model';
import { IConsoleOrganisationModel } from '../../pages/consoles/model/console-organisation.model';
import { OrganisationService } from '../../services/organisation/organisation.service';
import { ImagesService } from '../../services/shared/Images.service';
import { ToasterService } from '../../services/toaster.service';
import { BaseEffects } from '../baseEffects';
import {
  LoadOrganisation,
  LoadOrganisationSuccess,
  OrganisationActionTypes,
  UpdateOrganisation,
  UpdateOrganisationSuccess,
} from './actions';

@Injectable()
export class OrganisationEffects extends BaseEffects {
  
  GetOrganisation$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(OrganisationActionTypes.LoadOrganisation),
    mergeMap((action: LoadOrganisation) =>
      this.organisationService
        .getOrganisation(action.payload.organisationId)
        .pipe(
          map((organisation: ResultModelGeneric<IConsoleOrganisationModel>) => {
            this.router.navigate(['admin-console']);
            return new LoadOrganisationSuccess({
              Organisation: organisation.data,
            });
          }),
          catchError(() => {
            this.showError('Error loading organisation.');
            return observableEmpty();
          }),
        ),
    ),
  ));

  
  UpdateOrganisation$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(OrganisationActionTypes.UpdateOrganisation),
    mergeMap((action: UpdateOrganisation) =>
      this.organisationService
        .updateOrganisation(action.payload.Organisation)
        .pipe(
          switchMap(
            (organisation: ResultModelGeneric<IConsoleOrganisationModel>) => {
              if (organisation.status) {
                this.showMessage('Organisation updated.');
                this.imageService.removeCachedImage(
                  organisation.data.iconImageId,
                );
                this.router.navigate(['admin-console']);
                return [
                  new UpdateOrganisationSuccess({
                    Organisation: organisation.data,
                  }),
                ];
              }
            },
          ),
          catchError(() => {
            this.showError('Error updating organisation.');
            return observableEmpty();
          }),
        ),
    ),
  ));

  constructor(
    private actions$: Actions,
    private organisationService: OrganisationService,
    private router: Router,
    private imageService: ImagesService,
    toasterService: ToasterService,
  ) {
    super(toasterService);
  }
}
