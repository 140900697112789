import { SigningDocumentsActions, SigningDocumentsActionTypes } from './signing-documents-actions';

import { cloneDeep } from 'lodash';
import { IVersionSigningModel } from '../../model/version-signing.model';

export interface ISigningDocumentsState {
  signingDocuments: IVersionSigningModel[];
  loading: boolean;
}

export const initialState: ISigningDocumentsState = {
  signingDocuments: null,
  loading: false,
};

export function SigningDocumentsReducer(
  state = initialState,
  action: SigningDocumentsActions,
): ISigningDocumentsState {
  switch (action.type) {
  case SigningDocumentsActionTypes.UpdateSigningDocumentStatus: {
    return { ...state, loading: true };
  }
  case SigningDocumentsActionTypes.UpdateSigningDocumentStatusSuccess: {
    const newState = cloneDeep(state);
    const index: number = state.signingDocuments.findIndex(
      (a) =>
        a.versionId === action.payload.versionId,
    );
    if (index !== -1) {
      newState.signingDocuments[index].status = action.payload.status;
    }
    return { ...state, signingDocuments: newState.signingDocuments, loading: false };
  }
  case SigningDocumentsActionTypes.GetSigningDocuments: {
    return { ...state, loading: true };
  }
  case SigningDocumentsActionTypes.GetSigningDocumentsSuccess: {
    return { ...state, signingDocuments:  action.payload.signingDocuments, loading: false };
  }
  case SigningDocumentsActionTypes.GetSigningDocumentsFailed: 
  case SigningDocumentsActionTypes.UpdateSigningDocumentStatusFailed: {
    return { ...state, loading: false };
  }

  default: {
    return state;
  }
  }
}
