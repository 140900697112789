export enum Permission {
  ManageDefind = 87,
  ManagePerils = 91,
  ViewResource = 32,
  DeleteResource = 33,
  CreateResource = 30,
  EditResource = 31,
  ViewEnvironment = 35,
  ViewOrganisation = 3,
  DeleteOrganisation = 4,
  Viewroles = 15,
  ViewUser = 19,
  ViewCollaborator = 7,
  ViewFileManagement = 28,
  ViewTableManagement = 75,
  ViewExtracts = 70,
  ViewCollaboration = 11,
  DeleteTableManagement = 76,
  EditTableManagement = 74,
  FileManager = 22,
  ViewProducts = 93,
  ViewMatchSets = 120,
  ViewExportRequests = 123,
  EditMatchSets = 101,
  EditExtracts = 69,
  CreateExtracts = 68,
  DeleteExtracts = 71,
  CreateCollaborator = 5,
  EditCollaborator = 6,
  DeleteCollaborator = 8,
  CreateCollaboration = 9,
  EditCollaboration = 10,
  DeleteCollaboration = 12,
  AddUserToCollaborator = 94,
  CreateCollaborationTables = 114,
  EditCollaborationTables = 115,
  ViewCollaborationTables = 116,
  DeleteCollaborationTables = 117,
  ViewAuditLog = 250,
  InfrastructureConsole = 36,
  PowaIndexConsole = 45,
  EnablerConsole = 37,
  AdminConsole = 38,
  CustodianConsole = 39,
  ManageUsersAndPermissions = 41,
  ApproveCollaborations = 42,
  BillingAdmin = 44,
  PlayXSafeAdmin = 47,
  PlayXSafeUpload = 46,
  Convertr = 48,
  Venn = 49,
}
