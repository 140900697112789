import { NgIf } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import {
  MatLegacyDialogModule,
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { DialogDataModel } from '../../../model/dialog-data.model';
import { TranslatePipe } from '../../../services/translate/translate.pipe';
import { DialogContentModel } from './dialog-content.model';

@Component({
  selector: 'ixup-dialog-convertr',
  templateUrl: './ixup-dialog-convertr.component.html',
  standalone: true,
  imports: [
    MatLegacyDialogModule,
    NgIf,
    MatIconModule,
    MatButtonModule,
    TranslatePipe,
  ],
})
export class ConvertrDialog {
  options;
  constructor(
    public dialogRef: MatDialogRef<ConvertrDialog>,
    @Inject(MAT_DIALOG_DATA)
    public data: DialogDataModel<DialogContentModel>,
  ) {}

  confirm() {
    this.dialogRef.close(true);
  }

  cancel() {
    this.dialogRef.close(false);
  }

  okother() {
    this.dialogRef.close('other');
  }
}
