import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth/AuthService';

@Component({
  templateUrl: './login.component.html',
  standalone: true,
})
export class LoginComponent implements OnInit {
  constructor(public authService: AuthService) {}

  ngOnInit(): void {
    if (this.authService.isLoggedInIxup()) {
      //localStorage.getItem('token')) {
      // We have an IXUP token, so no need to reobtain one.
      this.authService.afterLogin();
      return;
    } else {
      this.loginRedirect();
    }
  }

  isLoggedInMsal = () => this.authService.isLoggedInMsal();

  isLoggedIn = () => this.authService.isLoggedIn();

  loginRedirect() {
    this.authService.login();
  }

  logout() {
    this.authService.logoutMsal();
  }
}
