<h1 mat-dialog-title><mat-icon *ngIf="checkValueExists(dialog.icon)">{{ dialog.icon }}</mat-icon> {{ dialog.heading |
  translate }}</h1>
<mat-dialog-content>
  <p>{{ dialog.textmessage | translate }}</p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button (click)="cancel()" class="sm-block">{{ dialog.canceltext | translate }}</button>
  <button *ngIf="checkValueExists(dialog.buttontext)" mat-raised-button (click)="delete()" color="warn"
    class="sm-block">{{ dialog.buttontext | translate }}</button>
</mat-dialog-actions>
