import { Injectable } from '@angular/core';

@Injectable()
export class CollaborationPassphraseService {

  private getStorageKey(collaborationId: number): string {
    return `collaboration.passphrase[${collaborationId}]`;
  }

  public getStoredPassphrase(collaborationId: number): string {
    return sessionStorage.getItem(this.getStorageKey(collaborationId));
  }

  public setStoredPassphrase(
    collaborationId: number,
    passphrase: string
  ): void {
    return sessionStorage.setItem(
      this.getStorageKey(collaborationId),
      passphrase
    );
  }
}
