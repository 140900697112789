import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { AsyncPipe, NgIf } from '@angular/common';
import { MatLegacyProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { AvatarModule } from 'ngx-avatars';
import { ImageContainer } from '../../../model/imagecontainer.interface';
import { untilDestroyed } from '../../../state/shared/utils';

@Component({
  selector: 'ixup-asynclogo',
  templateUrl: './asynclogo.component.html',
  standalone: true,
  imports: [NgIf, MatLegacyProgressSpinnerModule, AvatarModule, AsyncPipe],
})
export class AsyncLogoComponent implements OnInit {
  showspinner: boolean;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('imageContainer') imageHolder$: Observable<ImageContainer>;
  image$: Observable<ImageContainer>;

  readonly IMAGE_FULLTAG = 'data:image/jpeg;base64,';
  private untilDestroyed = untilDestroyed();

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.showspinner = true;
    this.imageHolder$
      .pipe(take(1))
      .pipe(this.untilDestroyed())
      .subscribe(() => {
        this.showspinner = false;
        this.cdr.detectChanges();
      });

    this.image$ = this.imageHolder$.pipe(
      map((imageHolder) => {
        return { ...imageHolder, image: imageHolder.image };
      }),
    );
  }
}
