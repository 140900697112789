import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationResult } from '@azure/msal-browser';
import { Store } from '@ngrx/store';
import { UserModel } from 'app/model/user.model';
import { Observable, ReplaySubject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { PassphraseResetTypeEnum } from '../../components/collaborator/profile/model/passphrase-reset-type.model';
import { ChangeOrResetPassphraseDialog } from '../../components/shared/utilities/change-or-reset-passphrase-dialog/change-or-reset-passphrase-dialog.component';
import { AuthUserModel } from '../../model/authuser.model';
import { LoginUserModel } from '../../model/loginuser.model';
import { Permission } from '../../model/Permission';
import { ResultModel, ResultModelGeneric } from '../../model/result.model';
import { IAppState } from '../../state/app.interfaces';
import { LoadOrganisation } from '../../state/organisation/actions';
import { EmitterService } from '../emitter.service';
import { IxupBaseService } from '../ixup-base.service';
import { TranslateService } from '../translate/translate.service';
import { UserService } from '../user/user.service';
import { LandingPageService } from './landing-page.service';
import { PagePermissionService } from './page-permission.service';
import { UserFactoryService } from './user-factory.service';

@Injectable()
export class AuthService extends IxupBaseService {
  public currentUserSubject: ReplaySubject<AuthUserModel>;

  private authKey = 'token';
  private msalAuthKey = 'msalToken';
  private collaboratorTokenExpiry: { collaboratorId: number; expiry: Date };
  private userConsole: string;
  private termsConditionsAccepted: boolean;
  private currentUser: AuthUserModel;

  constructor(
    @Inject(HttpClient) private http: HttpClient,
    private msalService: MsalService,
    private router: Router,
    private userService: UserService,
    private emitterService: EmitterService,
    private translate: TranslateService,
    private store: Store<IAppState>,
    private userFactory: UserFactoryService,
    private jwtHelper: JwtHelperService,
    private landingPageService: LandingPageService,
    private pagePermissionService: PagePermissionService,
    private dialog: MatDialog,
  ) {
    super();

    this.msalService
      .handleRedirectObservable()
      .subscribe((result: AuthenticationResult) => {
        if (result !== null) {
          this.msalService.instance.setActiveAccount(result.account);

          localStorage.setItem(this.msalAuthKey, result.idToken);

          this.checkIxupToken(result.idToken).subscribe(
            (result: ResultModelGeneric<string>) => {
              if (result.status) {
                this.setIxupUserAndToken(result.data, this.authKey);
                this.afterLogin();
              } else {
                this.logout('/inactiveaccount');
              }
              localStorage.setItem('isInTheMiddleOfNewTokenRequest', 'false');
            },
            () => {
              this.logout();
              localStorage.setItem('isInTheMiddleOfNewTokenRequest', 'false');
            },
          );
        }
      });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.currentUserSubject = <ReplaySubject<any>>new ReplaySubject(1);

    const token = localStorage.getItem(this.authKey);
    if (token) {
      const user = this.createUserFromToken(token);
      this.currentUserSubject.next(user);
    }
  }

  public isLoggedInMsal(): boolean {
    return this.msalService.instance.getActiveAccount() !== null;
  }

  public isLoggedInIxup(): boolean {
    return !!localStorage.getItem(this.authKey);
  }

  public isLoggedIn(): boolean {
    return this.isLoggedInMsal() && this.isLoggedInIxup();
  }

  public isMsalRedirect = (): boolean => !!this.msalService['redirectHash'];

  get currentUser$(): Observable<AuthUserModel> {
    return this.currentUserSubject.asObservable();
  }

  login() {
    this.msalService.loginRedirect();
  }

  logoutMsal() {
    this.msalService.logout();
  }

  logout(postLogoutRedirectUri?: string) {
    if (typeof localStorage !== 'undefined') {
      localStorage.removeItem(this.msalAuthKey);
      localStorage.removeItem(this.authKey);
      this.currentUserSubject.complete();
    }

    if (postLogoutRedirectUri) {
      this.logoutMsal();
      this.router.navigate([postLogoutRedirectUri]);
    } else {
      this.msalService.logout({
        postLogoutRedirectUri: '/login',
      });
    }
  }

  checkIxupToken(msalToken: string): Observable<ResultModel> {
    localStorage.setItem('isInTheMiddleOfNewTokenRequest', 'true');

    // get ixup token
    return this.callIxupAuthenticate(msalToken);
  }

  callIxupAuthenticate(
    msalToken: string,
  ): Observable<ResultModelGeneric<string>> {
    if (msalToken != null && msalToken !== 'null') {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${msalToken}`,
        }),
      };

      return this.resolveErrorGeneric<string>(
        this.http.post('/api/Auth/Authenticate', null, httpOptions),
      );
    }
  }

  setIxupUserAndToken(token: string, key: string): boolean {
    if (typeof localStorage !== 'undefined') {
      localStorage.setItem(key, token);
      this.currentUserSubject.next(this.createUserFromToken(token));
      return true;
    }
    return false;
  }

  createUserFromToken(accessToken): AuthUserModel {
    const user = this.userFactory.createUserFromToken(accessToken);

    this.collaboratorTokenExpiry = {
      collaboratorId: user.CollaboratorId,
      expiry: this.jwtHelper.getTokenExpirationDate(accessToken),
    };
    return user;
  }

  afterLogin() {
    this.setLanguageDefault();
    this.userService.getCurrentUser().subscribe((user: LoginUserModel) => {
      this.userConsole = user.currentConsole;
      this.termsConditionsAccepted = user.termsConditionsAccepted;
      this.emitterService.changeMessage('True');
      if (this.termsConditionsAccepted) {
        this.changeOrResetPassphrase();
        if (this.currentUser.Signing > 0) {
          if (!this.currentUser.MultipleSigning) {
            this.landingPageService.openDocument(this.currentUser.Signing, this.currentUser.CollaboratorId, true);
          }
        }
        this.redirectToCorrectLandingPage();
      } else {
        this.router.navigate(['/terms-and-conditions']);
      }
    });
  }

  setLanguageDefault(): void {
    this.userService.getUserProfile().subscribe((response: UserModel) => {
      this.translate.use(response.preferredLanguage);
    });
  }

  public redirectToCorrectLandingPage(): void {
    this.hasPermission([
      Permission.InfrastructureConsole,
      Permission.EnablerConsole,
      Permission.AdminConsole,
      Permission.CustodianConsole,
      Permission.PowaIndexConsole,
      // Permission.PlayXSafeAdmin,
      // Permission.PlayXSafeUpload,
      Permission.Convertr,
      Permission.Venn,
    ]).subscribe((p) => {
      this.landingPageService.redirectToCorrectLandingPage(
        this.userConsole,
        p[0],
        p[1],
        p[2],
        p[3],
        p[4],
        p[5],
        p[6],
        // p[7],
        // p[8],
        this.currentUser.MultipleSigning
      );
    });
  }

  hasPermission(keys: number[]): Observable<boolean[]> {
    return this.currentUser$.pipe(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      map((user: any) => {
        const result = [];
        const userPermissions = user.Permissions.split(',').map(Number);
        keys.forEach((key) => {
          result.push(userPermissions.indexOf(key) !== -1);
        });

        return result;
      }),
    );
  }

  refreshTokenWhenNearExpiry(): void {
    const now = new Date();
    const isInTheMiddleOfNewTokenRequest = localStorage.getItem(
      'isInTheMiddleOfNewTokenRequest',
    );
    if (isInTheMiddleOfNewTokenRequest !== 'true') {
      const tokenRemainingLife =
        this.collaboratorTokenExpiry.expiry.getTime() - now.getTime();
      if (tokenRemainingLife < 0) {
        this.logout();
      } else if (tokenRemainingLife < 1800000) {
        // less than 30 min
        this.refreshToken();
      }
    }
  }

  authenticateWithOrganisationId(organisationId: number, move: boolean): void {
    this.authenticationWithOrganisationId(organisationId).subscribe(
      (response: ResultModelGeneric<string>) => {
        if (response.status) {
          this.setIxupUserAndToken(response.data, this.authKey);
          if (move) {
            const page = this.router.url;
            if (page === '/admin-console') {
              this.router.navigate(['admin-console']);
            }
          }
          this.store.dispatch(new LoadOrganisation({ organisationId }));
        } else {
          this.logout();
        }
        localStorage.setItem('isInTheMiddleOfNewTokenRequest', 'false');
      },
    );
  }

  authenticationWithOrganisationId(organisationId: number) {
    return this.resolveErrorGeneric(
      this.http.post(
        '/api/Auth/AuthenticateWithOrganisationId',
        organisationId,
      ),
    );
  }

  refreshToken(): void {
    localStorage.setItem('isInTheMiddleOfNewTokenRequest', 'true');
    this.renewToken().subscribe((response: ResultModelGeneric<string>) => {
      if (response.status) {
        this.setIxupUserAndToken(response.data, this.authKey);
        const page = this.router.url;
        this.router.navigateByUrl(page);
      } else {
        this.logout();
      }
      localStorage.setItem('isInTheMiddleOfNewTokenRequest', 'false');
    });
  }

  renewToken() {
    return this.resolveErrorGeneric(
      this.http.post('/api/Auth/RenewToken', null),
    );
  }

  authenticateWithCollaboratorId(collaboratorId: number, move: boolean): void {
    this.authenticationWithCollaboratorId(collaboratorId).subscribe(
      (response: ResultModelGeneric<string>) => {
        if (response.status) {
          this.setIxupUserAndToken(response.data, this.authKey);
          if (move) {
            const page = this.router.url;
            if (page === '/custodian-console/switch-collaborator') {
              this.router.navigate(['custodian-console']);
            }
            else if (page === '/convertr/dashboard/switch-collaborator') {
              this.router.navigate(['convertr/collaborations']);
            }
            else if (page === '/venn/dashboard/switch-collaborator') {
              this.router.navigate(['venn/collaborations']);
            }
          }
        } else {
          this.logout();
        }
        localStorage.setItem('isInTheMiddleOfNewTokenRequest', 'false');
      },
    );
  }

  authenticationWithCollaboratorId(collaboratorId: number) {
    return this.resolveErrorGeneric(
      this.http.post(
        '/api/Auth/AuthenticateWithCollaboratorId',
        collaboratorId,
      ),
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  hasPagePermission = (state: any): any =>
    this.pagePermissionService.hasPagePermission(this.currentUser$, state);

  private changeOrResetPassphrase() {
    this.currentUser$.pipe(take(1)).subscribe((user) => {
      this.currentUser = user;
      if (this.currentUser.AuthCode != PassphraseResetTypeEnum.neither) {
        this.openPassphraseResetDialog();
      }
    });
  }

  private openPassphraseResetDialog() {
    const dialogRef = this.dialog.open(ChangeOrResetPassphraseDialog, {
      backdropClass: 'bg-passkey-dialog',
      panelClass: 'fg-passkey-dialog',
      maxWidth: '500px',
      minWidth: '360px',
      // minHeight: '500px',
      disableClose: true,
      data: {
        authCode: Number(this.currentUser.AuthCode),
        userId: this.currentUser.UserId,
        collaboratorId: this.currentUser.CollaboratorId,
      },
    });
    dialogRef.afterClosed().subscribe(() => ({}));
  }
}
