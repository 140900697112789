import 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CustodianModel } from '../../model/custodian.model';
import { ResultModelGeneric } from '../../model/result.model';
import { UserModel } from '../../model/user.model';
import { OrganisationBaseModel } from '../../pages/consoles/admin-console/model/organisation-base.model';
import { IAddCreditsModel } from '../../pages/consoles/model/add-credits.model';
import { IConsoleOrganisationModel } from '../../pages/consoles/model/console-organisation.model';
import { IConsoleUserModel } from '../../pages/consoles/model/console-user.model';
import { ICreateOrganisationModel } from '../../pages/consoles/model/create-organisation.model';
import { ICreditsTransaction } from '../../pages/consoles/model/credits-transactions.model';
import { IOrganisationCreditsModel } from '../../pages/consoles/model/organisation-credits.model';
import { IUpdatePlanModel } from '../../pages/consoles/model/update-plan.model';
import { IxupBaseService } from '../ixup-base.service';

@Injectable()
export class OrganisationService extends IxupBaseService {
  constructor(@Inject(HttpClient) private http: HttpClient) {
    super();
  }

  public getOrganisationList() {
    return this.resolveError(this.http.get('/api/Organisation/List'));
  }
  public getOrganisationTypes() {
    return this.resolveError(this.http.get('/api/Organisation/types'));
  }

  public getOrganisationListAll() {
    return this.resolveError(this.http.get('/api/Organisation/all'));
  }

  public isCollaboratorExist() {
    return this.resolveError(
      this.http.get('/api/Organisation/isCollaboratorExist'),
    );
  }

  public getEnvironmentDetails() {
    return this.resolveError(this.http.get('/api/Organisation'));
  }

  public getUsersByOrganisationId(
    organisationId,
  ): Observable<ResultModelGeneric<UserModel[]>> {
    return this.resolveErrorGeneric(
      this.http.get(`/api/Organisation/${organisationId}/users`),
    );
  }

  public setOrganisationById(organisationId: number, state: boolean) {
    return this.resolveErrorGeneric(
      this.http.post(`/api/Organisation/${organisationId}/${state}`, null),
    );
  }

  public deleteOrganisationById(organisationId: number) {
    return this.resolveErrorGeneric(
      this.http.post('/api/Organisation/delete', organisationId),
    );
  }

  public deleteOrgWithCollaborators(organisationId: number) {
    return this.resolveErrorGeneric(
      this.http.post('/api/Organisation/delete/entire', organisationId),
    );
  }

  public getAllCustodiansForUserOrganisations(): Observable<
    ResultModelGeneric<CustodianModel[]>
    > {
    return this.resolveErrorGeneric<CustodianModel[]>(
      this.http.get('/api/organisation/custodians'),
    );
  }

  public getMyOrganisations(): Observable<
    ResultModelGeneric<OrganisationBaseModel[]>
    > {
    return this.resolveErrorGeneric<OrganisationBaseModel[]>(
      this.http.get('/api/organisation/my'),
    );
  }

  public getMyOrganisationNames(): Observable<
    ResultModelGeneric<IConsoleOrganisationModel[]>
    > {
    return this.resolveErrorGeneric<IConsoleOrganisationModel[]>(
      this.http.get('/api/organisation/my'),
    );
  }

  public getOrganisationUsers(): Observable<
    ResultModelGeneric<IConsoleUserModel[]>
    > {
    return this.resolveErrorGeneric<IConsoleUserModel[]>(
      this.http.get('/api/Organisation/users'),
    );
  }

  public getOrganisationUsersNotCurrentlyInDataCustodian(): Observable<
    ResultModelGeneric<IConsoleUserModel[]>
    > {
    return this.resolveErrorGeneric<IConsoleUserModel[]>(
      this.http.get('/api/Organisation/users/custodian'),
    );
  }

  getUserDetails(userId) {
    return this.resolveError(
      this.http.get(`/api/Organisation/User/${userId}/details`),
    );
  }

  createOrganisationUser(
    user: IConsoleUserModel,
  ): Observable<ResultModelGeneric<IConsoleUserModel>> {
    const mappedUser = {
      ...user,
      roles: user.roles.map((r) => r.id),
    };

    return this.resolveErrorGeneric<IConsoleUserModel>(
      this.http.post('/api/organisation/createOrganisationUser', mappedUser),
    );
  }

  updateOrganisationUser(
    user: IConsoleUserModel,
  ): Observable<ResultModelGeneric<IConsoleUserModel>> {
    return this.resolveErrorGeneric<IConsoleUserModel>(
      this.http.post('/api/organisation/updateOrganisationUser', user),
    );
  }

  updateOrganisation(
    organisation: IConsoleOrganisationModel,
  ): Observable<ResultModelGeneric<IConsoleOrganisationModel>> {
    return this.resolveErrorGeneric<IConsoleOrganisationModel>(
      this.http.post('/api/organisation/update', organisation),
    );
  }

  createOrganisation(
    organisation: IConsoleOrganisationModel,
  ): Observable<ResultModelGeneric<IConsoleOrganisationModel>> {
    return this.resolveErrorGeneric<IConsoleOrganisationModel>(
      this.http.post('/api/organisation/create', organisation),
    );
  }

  createOrganisationWithUser(
    organisation: ICreateOrganisationModel,
  ): Observable<ResultModelGeneric<ICreateOrganisationModel>> {
    return this.resolveErrorGeneric<ICreateOrganisationModel>(
      this.http.post('/api/organisation/create', organisation),
    );
  }

  getOrganisation(
    id: number,
  ): Observable<ResultModelGeneric<IConsoleOrganisationModel>> {
    return this.resolveErrorGeneric<IConsoleOrganisationModel>(
      this.http.get(`/api/organisation/${id}`),
    );
  }

  //// Cost Management & Billing /////
  getOrganisationCredits(
    organisationId: number,
  ): Observable<ResultModelGeneric<IOrganisationCreditsModel>> {
    return this.resolveErrorGeneric<IOrganisationCreditsModel>(
      this.http.get(`/api/billing/organisation/${organisationId}/credits`),
    );
  }
  saveOrganisationCredits(
    credits: IAddCreditsModel,
  ): Observable<ResultModelGeneric<IAddCreditsModel>> {
    return this.resolveErrorGeneric<IAddCreditsModel>(
      this.http.post('/api/billing/organisation/credits', credits),
    );
  }

  getCreditsTransactions(
    organisationId: number,
  ): Observable<ResultModelGeneric<ICreditsTransaction[]>> {
    return this.resolveErrorGeneric<ICreditsTransaction[]>(
      this.http.get(
        `/api/billing/organisation/${organisationId}/credits-transactions`,
      ),
    );
  }

  updatePlan(
    planUpdates: IUpdatePlanModel,
  ): Observable<ResultModelGeneric<IConsoleOrganisationModel>> {
    return this.resolveErrorGeneric<IConsoleOrganisationModel>(
      this.http.post('/api/billing/organisation/update-plan', planUpdates),
    );
  }
}
