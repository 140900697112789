<a
  mat-list-item
  [routerLink]="['/custodian-console', 'collaborations']"
  [class.mat-active]=" router.url.includes('collaborations') ||
  router.url.includes('control-centre')"
>
  <mat-icon
    matListIcon
    [matTooltip]="!miniNav ? '' : 'Collaborations'"
    matTooltipPosition="after"
    matTooltipClass="ixup-canvas-tooltip"
  >
    pie_chart
  </mat-icon>
  <span
    mat-line
    *ngIf="!miniNav"
  >Collaborations</span>
</a>
<a
  mat-list-item
  [routerLink]="['/custodian-console', 'data-explorer']"
  [routerLinkActive]="['mat-active']"
>
  <mat-icon
    matListIcon
    [matTooltip]="!miniNav ? '' : 'Data Explorer'"
    matTooltipPosition="after"
    matTooltipClass="ixup-canvas-tooltip"
  >
    <span class="icon-data-explorer"><span class="path1"></span><span class="path2"></span><span
        class="path3"></span><span class="path4"></span></span>
  </mat-icon>
  <span
    mat-line
    *ngIf="!miniNav"
  >Data Explorer</span>
</a>
<a
  mat-list-item
  *ngIf="manageUsers"
  [routerLink]="['/custodian-console/users']"
  [routerLinkActive]="['mat-active']"
>
  <mat-icon
    matListIcon
    [matTooltip]="!miniNav ? '' : 'Users'"
    matTooltipPosition="after"
    matTooltipClass="ixup-canvas-tooltip"
  >
    person
  </mat-icon>
  <span
    mat-line
    *ngIf="!miniNav"
  >Users</span>
</a>
<a
  mat-list-item
  [routerLink]="['/custodian-console/audit/', dataCustodianId]"
  [routerLinkActive]="['mat-active']"
>
  <mat-icon
    matListIcon
    [matTooltip]="!miniNav ? '' : 'Audit Log'"
    matTooltipPosition="after"
    matTooltipClass="ixup-canvas-tooltip"
  >
    <span class="icon-audits"></span>
  </mat-icon>
  <span
    mat-line
    *ngIf="!miniNav"
  >Audit Log</span>
</a>
<a
  mat-list-item
  *ngIf="billingAdmin"
  [routerLink]="['/custodian-console/credits']"
  [routerLinkActive]="['mat-active']"
>
  <mat-icon
    matListIcon
    [matTooltip]="!miniNav ? '' : 'Cost Management'"
    matTooltipPosition="after"
    matTooltipClass="ixup-canvas-tooltip"
  >
    <span class="icon-costmgmt-billing"><span class="path1"></span><span class="path2"></span><span
        class="path3"></span><span class="path4"></span></span>
  </mat-icon>
  <span
    mat-line
    *ngIf="!miniNav"
  >Costs</span>
</a>