<div class="container">
  <div class="empty-state push-top-lg">
    <mat-icon color="warn">warning</mat-icon>
    <h1 class="mat-display-3 push-bottom-lg">{{ 'Sorry!' | translate }}</h1>
    <h4>
      {{ 'It seems this account does not have any roles attached.' | translate
      }}<br />
      {{ 'Please
      contact your system administrator.' | translate }}
    </h4>
    <a aria-hidden="true" mat-stroked-button color="primary" (click)="cancel()">{{
      'Return to sign in page' | translate
      }}</a>
    <p>
      {{
      "If you think you shouldn't be seeing this message, try to sign in again."
      | translate
      }}
    </p>
    <p>
      {{
      'If you keep returning here, try to sign in after closing and reopening your browser, or contact your system
      administrator.'
      | translate
      }}
    </p>
  </div>
</div>
