import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { PipeModule } from '../../pipe.module';
import { NoRoleAccountComponent } from './norole-account.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    PipeModule.forRoot(),
    MatIconModule,
    NoRoleAccountComponent,
  ],
  exports: [NoRoleAccountComponent],
  providers: [],
})
export class NoRoleAccountModule {}
