import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { NgFor, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatLineModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatLegacyListModule } from '@angular/material/legacy-list';
import { MatLegacyProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';

@Component({
  selector: 'ixup-summary',
  templateUrl: 'summary.component.html',
  animations: [
    trigger('animate', [
      state('true', style({ opacity: 1 })),
      state('false', style({ opacity: 0 })),
      transition('false => true', animate(300)),
      transition('true => false', animate(300)),
    ]),
  ],
  standalone: true,
  imports: [
    MatCardModule,
    MatLegacyListModule,
    NgFor,
    NgIf,
    MatIconModule,
    MatLegacyProgressSpinnerModule,
    MatLineModule,
  ],
})
export class SummaryComponent {
  @Input() items;
  @Input() animateit = 'false';
}
