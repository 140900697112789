import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IxupBaseService } from '../ixup-base.service';
import { RenameDialogModel } from './renamedialog.model';
import { ToasterService } from '../toaster.service';
import { IxupHttpCustomParams } from '../../http/http-interceptor-params';
import { Observable, of } from 'rxjs';

@Injectable()
export class FilesService extends IxupBaseService {
  constructor(
    @Inject(HttpClient) private http: HttpClient,
    private toaster: ToasterService,
  ) {
    super();
  }

  public getList() {
    return this.resolveError(
      this.http.get('/api/FileManagement/my/container/files'),
    );
  }

  public deleteFile(name: string) {
    return this.resolveError(this.http.delete(`/api/FileManagement/${name}`));
  }

  public archiveFile(name: string) {
    return this.resolveError(
      this.http.post('/api/filemanagement/archive/' + encodeURI(name), null),
    );
  }

  public renameFile(renameDialog: RenameDialogModel) {
    return this.resolveError(
      this.http.post('/api/filemanagement/rename', renameDialog),
    );
  }

  public getFileContent(name) {
    return this.resolveError(this.http.get(`/api/FileManagement/${name}/1`));
  }

  public previewAndValidateMapping(name, fileMappings) {
    return this.resolveError(
      this.http.post(
        `/api/filemanagement/${name}/preview/validate`,
        fileMappings,
      ),
    );
  }

  public releaseData(model) {
    if (model.destinationType === 'append') {
      if (model.replace === 'true') {
        return this.replaceData(model);
      } else {
        return this.appendData(model);
      }
    }
    if (model.destinationType === 'create') {
      return this.moveData(model);
    }
  }

  public moveData(filePublishData) {
    return this.http.post('/api/FileManagement/create', filePublishData);
  }

  public appendData(filePublishData) {
    return this.resolveError(
      this.http.post('/api/FileManagement/append', filePublishData),
    );
  }

  public replaceData(filePublishData) {
    return this.resolveError(
      this.http.post('/api/FileManagement/replace', filePublishData),
    );
  }

  public saveValidation(name) {
    return this.resolveError(
      this.http.post(`/api/FileManagement/save/${name}/validation`, null),
    );
  }

  public getFileMappings(name) {
    return this.resolveError(
      this.http.get(`/api/FileManagement/${name}/mappings`),
    );
  }

  public saveFileMappings(mappingModels, name) {
    return this.resolveError(
      this.http.post(`/api/FileManagement/save/${name}/mapping`, mappingModels),
    );
  }

  public downloadFileManagementApp() {
    this.http
      .get('/api/FileManagement/installer', { responseType: 'blob' })
      .subscribe(
        (result) => {
          const url = window.URL.createObjectURL(result);
          const downlaodAnchor = document.createElement('a');
          document.body.appendChild(downlaodAnchor);
          downlaodAnchor.setAttribute('style', 'display: none');

          const blob = new Blob([result], { type: 'application/x-msdownload' });
          downlaodAnchor.href = URL.createObjectURL(blob);
          downlaodAnchor.download = 'IXUPsetup.exe';
          downlaodAnchor.click();
          window.URL.revokeObjectURL(url);
          downlaodAnchor.remove();
        },
        () => {
          this.toaster.toast('IXUP Agent download failed.', 'Error');
        },
      );
  }

  public downloadFile(
    contents: Blob,
    fileType: string,
    fileName: string,
  ): Observable<boolean> {
    const url = window.URL.createObjectURL(contents);
    const downlaodAnchor = document.createElement('a');
    document.body.appendChild(downlaodAnchor);
    downlaodAnchor.setAttribute('style', 'display: none');

    const blob = new Blob([contents], { type: fileType });
    downlaodAnchor.href = URL.createObjectURL(blob);
    downlaodAnchor.download = fileName;
    downlaodAnchor.click();
    window.URL.revokeObjectURL(url);
    downlaodAnchor.remove();

    return of(true);
  }

  public postFileContents(fileContents: string) {
    return this.resolveError(
      this.http.post('/api/client/keys/upload', fileContents, {
        params: new IxupHttpCustomParams(true),
      }),
    );
  }
}
