import { Injectable } from '@angular/core';
import { AuthUserModel } from '../../model/authuser.model';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Permission } from '../../model/Permission';

@Injectable()
export class PagePermissionService {
  private access: number[] = [];


  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  hasPagePermission(currentUser$: Observable<AuthUserModel>, state: any): any {
    this.access.pop();
    this.access.push(this.isViewable(state.url));

    return currentUser$.pipe(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      map((user: any) => {
        return this.access[0] === 0
          ? true
          : user.Permissions.indexOf(this.access) > -1;
      }),
    );
  }

  isViewable(url: string): number {
    switch (url) {
    case '/dashboard': {
      return 0;
    }
    case '/workbench-standard': {
      return 3;
      break;
    }
    case '/environment-management': {
      return 35;
    }
    case '/organisations': {
      return 3;
    }
    case '/roles': {
      return 15;
    }
    case '/users': {
      return 19;
    }
    case '/setup': {
      return 7;
    }
    case '/collaborators': {
      return 7;
    }
    // case 'workspaces': {
    //   return 79;
    //   break;
    // }
    case '/workspace-templates': {
      return 62;
    }
    case '/database-provider': {
      return 54;
    }
    case '/centralrepository': {
      return 93;
    }
    case '/file-management': {
      return 28;
    }
    case '/table-management': {
      return 75;
    }
    case '/table-management-staging': {
      return 121;
    }
    case '/resource-collections': {
      return 32;
    }
    case '/stacks': {
      return 11;
    }
    case '/metadata-matchsets': {
      return 120;
    }
    case '/metadata': {
      return 66;
    }
    case '/data-warehouse': {
      return 58;
    }
    case '/manageextracts': {
      return 70;
    }
    case '/collaborations': {
      return 11;
    }
    case '/export': {
      return Permission.ViewExportRequests;
    }
    case '/custodian-console': {
      return Permission.CustodianConsole;
    }
    case '/custodian-console/users': {
      return Permission.ManageUsersAndPermissions;
    }
    case '/infrastructure-console': {
      return Permission.InfrastructureConsole;
    }
    case '/enabler-console': {
      return Permission.EnablerConsole;
    }
    case '/admin-console': {
      return Permission.AdminConsole;
    }
    default: {
      return 0;
    }
    }
  }
}
