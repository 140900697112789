import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';

import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';

import { SharedModule } from '../../../../../components/shared/shared.module';
import { ChangeOrResetPassphraseDialog } from '../../../../../components/shared/utilities/change-or-reset-passphrase-dialog/change-or-reset-passphrase-dialog.component';
import { ConfirmationComponent } from '../../../../../components/shared/utilities/change-or-reset-passphrase-dialog/confirmation/confirmation.component';
import { PassphraseFormComponent } from '../../../../../components/shared/utilities/change-or-reset-passphrase-dialog/passphrase-form/passphrase-form.component';
import { PipeModule } from '../../../../../pipe.module';
import { CollaborationService } from '../../../../../services/collaboration/collaboration.service';
import { CustodianPassphraseDialog } from './custodian-passphrase-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatButtonModule,
    MatRadioModule,
    MatIconModule,
    MatInputModule,
    MatTooltipModule,
    MatIconModule,
    MatTabsModule,
    MatRadioModule,
    MatTooltipModule,
    MatButtonToggleModule,
    MatCardModule,
    MatSelectModule,
    MatDividerModule,
    MatToolbarModule,
    ReactiveFormsModule,
    PipeModule.forRoot(),
    CustodianPassphraseDialog,
    ChangeOrResetPassphraseDialog,
    PassphraseFormComponent,
    ConfirmationComponent,
  ],
  exports: [],
  providers: [CollaborationService],
})
export class CustodianPassphraseDialogSharedModule {}
