<h1 mat-dialog-title>
  <mat-icon *ngIf="!!options.icon">{{ options.icon }}</mat-icon>
  {{ options.title | translate }}
</h1>
<mat-dialog-content>
  <p>{{ options.message | translate }}</p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button
    mat-button
    *ngIf="!!options.canceltext"
    (click)="cancel()"
    class="sm-block"
  >
    {{ options.canceltext | translate }}
  </button>
  <button
    mat-raised-button
    (click)="confirm()"
    [color]="options.confirmColor || 'warn'"
    class="sm-block"
  >
    {{ options.oktext | translate }}
  </button>
</mat-dialog-actions>