import { of as observableOf, Observable } from 'rxjs';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BreadcrumbModel } from './breadcrumb.model';
import { RouterLink } from '@angular/router';
import { NgFor, AsyncPipe } from '@angular/common';

@Component({
  selector: 'ixup-breadcrumb',
  templateUrl: 'breadcrumb.component.html',
  standalone: true,
  imports: [NgFor, RouterLink, AsyncPipe],
})
export class BreadcrumbComponent implements OnChanges {
  @Input() breadcrumb: BreadcrumbModel[];
  @Input() lastcrumb: Observable<string> = observableOf('');
  rest: BreadcrumbModel[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.breadcrumb) {
      if (this.breadcrumb != null) {
        this.rest = this.breadcrumb.slice(0);
      }
    }
  }

}
