import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { ResultModelGeneric } from 'app/model/result.model';
import { UserModel } from 'app/model/user.model';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { AuthUserModel } from '../../model/authuser.model';
import { IOrganisationTypeModel } from '../../pages/consoles/model/organisationTypes.model';
import { AuthService } from '../../services/auth/AuthService';
import { OrganisationService } from '../../services/organisation/organisation.service';
import { ToasterService } from '../../services/toaster.service';
import { BaseEffects } from '../baseEffects';
import { LoadVennDashboardFailed } from '../dashboard-venn/actions';
import {
  GetUsersByOrganisationId,
  GetUsersByOrganisationIdFailed,
  GetUsersByOrganisationIdSuccess,
  LoadOrganisationTypesFailed,
  LoadOrganisationTypesSuccess,
  SetUserSuccess,
  UserActionTypes
} from './user-actions';

@Injectable()
export class UserEffects extends BaseEffects {
  
  SetUser$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(UserActionTypes.SetUser),
    mergeMap(() =>
      this.authService.currentUser$.pipe(
        map((user: AuthUserModel) => {
          return new SetUserSuccess({
            user: user,
          });
        }),
        catchError(() => {
          this.showError('Error loading collaborations');
          return of(new LoadVennDashboardFailed());
        }),
      ),
    ),
  ));

  GetUsersByOrganisationId$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(UserActionTypes.GetUsersByOrganisationId),
    mergeMap((action: GetUsersByOrganisationId) =>
      this.organisationService
        .getUsersByOrganisationId(action.payload.organisationId).pipe(
          map((users: ResultModelGeneric<UserModel[]>) => {
            return new GetUsersByOrganisationIdSuccess({
              orgId: action.payload.organisationId,
              users: users.data,
            });
          }),
          catchError(() => {
            this.showError('Error loading users by organisationId');
            return of(new GetUsersByOrganisationIdFailed());
          }),
        ),
    ),
  ));

  
  LoadOrganisationTypes$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(UserActionTypes.LoadOrganisationTypes),
    mergeMap(() =>
      this.organisationService.getOrganisationTypes().pipe(
        map((organisation: IOrganisationTypeModel[]) => {
          return new LoadOrganisationTypesSuccess({
            organisationTypes: organisation,
          });
        }),
        catchError(() => {
          this.showError('Error loading organisation types.');
          return of(new LoadOrganisationTypesFailed());
        }),
      ),
    ),
  ));

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private organisationService: OrganisationService,
    toasterService: ToasterService,
  ) {
    super(toasterService);
  }
}
