export const LANG_EN_NAME = 'en';

export const LANG_EN_TRANS = {
  // 'hello world': 'Flight Centre',
  // 'collaboration': 'Collaborations',
  // 'Dashboard': 'Dashboard',
  // 'Organisations': 'Organisations',
  // 'Collaborations': 'Collaborations',
  // 'User': 'User',
  // 'AttachUser': 'Attach user',
  // 'Central Repository': 'Central repository',
  // 'ManageDefindrepository': "Manage Defin'd repository",
  // 'ManagePerils': 'Manage Perils repository',
  // 'DatabaseAccess': 'Database access',
  // 'Container Management': 'File management',
  // 'Extract Management': 'Extract management',
  // 'Manage': 'Manage extracts',
  // 'start workspace': 'Start workspace',
  // 'stop workspace': 'Stop workspace',

};