<form [formGroup]="modelForm">
  <h2 class="ixup-section-title" *ngIf="!display">
    {{ 'Administrator *' | translate }}
  </h2>
  <h2 class="ixup-section-title" *ngIf="display" class="mat-input-error">
    {{ 'Administrator *' | translate }}
  </h2>
  <mat-form-field class="items-center align-center full-width">
    <input type="admin user" matInput formControlName="selectedUser" [matAutocomplete]="auto"
      (input)="clearSelection()">
    <button *ngIf="modelForm.get('selectedUser').value" matSuffix mat-icon-button aria-label="Clear" (click)="clear()">
      <mat-icon class="fs-14 h-[14px] w-[14px]" style="font-size:100%">close</mat-icon>
    </button>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)" [displayWith]="displayFn">
      <mat-option *ngFor="let option of filteredUsers | async" [value]="option">
        {{option.label}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <p *ngIf="!display" class="mat-caption">
    {{ 'Add an administrator by clicking in the area above' | translate }}
  </p>
  <p *ngIf="display" class="mat-caption mat-input-error">
    {{ 'Please select an administrator' | translate }}
  </p>
</form>
