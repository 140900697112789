<form [formGroup]="modelForm" novalidate #form>
  <h2 mat-dialog-title>Data access</h2>
  <mat-dialog-content style="width:480px">
    <p>The collaborator passphrase (set by a collaborator administrator) is required to access secure collaborator data.
    </p>
    <div class="form-group push-bottom">
      <mat-form-field class="block">
        <input type="password" matInput placeholder="Collaborator passphrase" formControlName="password"
          [type]="hide ? 'password' : 'text'">
        <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility' : 'visibility_off'}}</mat-icon>
        <mat-hint align="start">{{ 'The passphrase is likely to be different to your user password' | translate
          }}</mat-hint>
      </mat-form-field>
      <span *ngIf="passwordIncorrect" class="mat-error mat-caption">Please enter the correct collaborator passphrase...
      </span>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button type="button" (click)="onNoClick()">{{ 'Cancel' | translate }}</button>
    <button mat-raised-button color="primary" type="button" [disabled]="!modelForm.valid" (click)="submit()">{{ 'Enter
      passphrase'| translate }}
    </button>
  </mat-dialog-actions>
</form>
