import {
  Component,
  DoCheck,
  EventEmitter,
  Input, OnInit,
  Output
} from '@angular/core';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, filter, Observable, timer } from 'rxjs';

import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyOptionModule } from '@angular/material/legacy-core';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyListModule } from '@angular/material/legacy-list';
import { MatLegacySelectModule } from '@angular/material/legacy-select';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AuthUserModel } from '../../model/authuser.model';
import { CollaboratorBasicModel } from '../../model/collaborator-basic.model';
import { ExtendedImageContainer } from '../../model/imagecontainer.interface';
import { Permission } from '../../model/Permission';
import { UserCollaborationsModel } from '../../pages/powaindex/model/user-collaborations.model';
import { AgentService } from '../../services/agent/agent.service';
import { AuthService } from '../../services/auth/AuthService';
import { CollaboratorService } from '../../services/collaborator/collaborator.service';
import { EmitterService } from '../../services/emitter.service';
import { FilesService } from '../../services/files/files.service';
import { ImagesService } from '../../services/shared/Images.service';
import { TranslatePipe } from '../../services/translate/translate.pipe';
import * as fromAgentAction from '../../state/agent/agent-action';
import { IAgentState } from '../../state/agent/agent-reducer';
import { getAgentStatusState } from '../../state/agent/agent-selectors';
import { IAppState } from '../../state/app.interfaces';
import { getRouterUrlRootSection } from '../../state/app.reducer';
import {
  LoadConvertrDashboard,
  SetCurrentConvertrDashboard
} from '../../state/dashboard-convertr/actions';
import {
  getConvertrUserCollaborationState,
  getDefaultConvertrUserCollaborationId
} from '../../state/dashboard-convertr/selectors';
import {
  LoadVennDashboard,
  SetCurrentVennDashboard
} from '../../state/dashboard-venn/actions';
import {
  getDefaultVennUserCollaborationId,
  getVennUserCollaborationState
} from '../../state/dashboard-venn/selectors';
import {
  SetCurrentDashboard
} from '../../state/dashboard/actions';
import {
  LoadPlayXSafeDashboard,
  SetCurrentPlayXSafeDashboard
} from '../../state/playXsafe-dashboard/actions';
import {
  getDefaultPlayXSafeUserCollaborationId,
  getPlayXSafeUserCollaborationState
} from '../../state/playXsafe-dashboard/selectors';
import { untilDestroyed } from '../../state/shared/utils';
import { fadeIn } from '../../theme/animations/fadein-animation';
import { LogoComponent } from '../shared/logo/logo.component';
import { StyleManager } from '../shared/style-manager/style-manager';

// THIS IS FOR TESTING THE DASHBOARD SELECTOR
interface Dashboard {
  value: number;
  viewDashboard: string;
}

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  animations: [fadeIn],
  standalone: true,
  imports: [
    NgIf,
    MatToolbarModule,
    MatButtonModule,
    RouterLink,
    LogoComponent,
    MatLegacyFormFieldModule,
    MatLegacySelectModule,
    MatIconModule,
    NgFor,
    MatLegacyOptionModule,
    MatTooltipModule,
    RouterLinkActive,
    NgClass,
    MatMenuModule,
    MatLegacyListModule,
    MatDividerModule,
    AsyncPipe,
    TranslatePipe,
  ],
})
export class AppHeaderComponent implements OnInit, DoCheck {
  public currentUser: AuthUserModel;
  ownerCollaboratorList: CollaboratorBasicModel[];
  public currentUserOrgId: number;
  @Input() private model: AuthUserModel;
  @Output() private orgId: EventEmitter<number> = new EventEmitter<number>();
  private emitMessage: string;
  isUserLoggedIn = false;
  private authKey = 'token';
  currentUser$: Observable<AuthUserModel>;
  accessInfraConsole: boolean;
  accessEnablerConsole: boolean;
  accessAdminConsole: boolean;
  accessCustodianConsole: boolean;
  dataUploader = false;
  fileManager = false;
  collaboratorName: string;
  urlRootSection$: Observable<string>;
  collaboratorIconContainer: ExtendedImageContainer;
  organisationIconContainer: ExtendedImageContainer;
  enablerIconContainer: ExtendedImageContainer;
  userIconContainer: ExtendedImageContainer;
  agentRunning: boolean;
  organisationName: string;
  enablerAccessOnly = true;
  tandc = false;
  powaIndexUser = false;
  public playXSafeUser = false;
  public convertrUser = false;
  public vennUser = false;

  dashboards: Dashboard[];
  selectedDashboard: string;

  playXSafeDashboards: { value: number; viewDashboard: string }[];
  selectedPlayXSafeDashboard: string;
  public convertrDashboards: { value: number; viewDashboard: string }[];
  public vennDashboards: { value: number; viewDashboard: string }[];
  public selectedConvertrDashboard: string;
  public selectedVennDashboard: string;
  collaborationId: number;
  private untilDestroyed = untilDestroyed();

  constructor(
    public authService: AuthService,
    private emitterService: EmitterService,
    private styleManager: StyleManager,
    private collaboratorService: CollaboratorService,
    private imagesService: ImagesService,
    private filesService: FilesService,
    private agentService: AgentService,
    private store: Store<IAppState>,
    private router: Router,
  ) {
    this.urlRootSection$ = store.select(getRouterUrlRootSection);
  }

  ngDoCheck() {
    if (!!this.model && !this.isUserLoggedIn) {
      this.emitterService.currentMessage
        .pipe(this.untilDestroyed())
        .subscribe((emitMessage) => (this.emitMessage = emitMessage));
      if (this.emitMessage === 'True') {
        this.isUserLoggedIn = true;
        this.loadHeader();
      }
    }
  }

  ngOnInit() {
    this.authService.currentUser$
      .pipe(this.untilDestroyed())
      .subscribe((user) => {
        this.currentUser = user;
        const tc = String(user.TandC);
        if (tc === 'True') {
          this.tandc = true;
        }
      });

    this.imagesService
      .observeCollaboratorImage()
      .pipe(this.untilDestroyed())
      .subscribe((imageContainer: ExtendedImageContainer) => {
        this.getCollaboratorImage(
          imageContainer.imageId,
          imageContainer.alternativeName,
        );
      });

    this.imagesService
      .observeEnablerImage()
      .pipe(this.untilDestroyed())
      .subscribe((imageContainer: ExtendedImageContainer) => {
        this.getEnablerImage(
          imageContainer.imageId,
          imageContainer.alternativeName,
        );
      });

    this.imagesService
      .observeUserImage()
      .pipe(this.untilDestroyed())
      .subscribe((imageContainer: ExtendedImageContainer) => {
        this.getUserImage(
          imageContainer.imageId,
          imageContainer.alternativeName,
        );
      });

    this.imagesService
      .observeOrganisationImage()
      .pipe(this.untilDestroyed())
      .subscribe((imageContainer: ExtendedImageContainer) => {
        this.getOrganisationImage(
          imageContainer.imageId,
          imageContainer.alternativeName,
        );
      });

    this.authService.currentUser$
      .pipe(this.untilDestroyed())
      .subscribe((user) => {
        this.currentUserOrgId = user.OrganisationId;
        this.collaboratorService
          .getCollaboratorsForCurrentUser()
          .pipe(this.untilDestroyed())
          .subscribe((response: CollaboratorBasicModel[]) => {
            this.ownerCollaboratorList = response;
          });
      });

    this.isUserLoggedIn = this.authService.isLoggedIn();

    //new permissions
    this.authService
      .hasPermission([Permission.InfrastructureConsole])
      .pipe(this.untilDestroyed())
      .subscribe((result) => {
        this.accessInfraConsole = result[0];
      });

    this.authService
      .hasPermission([Permission.EnablerConsole])
      .pipe(this.untilDestroyed())
      .subscribe((result) => {
        this.accessEnablerConsole = result[0];
      });
    this.authService
      .hasPermission([Permission.AdminConsole])
      .pipe(this.untilDestroyed())
      .subscribe((result) => {
        this.accessAdminConsole = result[0];
      });
    this.authService
      .hasPermission([Permission.CustodianConsole])
      .pipe(this.untilDestroyed())
      .subscribe((result) => {
        this.accessCustodianConsole = result[0];
      });
    this.authService
      .hasPermission([Permission.PowaIndexConsole])
      .pipe(this.untilDestroyed())
      .subscribe((result) => {
        this.powaIndexUser = result[0];
      });

    this.authService
      .hasPermission([Permission.PlayXSafeAdmin, Permission.PlayXSafeUpload])
      .pipe(this.untilDestroyed())
      .subscribe((result) => {
        this.playXSafeUser = result[0] || result[1];
      });

    this.authService
      .hasPermission([Permission.Convertr])
      .pipe(this.untilDestroyed())
      .subscribe((result) => {
        this.convertrUser = result[0];
      });

    this.authService
      .hasPermission([Permission.Venn])
      .pipe(this.untilDestroyed())
      .subscribe((result) => {
        this.vennUser = result[0];
      });

    this.loadHeader();
    this.setAgentStatus();
  }

  private setAgentStatus() {
    this.store
      .select(getAgentStatusState)
      .pipe(this.untilDestroyed())
      .subscribe((agentState: IAgentState) => {
        if (agentState !== null) {
          this.agentRunning = agentState.status;
        }
      });
  }

  private setTheme(theme: string): void {
    this.styleManager.setStyle('theme', `assets/themes/${theme}`);
  }

  loadHeader() {
    this.authService
      .hasPermission([
        Permission.InfrastructureConsole,
        Permission.EnablerConsole,
        Permission.AdminConsole,
        Permission.CustodianConsole,
        Permission.PowaIndexConsole,
        Permission.PlayXSafeAdmin,
        Permission.PlayXSafeUpload,
        Permission.Convertr,
        Permission.Venn,
      ])
      .pipe(this.untilDestroyed())
      .subscribe((p) => {
        if (
          !p[0] &&
          !p[1] &&
          !p[2] &&
          !p[3] &&
          !p[4] &&
          !p[5] &&
          !p[6] &&
          !p[7] &&
          !p[8]
        ) {
          this.isUserLoggedIn = false;
        }
      });

    if (this.isUserLoggedIn) {
      this.collaboratorService
        .getTheme()
        .pipe(this.untilDestroyed())
        .subscribe((response: string) => {
          if (response) {
            this.setTheme(response);
          } else {
            this.setTheme('green.min.css');
          }
        });

      this.currentUser$ = this.authService.currentUser$;

      this.authService.currentUser$
        .pipe(this.untilDestroyed())
        .subscribe((response: AuthUserModel) => {
          const user = response;
          this.orgId.emit(user.OrganisationId);
          const tc = String(user.TandC);
          if (tc) {
            this.tandc = true;
          } else {
            this.tandc = false;
          }

          if (this.playXSafeUser) {
            this.setPlayXSafeUserDashboard();
          }
          if (this.convertrUser) {
            this.setConvertrUserDashboard();
          }
          if (this.vennUser) {
            this.setVennUserDashboard();
          }
          this.getUserImage(user.AvatarId, user.name);
          this.collaboratorName = user.CollaboratorName;
          this.getCollaboratorImage(user.IconId, user.CollaboratorName);
          if (this.accessAdminConsole) {
            this.organisationName = user.OrganisationName;
            this.getOrganisationImage(
              user.OrganisationIconId,
              user.OrganisationName,
            );
          }
          if (this.accessEnablerConsole) {
            this.getEnablerImage(user.EnablerIconId, user.EnablerName);
          }
          if (
            this.accessAdminConsole ||
            this.accessCustodianConsole ||
            this.powaIndexUser ||
            this.playXSafeUser ||
            this.convertrUser ||
            this.vennUser
          ) {
            this.enablerAccessOnly = false;
          }
        });

      if (!this.enablerAccessOnly) {
        this.agentConfiguration();
        this.store
          .select(getAgentStatusState)
          .pipe(this.untilDestroyed())
          .subscribe((agentState: IAgentState) => {
            if (agentState !== null) {
              this.agentRunning = agentState.status;
            }
          });
      }
    }
  }

  private setPlayXSafeUserDashboard(): void {
    this.store.dispatch(new LoadPlayXSafeDashboard());
    this.store
      .select(getPlayXSafeUserCollaborationState)
      .subscribe((collaborations) => {
        if (collaborations) {
          this.playXSafeDashboards = collaborations.map(
            (collaboration: UserCollaborationsModel) => {
              return {
                value: collaboration.collaborationId,
                viewDashboard: collaboration.collaboration,
              };
            },
          );
        } else {
          return null;
        }
      });

    this.store
      .select(getDefaultPlayXSafeUserCollaborationId)
      .pipe(this.untilDestroyed())
      .subscribe((collaborationId: number) => {
        if (collaborationId) {
          this.collaborationId = collaborationId;
          this.store.dispatch(
            new SetCurrentPlayXSafeDashboard({
              currentCollaborationId: collaborationId,
            }),
          );
          this.selectedPlayXSafeDashboard = this.playXSafeDashboards
            ? this.playXSafeDashboards.find((c) => c.value === collaborationId)
              .viewDashboard
            : null;
        }
      });
  }

  private setConvertrUserDashboard(): void {
    this.store.dispatch(new LoadConvertrDashboard());
    this.store
      .select(getConvertrUserCollaborationState)
      .subscribe((collaborations) => {
        if (collaborations) {
          this.convertrDashboards = collaborations.map(
            (collaboration: UserCollaborationsModel) => {
              return {
                value: collaboration.collaborationId,
                viewDashboard: collaboration.collaboration,
              };
            },
          );
        } else {
          return null;
        }
      });

    this.store
      .select(getDefaultConvertrUserCollaborationId)
      .pipe(this.untilDestroyed())
      .subscribe((collaborationId: number) => {
        if (collaborationId) {
          this.collaborationId = collaborationId;
          this.store.dispatch(
            new SetCurrentConvertrDashboard({
              currentCollaborationId: collaborationId,
            }),
          );
        }
      });
  }

  private setVennUserDashboard(): void {
    this.store.dispatch(new LoadVennDashboard());
    this.store
      .select(getVennUserCollaborationState)
      .subscribe((collaborations) => {
        if (collaborations) {
          this.vennDashboards = collaborations.map(
            (collaboration: UserCollaborationsModel) => {
              return {
                value: collaboration.collaborationId,
                viewDashboard: collaboration.collaboration,
              };
            },
          );
        } else {
          return null;
        }
      });

    this.store
      .select(getDefaultVennUserCollaborationId)
      .pipe(this.untilDestroyed())
      .subscribe((collaborationId: number) => {
        if (collaborationId) {
          this.collaborationId = collaborationId;
          this.store.dispatch(
            new SetCurrentVennDashboard({
              currentCollaborationId: collaborationId,
            }),
          );
        }
      });
  }

  agentConfiguration() {
    this.agentService.loadAgentConfiguration();
    combineLatest([
      this.agentService
        .getAgentConfigurationState()
        .pipe(filter((config) => !!config)),
      timer(0, 10000),
    ])
      .pipe(this.untilDestroyed())
      .subscribe(() => {
        this.store.dispatch(new fromAgentAction.CheckAgentStatus());
        /* if (this.agentRunning) {
          this.store.dispatch(new ConnectToHub());
        } */
      });
  }

  getCollaboratorImage(iconImageId: number, alternativeName: string) {
    this.imagesService
      .getImage({
        imageId: iconImageId,
        alternativeName,
      })
      .pipe(this.untilDestroyed())
      .subscribe((res: ExtendedImageContainer) => {
        this.collaboratorIconContainer = res;
      });
  }

  getUserImage(iconImageId: number, alternativeName: string) {
    this.imagesService
      .getImage({
        imageId: iconImageId,
        alternativeName: alternativeName,
      })
      .pipe(this.untilDestroyed())
      .subscribe((res: ExtendedImageContainer) => {
        this.userIconContainer = res;
      });
  }

  getOrganisationImage(iconImageId: number, alternativeName: string) {
    this.imagesService
      .getImage({
        imageId: iconImageId,
        alternativeName: alternativeName,
      })
      .pipe(this.untilDestroyed())
      .subscribe((res: ExtendedImageContainer) => {
        this.organisationIconContainer = res;
      });
  }

  getEnablerImage(iconImageId: number, alternativeName: string) {
    this.imagesService
      .getImage({
        imageId: iconImageId,
        alternativeName,
      })
      .pipe(this.untilDestroyed())
      .subscribe((res: ExtendedImageContainer) => {
        this.enablerIconContainer = res;
      });
  }

  downloadFileManagementApp() {
    this.filesService.downloadFileManagementApp();
  }

  logout() {
    this.authService.logout();
  }

  adminConsole() {
    this.loadHeader();
    this.router.navigate(['admin-console']);
  }

  custodianConsole() {
    this.loadHeader();
    this.router.navigate(['custodian-console']);
  }

  powaIndexDashboard() {
    this.router.navigate(['powaindex/dashboard']);
  }

  playXSafeDashboard() {
    if (this.collaborationId) {
      this.router.navigate([
        `playXsafe/dashboard/${this.collaborationId}/overview`,
      ]);
    } else {
      this.router.navigate(['playXsafe/noCollaborationId']);
    }
  }

  convertrDashboard() {
    this.authService.authenticateWithCollaboratorId(
      this.currentUser.CollaboratorId,
      false,
    );
    this.router.navigate(['convertr/collaborations']);
  }

  public goToDashboard(option: Dashboard) {
    this.store.dispatch(
      new SetCurrentDashboard({ currentCollaborationId: option.value }),
    );
  }

  public vennDashboard() {
    this.authService.authenticateWithCollaboratorId(
      this.currentUser.CollaboratorId,
      false,
    );
    this.router.navigate(['venn/collaborations']);
  }

  public goToPlayXSafeDashboard(option: Dashboard) {
    this.store.dispatch(
      new SetCurrentPlayXSafeDashboard({
        currentCollaborationId: option.value,
      }),
    );
    this.router.navigate([`playXsafe/dashboard/${option.value}/overview`]);
  }

  // public goToConvertrDashboard(option: Dashboard) {
  //   this.store.dispatch(
  //     new SetCurrentConvertrDashboard({
  //       currentCollaborationId: option.value,
  //     }),
  //   );
  //   this.router.navigate([`convertr/dashboard/${option.value}/overview`]);
  // }

  // public goToVennDashboard(option: Dashboard) {
  //   this.store.dispatch(
  //     new SetCurrentVennDashboard({
  //       currentCollaborationId: option.value,
  //     }),
  //   );
  //   this.router.navigate([`venn/dashboard/${option.value}/overview`]);
  // }
}
