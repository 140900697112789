<form
  [formGroup]="modelForm"
  (submit)="submit()"
>
  <h1 mat-dialog-title>
    <mat-icon class="text-top"><i class="icon icon-agent"></i></mat-icon>
    Confirm passphrase
  </h1>
  <mat-dialog-content>
    <p
      *ngIf="sampleDataWarning"
      class="push-bottom-md"
    >
      {{ sampleDataWarning }}
    </p>
    <div class="alert alert-primary push-bottom-lg">
      <div class="flex flex-row justify-start align-center">
        <div class="pad-right">
          <mat-icon class="mat-primary">
            <i class="icon icon-agent" aria-hidden="true"></i>
          </mat-icon>
        </div>
        <div class="full-width">
          <strong> This function is performed client-side through the IXUP
            Encryption Gateway. </strong>
        </div>
      </div>
    </div>
    <p> Data Custodian and collaboration passphrases are required to access and
      work with collaboration data. </p>
    <mat-form-field
      appearance="outline"
      class="block push-top-lg pad-bottom-md"
    >
      <mat-label>{{ 'Data Custodian passphrase' | translate }}</mat-label>
      <input
        matInput
        type="password"
        formControlName="custodianPassphrase"
        [type]="hideDCPassphrase ? 'password' : 'text'"
        class="qa-ei-custodian-pw"
      />
      <button
        mat-icon-button
        matSuffix
        tabindex="-1"
        (click)="hideDCPassphrase = !hideDCPassphrase"
        [attr.aria-label]="'Hide password'"
        [attr.aria-pressed]="hideDCPassphrase"
        type="button"
        matTooltip="{{
          hideDCPassphrase
            ? 'Show Data Custodian passphrase'
            : 'Hide Data Custodian passphrase'
        }}"
        matTooltipClass="ixup-canvas-tooltip"
      >
        <mat-icon>{{ hideDCPassphrase ? 'visibility_off' : 'visibility'
          }}</mat-icon>
      </button>
      <mat-hint> The Data Custodian passphrase should be different to the
        Collaboration passphrase. </mat-hint>
      <mat-error *ngIf="modelForm.get('custodianPassphrase').hasError('required')">
        {{ 'The Data Custodian passphrase is required...' | translate }}
      </mat-error>
      <!-- <mat-error
        *ngIf="
          modelForm
            .get('custodianPassphrase')
            .hasError('custodianPassphraseIncorrect')
        "
        >{{
          'The data custodian passphrase is incorrect...' | translate
        }}</mat-error
      > -->
    </mat-form-field>
    <mat-divider class="push-bottom-lg"></mat-divider>
    <mat-form-field
      appearance="outline"
      class="block pad-bottom-md"
    >
      <mat-label>{{ 'Collaboration passphrase' | translate }}</mat-label>
      <input
        matInput
        type="password"
        formControlName="collaborationPassphrase"
        [type]="hideCPassphrase ? 'password' : 'text'"
        class="qa-ei-collaboration-pw"
      />
      <button
        mat-icon-button
        matSuffix
        tabindex="-1"
        (click)="hideCPassphrase = !hideCPassphrase"
        [attr.aria-label]="'Hide password'"
        [attr.aria-pressed]="hideCPassphrase"
        type="button"
        matTooltip="{{
          hideCPassphrase
            ? 'Show collaboration passphrase'
            : 'Hide collaboration passphrase'
        }}"
        matTooltipClass="ixup-canvas-tooltip"
      >
        <mat-icon>{{ hideCPassphrase ? 'visibility_off' : 'visibility'
          }}</mat-icon>
      </button>
      <mat-hint> The Collaboration passphrase should be different to the Data
        Custodian passphrase. </mat-hint>
      <mat-error *ngIf="modelForm.get('collaborationPassphrase').hasError('required')">
        {{ 'The collaboration passphrase is required...' | translate }}
      </mat-error>
    </mat-form-field>

  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button
      mat-button
      (click)="cancel()"
      class="btn-rounded"
      type="button"
    >
      {{ 'Cancel' | translate }}
    </button>
    <button
      mat-stroked-button
      color="primary"
      class="btn-rounded"
      type="submit"
      [disabled]="modelForm.invalid"
    >
      <i class="icon icon-agent" aria-hidden="true"></i>
      {{ 'Confirm' | translate }}
    </button>
  </mat-dialog-actions>
</form>
