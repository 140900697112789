import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of as observableOf, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';

import { ResultModelGeneric } from '../../model/result.model';
import { AgentService } from '../../services/agent/agent.service';
import { AuthService } from '../../services/auth/AuthService';
import { CollaboratorService } from '../../services/collaborator/collaborator.service';
import { ToasterService } from '../../services/toaster.service';
import { BaseEffects } from '../baseEffects';
import { IAgentConfigurationModel } from '../model/agent-configuration';
import { IDefaultLocationsModel } from '../model/default-location.model';
import {
  AgentActions,
  AgentActionTypes,
  CheckAgentStatusFailed,
  CheckAgentStatusSuccess,
  CheckLocationsIsValid,
  CheckLocationsIsValidFailed,
  CheckLocationsIsValidSuccess, LoadAgentConfigurationFailed,
  LoadAgentConfigurationSuccess,
  SetCustodianPassphrase,
  SetCustodianPassphraseFailed,
  SetCustodianPassphraseSuccess,
  UpdateAgentConfiguration,
  UpdateAgentConfigurationFailed,
  UpdateAgentConfigurationSuccess,
  UpdateAgentConfigurationWithChecks
} from './agent-action';

@Injectable()
export class AgentEffects extends BaseEffects {
  
  GetAgentConfiguration$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(AgentActionTypes.LoadAgentConfiguration),
    mergeMap(() =>
      this.agentService.getAgentConfiguration().pipe(
        switchMap(
          (
            AgentConfiguration: ResultModelGeneric<IAgentConfigurationModel>,
          ) => {
            if (AgentConfiguration.status) {
              return [
                new LoadAgentConfigurationSuccess({
                  AgentConfiguration: AgentConfiguration.data,
                }),
              ];
            }
          },
        ),
        catchError(() => {
          this.showError('Error loading agent configuration.');
          return of(new LoadAgentConfigurationFailed());
        }),
      ),
    ),
  ));

  
  UpdateAgentConfiguration$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(AgentActionTypes.UpdateAgentConfiguration),
    mergeMap((action: UpdateAgentConfiguration) =>
      this.agentService
        .updateAgentConfiguration(action.payload.agentConfiguration)
        .pipe(
          switchMap(
            (
              AgentConfiguration: ResultModelGeneric<IAgentConfigurationModel>,
            ) => {
              if (AgentConfiguration.status) {
                this.showMessage('Encryption Gateway configuration updated.');
                return [
                  new UpdateAgentConfigurationSuccess({
                    agentConfiguration: action.payload.agentConfiguration,
                  }),
                ];
              }
            },
          ),
          tap(() => this.router.navigate(['/'])),
          catchError((e) => {
            this.showError(e);
            return of(
              new UpdateAgentConfigurationFailed({
                AgentConfiguration: action.payload.agentConfiguration,
              }),
            );
          }),
        ),
    ),
  ));

  
  checkStatusEffects$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(AgentActionTypes.CheckAgentStatus),
    mergeMap(() =>
      this.agentService.checkAgentStatus().pipe(
        map(() => {
          return new CheckAgentStatusSuccess({
            lastCheckedOn: new Date(),
            status: true,
            agentConfiguration: null,
          });
        }),

        catchError(() => {
          return observableOf(
            new CheckAgentStatusFailed({
              lastCheckedOn: new Date(),
              status: false,
              agentConfiguration: null,
            }),
          );
        }),
      ),
    ),
  ));

  
  updateAgentConfigurationWithCheckEffects$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AgentActionTypes.UpdateAgentConfigurationWithChecks),
      mergeMap((action: UpdateAgentConfigurationWithChecks) =>
        this.agentService
          .checkLocationsIsValid(action.payload.agentConfiguration)
          .pipe(
            switchMap((defaultLocations: IDefaultLocationsModel) => {
              if (
                defaultLocations.downloadLocationIsValid &&
                defaultLocations.uploadLocationIsValid &&
                action.payload.onSave
              ) {
                return [
                  new UpdateAgentConfiguration({
                    agentConfiguration: action.payload.agentConfiguration,
                  }),
                  new CheckLocationsIsValidSuccess({
                    defaultLocations,
                  }),
                ];
              }
              return [
                new CheckLocationsIsValidSuccess({
                  defaultLocations,
                }),
              ];
            }),

            catchError(() => {
              return [
                new CheckLocationsIsValidFailed({
                  defaultLocations: {
                    uploadLocation:
                      action.payload.agentConfiguration.uploadLocation,
                    downloadLocation:
                      action.payload.agentConfiguration.downloadLocation,
                  },
                }),
              ];
            }),
          ),
      ),
    ));

  
  checkLocationsIsValidEffects$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(AgentActionTypes.CheckLocationsIsValid),
    mergeMap((action: CheckLocationsIsValid) =>
      this.agentService
        .checkLocationsIsValid(action.payload.agentConfiguration)
        .pipe(
          switchMap((defaultLocations: IDefaultLocationsModel) => {
            if (
              defaultLocations.downloadLocationIsValid &&
              defaultLocations.uploadLocationIsValid &&
              action.payload.onSave
            ) {
              return [
                new UpdateAgentConfiguration({
                  agentConfiguration: action.payload.agentConfiguration,
                }),
                new CheckLocationsIsValidSuccess({
                  defaultLocations,
                }),
              ];
            }
            return [
              new CheckLocationsIsValidSuccess({
                defaultLocations,
              }),
            ];
          }),

          catchError(() => {
            return [
              new CheckLocationsIsValidFailed({
                defaultLocations: {
                  uploadLocation:
                    action.payload.agentConfiguration.uploadLocation,
                  downloadLocation:
                    action.payload.agentConfiguration.downloadLocation,
                },
              }),
            ];
          }),
        ),
    ),
  ));

  
  SetCustodianPassphrase$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(AgentActionTypes.SetCustodianPassphrase),
    mergeMap((action: SetCustodianPassphrase) => {
      return this.collaboratorService
        .setCustodianPassphrase(
          action.payload.keyModel,
          action.payload.agentBaseUrl,
        )
        .pipe(
          map(() => {
            this.authService.refreshToken();
            return new SetCustodianPassphraseSuccess();
          }),
          catchError(() => {
            this.showError('Error setting custodian passphrase.');
            return of(new SetCustodianPassphraseFailed());
          }),
        );
    }),
  ));

  constructor(
    private actions$: Actions<AgentActions>,
    private authService: AuthService,
    private agentService: AgentService,
    private collaboratorService: CollaboratorService,
    private router: Router,
    toasterService: ToasterService,
  ) {
    super(toasterService);
  }
}
