import { Action } from '@ngrx/store';

import { IApiParameters } from '../services/audit-log.service';
import { IAuditLogState } from './audit-log.state';

export const GET_AUDITLOG = '[AuditLog] GET_AUDITLOG';
export const GET_AUDITLOG_SUCCESS = '[AuditLog] GET_AUDITLOG_SUCCESS';
export const GET_AUDITLOG_ERROR = '[AuditLog] GET_AUDITLOG_ERROR';

export const DOWNLOAD_AUDITLOG = '[AuditLog] DOWNLOAD';
export const DOWNLOAD_AUDITLOG_SUCCESS = '[AuditLog] DOWNLOAD_SUCCESS';

export const DOWNLOAD_RESET = '[AuditLog] DOWNLOAD_RESET';

export class GetAuditLog implements Action {
  readonly type = GET_AUDITLOG;
  constructor(public payload: IApiParameters) {}
}

export class GetAuditLogSuccess implements Action {
  readonly type = GET_AUDITLOG_SUCCESS;
  constructor(public payload: IAuditLogState) {}
}

export class GetAuditLogError implements Action {
  readonly type = GET_AUDITLOG_ERROR;
}

export class Download implements Action {
  readonly type = DOWNLOAD_AUDITLOG;
  constructor(public payload: IApiParameters) {}
}

export class DownloadSuccess implements Action {
  readonly type = DOWNLOAD_AUDITLOG_SUCCESS;
  constructor(public payload: string) {}
}

export class DownloadReset implements Action {
  readonly type = DOWNLOAD_RESET;
}

export type All =
  | GetAuditLog
  | GetAuditLogSuccess
  | GetAuditLogError
  | Download
  | DownloadSuccess
  | DownloadReset;
