<div class="wb-container mat-container">
  <div class="wb-hero-bg bg-primary"></div>
  <div class="wb-hero-page">
    <div class="mat-header wb-hero-header" style="min-height:200px;">
      <div class="mat-header-title">
        <h1 class="push-top-lg" style="color:#ffffff;">Welcome!</h1>
        <h4 style="color:#ffffff; font-weight:300">For security, we need to sign you in with a client certificate.</h4>
        <div style="margin-bottom: 100px"></div>
        <div *ngIf="!showAppDownloadButton">
          <div><button mat-button color="primary" type="button" (click)="onUseExistingCert()">{{ 'I have an IXUP CLIENT certificate on this computer' | translate }}</button></div>
          <div><button mat-button color="primary" type="button" (click)="onGetNewCert()">{{ 'I need to get a new certificate' | translate }}</button></div>
        </div>
        <div *ngIf="showAppDownloadButton">
          <h5 style="color: Red" *ngIf="showPingFailed">You haven't presented a valid certificate.</h5>
          <h5>Follow these steps to get your IXUP CLIENT certificate.</h5>
          <button mat-button color="primary" (click)="downloadClientManager()" type="button">
            <mat-icon>file_download</mat-icon>{{ 'Download IXUP Client Manager' | translate }}
          </button>
          <a aria-label="download" fileDownloadAnchor download="IxupClientManagerSetup.msi"></a>
          <ul>
            <li>Run the setup utility, then launch the client manager using the shortcut placed on your desktop</li>
            <li>Sign In to client manager using your IXUP login details</li>
            <li><b>Close all browser tabs and windows</b>, then re-open and navigate back to IXUP</li>
            <li>Click "I have an IXUP CLIENT certificate"</li>
            <li>When the "Select a certificate" dialog pops up, select IXUP CLIENT and click OK</li>
          </ul>
          <div><button mat-button color="primary" type="button" (click)="onUseExistingCert()">{{ 'Try again to sign in with certificate' | translate }}</button></div>
        </div>
      </div>
    </div>
  </div>
</div>
