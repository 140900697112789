import {
  Component,
  EventEmitter,
  Input,
  OnChanges, Output
} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup
} from '@angular/forms';

import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import {
  MatLegacyAutocompleteModule,
  MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent
} from '@angular/material/legacy-autocomplete';
import { MatLegacyOptionModule } from '@angular/material/legacy-core';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AuthUserModel } from '../../../model/authuser.model';
import { AuthService } from '../../../services/auth/AuthService';
import {
  ActivityType,
  LoggingService
} from '../../../services/logging/logging.service';
import { TranslatePipe } from '../../../services/translate/translate.pipe';
import { IDropdownModel } from '../model/user-dropdown.model';

@Component({
  selector: 'ixup-add-existing-user',
  templateUrl: './add-existing-user.component.html',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgIf,
    MatLegacyFormFieldModule,
    MatLegacyInputModule,
    MatLegacyAutocompleteModule,
    MatButtonModule,
    MatIconModule,
    NgFor,
    MatLegacyOptionModule,
    AsyncPipe,
    TranslatePipe,
  ],
})
export class AddExistingUserComponent implements OnChanges {
  modelForm: UntypedFormGroup;
  selectedUsers: AuthUserModel[] = [];
  submitted = false;
  currentUser: AuthUserModel;
  public filteredUsers: Observable<IDropdownModel[]>;

  @Input() organisationId: number;
  @Input() organisationUsersList: IDropdownModel[];
  @Output() selectedExistingUser = new EventEmitter<number | string>();
  display: boolean;

  constructor(
    fb: UntypedFormBuilder,
    private ixupLoggerService: LoggingService,
    private authService: AuthService,
  ) {
    this.authService.currentUser$.subscribe((user) => {
      this.currentUser = user;
    });
    this.modelForm = fb.group({
      selectedUser: new UntypedFormControl(''),
    });
  }

  ngOnChanges(): void {
    if(this.organisationUsersList) {
      this.filteredUsers = this.modelForm.controls[
        'selectedUser'
      ].valueChanges.pipe(
        startWith(''),
        map((value) => {
        // this.modelForm.reset();
          const name = typeof value === 'string' ? value : value?.name;
          return name ? this._filter(name as string) : this.organisationUsersList.slice();
        }),
      );
    }
    this.ixupLoggerService.log(ActivityType.pageVisit, '', '');
    
  }

  displayFn(user: IDropdownModel): string {
    return user && user.label ? user.label : '';
  }

  private _filter(name: string): IDropdownModel[] {
    const filterValue = name.toLowerCase();
    return this.organisationUsersList.filter((option) =>
      option.label.toLowerCase().includes(filterValue),
    );
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.selectedExistingUser.emit(event.option.value);
    this.display = false;
  }

  clearSelection(): void {
    this.selectedExistingUser.emit('');
    this.display = false;
  }

  clear(): void {
    this.modelForm.get('selectedUser').setValue('');
    this.selectedExistingUser.emit('');

    this.display = false;
  }

}
