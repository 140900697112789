import './polyfills.ts';

import { enableProdMode, importProvidersFrom } from '@angular/core';

import { OverlayModule } from '@angular/cdk/overlay';
import { DatePipe } from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi
} from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';

import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { bootstrapApplication, BrowserModule } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withPreloading } from '@angular/router';
import { JwtModule } from '@auth0/angular-jwt';
import { MsalModule, MsalService, MSAL_INSTANCE } from '@azure/msal-angular';
import { jsPlumbToolkitModule } from '@jsplumbtoolkit/browser-ui-angular';
import {
  FullRouterStateSerializer,
  StoreRouterConnectingModule
} from '@ngrx/router-store';
import { AppComponent } from './app/app.component';
import {
  CLIENT_API_ENDPOINT,
  FILE_DROP_ALLOWED_EXTENSIONS,
  FILE_DROP_MAX_FILE_SIZE_BYTES,
  MSALInstanceFactory
} from './app/app.module';
import { APP_ROUTES } from './app/app.routing';
import { AppHeaderModule } from './app/components/app-header/app-header.module';
import { CLIENT_MANAGER_URL } from './app/components/certify/certify.component';
import { InactiveAccountModule } from './app/components/inactive-account/inactive-account.module';
import { NoRoleAccountModule } from './app/components/norole-account/norole-account.module';
import { NotFoundModule } from './app/components/not-found/not-found.module';
import { SharedModule } from './app/components/shared/shared.module';
import { StyleManager } from './app/components/shared/style-manager/style-manager';
import { CustomPreloadingStrategy } from './app/custom-preLoading-strategy';
import { CertifyGuard } from './app/guards/certify.guard';
import { LoggedInGuard } from './app/guards/loggedIn.guard';
import { IxupHttpInterceptor } from './app/http/http-interceptor.service';
import { AuditLogService } from './app/pages/audit-log/services/audit-log.service';
import { CanvasService } from './app/pages/canvas/services/canvas.service';
import { ConvertrService } from './app/pages/convertr/service/convertr.service';
import { LoginModule } from './app/pages/login/login.module';
import { VennService } from './app/pages/venn/service/venn.service';
import { PipeModule } from './app/pipe.module';
import { AgentService } from './app/services/agent/agent.service';
import { AuthService } from './app/services/auth/AuthService';
import { LandingPageService } from './app/services/auth/landing-page.service';
import { PagePermissionService } from './app/services/auth/page-permission.service';
import { UserFactoryService } from './app/services/auth/user-factory.service';
import { CollaborationService } from './app/services/collaboration/collaboration.service';
import { EmitterService } from './app/services/emitter.service';
import { FilesService } from './app/services/files/files.service';
import { HubConnectionService } from './app/services/hub/hub.services';
import { LoggingService } from './app/services/logging/logging.service';
import {
  PingService,
  REQUIRE_CLIENT_CERT
} from './app/services/ping/ping.service';
import { ImagesService } from './app/services/shared/Images.service';
import { SignalRService } from './app/services/signalR/signalR.service';
import { SiteStatus } from './app/services/siteStatus.service';
import { ToasterService } from './app/services/toaster.service';
import { TranslateService } from './app/services/translate/translate.service';
import { TRANSLATION_PROVIDERS } from './app/services/translate/translations';
import { StateModule } from './app/state/state.module';
import { environment } from './environments/environment';
import { API_BASE_URL} from './environments/environment-model';
import { PlayXSafeService } from 'app/pages/playxsafe/service/playxsafe.service';
import { MatDialogModule } from '@angular/material/dialog';

if (environment.production) {
  enableProdMode();
}
bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      JwtModule.forRoot({
        config: {
          tokenGetter: () => '',
        },
      }),
      MsalModule,
      BrowserModule,
      LoginModule,
      MatIconModule,
      MatCardModule,
      MatInputModule,
      MatMenuModule,
      MatButtonModule,
      NotFoundModule,
      MatSidenavModule,
      MatFormFieldModule,
      OverlayModule,
      PipeModule,
      MatDialogModule,
      MatSnackBarModule,
      MatListModule,
      MatTooltipModule,
      MatToolbarModule,
      InactiveAccountModule,
      NoRoleAccountModule,
      FormsModule,
      AppHeaderModule,
      StateModule.forRoot(),
      SharedModule,
      StoreRouterConnectingModule.forRoot({
        serializer: FullRouterStateSerializer,
        /*
              They stateKey defines the name of the state used by the router-store reducer.
              This matches the key defined in the map of reducers
            */
        stateKey: 'router',
      }),
      jsPlumbToolkitModule,
    ),
    { provide: API_BASE_URL, useValue: environment.webApiEndpoint },
    { provide: CLIENT_MANAGER_URL, useValue: environment.clientManagerUrl },
    { provide: REQUIRE_CLIENT_CERT, useValue: environment.requireClientCert },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: IxupHttpInterceptor,
      multi: true,
    },
    // { provide: Http, useClass: ExtendedHttpService },
    {
      provide: FILE_DROP_ALLOWED_EXTENSIONS,
      useValue: environment.fileDrop.allowedExtensions,
    },
    {
      provide: FILE_DROP_MAX_FILE_SIZE_BYTES,
      useValue: environment.fileDrop.maxFileSizeBytes,
    },
    { provide: CLIENT_API_ENDPOINT, useValue: environment.clientApiEndpoint },
    PingService,
    AuthService,
    DatePipe,
    CertifyGuard,
    LoggedInGuard,
    TRANSLATION_PROVIDERS,
    TranslateService,
    LoggingService,
    EmitterService,
    ImagesService,
    ToasterService,
    LoggingService,
    FilesService,
    StyleManager,
    AuditLogService,
    CanvasService,
    SignalRService,
    AgentService,
    HubConnectionService,
    CollaborationService,
    PlayXSafeService,
    ConvertrService,
    VennService,
    CustomPreloadingStrategy,
    SiteStatus,
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    MsalService,
    LandingPageService,
    UserFactoryService,
    PagePermissionService,
    provideRouter(APP_ROUTES, withPreloading(CustomPreloadingStrategy)),
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations(),
  ],
});
