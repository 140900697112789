import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'credits',
  standalone: true,
})
export class CreditsPipe implements PipeTransform {
  constructor(private decimalPipe: DecimalPipe) {}
  
  transform(value: number): string {
    if (typeof value === 'number') {
      return this.decimalPipe.transform(value, '1.2-2');
    }
  }
}
