<div
  [@animate]
  class="pad-left-lg pad-right-lg push-bottom ixup-convertr-lettering ixup-tab-links-convertr"
>
  <mat-tab-nav-panel #tabPanel>
    <nav
      mat-tab-nav-bar
      mat-stretch-tabs="false"
      mat-align-tabs="start"
      [tabPanel]="tabPanel"
    >
      <a
        *ngIf="convertr"
        mat-tab-link
        [routerLink]="['/convertr/dashboard/' + collaborationId + '/database']"
        routerLinkActive
        #rla1="routerLinkActive"
        [active]="rla1.isActive"
        [class.mat-tab-label-active]="rla1.isActive"
      >
        <span mat-line>
          Database
        </span>
      </a>
      <a
        *ngIf="convertr"
        [disabled]="!states.insights"
        mat-tab-link
        [routerLink]="['/convertr/dashboard/' + collaborationId + '/insights']"
        routerLinkActive
        #rla0="routerLinkActive"
        [active]="rla0.isActive"
        [class.mat-tab-label-active]="!states.insights"
      >
        <span mat-line>
          Insights
        </span>
      </a>
      <a
        *ngIf="convertr"
        [disabled]="!states.segments"
        mat-tab-link
        [routerLink]="['/convertr/dashboard/' + collaborationId + '/segments']"
        routerLinkActive
        #rla2="routerLinkActive"
        [active]="rla2.isActive"
        [class.mat-tab-label-active]="!states.segments"
      >
        <span mat-line>
          Segments
        </span>
      </a>
    </nav>
  </mat-tab-nav-panel>
  <div class="flex">
  </div>
</div>