import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import {
  ActionReducer,
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
  MetaReducer
} from '@ngrx/store';
import { environment } from '../../environments/environment';
import * as fromAuditLogReducer from '../pages/audit-log/state/audit-log.reducer';
import * as fromAgentReducer from './agent/agent-reducer';
import { IAppState } from './app.interfaces';
import * as fromConvertrDashboardReducer from './dashboard-convertr/reducer';
import * as fromVennDashboardReducer from './dashboard-venn/reducer';
import * as fromDashboardReducer from './dashboard/reducer';
import * as fromHubReducer from './hub/hub-reducer';
import * as fromOrganisationReducer from './organisation/reducer';
import * as fromPlayXSafeDashboardReducer from './playXsafe-dashboard/reducer';
import { RouterStateUrl } from './shared/utils';
import * as fromSignalRReducer from './signalR/signalR.reducer';
import { SigningDocumentsReducer } from './signing-documents/signing-documents-reducer';
import * as fromUserReducer from './user/user-reducer';

export const appReducer: ActionReducerMap<IAppState> = {
  router: routerReducer,
  signalR: fromSignalRReducer.SignalRReducer,
  auditlog: fromAuditLogReducer.AuditLogReducer,
  agent: fromAgentReducer.AgentReducer,
  hubConnection: fromHubReducer.HubReducer,
  user: fromUserReducer.UserReducer,
  dashboard: fromDashboardReducer.DashboardReducer,
  playXSafeDashboard: fromPlayXSafeDashboardReducer.PlayXSafeDashboardReducer,
  convertrDashboard: fromConvertrDashboardReducer.ConvertrDashboardReducer,
  vennDashboard: fromVennDashboardReducer.VennDashboardReducer,
  organisation: fromOrganisationReducer.organisationReducer,
  signingDocuments: SigningDocumentsReducer,
};

export function logger(
  reducer: ActionReducer<IAppState>,
): ActionReducer<IAppState> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return function (state: IAppState, action: any): IAppState {
    return reducer(state, action);
  };
}

export const appMetaReducers: Array<MetaReducer<IAppState>> =
  !environment.production ? [logger] : [];

// Reducer selectors
export const selectReducerState =
  createFeatureSelector<RouterReducerState<RouterStateUrl>>('router');

export const getRouterInfo = createSelector(selectReducerState, (state) => {
  return state && state.state;
});

export const getRouterUrl = createSelector(
  getRouterInfo,
  (state) => state && state.url,
);

export const getRouterUrlRootSection = createSelector(getRouterUrl, (url) => {
  if (url) {
    const splits = url.split('/');
    if (splits.length > 1) {
      return splits[1];
    }
  }
  return null;
});

export const getRouterUrlIdSection = createSelector(getRouterUrl, (url) => {
  if (url) {
    const splits = url.split('/');
    if (splits.length > 1) {
      return splits[3];
    }
  }
  return null;
});

export const getRouterUrlTailSection = createSelector(getRouterUrl, (url) => {
  if (url) {
    const splits = url.split('/');
    if (splits.length > 1) {
      const lastSplits = splits[splits.length - 2];
      if (lastSplits === 'batches') {
        return 'tables';
      }
      return splits[splits.length - 1];
    }
  }
  return null;
});
