<a
  mat-list-item
  [routerLink]="['/enabler-console/organisations']"
  [routerLinkActive]="['mat-active']"
>
  <mat-icon
    matListIcon
    [matTooltip]="!miniNav ? '' : 'Organisations'"
    matTooltipPosition="after"
    matTooltipClass="ixup-canvas-tooltip"
  >
    <span class="icon-organisations"></span>
  </mat-icon>
  <span mat-line *ngIf="!miniNav">Organisations</span>
</a>
<a
  mat-list-item
  [routerLink]="['/enabler-console/audit']"
  [routerLinkActive]="['mat-active']"
>
  <mat-icon
    matListIcon
    [matTooltip]="!miniNav ? '' : 'Audit Log'"
    matTooltipPosition="after"
    matTooltipClass="ixup-canvas-tooltip"
  >
    <span class="icon-audits"></span>
  </mat-icon>
  <span mat-line *ngIf="!miniNav">Audit Log</span>
</a>
