import { Component, EventEmitter, Output } from '@angular/core';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { DataProtectionService } from '../../../../../services/data-protection/data-protection.service';
import { TranslatePipe } from '../../../../../services/translate/translate.pipe';

@Component({
  selector: 'ixup-data-protection-passphrase-prompt',
  templateUrl: 'data-protection-passphrase-prompt.component.html',
  standalone: true,
  imports: [MatIconModule, MatButtonModule, TranslatePipe],
})
export class DataProtectionPassphrasePromptComponent {
  password: string;
  @Output() isValid = new EventEmitter<boolean>();
  constructor(private dataProtectionService: DataProtectionService) {}

  checkPassword() {
    this.dataProtectionService.passphrase$.subscribe((password) => {
      this.password = password;
      if (this.password) {
        this.isValid.emit(true);
      } else {
        this.isValid.emit(false);
      }
    });
  }
}
