import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { TranslatePipe } from '../../services/translate/translate.pipe';

@Component({
  selector: 'not-found',
  templateUrl: './not-found.component.html',
  standalone: true,
  imports: [MatIconModule, MatButtonModule, TranslatePipe],
})
export class NotFoundComponent {
  constructor(private location: Location) {}
  cancel() {
    this.location.back();
  }
}
