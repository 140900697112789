import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IAuditLogState } from './audit-log.state';

const getAuditLogState = createFeatureSelector<IAuditLogState>('audit');

export const getAuditLog = createSelector(getAuditLogState, (state) => state);

export const getSearchPage = createSelector(getAuditLog, (state) => state?.searchPage);

export const getAuditLogs = createSelector(getSearchPage, (state) => state?.auditlogs);

export const getAuditLogsLoading = createSelector(getSearchPage, (state) => state?.loading);
export const getAuditLogsDownloading = createSelector(getSearchPage, (state) => state?.downloading);
export const getBlobUrl = createSelector(getSearchPage, (state) => state?.blobUrl);
