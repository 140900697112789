import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PlayXSafeDashboardState } from './reducer';

export const playXSafeDashboardState =
  createFeatureSelector<PlayXSafeDashboardState>('playXSafeDashboard');

export const getPlayXSafeDashboardState = createSelector(playXSafeDashboardState, (state) => state);

export const getPlayXSafeCollaboratorOverviewState = createSelector(
  playXSafeDashboardState,
  (state) => state.overview,
);

export const getPlayXSafeUserCollaborationState = createSelector(
  getPlayXSafeDashboardState,
  (state) => state.collaborations,
);

export const getDefaultPlayXSafeUserCollaborationId = createSelector(
  getPlayXSafeUserCollaborationState,
  (state) => {
    return state ? state[0]?.collaborationId : null;
  },
);

export const getCurrentPlayXSafeCollaborationId = createSelector(
  getPlayXSafeDashboardState,
  (state) => {
    return state ? state.currentDashboardCollaborationId : null;
  },
);

export const getCurrentCanvasId = (collaborationId: number) =>
  createSelector(getPlayXSafeUserCollaborationState, (state) => {
    return state
      ? state.find((collaboration) => collaboration.collaborationId === collaborationId)?.canvasId
      : null;
  });

export const getDashboardStatus = createSelector(getPlayXSafeDashboardState, (state) => {
  return state ? state.currentStatus : null;
});

export const getDataOwner = createSelector(getPlayXSafeDashboardState, (state) => {
  return state ? state.dataOwner : false;
});
