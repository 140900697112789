import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DashboardState } from './reducer';

export const dashboardState = createFeatureSelector<DashboardState>('dashboard');

export const getDashboardState = createSelector(dashboardState, (state) => state);

export const getUserCollaborationState = createSelector(
  getDashboardState,
  (state) => state.collaborations,
);

export const getDefaultUserCollaborationId = createSelector(getUserCollaborationState, (state) => {
  return state ? state[0]?.collaborationId : null;
});

export const getCurrentCollaborationId = createSelector(getDashboardState, (state) => {
  return state ? state.currentDashboardCollaborationId : null;
});

export const getCurrentCanvasId = (collaborationId: number) =>
  createSelector(getUserCollaborationState, (state) => {
    return state
      ? state.find((collaboration) => collaboration.collaborationId === collaborationId)?.canvasId
      : null;
  });

export const getDashboardStatus = createSelector(getDashboardState, (state) => {
  return state ? state.currentStatus : null;
});

export const getDataOwner = createSelector(getDashboardState, (state) => {
  return state ? state.dataOwner : false;
});
