import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ResultModelGeneric } from '../../model/result.model';
import { IConsoleEnablerModel } from '../../pages/consoles/model/console-enabler.model';
import { IConsoleOrganisationModel } from '../../pages/consoles/model/console-organisation.model';
import { IConsoleUserModel } from '../../pages/consoles/model/console-user.model';
import { IxupBaseService } from '../ixup-base.service';

@Injectable()
export class EnablerService extends IxupBaseService {
  constructor(
    @Inject(HttpClient) private http: HttpClient,
  ) {
    super();
  }

  getAllOrganisationsForEnabler(): Observable<
    ResultModelGeneric<IConsoleOrganisationModel[]>
    > {
    return this.resolveErrorGeneric<IConsoleOrganisationModel[]>(
      this.http.get('/api/enabler/organisations')
    );
  }

  createOrganisation(organisation: IConsoleOrganisationModel) {
    return this.resolveErrorGeneric<IConsoleOrganisationModel>(
      this.http.post('/api/organisation/create', organisation)
    );
  }

  getEnabler(
    enabler: number
  ): Observable<ResultModelGeneric<IConsoleEnablerModel>> {
    return this.resolveErrorGeneric<IConsoleEnablerModel>(
      this.http.get(`/api/enabler/${enabler}`)
    );
  }

  updateEnabler(
    enabler: IConsoleEnablerModel
  ): Observable<ResultModelGeneric<IConsoleEnablerModel>> {
    return this.resolveErrorGeneric<IConsoleEnablerModel>(
      this.http.post('/api/enabler/update', enabler)
    );
  }

  createUser(
    user: IConsoleUserModel
  ): Observable<ResultModelGeneric<IConsoleUserModel>> {
    const mappedUser = {
      ...user,
      roles: user.roles.map(r => r.id)
    };

    return this.resolveErrorGeneric<IConsoleUserModel>(
      this.http.post('/api/enabler/createUser', mappedUser)
    );
  }

  updateUser(
    user: IConsoleUserModel
  ): Observable<ResultModelGeneric<IConsoleUserModel>> {
    return this.resolveErrorGeneric<IConsoleUserModel>(
      this.http.post('/api/enabler/updateUser', user)
    );
  }

  public getUsers(
    enablerId: number
  ): Observable<ResultModelGeneric<IConsoleUserModel[]>> {
    return this.resolveErrorGeneric<IConsoleUserModel[]>(
      this.http.get(`/api/enabler/${enablerId}/users`)
    );
  }

  deleteOrganisation(organisationId: number) {
    return this.resolveErrorGeneric<number>(
      this.http.post('/api/organisation/delete', organisationId)
    );
  }

  public isEnablerNameUnique(
    name: string
  ): Observable<ResultModelGeneric<boolean>> {
    return this.resolveErrorGeneric<boolean>(
      this.http.get(`/api/enabler/${name}/isUnique`)
    );
  }
}
