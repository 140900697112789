<div>
  <div
    @animate
    *ngIf="showSpinner"
    title="{{monitor | async}}"
  >
    <div class="pad push">
      <mat-progress-spinner
        class="mat-spinner-container"
        mode="indeterminate"
        color="accent"
        [diameter]="40"
      ></mat-progress-spinner>
    </div>
  </div>
  <div
    *ngIf="!showSpinner"
    @animate
  >
    <ng-content></ng-content>
  </div>
</div>