import { Action } from '@ngrx/store';
import { IVersionSigningModel, StatusEnum } from '../../model/version-signing.model';


export enum SigningDocumentsActionTypes {
  GetSigningDocuments = '[SigingDocuments] Get Signing Documentss By OrganisationId',
  GetSigningDocumentsSuccess = '[SigingDocuments] Get Signing Documents Success',
  GetSigningDocumentsFailed = '[SigingDocuments] Get Signing Documents Failed',
  UpdateSigningDocumentStatus = '[SigingDocuments] Update Signing Documents Status',
  UpdateSigningDocumentStatusSuccess = '[SigingDocuments] Update Signing Documents Status Success',
  UpdateSigningDocumentStatusFailed = '[SigingDocuments] Update Signing Documents Status Failed',
}


export class UpdateSigningDocumentStatus implements Action {
  readonly type = SigningDocumentsActionTypes.UpdateSigningDocumentStatus;

  constructor(public payload: { versionId: number, status: StatusEnum }) {}
}


export class UpdateSigningDocumentStatusSuccess implements Action {
  readonly type = SigningDocumentsActionTypes.UpdateSigningDocumentStatusSuccess;

  constructor(public payload: { versionId: number, status: StatusEnum }) {}
}


export class UpdateSigningDocumentStatusFailed implements Action {
  readonly type = SigningDocumentsActionTypes.UpdateSigningDocumentStatusFailed;
}

export class GetSigningDocuments implements Action {
  readonly type = SigningDocumentsActionTypes.GetSigningDocuments;
}

export class GetSigningDocumentsSuccess implements Action {
  readonly type = SigningDocumentsActionTypes.GetSigningDocumentsSuccess;

  constructor(public payload: { signingDocuments: IVersionSigningModel[] }) {}
}

export class GetSigningDocumentsFailed implements Action {
  readonly type = SigningDocumentsActionTypes.GetSigningDocumentsFailed;
}


export type SigningDocumentsActions =
  | UpdateSigningDocumentStatus
  | UpdateSigningDocumentStatusSuccess
  | UpdateSigningDocumentStatusFailed
  | GetSigningDocuments
  | GetSigningDocumentsSuccess
  | GetSigningDocumentsFailed;
