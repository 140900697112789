import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';

import { ResultModelGeneric } from '../../model/result.model';

import {
  CampaignModel,
  inProgressEnumConverter,
} from '../../pages/venn/model/campaign.model';
import { VennService } from '../../pages/venn/service/venn.service';
import { IOutputTableModel } from '../../pages/output-tables/models/output-table.model';
import { LoadOutputTablesFailed } from '../../pages/output-tables/state/output-tables.actions';
import { CollaborationService } from '../../services/collaboration/collaboration.service';
import { ToasterService } from '../../services/toaster.service';
import { BaseEffects } from '../baseEffects';
import { CanvasActionChart } from '../model/canvas-action-chart';
import { TableStateEnum } from '../model/table-state';
import {
  DashboardActionTypes,
  GetChartDetails,
  GetChartDetailsFailed,
  GetChartDetailsSuccess,
  GetVennDashboard,
  GetVennDashboardFailed,
  GetVennDashboardSuccess,
  LoadVennDashboardFailed,
  LoadVennDashboardSuccess,
  LoadSegments,
  LoadSegmentsSuccess,
  RunCanvasRequest,
  RunCanvasRequestFailed,
  RunCanvasRequestSuccess,
  SetCurrentVennDashboardSuccess,
  SubmitForMatching,
  SubmitForMatchingFailed,
  SubmitForMatchingSuccess,
  UpdateVennApprovalStatus,
  UpdateVennApprovalStatusFailed,
  UpdateVennApprovalStatusSuccess,
  UpdateVennLastExported,
  UpdateVennLastExportedFailed,
  UpdateVennLastExportedSuccess,
  UpdateVennPercentageMatches,
  UpdateVennPercentageMatchesFailed,
  UpdateVennPercentageMatchesSuccess,
  UpdateDashboardVennStatus,
  UpdateDashboardVennStatusFailed,
  UpdateDashboardVennStatusSuccess,
  UpdateStatusList,
  UpdateTableState,
} from './actions';

@Injectable()
export class VennDashboardEffects extends BaseEffects {
  
  GetUserCollaborations$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(DashboardActionTypes.LoadVennDashboard),
    mergeMap(() =>
      this.vennService.getUserCollaborations().pipe(
        map((collaborations: ResultModelGeneric<CampaignModel[]>) => {
          return new LoadVennDashboardSuccess({
            collaborations: collaborations.data,
          });
        }),
        catchError(() => {
          this.showError('Error loading collaborations');
          return of(new LoadVennDashboardFailed());
        }),
      ),
    ),
  ));

  
  GetVennDashboard$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(DashboardActionTypes.GetVennDashboard),
    switchMap((action: GetVennDashboard) =>
      this.vennService.getCampaignById(action.payload.collaborationId).pipe(
        map((collaboration: ResultModelGeneric<CampaignModel>) => {
          return new GetVennDashboardSuccess({
            collaboration: collaboration.data,
          });
        }),
        catchError(() => {
          this.showError('Error getting collaboration');
          return of(new GetVennDashboardFailed());
        }),
      ),
    ),
  ));

  
  GetChartDetails$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(DashboardActionTypes.GetChartDetails),
    switchMap((action: GetChartDetails) =>
      this.vennService.getChartDetails(action.payload.collaborationId).pipe(
        map((chart: ResultModelGeneric<CanvasActionChart[]>) => {
          return new GetChartDetailsSuccess({
            collaborationId: action.payload.collaborationId,
            canvasActionChart: chart.data,
          });
        }),
        catchError(() => {
          this.showError('Error getting chart details');
          return of(new GetChartDetailsFailed());
        }),
      ),
    ),
  ));

  
  SubmitForMatching$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(DashboardActionTypes.SubmitForMatching),
    mergeMap((action: SubmitForMatching) =>
      this.vennService
        .matchingSubmit(action.payload.collaborationId, action.payload.model)
        .pipe(
          switchMap((c: ResultModelGeneric<boolean>) => {
            if (c.data && action.payload.submitForMatchingButtonPressed) {
              return [
                new SubmitForMatchingSuccess({
                  collaborationId: action.payload.collaborationId,
                  model: action.payload.model,
                  dataCustodianId: action.payload.dataCustodianId,
                  reportSignalRGroupName: action.payload.reportSignalRGroupName,
                }),
                new UpdateStatusList({
                  collaborationId: action.payload.collaborationId,
                  statusList: {
                    matching: inProgressEnumConverter(),
                  },
                }),
                new UpdateTableState({ tableState: TableStateEnum.matching }),
              ];
            } else {
              return [
                new UpdateStatusList({
                  collaborationId: action.payload.collaborationId,
                  dataCustodianId: action.payload.dataCustodianId,
                  statusList: { uploadDatabase: true },
                }),
              ];
            }
          }),
          catchError(() => {
            this.showError('Error submitting for match');
            return of(new SubmitForMatchingFailed());
          }),
        ),
    ),
  ));

  
  SubmitForMatchingSuccess$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(DashboardActionTypes.SubmitForMatchingSuccess),
    switchMap((action: SubmitForMatchingSuccess) => {
      return [
        new UpdateTableState({
          tableState: TableStateEnum.encrypted,
        }),
        new RunCanvasRequest({
          collaborationId: action.payload.collaborationId,
          reportDashboardSignalRGroupName:
            action.payload.reportSignalRGroupName,
        }),
      ];
    }),
    catchError(() => {
      this.showError('Error running canvas');
      return of(new RunCanvasRequestFailed());
    }),
  ));

  
  RunCanvasRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(DashboardActionTypes.RunCanvasRequest),
    mergeMap((action: RunCanvasRequest) =>
      this.collaborationService
        .runCanvasFromAgent(
          action.payload.collaborationId,
          action.payload.reportDashboardSignalRGroupName,
        )
        .pipe(
          switchMap(() => {
            return [new RunCanvasRequestSuccess()];
          }),
          catchError(() => {
            //this.showError('An error occurred running the canvas.');
            return of(new RunCanvasRequestFailed());
          }),
        ),
    ),
  ));

  
  UpdateDashboardVennStatus$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(DashboardActionTypes.UpdateDashboardVennStatus),
    switchMap((action: UpdateDashboardVennStatus) => [
      new UpdateDashboardVennStatusSuccess({
        currentStatus: action.payload.currentStatus,
      }),
    ]),
    catchError(() => {
      this.showError('Error getting dashboard status');
      return of(new UpdateDashboardVennStatusFailed());
    }),
  ));

  // @Effect()
  // UpdateTableState$: Observable<Action> = this.actions$.pipe(
  //   ofType(DashboardActionTypes.UpdateTableState),
  //   switchMap((action: UpdateTableState) => [
  //     new UpdateTableStateSuccess({
  //       tableState: action.payload.tableState
  //     }),
  //   ]),
  //   catchError(() => {
  //     this.showError('Error getting dashboard status');
  //     return of(new UpdateDashboardVennStatusFailed());
  //   }),
  // );

  
  UpdateVennPercentageMatches$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(DashboardActionTypes.UpdateVennPercentageMatches),
    switchMap((action: UpdateVennPercentageMatches) => [
      new UpdateVennPercentageMatchesSuccess({
        collaborationId: action.payload.collaborationId,
        percentageMatches: action.payload.percentageMatches,
      }),
    ]),
    catchError(() => {
      this.showError('Error updating percentage matches.');
      return of(new UpdateVennPercentageMatchesFailed());
    }),
  ));

  
  UpdateVennApprovalStatus$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(DashboardActionTypes.UpdateVennApprovalStatus),
    switchMap((action: UpdateVennApprovalStatus) => [
      new UpdateVennApprovalStatusSuccess({
        collaborationId: action.payload.collaborationId,
        approved: action.payload.approved,
      }),
    ]),
    catchError(() => {
      this.showError('Error updating approval status.');
      return of(new UpdateVennApprovalStatusFailed());
    }),
  ));

  
  UpdateVennLastExported$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(DashboardActionTypes.UpdateVennLastExported),
    switchMap((action: UpdateVennLastExported) => [
      new UpdateVennLastExportedSuccess({
        collaborationId: action.payload.collaborationId,
        date: action.payload.date,
      }),
    ]),
    catchError(() => {
      this.showError('Error updating last exported date.');
      return of(new UpdateVennLastExportedFailed());
    }),
  ));

  
  SetCurrentVennCollaborationId$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(DashboardActionTypes.SetCurrentVennDashboard),
    switchMap(() => [new SetCurrentVennDashboardSuccess()]),
    catchError(() => {
      this.showError('Error setting collaboration.');
      return of(new SetCurrentVennDashboardSuccess());
    }),
  ));

  
  LoadSegments$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(DashboardActionTypes.LoadSegments),
    mergeMap((action: LoadSegments) =>
      this.vennService.getSegments(action.payload.collaborationId).pipe(
        map((dataOutputs: ResultModelGeneric<IOutputTableModel[]>) => {
          return new LoadSegmentsSuccess({
            segments: dataOutputs.data,
          });
        }),
        catchError(() => {
          this.showError('Error loading output tables.');
          return of(new LoadOutputTablesFailed());
        }),
      ),
    ),
  ));

  constructor(
    private actions$: Actions,
    private vennService: VennService,
    private collaborationService: CollaborationService,
    toasterService: ToasterService,
  ) {
    super(toasterService);
  }
}
