import { Action } from '@ngrx/store';
import { ICreateCollaborationCanvas } from '../../../../data-explorer/models/createCollaborationCanvas.model';
import { IUpdateCollaborationModel } from '../../../approvals/models/update-collaboration.model';
import { ICollaborationModel } from '../../models/collaboration.model';
import { IUnlockCanvasResponseModel } from '../../models/unlock-canvas-response.model';
import { IUpdateCollaborationMembersModel } from '../../models/update-collaboration-members.model';

export enum CollaborationActionTypes {
  LoadCollaboration = '[ControlCentre.Collaborations] Load Collaboration',
  LoadCollaborationSuccess = '[ControlCentre.Collaborations] Load Collaboration Success',
  LoadCollaborationFailed = '[ControlCentre.Collaborations] Load Collaboration Failed',

  CreateCollaborationCanvas = '[ControlCentre.Collaborations] Create Collaboration Canvas',
  CreateCollaborationCanvasSuccess = '[ControlCentre.Collaborations] Create Collaboration Canvas Success',
  CreateCollaborationCanvasFailed = '[ControlCentre.Collaborations] Create Collaboration Canvas Failed',

  SaveCollaboration = '[ControlCentre.Collaborations] Save Collaboration',
  SaveCollaborationSuccess = '[ControlCentre.Collaborations] Save Collaboration Success',
  SaveCollaborationFailed = '[ControlCentre.Collaborations] Save Collaboration Failed',

  UnlockCanvas = '[ControlCentre.Collaborations] Unlock Canvas',
  UnlockCanvasSuccess = '[ControlCentre.Collaborations] Unlock Canvas Success',

  ClearCollaboration = '[ControlCentre.Collaborations] Clear Collaboration',
  ClearCollaborationMembers = '[ControlCentre.Collaborations] Clear Collaboration Members',

  UpdateCollaborationMembers = '[ControlCentre.Collaborations] Update Collaboration Members',
  UpdateCollaborationMembersSuccess = '[ControlCentre.Collaborations] Update Collaboration Members Success',
  UpdateCollaborationMembersFailed = '[ControlCentre.Collaborations] Update Collaboration Members Success',
}

export class LoadCollaboration implements Action {
  readonly type = CollaborationActionTypes.LoadCollaboration;

  constructor(public payload: { collaborationId: number }) {}
}

export class LoadCollaborationSuccess implements Action {
  readonly type = CollaborationActionTypes.LoadCollaborationSuccess;

  constructor(public payload: { collaboration: ICollaborationModel }) {}
}

export class LoadCollaborationFailed implements Action {
  readonly type = CollaborationActionTypes.LoadCollaborationFailed;
}

export class CreateCollaborationCanvas implements Action {
  readonly type = CollaborationActionTypes.CreateCollaborationCanvas;

  constructor(public payload: { collaboration: ICreateCollaborationCanvas }) {}
}

export class CreateCollaborationCanvasSuccess implements Action {
  readonly type = CollaborationActionTypes.CreateCollaborationCanvasSuccess;

  constructor(public payload: { newCollaborationId: number }) {}
}

export class CreateCollaborationCanvasFailed implements Action {
  readonly type = CollaborationActionTypes.CreateCollaborationCanvasFailed;
}

export class SaveCollaboration implements Action {
  readonly type = CollaborationActionTypes.SaveCollaboration;

  constructor(public payload: { update: IUpdateCollaborationModel }) {}
}

export class SaveCollaborationSuccess implements Action {
  readonly type = CollaborationActionTypes.SaveCollaborationSuccess;
}

export class SaveCollaborationFailed implements Action {
  readonly type = CollaborationActionTypes.SaveCollaborationFailed;
}

export class UnlockCanvas implements Action {
  readonly type = CollaborationActionTypes.UnlockCanvas;

  constructor(
    public payload: {
      collaborationId: number;
      canvasId: number;
      versionId: number;
      reason: string;
    },
  ) {}
}

export class UnlockCanvasSuccess implements Action {
  readonly type = CollaborationActionTypes.UnlockCanvasSuccess;

  constructor(public payload: { response: IUnlockCanvasResponseModel }) {}
}

export class ClearCollaboration implements Action {
  readonly type = CollaborationActionTypes.ClearCollaboration;
}

export class ClearCollaborationMembers implements Action {
  readonly type = CollaborationActionTypes.ClearCollaborationMembers;
}
export class UpdateCollaborationMembers implements Action {
  readonly type = CollaborationActionTypes.UpdateCollaborationMembers;
  constructor(
    public payload: {
      collaborationMembers: IUpdateCollaborationMembersModel;
    },
  ) {}
}
export class UpdateCollaborationMembersSuccess implements Action {
  readonly type = CollaborationActionTypes.UpdateCollaborationMembersSuccess;
}

export class UpdateCollaborationMembersFailed implements Action {
  readonly type = CollaborationActionTypes.UpdateCollaborationMembersFailed;
}

export type CollaborationActions =
  | LoadCollaboration
  | LoadCollaborationSuccess
  | LoadCollaborationFailed
  | CreateCollaborationCanvas
  | CreateCollaborationCanvasSuccess
  | CreateCollaborationCanvasFailed
  | SaveCollaboration
  | SaveCollaborationSuccess
  | SaveCollaborationFailed
  | UnlockCanvas
  | UnlockCanvasSuccess
  | ClearCollaboration
  | ClearCollaborationMembers
  | UpdateCollaborationMembers
  | UpdateCollaborationMembersSuccess
  | UpdateCollaborationMembersFailed;
