import { getAgentConfigurationState, getAgentStatus } from '../../state/agent/agent-selectors';

import { HttpClient } from '@angular/common/http';
import { IAgentConfigurationModel } from '../../state/model/agent-configuration';
import { IAppState } from '../../state/app.interfaces';
import { Injectable } from '@angular/core';
import { IxupBaseService } from '../ixup-base.service';
import { IxupHttpCustomParams } from '../../http/http-interceptor-params';
import { LoadAgentConfiguration } from '../../state/agent/agent-action';
import { Observable } from 'rxjs';
import { ResultModelGeneric } from '../../model/result.model';
import { Store } from '@ngrx/store';
import { IDefaultLocationsModel } from '../../state/model/default-location.model';

@Injectable()
export class AgentService extends IxupBaseService {
  host: string;
  constructor(private http: HttpClient, private store: Store<IAppState>) {
    super();
  }

  getAgentConfiguration(): Observable<ResultModelGeneric<IAgentConfigurationModel>> {
    return this.resolveErrorGeneric<IAgentConfigurationModel>(this.http.get('/api/agent'));
  }

  updateAgentConfiguration(
    agent: IAgentConfigurationModel,
  ): Observable<ResultModelGeneric<IAgentConfigurationModel>> {
    return this.resolveErrorGeneric<IAgentConfigurationModel>(this.http.post('/api/agent', agent));
  }

  checkAgentStatus() {
    return this.http.get('', {
      params: new IxupHttpCustomParams(true),
      responseType: 'text',
    });
  }

  loadAgentConfiguration() {
    this.store.dispatch(new LoadAgentConfiguration());
  }

  getAgentConfigurationState() {
    return this.store.select(getAgentConfigurationState);
  }

  getAgentStatusState() {
    return this.store.select(getAgentStatus);
  }

  testEncryptionGatewayUrl(path: string) {
    return this.http.get(path, {
      params: new IxupHttpCustomParams(false, path, false),
      responseType: 'text',
    });
  }

  public checkLocationsIsValid(agent: IAgentConfigurationModel) {
    const locations: IDefaultLocationsModel = {
      uploadLocation: agent.uploadLocation,
      downloadLocation: agent.downloadLocation,
    };

    return this.http.post('/datasources/createDefaultLocations', locations, {
      params: new IxupHttpCustomParams(false, agent.baseUrl.replace(/\/$/, ''), false),
    });
  }
}
