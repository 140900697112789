import { IPagination } from '../../../model/pagination.model';
import * as AuditLogActions from './audit-log.action';
import { initializeAuditLogState } from './audit-log.state';

export type Action = AuditLogActions.All;

export function AuditLogReducer(
  state = initializeAuditLogState(),
  action: Action
) {
  switch (action.type) {
  case AuditLogActions.GET_AUDITLOG: {
    return {
      ...state,
      searchPage: {
        ...state.searchPage,
        loading: true
      }
    };
  }
  case AuditLogActions.GET_AUDITLOG_SUCCESS: {
    return {
      ...state,
      searchPage: {
        auditlogs: action.payload.searchPage.auditlogs,
        loading: false,
        downloading: false,
        blobUrl: null,
        pagination: {
          offset: action.payload.searchPage.pagination.offset,
          pageSize: action.payload.searchPage.pagination.pageSize
        } as IPagination
      }
    };
  }

  case AuditLogActions.DOWNLOAD_AUDITLOG: {
    return {
      ...state,
      searchPage: {
        ...state.searchPage,
        downloading: true,
        blobUrl: null
      }
    };
  }

  case AuditLogActions.DOWNLOAD_AUDITLOG_SUCCESS: {
    return {
      ...state,
      searchPage: {
        ...state.searchPage,
        downloading: false,
        blobUrl: action.payload
      }
    };
  }

  case AuditLogActions.DOWNLOAD_RESET: {
    return {
      ...state,
      searchPage: {
        ...state.searchPage,
        downloading: false,
        blobUrl: null
      }
    };
  }
  }
  return state;
}
