
import { of as observableOf } from 'rxjs';

import { HttpBackend, HttpClient } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { mergeMap } from 'rxjs/operators';

import { API_BASE_URL } from '../../../environments/environment-model';
import { IxupBaseService } from '../ixup-base.service';

export const REQUIRE_CLIENT_CERT = new InjectionToken('REQUIRE_CLIENT_CERT');

Injectable();

// Ping is an unauthenticated web api endpoint
// used to establish whether a valid client certificate is in place.
@Injectable()
export class PingService extends IxupBaseService {
  private http: HttpClient;

  constructor(
    @Inject(REQUIRE_CLIENT_CERT) private requireClientCert: boolean,
    @Inject(API_BASE_URL) private baseUrl: string,
    @Inject(HttpBackend) handler: HttpBackend
  ) {
    super();

    // Using HttpClient with HttpBackend bypasses the interceptor chain,
    // this avoids the ping request being intercepted by authentication logic
    this.http = new HttpClient(handler);
  }

  ping() {
    return this.http
      .get(this.baseUrl + '/api/ping').pipe(

        mergeMap(response => {
          // record successful ping so on next visit, certificate challenge will occur immediately
          this.setApiConnectionConfirmed('true');
          this.setClientCertAccepted('true');
          return observableOf(response);
        }));
  }

  canMakeApiConnection(): boolean {
    return !this.requireClientCert || this.apiConnectionConfirmed();
  }

  private apiConnectionConfirmed(): boolean {
    return sessionStorage.getItem('apiConnectionConfirmed') === 'true';
  }

  private setApiConnectionConfirmed(status: string) {
    sessionStorage.setItem('apiConnectionConfirmed', status);
  }

  public clientCertAccepted(): boolean {
    return localStorage.getItem('clientCertAccepted') === 'true';
  }

  public setClientCertAccepted(status: string) {
    localStorage.setItem('clientCertAccepted', status);
  }
}
