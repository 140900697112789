export const LANG_XX_NAME = 'xx';

export const LANG_XX_TRANS = {
  // README - When writing apostrophes in the translated piece, be sure to use double quotes to surround the translation ""

  // GENERAL    
       
  'Cancel': 'Cancel^',
  'Create': 'Create^',
  'Created': 'Created^',
  'Delete': 'Delete^',
  'Deleted': 'Deleted^',
  'Edit': 'Edit^',
  'Edited': 'Edited^',
  'Login': 'Login^',
  'Logout': 'Logout^',
  'Modified': 'Modified^',
  'Modify': 'Modify^',
  'Oh dear!!!': 'Oh dear!!!^',
  'OK': 'OK^',
  'Remove': 'Remove^',
  'Removed': 'Removed^',
  'Return': 'Return^',
  'Rows per page:': 'Rows per page:^',
  'Status': 'Status^',
  'View': 'View^',
  'Viewed': 'Viewed^',
  'Previous': 'Previous^',
  'Next': 'Next^',
  'Active': 'Active^',


  // BRANDING
  'assets/images/logo-ixup-x-azure-200px.png': 'assets/images/logo-ixup-x-azure-200px-test.png',
    

  // DASHBOARD ETC
  'hello world': 'hello world^',    
  'Dashboard': 'Dashboard^',
  'Invitations': 'Invitations^',    


  // COLLABORATORS
  'organisation': 'organisation^',
  'organisations': 'organisations^',
  'Organisation': 'Organisation^',
  'Organisations': 'Organisations^',        
  'Cant remove anchor organisation.': 'Can\'t remove anchor organisation.^',
  'Create organisation': 'Create organisation^',
  'Edit organisation': 'Edit organisation^',
  'Save organisation': 'Save organisation^',
  'Update organisation': 'Update organisation^',
  'View organisation': 'View organisation^',

  'Remove organisation': 'Remove organisation^',
  'Are you sure you want to remove this organisation?': 'Are you sure you want to remove this organisation?^',


  // COLLABORATIONS
  'collaboration': 'collaboration^',
  'collaborations': 'collaborations^',
  'Collaboration': 'Collaboration^',    
  'Collaborations': 'Collaborations^',
  'Create collaboration': 'Create collaboration^',
  'Edit collaboration': 'Edit collaboration^',
  'Save collaboration': 'Save collaboration^',
  'Update collaboration': 'Update collaboration^',
  'View collaboration': 'View collaboration^',

  'Collaboration Info': 'Collaboration information^',
  'Collaboration created': 'Collaboration invitations have been sent^',
  'Collaboration updated.': 'The collaboration has been successfully updated.^',

  'Remove collaboration': 'Remove collaboration^',
  'Are you sure you want to remove this collaboration?': 'Are you sure you want to remove this collaboration?^',

  'Create a collaboration': 'Create a collaboration^',
  'Collaboration details': 'Collaboration details^',    
  'Collaboration name': 'Collaboration name^',
  'What you want to call the collaboration...': 'What you want to call the collaboration...^',
  'Collaboration description': 'Collaboration description^',
  'How would you describe the collaboration...': 'How would you describe the collaboration...^',

  'Collaboration type': 'Collaboration type^',
  'Internal': 'Internal^',
  'External': 'External^',

  'Collaborations can be within a collaborator (internal) or with other collaborators (external).': 'Collaborations can be within a collaborator (internal) or with other collaborators (external).^',

  'All members must approve': 'All members must approve^',
  'Collaboration owner can approve': 'Collaboration owner can approve^',
  'Collaboration is closed': 'Collaboration is closed^',
  'After collaboration invitations have been sent, collaborations can be open for additional members to be invited or closed to additional members.': 'After collaboration invitations have been sent, collaborations can be open for additional members to be invited or closed to additional members.^',

  'Invite': 'Invite^',
  '-- Collaboration members --': '-- Collaboration members --^',
  'Select member(s) you want to invite to the collaboration': 'Select member(s) you want to invite to the collaboration^',

  'Invitation message': 'Invitation message^',
  'Personalise your collaboration invitation by adding a message': 'Personalise your collaboration invitation by adding a message^',
  'Send collaboration invitation(s)': 'Send collaboration invitation(s)^',
    

  // LOGIN
  'I accept the terms and conditions': 'I accept the terms and conditions^',
  'Terms and conditions': 'Terms and conditions^',
  '&copy; IXUP Pty Ltd. Use of the information contained on this site is governed by Australian law and is subject to IXUP <a>disclaimers</a> and <a>privacy policy</a>.': '&copy; IXUP Pty Ltd. Use of the information contained on this site is governed by Australian law and is subject to IXUP^',
  'disclaimers': 'disclaimers^',
  'and': 'and^',
  'privacy policy': 'privacy policy^',


  // ORGANISATIONS
  'collaborator': 'collaborator^',
  'collaborators': 'collaborators^',
  'Collaborator': 'Collaborator^',    
  'Collaborators': 'Collaborators^',
  'Create collaborator': 'Create collaborator^',
  'Edit collaborator': 'Edit collaborator^',
  'Save collaborator': 'Save collaborator^',
  'Update collaborator': 'Update collaborator^',
  'View collaborator': 'View collaborator^',
  'Pending collaborators': 'Pending collaborators^',
  'Active collaborators': 'Active collaborators^',

  'Create a collaborator': 'Create a collaborator^',
  'Collaborator setup': 'Collaborator setup^',
  'Collaborator details': 'Collaborator details^',
  'Collaborator name': 'Collaborator name^',
  'The name of the collaborator': 'The name of the collaborator^',
  'Collaborator description': 'Collaborator description^',
  'A description of the collaborator': 'A description of the collaborator^',
  'Collaborator email': 'Collaborator email^',
  'The primary email account of the collaborator': 'The primary email account of the collaborator^',

  'Collaborator settings': 'Collaborator settings^',
  'Can create collaborations': 'Can create collaborations^',
  'Can create users': 'Can create users^',
  'Can create roles': 'Can create roles^',

  'Collaborator customisation': 'Collaborator customisation^',
  'Collaborator logo': 'Collaborator logo^',
  'Collaborator icon': 'Collaborator icon^',
  'Collaborator theme': 'Collaborator theme^',
  'Colour theme': 'Colour theme^',

  'IXUP Green (default)': 'IXUP Green (default)^',

  'Collaborator data': 'Collaborator data^',
  'Data pool size': 'Data pool size^',
  'Database plan': 'Database plan^',

  'Collaborator address': 'Collaborator address^',
  'Street address': 'Street address^',
  'Suburb': 'Suburb^',
  'State': 'State^',
  'Postcode': 'Postcode^',
  'Country': 'Country^',
  'Website address': 'Website address^',
  'Contact number': 'Contact number^',

  'Continue setup': 'Continue setup^',

  'Manage data': 'Manage data^',
  'Remove collaborator': 'Remove collaborator^',
  'Are you sure you want to remove this collaborator?': 'Are you sure you want to remove this collaborator?^',

  'Looks like there arent any collaborators yet...': 'Looks like there aren\'t any collaborators yet...^',


  // PROVIDER TYPE
  'Small Pool': 'Small pool^',
  'Medium Pool': 'Medium pool^',
  'Large Pool': 'Large pool^',


  // ROLES
  'role': 'role^',    
  'roles': 'roles^',
  'Role': 'Role^',
  'Roles': 'Roles^',
  'Create role': 'Create role^',
  'Edit role': 'Edit role^',
  'Save role': 'Save role^',
  'Update role': 'Update role^',
  'View role': 'View role^',

  'Role setup': 'Role setup^',
  'Role details': 'Role details^',
  'Role name': 'Role name^',
  'What do you want to call the role...': 'What do you want to call the role...^',
  'Role description': 'Role description^',
  'How would you describe the role...': 'How would you describe the role...^',

  'Role access and permissions': 'Role access and permissions^',
  'area - Access and permissions': 'area - Access and permissions^',
  'Organisations area - Access and permissions': 'Organisations area - Access and permissions^',
  'Workbench area - Access and permissions': 'Workbench area - Access and permissions^',

  'Can access and view': 'Can access and view^',

  'User(s) attached': 'User(s) attached^',
  'Roles with user(s) attached can not be removed.': 'Roles with user(s) attached can not be removed.^',
  'Remove Role': 'Remove Role^',
  'Are you sure you want to remove this role?': 'Are you sure you want to remove this role?^',


  // USER
  'user': 'user^',
  'User': 'User^',
  'Profile': 'Profile^',    

  'Your primary email address': 'Your primary email address^',
  'Your primary phone number': 'Your primary phone number^',

  'Profile update': 'Profile update^',
  'Youve updated your profile!': 'You\'ve updated your profile!^',
    

  // USERS
  'users': 'users^',
  'Users': 'Users^',
  'Create user': 'Create user^',
  'Edit user': 'Edit user^',
  'Save user': 'Save user^',
  'Update user': 'Update user^',
  'View user': 'View user^',
  'Add user': 'Add user^',
  'Add user(s)': 'Add user(s)^',
  'Add existing user': 'Add existing user^',

  'User setup': 'User setup^',
  'User details': 'User details^',

  'Title': 'Title^',
  'Salutation': 'Salutation^',
  'For example Mr, Mrs, Ms, Dr': 'For example Mr, Mrs, Ms, Dr^',    
  'First name': 'First name^',
  'Your first name': 'Your first name^',  
  'The first name of the user': 'The first name of the user^',  
  'Last name': 'Last name^',
  'Your last name': 'Your last name^',
  'The last name of the user': 'The last name of the user^',
  'Business title': 'Business title^',
  'Your business title': 'Your business title^',
  'The business title of the user': 'The business title of the user^',
  'Username': 'Username^',
  'The username needs to match the Active Directory username': 'The username needs to match the Active Directory username^',
  'Email address': 'Email address^',
  'The primary email account of the user': 'The primary email account of the user^',
  'Phone number': 'Phone number^',
  'The primary phone number of the user': 'The primary phone number of the user^',

  'Language': 'Language^',
  'Language select - currently selected language is': 'Language select - currently selected language is^',
  'Avatar': 'Avatar^',
  'Display picture': 'Display picture^',
  'Role(s)': 'Role(s)^',
  '-- Role --': '-- Role --^',
  'Select role(s) for this user': 'Select role(s) for this user^',

  'Primary email': 'Primary email^',
  'Primary phone': 'Primary phone^',
  'Preferred language': 'Preferred language^',    

  'Remove user': 'Remove user^',
  'Are you sure you want to remove this user?': 'Are you sure you want to remove this user?^',
  'Cant remove users': 'Can\'t remove users^',

};