import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { RouterReducerState } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { combineLatest, map, Observable } from 'rxjs';

import {
  AsyncPipe,
  DatePipe,
  NgFor,
  NgIf,
  TitleCasePipe,
} from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterLink } from '@angular/router';
import * as ApprovalStatusEnum from '../../../model/approval-status.enum';
import {
  CollaborationTypeEnum,
  collaborationTypeEnumToString,
} from '../../../pages/control-centre/shared/models/collaboration-type-enum';
import { ICollaborationModel } from '../../../pages/control-centre/shared/models/collaboration.model';
import { LoadCollaboration } from '../../../pages/control-centre/shared/state/collaboration/actions';
import { ICollaborationState } from '../../../pages/control-centre/shared/state/collaboration/reducers';
import {
  getCollaboration,
  isSecureCloudTransfer,
} from '../../../pages/control-centre/shared/state/collaboration/selectors';
import {
  getRouterUrlRootSection,
  getRouterUrlTailSection,
} from '../../../state/app.reducer';
import { RouterStateUrl } from '../../../state/shared/utils';
import { fadeIn } from '../../../theme/animations/fadein-animation';

export function enumConverter() {
  return Object.keys(CollaborationTypeEnum)[
    Object.values(CollaborationTypeEnum).indexOf(
      CollaborationTypeEnum.SecureCloudTransfer,
    )
  ];
}
@Component({
  selector: 'control-centre-topmenu',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './control-centre-topmenu.component.html',
  animations: [fadeIn],
  standalone: true,
  imports: [
    NgIf,
    MatTabsModule,
    NgFor,
    RouterLink,
    AsyncPipe,
    TitleCasePipe,
    DatePipe,
  ],
})
export class ControlCentreTopmenuComponent implements OnInit {
  @Input() collaborationId;
  links: string[];
  activeLink$: Observable<string>;
  background = '';
  label: string;
  url: string[];
  collaboration$: Observable<ICollaborationModel>;
  ApprovalStatus = ApprovalStatusEnum.ApprovalStatus;
  public isSecureCloudTransfer$: Observable<boolean>;
  public collaborationTypeEnumToString = collaborationTypeEnumToString;

  constructor(
    private store: Store<RouterReducerState<RouterStateUrl>>,
    private canvasStore: Store<ICollaborationState>,
  ) {}

  ngOnInit() {
    this.canvasStore.dispatch(
      new LoadCollaboration({ collaborationId: this.collaborationId }),
    );

    this.activeLink$ = combineLatest([
      this.store.select(getRouterUrlRootSection),
      this.store.select(getRouterUrlTailSection),
    ]).pipe(
      map(([root, tail]) => {
        const newTail = tail == 'initiate' || tail == 'unlock' ? 'approvals' : tail;
        return root === 'control-centre' ? newTail : '';
      }),
    );

    this.collaboration$ = this.canvasStore.select(getCollaboration);
    this.isSecureCloudTransfer$ = this.canvasStore.select(
      isSecureCloudTransfer,
    );
  }

  public getLinksList(isSecureCloudTransfer: boolean) {
    return !isSecureCloudTransfer
      ? ['overview', 'canvas', 'tables', 'output', 'approvals']
      : (this.links = ['overview', 'approvals']);
  }

  routeToLink(link: string) {
    switch (link) {
    case 'canvas':
      this.label = 'Canvas';
      break;
    case 'tables':
      this.label = 'Input';
      break;
    case 'output':
      this.label = 'Output';
      break;
    case 'overview':
      this.label = 'Overview';
      break;
    case 'approvals':
      this.label = 'Approvals';
      break;
    }
    return ['/control-centre', this.collaborationId, link];
  }

  toggleBackground() {
    this.background = this.background ? '' : 'primary';
  }
}
