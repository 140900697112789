import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar, MatLegacySnackBarConfig as MatSnackBarConfig } from '@angular/material/legacy-snack-bar';

@Injectable()
export class ToasterService {

  constructor(private snackBar: MatSnackBar) {
  }

  showToaster(msg: string, label: string, action: boolean, autohide: boolean, hidetime: number, style = false, styleclass= '') {
    const config = new MatSnackBarConfig();
    if (autohide) {
      config.duration = hidetime;
    }
    if(style)
    {
      config.panelClass = styleclass;
    }
    this.snackBar.open(msg, action && label, config);

  }

  toast(message, status = 'Ok') {
    this.showToaster(message, status, true, true, 7000);
  }
}
