import { NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import {
  MatLegacyDialogModule,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { Dialog } from '../../../model/dialog.model';
import { TranslatePipe } from '../../../services/translate/translate.pipe';

@Component({
  selector: 'ixup-dialog',
  templateUrl: '../../shared/ixup-dialog/ixup-dialog.html',
  providers: [Dialog],
  standalone: true,
  imports: [
    MatLegacyDialogModule,
    NgIf,
    MatIconModule,
    MatButtonModule,
    TranslatePipe,
  ],
})
export class IxupDialog implements OnInit {
  @Input() modalmessage: string;
  private value = false;
  constructor(public dialogRef: MatDialogRef<IxupDialog>, public dialog: Dialog) {}

  ngOnInit() {
    this.dialog.textmessage = this.modalmessage[2];
    this.dialog.heading = this.modalmessage[0];
    this.dialog.buttontext = this.modalmessage[1];
    this.dialog.canceltext = this.modalmessage[3];
    this.dialog.icon = this.modalmessage[4];
  }

  delete() {
    this.dialogRef.close(true);
  }
  
  checkValueExists(value: string) {
    if (value) {
      return true;
    }
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
