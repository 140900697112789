<div class="inline-block" style="position: relative">
  <input
    type="file"
    multiple
    (change)="fileChange(input)"
    #input
    name="file"
    id="inputfile"
    style="opacity: 1; z-index: 1; float: left; width: 240px; cursor: pointer"
    tabindex="1"
  />
</div>
