import { of as observableOf, Observable, map } from 'rxjs';
import { Injectable } from '@angular/core';

import { PingService } from '../services/ping/ping.service';
import { AuthService } from '../services/auth/AuthService';
import {
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';

@Injectable()
export class LoggedInGuard {
  constructor(
    private pingService: PingService,
    private authService: AuthService,
    private router: Router,
  ) {}

  canActivate(
    _: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    if (!this.pingService.canMakeApiConnection()) {
      this.router.navigate(['/certify']);
      return observableOf(false);
    }
    if (!this.authService.isLoggedInIxup()) {
      // this.authService.login();
      console.log('LoggedInGuard - login');
      this.router.navigate(['/login']);
    }
    return this.authService.hasPagePermission(state).pipe(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      map((response: any) => {
        if (response === true) {
          return true;
        }
        this.router.navigate(['/insufficient-permission']);
        return false;
      }),
    );
  }
}
