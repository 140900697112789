import { ICollaborationBillingModel } from '../../models/collaboration-billing.model';
import { CollaborationActions, CollaborationActionTypes } from './actions';

export interface ICollaborationBillingState {
  billing: ICollaborationBillingModel;
  loadingCollaborationBilling: boolean;
}

export const initialState: ICollaborationBillingState = {
  billing: null,
  loadingCollaborationBilling: false
};

export function billingReducer(
  state = initialState,
  action: CollaborationActions
): ICollaborationBillingState {
  switch (action.type) {
  case CollaborationActionTypes.LoadCollaborationBilling: {
    return { ...state, loadingCollaborationBilling: true };
  }

  case CollaborationActionTypes.LoadCollaborationBillingSuccess: {
    return {
      ...state,
      billing: action.payload.billing,
      loadingCollaborationBilling: false
    };
  }

  case CollaborationActionTypes.LoadCollaborationBillingFailed: {
    return { ...state, loadingCollaborationBilling: false };
  }

  default: {
    return state;
  }
  }
}
