import { Component, Inject, OnInit } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import {
  MatLegacyDialogModule,
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA
} from '@angular/material/legacy-dialog';

import { NgIf } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PassphraseResetTypeEnum } from '../../../../../components/collaborator/profile/model/passphrase-reset-type.model';
import { ValidationHelper } from '../../../../../components/shared/validators/validation-helper';
import { PassphraseComplexityPolicyModel } from '../../../../../model/passphraseComplexityPolicyModel';
import { TranslatePipe } from '../../../../../services/translate/translate.pipe';

@Component({
  templateUrl: 'custodian-passphrase-dialog.component.html',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatLegacyDialogModule,
    MatIconModule,
    MatLegacyFormFieldModule,
    MatLegacyInputModule,
    MatTooltipModule,
    NgIf,
    MatButtonModule,
    TranslatePipe,
  ],
})
export class CustodianPassphraseDialog implements OnInit {
  passwordIncorrect: boolean;
  modelForm: UntypedFormGroup;
  complexityPolicy: PassphraseComplexityPolicyModel;
  hideAddCPassphrase = true;
  hideReenterCPassphrase = true;

  validationErrorMessage(): string {
    if (this.modelForm.hasError('invalidPassword'))
      return 'The entered passphrases must match.';

    return null;
  }

  constructor(
    public dialogRef: MatDialogRef<CustodianPassphraseDialog>,
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA)
    public data: { authCode: PassphraseResetTypeEnum },
    
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private matDialogRef: MatDialogRef<CustodianPassphraseDialog>,
  ) {}

  ngOnInit(): void {
    this.modelForm = this.fb.group(
      {
        newPassword: ['', Validators.required],
        confirmPassKey: ['', Validators.required],
      },
      {
        validator: ValidationHelper.checkPassword,
      },
    );
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  submit() {
    this.matDialogRef.close(this.modelForm.get('newPassword').value);
  }
}
