import { Action } from '@ngrx/store';

import { IReportTokenModel } from '../../canvas/models/report-token.model';
import { ITableFieldModel } from '../../input-tables/models/table-field.model';
import { IReleaseSettings } from '../models/auto-release.model';
import { IOutputTableModel } from '../models/output-table.model';

export interface ICredentialsRequestModel {
  tableUniqueName: string;
  dataCustodianPassphrase: string;
  collaborationPassphrase: string;
  signalRGroupName: string;
  deactivateExistingTokens: boolean;
}

export enum OutputTablesActionTypes {
  LoadOutputTables = '[OutputTables] Load OutputTabless',
  LoadOutputTablessSuccess = '[OutputTables] Load OutputTabless Success',
  LoadOutputTablesFailed = '[OutputTables] Load OutputTabless Failed',
  UpdateOutputTablesExportingState = '[OutputTables] Update Exporting State',
  UpdateOutputTablesPendingState = '[OutputTables] Update Pending State',
  LoadFields = '[OutputTables] Load Fields',
  LoadFieldsSuccess = '[OutputTables] Load Fields Success',
  UpdateFields = '[OutputTables] Update Fields',
  UpdateFieldsSuccess = '[OutputTables] Update Fields Success',
  UpdateReleaseSettings = '[OutputTables] UpdateReleaseSettings',
  UpdateReleaseSettingsSuccess = '[OutputTables] Update Release Settings Success',
  UpdateReleaseSettingsFailed = '[OutputTables] Update Release Settings Failed',
  UpdateReportToken = '[OutputTables] Set Report Token',
  UpdateReportTokenSuccess = '[OutputTables] Load Report Token Success',
  UpdateReportTokenFailed = '[OutputTables] Load Report Token Failed',
}

export class LoadOutputTables implements Action {
  readonly type = OutputTablesActionTypes.LoadOutputTables;
  constructor(
    public payload: {
      collaborationId: number;
      tableType: string[];
      includeDeactivated: boolean;
    },
  ) {}
}

export class LoadOutputTablessSuccess implements Action {
  readonly type = OutputTablesActionTypes.LoadOutputTablessSuccess;
  constructor(public payload: { outputs: IOutputTableModel[] }) {}
}

export class LoadOutputTablesFailed implements Action {
  readonly type = OutputTablesActionTypes.LoadOutputTablesFailed;
}

export class UpdateOutputTablesExportingState implements Action {
  readonly type = OutputTablesActionTypes.UpdateOutputTablesExportingState;
  constructor(
    public payload: { id: number; exporting: boolean; lastExportDate: Date },
  ) {}
}

export class UpdateOutputTablesPendingState implements Action {
  readonly type = OutputTablesActionTypes.UpdateOutputTablesPendingState;
  constructor(public payload: { id: number; pending: boolean }) {}
}

export class LoadFields implements Action {
  readonly type = OutputTablesActionTypes.LoadFields;
  constructor(
    public payload: {
      tableId: number;
    },
  ) {}
}

export class LoadFieldsSuccess implements Action {
  readonly type = OutputTablesActionTypes.LoadFieldsSuccess;
  constructor(
    public payload: { tableId: number; fields: ITableFieldModel[] },
  ) {}
}

export class UpdateFields implements Action {
  readonly type = OutputTablesActionTypes.UpdateFields;
  constructor(
    public payload: { tableId: number; fields: ITableFieldModel[] },
  ) {}
}

export class UpdateFieldsSuccess implements Action {
  readonly type = OutputTablesActionTypes.UpdateFieldsSuccess;
  constructor(
    public payload: { tableId: number; fields: ITableFieldModel[] },
  ) {}
}

export class UpdateReleaseSettings implements Action {
  readonly type = OutputTablesActionTypes.UpdateReleaseSettings;
  constructor(
    public payload: {
      releaseSettings: IReleaseSettings;
    },
  ) {}
}

export class UpdateReleaseSettingsSuccess implements Action {
  readonly type = OutputTablesActionTypes.UpdateReleaseSettingsSuccess;
  constructor(
    public payload: {
      releaseSettings: IReleaseSettings;
    },
  ) {}
}

export class UpdateReleaseSettingsFailed implements Action {
  readonly type = OutputTablesActionTypes.UpdateReleaseSettingsFailed;
}

export class UpdateReportToken implements Action {
  readonly type = OutputTablesActionTypes.UpdateReportToken;
  constructor(
    public payload: {
      tableId: number;
      collaborationId: number;
      credentialsRequest: ICredentialsRequestModel;
    },
  ) {}
}

export class UpdateReportTokenSuccess implements Action {
  readonly type = OutputTablesActionTypes.UpdateReportTokenSuccess;
  constructor(
    public payload: {
      tableId: number;
      oDataFeed: IReportTokenModel;
    },
  ) {}
}

export class UpdateReportTokenFailed implements Action {
  readonly type = OutputTablesActionTypes.UpdateReportTokenFailed;
}

export type OutputTablesActions =
  | LoadOutputTables
  | LoadOutputTablessSuccess
  | LoadOutputTablesFailed
  | UpdateOutputTablesExportingState
  | UpdateOutputTablesPendingState
  | LoadFields
  | LoadFieldsSuccess
  | UpdateFields
  | UpdateFieldsSuccess
  | UpdateReleaseSettings
  | UpdateReleaseSettingsSuccess
  | UpdateReleaseSettingsFailed
  | UpdateReportToken
  | UpdateReportTokenSuccess
  | UpdateReportTokenFailed;
