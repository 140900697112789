import { Action } from '@ngrx/store';
import { DashboardStatusEnum } from '../../pages/powaindex/model/dashboard-status.enum';
import { DashboardStatusModel } from '../../pages/powaindex/model/dashboard-status.model';
import { ReportDashboardStatusEnum } from '../../pages/powaindex/model/report-dashboard-status.enum';
import { UserCollaborationsModel } from '../../pages/powaindex/model/user-collaborations.model';

export enum DashboardActionTypes {
  LoadDashboard = '[Dashboard] Load Dashboard',
  LoadDashboardSuccess = '[Dashboard] Load Dashboard Success',
  LoadDashboardFailed = '[Dashboard] Load Dashboard Failed',
  SetCurrentDashboard = '[Dashboard] SetCurrent Dashboard',
  SetCurrentDashboardSuccess = '[Dashboard] SetCurrent Dashboard Success',
  SetCurrentDashboardFailed = '[Dashboard] SetCurrent Dashboard Failed',
  GetDashboardStatus = '[Dashboard] Get Dashboard Status',
  getDataOwner = '[Dashboard] Get Data Owner',
  GetDashboardStatusSuccess = '[Dashboard] Get Dashboard Status Success',
  GetDashboardStatusFailed = '[Dashboard] Get Dashboard Status Failed',
  UpdateDashboardStatus = '[Dashboard] Update Dashboard Status',
  UpdateDashboardStatusSuccess = '[Dashboard] Update Dashboard Status Success',
  UpdateDashboardStatusFailed = '[Dashboard] Update Dashboard Status Failed',
}

export class LoadDashboard implements Action {
  readonly type = DashboardActionTypes.LoadDashboard;
}

export class LoadDashboardSuccess implements Action {
  readonly type = DashboardActionTypes.LoadDashboardSuccess;

  constructor(public payload: { collaborations: UserCollaborationsModel[] }) {}
}

export class LoadDashboardFailed implements Action {
  readonly type = DashboardActionTypes.LoadDashboardFailed;
}

export class SetCurrentDashboard implements Action {
  readonly type = DashboardActionTypes.SetCurrentDashboard;

  constructor(public payload: { currentCollaborationId: number }) {}
}

export class SetCurrentDashboardSuccess implements Action {
  readonly type = DashboardActionTypes.SetCurrentDashboardSuccess;
}

export class SetCurrentDashboardFailed implements Action {
  readonly type = DashboardActionTypes.SetCurrentDashboardFailed;
}

export class GetDashboardStatus implements Action {
  readonly type = DashboardActionTypes.GetDashboardStatus;

  constructor(public payload: { collaborationId: number }) {}
}

export class GetDashboardStatusSuccess implements Action {
  readonly type = DashboardActionTypes.GetDashboardStatusSuccess;

  constructor(public payload: { dashboardStatus: DashboardStatusModel }) {}
}

export class GetDashboardStatusFailed implements Action {
  readonly type = DashboardActionTypes.GetDashboardStatusFailed;
}

export class UpdateDashboardStatus implements Action {
  readonly type = DashboardActionTypes.UpdateDashboardStatus;

  constructor(
    public payload: {
      currentStatus: DashboardStatusEnum | ReportDashboardStatusEnum;
    },
  ) {}
}

export class UpdateDashboardStatusSuccess implements Action {
  readonly type = DashboardActionTypes.UpdateDashboardStatusSuccess;

  constructor(
    public payload: {
      currentStatus: DashboardStatusEnum | ReportDashboardStatusEnum;
    },
  ) {}
}

export class UpdateDashboardStatusFailed implements Action {
  readonly type = DashboardActionTypes.UpdateDashboardStatusFailed;
}

export type DashboardActions =
  | LoadDashboard
  | LoadDashboardSuccess
  | LoadDashboardFailed
  | SetCurrentDashboard
  | SetCurrentDashboardSuccess
  | SetCurrentDashboardFailed
  | GetDashboardStatus
  | GetDashboardStatusSuccess
  | GetDashboardStatusFailed
  | UpdateDashboardStatus
  | UpdateDashboardStatusSuccess
  | UpdateDashboardStatusFailed;
