import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { IConsoleOrganisationModel } from '../../pages/consoles/model/console-organisation.model';
import { OrganisationActions, OrganisationActionTypes } from './actions';

const adapter: EntityAdapter<IConsoleOrganisationModel> = createEntityAdapter<
  IConsoleOrganisationModel
>({
  selectId: m => m.id
});

export interface IOrganisationState
  extends EntityState<IConsoleOrganisationModel> {
  // additional entities state properties
  loading: boolean;
}

export const initialState: IOrganisationState = adapter.getInitialState({
  // additional entity state properties
  loading: false
});

export const { selectIds, selectEntities, selectAll } = adapter.getSelectors();

export function organisationReducer(
  state = initialState,
  action: OrganisationActions
): IOrganisationState {
  switch (action.type) {
  case OrganisationActionTypes.LoadOrganisationSuccess: {
    return adapter.upsertOne(
      action.payload.Organisation,
      adapter.removeAll(state)
    );
  }

  case OrganisationActionTypes.UpdateOrganisationSuccess: {
    return adapter.upsertOne(
      action.payload.Organisation,
      adapter.removeAll(state)
    );
  }

  case OrganisationActionTypes.UpdateOrganisationProfile: {
    return adapter.updateOne(
      {
        id: action.payload.organisationId,
        changes: {
          organisationName: action.payload.organisationName,
          image: action.payload.image,
          iconImageId: action.payload.iconImageId
        }
      },
      state
    );
  }

  default: {
    return state;
  }
  }
}
