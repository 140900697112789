import { NgIf } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatLegacyCheckboxModule } from '@angular/material/legacy-checkbox';
import {
  MatLegacyDialogModule,
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { TranslatePipe } from '../../../../services/translate/translate.pipe';
import { CanvasService } from '../../../canvas/services/canvas.service';

@Component({
  selector: 'confirm-data-to-ingest-dialog',
  templateUrl: './confirm-data-to-ingest-dialog.component.html',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatLegacyDialogModule,
    MatIconModule,
    NgIf,
    MatLegacyCheckboxModule,
    MatButtonModule,
    TranslatePipe,
  ],
})
export class ConfirmDataToIngestDialog implements OnInit {
  modelForm: UntypedFormGroup;
  collaborationId: number;
  sampleDataWarning: string;
  ingestFiles = false;

  constructor(
    private dialogRef: MatDialogRef<ConfirmDataToIngestDialog>,
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) data,
    canvasSerice: CanvasService,
  ) {
    this.collaborationId = data.collaborationId;

    canvasSerice
      .getApprovalStatus(data.collaborationId)
      .subscribe((response: string) => {
        this.sampleDataWarning =
          response !== 'approved'
            ? (this.sampleDataWarning = 'This collaboration has not been approved. Only sample data from the first 50 rows will be ingested and available to use at this time.')
            : null;
      });
  }

  ngOnInit() {
    this.modelForm = this.fb.group({
      checkFilesReady: [''],
    });
  }

  check(check: boolean) {
    if (check) {
      this.ingestFiles = true;
    } else {
      this.ingestFiles = false;
    }
  }

  submit() {
    this.dialogRef.close({
      dataCustodianPassphrase: null,
      collaborationSharedPassphrase: null,
      ingestFiles: this.ingestFiles,
    });
  }

  cancel() {
    this.dialogRef.close(null);
  }
}
