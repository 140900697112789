import { Observable } from 'rxjs';

import { Component, Input, OnInit } from '@angular/core';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, RouterLink } from '@angular/router';
import { Store } from '@ngrx/store';

import { NgClass, NgIf } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatLegacyListModule } from '@angular/material/legacy-list';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AuthUserModel } from 'app/model/authuser.model';
import { ExtendedImageContainer } from '../../../model/imagecontainer.interface';
import { AuthService } from '../../../services/auth/AuthService';
import { ImagesService } from '../../../services/shared/Images.service';
import { DashboardState } from '../../../state/dashboard/reducer';
import { getCurrentCollaborationId } from '../../../state/dashboard/selectors';
import { untilDestroyed } from '../../../state/shared/utils';
import { fadeIn } from '../../../theme/animations/fadein-animation';
import { LogoComponent } from '../../shared/logo/logo.component';

const CONVERTR_COLLABORATIONS_ICON = `
  <svg xmlns="http://www.w3.org/2000/svg" width="14px" height="14px">
  <path d="M7,4.45454545 C5.59363636,4.45454545 4.45454545,5.59363636 4.45454545,7 C4.45454545,8.40636364 5.59363636,9.54545455 7,9.54545455 C8.40636364,9.54545455 9.54545455,8.40636364 9.54545455,7 C9.54545455,5.59363636 8.40636364,4.45454545 7,4.45454545 Z M12.6890909,6.36363636 C12.3963636,3.71 10.29,1.60363636 7.63636364,1.31090909 L7.63636364,0 L6.36363636,0 L6.36363636,1.31090909 C3.71,1.60363636 1.60363636,3.71 1.31090909,6.36363636 L0,6.36363636 L0,7.63636364 L1.31090909,7.63636364 C1.60363636,10.29 3.71,12.3963636 6.36363636,12.6890909 L6.36363636,14 L7.63636364,14 L7.63636364,12.6890909 C10.29,12.3963636 12.3963636,10.29 12.6890909,7.63636364 L14,7.63636364 L14,6.36363636 L12.6890909,6.36363636 Z M7,11.4545455 C4.53727273,11.4545455 2.54545455,9.46272727 2.54545455,7 C2.54545455,4.53727273 4.53727273,2.54545455 7,2.54545455 C9.46272727,2.54545455 11.4545455,4.53727273 11.4545455,7 C11.4545455,9.46272727 9.46272727,11.4545455 7,11.4545455 Z" />
</svg>
`;

@Component({
  selector: 'ixup-convertr-dashboard-left-navigation-menu',
  templateUrl: './convertr-dashboard-left-navigation-menu.component.html',
  animations: [fadeIn],
  standalone: true,
  imports: [
    MatLegacyListModule,
    RouterLink,
    LogoComponent,
    MatTooltipModule,
    NgIf,
    MatButtonModule,
    NgClass,
    MatIconModule,
  ],
})
export class ConvertrDashboardLeftNavigationMenuComponent implements OnInit {
  @Input() miniNav: boolean;
  public collaborationId$: Observable<number>;
  @Input() manageUsers: boolean;

  collaboratorIconContainer: ExtendedImageContainer;
  collaboratorName: string;
  private untilDestroyed = untilDestroyed();

  constructor(
    private store: Store<DashboardState>,
    public router: Router,
    public authService: AuthService,
    private imagesService: ImagesService,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
  ) {
    this.authService.currentUser$
      .pipe(this.untilDestroyed())
      .subscribe((response: AuthUserModel) => {
        this.getCollaboratorImage(response.IconId, response.CollaboratorName);
        this.collaboratorName = response.CollaboratorName;
      });

    iconRegistry.addSvgIconLiteral(
      'convertr-collaborations',
      sanitizer.bypassSecurityTrustHtml(CONVERTR_COLLABORATIONS_ICON),
    );
  }

  public ngOnInit() {
    this.collaborationId$ = this.store.select(getCurrentCollaborationId);
  }

  public getCollaboratorImage(iconImageId: number, alternativeName: string) {
    this.imagesService
      .getImage({
        imageId: iconImageId,
        alternativeName,
      })
      .pipe(this.untilDestroyed())
      .subscribe((res: ExtendedImageContainer) => {
        this.collaboratorIconContainer = res;
      });
  }
}
