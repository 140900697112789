import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { ImageModel } from '../../model/image.model';
import { ExtendedImageContainer } from '../../model/imagecontainer.interface';
import { ResultModelGeneric } from '../../model/result.model';
import { IxupBaseService } from '../ixup-base.service';

@Injectable()
export class ImagesService extends IxupBaseService {
  localCaching: { [key: string]: Observable<ExtendedImageContainer> } = {};

  public collaboratorImageSubject = new Subject<ExtendedImageContainer>();
  public userImageSubject = new Subject<ExtendedImageContainer>();
  public organisationImageSubject = new Subject<ExtendedImageContainer>();
  public enablerImageSubject = new Subject<ExtendedImageContainer>();

  constructor(@Inject(HttpClient) private http: HttpClient) {
    super();
  }

  observeCollaboratorImage(): Observable<ExtendedImageContainer> {
    return this.collaboratorImageSubject.asObservable();
  }

  observeUserImage(): Observable<ExtendedImageContainer> {
    return this.userImageSubject.asObservable();
  }

  observeOrganisationImage(): Observable<ExtendedImageContainer> {
    return this.organisationImageSubject.asObservable();
  }

  observeEnablerImage(): Observable<ExtendedImageContainer> {
    return this.enablerImageSubject.asObservable();
  }

  triggerGetCollaboratorImage(imageId: number, alternativeName: string) {
    this.collaboratorImageSubject.next({
      imageId: imageId,
      alternativeName: alternativeName,
    });
  }

  triggerGetUserImage(imageId: number, alternativeName: string) {
    this.userImageSubject.next({
      imageId: imageId,
      alternativeName: alternativeName,
    });
  }

  triggerGetOrganisationImage(imageId: number, alternativeName: string) {
    this.organisationImageSubject.next({
      imageId: imageId,
      alternativeName: alternativeName,
    });
  }

  triggerGetEnablerImage(imageId: number, alternativeName: string) {
    this.enablerImageSubject.next({
      imageId: imageId,
      alternativeName: alternativeName,
    });
  }

  getImage(
    container: ExtendedImageContainer,
  ): Observable<ExtendedImageContainer> {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { image, imageId, expire } = container;

    if (!!image || !imageId) {
      return of(container);
    }

    if (this.localCaching[imageId] && !container.expire) {
      const cachedImage$ = this.localCaching[imageId];
      cachedImage$.subscribe({
        next: (i) => {
          container.expire = i.expire;
        },
        error: () => {
          console.log(
            `ImagesService.getImage(${imageId}) - Error getting image from local cache.`,
          );
        },
      });
    }

    if (!this.localCaching[imageId] || container.expire) {
      this.setImageInLocalStorage(imageId);
    }

    return this.localCaching[imageId];
  }

  getCustomerImage(imageId: number): Observable<ImageModel> {
    return (this.localCaching[imageId] = this.resolveError(
      this.http.get(`/api/Image/${imageId}`),
    ));
  }

  public saveSnapshotImage(model) {
    return this.resolveErrorGeneric(
      this.http.post('/api/Image/snapshot', model),
    );
  }

  setImageInLocalStorage(imageId: number) {
    this.localCaching[imageId] = this.resolveErrorMessage(
      this.http.get(`/api/Image/${imageId}`),
    ).pipe(
      map((result: ResultModelGeneric<ImageModel>) => {
        // why is this called so much?  Needs fixing
        return <ExtendedImageContainer>{
          image: result.data.image,
          expire: false,
        };
      }),
    );
  }

  public updateCustodianImage(
    newImage: string,
    imageId: number,
    custodian: number,
  ) {
    return this.resolveErrorGeneric(
      this.http.post('/api/collaborator/update/icon', {
        iconImageId: imageId,
        image: newImage,
        custodianId: custodian,
      }),
    );
  }

  public removeCachedImage(imageId: number) {
    if (this.localCaching[imageId]) {
      delete this.localCaching[imageId];
    }
  }
}
